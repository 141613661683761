import { Outlet } from 'react-router-dom';
import { DashboardLayout } from '../layouts/DashboardLayout';

export const DashboardPage = () => {
  return (
    <>
      <div className="hidden h-full md:block">
        <DashboardLayout>
          <Outlet />
        </DashboardLayout>
      </div>
      <div className="flex flex-col items-center justify-center h-full px-4 md:hidden">
        <p className="font-linear-sans mt-5 text-[22px] text-black font-semibold">
          Sorry, for a better experience, we kindly ask you to use a desktop or laptop device.
        </p>
      </div>
    </>
  );
};
