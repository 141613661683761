import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { CreateEventProps, Option } from 'src/common/types/event';

import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp } from 'src/common/hooks';
import { queryParams } from 'src/common/utils';
import { User } from 'src/common/types/user';

export const EventMeetingTab = ({ setCurrentStep, initialData, collectData }: CreateEventProps) => {
  const { accessToken, profile } = useContext(AuthContext);
  const [hostsOptions, setHostsOptions] = useState<Option[]>([]);
  const [startTimeOptions, setStartTimeOptions] = useState<Option[]>([]);
  const [endTimeOptions, setEndTimeOptions] = useState<Option[]>([]);
  const { sendRequest: getMembers, isLoading: isGettingMembers } = useHttp();

  const formik = useFormik({
    initialValues: initialData.meeting,
    validationSchema: Yup.object({
      date: Yup.date().required(),
      startTime: Yup.string()
        .required()
        .test('is-valid-start-time', 'Invalid start time', function (value) {
          const selectedDate: Date = this.resolve(Yup.ref('date'));
          const dateString = selectedDate.toISOString().slice(0, 10);

          // Convert the start time string to a Date object
          const startTime = new Date(`${dateString}T${value}`);

          if (isNaN(startTime.getTime()) || startTime < new Date()) {
            return false;
          }

          return true;
        }),
      endTime: Yup.string()
        .required()
        .test('is-valid-end-time', 'Invalid end time', function (value) {
          const selectedDate: Date = this.resolve(Yup.ref('date'));
          const dateString = selectedDate.toISOString().slice(0, 10);

          // Convert the end time string to a Date object
          const endTime = new Date(`${dateString}T${value}`);

          // Get the start time value from the form data
          const startTime = this.resolve(Yup.ref('startTime'));

          // Compare the end time with the start time
          if (startTime) {
            const startTimeObject = new Date(`${dateString}T${startTime}`);
            if (isNaN(endTime.getTime()) || endTime <= startTimeObject) {
              return false;
            }
          }

          return true;
        }),
      visibility: Yup.string().trim().required(),
      hosts: Yup.array().when('visibility', {
        is: 'private',
        then: Yup.array().min(1).required(),
        otherwise: Yup.array(),
      }),
    }),
    onSubmit: values => {
      const event = { ...initialData, meeting: values };
      collectData(event);
      setCurrentStep(3);
    },
  });

  useEffect(() => {
    if (profile) {
      getMembersHandler();
      setTimeOptions();
    }

    setTimeOptions();
  }, [accessToken, profile, formik.values.date]);

  const getMembersHandler = async () => {
    const params = {
      filter: {
        isApproved: true,
        skipCurrentUser: true,
      },
    };

    await getMembers(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        const members = data.data.map((user: User) => ({
          label: user?.name,
          value: user.id,
        }));

        setHostsOptions(members);
      },
    );
  };

  const setTimeOptions = () => {
    const now = new Date();
    const selectedDate = formik.values.date;
    const isToday = selectedDate.toISOString().slice(0, 10) === now.toISOString().slice(0, 10);

    const timeInterval = 10; // Change this interval value to your desired interval

    const startHour = isToday ? now.getHours() : 0;
    let startMinute = isToday ? Math.ceil(now.getMinutes() / timeInterval) * timeInterval : 0;

    const startTimeOptions = [];
    const endTimeOptions = [];

    const endHour = 23;

    for (let hours = startHour; hours <= endHour; hours++) {
      const maxMinutes =
        hours === startHour ? 60 : 60 - ((timeInterval - startMinute) % timeInterval);

      for (let minutes = startMinute; minutes < maxMinutes; minutes += timeInterval) {
        if (hours === endHour && minutes > 60 - timeInterval) {
          break;
        }

        const label = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        const value = label;
        startTimeOptions.push({ label, value });
        endTimeOptions.push({ label, value });
      }

      startMinute = 0; // Reset startMinute after the first hour
    }

    setStartTimeOptions(startTimeOptions);
    setEndTimeOptions(endTimeOptions);
  };

  return (
    <>
      <div className="max-w-[570px] w-full mx-auto mt-7">
        <div className="bg-white border border-[#E5E5E5] rounded-2xl p-14 text-black shadow">
          <h1 className="font-[sohne-breit] text-2xl mb-3.5">Date & Hosts</h1>
          <p className="mb-10 leading-snug text-black text-opacity-70">
            The power of reality and how people come together, collaborate, and communicate. It
            represents the third evolution of the internet.
          </p>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-7">
              <label className="space-y-2">
                <div className="font-bold">Event date</div>

                <DatePicker
                  selected={formik.values.date}
                  onChange={date => {
                    // Formik's handleChange expects an event object with a 'target' property,
                    // so we need to manually create a fake event object for Formik.
                    const fakeEvent = {
                      target: {
                        name: 'date',
                        value: date,
                      },
                    };
                    formik.handleChange(fakeEvent);
                  }}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Choose a date"
                  className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                    formik.touched.date && formik.errors.date
                      ? '!border-b-[#F72300] bg-[#FFF2F2]'
                      : '!border-b-[#005AFF]'
                  }`}
                />
              </label>

              <div className="flex gap-5">
                <label className="w-full space-y-2">
                  <div className="font-bold">Event start time</div>

                  <select
                    name="startTime"
                    value={formik.values.startTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full rounded-md h-[60px] p-4 border border-gray-300 focus:border-gray-300 text-sm ${
                      formik.touched.startTime && formik.errors.startTime
                        ? '!border-b-[#F72300] bg-[#FFF2F2]'
                        : '!border-b-[#005AFF]'
                    }`}
                  >
                    <option>Choose a time (HH:mm)</option>

                    {startTimeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>

                <label className="w-full space-y-2">
                  <div className="font-bold">Event end time</div>

                  <select
                    name="endTime"
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={`w-full rounded-md h-[60px] p-4 border border-gray-300 focus:border-gray-300 text-sm ${
                      formik.touched.endTime && formik.errors.endTime
                        ? '!border-b-[#F72300] bg-[#FFF2F2]'
                        : '!border-b-[#005AFF]'
                    }`}
                  >
                    <option>Choose a time (HH:mm)</option>

                    {endTimeOptions.map(option => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              <label className="space-y-2">
                <div className="font-bold">Event Visibility</div>

                <div className="flex">
                  <div
                    className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-l-lg ${
                      formik.values.visibility == 'public'
                        ? 'text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current font-bold'
                        : 'text-opacity-50'
                    }`}
                    onClick={() => formik.setFieldValue('visibility', 'public')}
                  >
                    Public
                  </div>

                  <div
                    className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-r-lg ${
                      formik.values.visibility == 'private'
                        ? 'text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current font-bold'
                        : 'text-opacity-50'
                    }`}
                    onClick={() => formik.setFieldValue('visibility', 'private')}
                  >
                    Private
                  </div>
                </div>
              </label>

              <label className="space-y-2">
                <div>
                  <div className="font-bold leading-none">Host (besides you)</div>
                  <span className="text-sm">A maximum of 16 hosts can be added.</span>
                </div>

                <Select
                  isDisabled={isGettingMembers}
                  value={formik.values.hosts}
                  name="hosts"
                  onChange={selectedOptions => {
                    // Formik's handleChange expects an event object with a 'target' property,
                    // so we need to manually create a fake event object for Formik.
                    const fakeEvent = {
                      target: {
                        name: 'hosts',
                        value: selectedOptions,
                      },
                    };
                    formik.handleChange(fakeEvent);
                  }}
                  options={hostsOptions}
                  isMulti
                  classNames={{
                    control: () =>
                      `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                        formik.errors.hosts && formik.touched.hosts
                          ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                          : ''
                      }`,
                    indicatorSeparator: () => '!my-0',
                  }}
                />
              </label>

              <button
                className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
                type="submit"
              >
                Save Data & Hosts
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
