import { Media } from 'src/common/types/media';
import { formatDateTime } from 'src/common/utils';

interface RecordingCardProps {
  media: Media;
}

export const RecordingCard = ({ media }: RecordingCardProps) => {
  const formatBytes = (sizeInBytes: number) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };

  return (
    <>
      <div className="bg-white min-h-[424px] w-[306px] p-5 rounded-[15px] flex flex-col justify-between">
        <div className="h-full">
          <div className="h-[140px] rounded-[15px] rounded-bl-none bg-blue-600 mb-[30px] flex items-center justify-center relative">
            <svg
              width="268"
              height="150"
              viewBox="0 0 268 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute inset-0"
            >
              <path
                d="M0 15C0 6.71573 6.71573 0 15 0H253C261.284 0 268 6.71573 268 15V125C268 133.284 261.284 140 253 140H0V15Z"
                fill="#0B1444"
              />
              <g opacity="0.2">
                <line x1="20.5" y1="63" x2="20.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="27.5" y1="60" x2="27.5" y2="80" stroke="white" strokeWidth="3" />
                <line x1="34.5" y1="63" x2="34.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="41.5" y1="55" x2="41.5" y2="85" stroke="white" strokeWidth="3" />
                <line x1="48.5" y1="50" x2="48.5" y2="90" stroke="white" strokeWidth="3" />
                <line x1="55.5" y1="60" x2="55.5" y2="80" stroke="white" strokeWidth="3" />
                <line x1="62.5" y1="54" x2="62.5" y2="86" stroke="white" strokeWidth="3" />
                <line x1="69.5" y1="62" x2="69.5" y2="78" stroke="white" strokeWidth="3" />
                <line x1="76.5" y1="64.5" x2="76.5" y2="75.5" stroke="white" strokeWidth="3" />
                <line x1="83.5" y1="67.5" x2="83.5" y2="72.5" stroke="white" strokeWidth="3" />
                <line x1="90.5" y1="57" x2="90.5" y2="83" stroke="white" strokeWidth="3" />
                <line x1="97.5" y1="50" x2="97.5" y2="90" stroke="white" strokeWidth="3" />
                <line x1="104.5" y1="54" x2="104.5" y2="86" stroke="white" strokeWidth="3" />
                <line x1="111.5" y1="63" x2="111.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="118.5" y1="67.5" x2="118.5" y2="72.5" stroke="white" strokeWidth="3" />
                <line x1="125.5" y1="59" x2="125.5" y2="81" stroke="white" strokeWidth="3" />
                <line x1="132.5" y1="50" x2="132.5" y2="90" stroke="white" strokeWidth="3" />
                <line x1="139.5" y1="63" x2="139.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="146.5" y1="60" x2="146.5" y2="80" stroke="white" strokeWidth="3" />
                <line x1="153.5" y1="63" x2="153.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="160.5" y1="55" x2="160.5" y2="85" stroke="white" strokeWidth="3" />
                <line x1="167.5" y1="50" x2="167.5" y2="90" stroke="white" strokeWidth="3" />
                <line x1="174.5" y1="60" x2="174.5" y2="80" stroke="white" strokeWidth="3" />
                <path d="M180 54L180 86" stroke="white" strokeWidth="3" />
                <line x1="188.5" y1="62" x2="188.5" y2="78" stroke="white" strokeWidth="3" />
                <line x1="195.5" y1="64.5" x2="195.5" y2="75.5" stroke="white" strokeWidth="3" />
                <line x1="202.5" y1="67.5" x2="202.5" y2="72.5" stroke="white" strokeWidth="3" />
                <line x1="209.5" y1="57" x2="209.5" y2="83" stroke="white" strokeWidth="3" />
                <line x1="216.5" y1="50" x2="216.5" y2="90" stroke="white" strokeWidth="3" />
                <line x1="223.5" y1="54" x2="223.5" y2="86" stroke="white" strokeWidth="3" />
                <line x1="230.5" y1="63" x2="230.5" y2="77" stroke="white" strokeWidth="3" />
                <line x1="237.5" y1="67.5" x2="237.5" y2="72.5" stroke="white" strokeWidth="3" />
                <line x1="244.5" y1="59" x2="244.5" y2="81" stroke="white" strokeWidth="3" />
                <line x1="251.5" y1="50" x2="251.5" y2="90" stroke="white" strokeWidth="3" />
              </g>
            </svg>
          </div>

          <div className="flex items-center gap-2 mb-5">
            <h4 className="text-[20px] font-bold truncate max-w-[160px]">{media.name}</h4>

            <span className="bg-[#E5E5E5] p-[5px] rounded-full text-[13px] leading-none">
              {formatBytes(media.size)}
            </span>
          </div>

          {media.event ? (
            <div className="rounded-xl bg-[#E5E5E566] p-[15px] relative mb-5">
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-[10px] top-[10px]"
              >
                <path
                  d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V14C24 18.4183 20.4183 22 16 22H8C3.58172 22 0 18.4183 0 14V8Z"
                  fill="#F7ED00"
                />
                <g opacity="0.7">
                  <g opacity="0.7">
                    <path
                      d="M13.3877 5.67188V7.33563"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3877 14.959V16.3505"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3877 12.5976V9.2832"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0266 16.5C18.3931 16.5 19.5 15.498 19.5 14.2609V12.4786C18.5957 12.4786 17.8675 11.8193 17.8675 11.0007C17.8675 10.1821 18.5957 9.52215 19.5 9.52215L19.4992 7.73909C19.4992 6.502 18.3916 5.5 17.0258 5.5H6.97419C5.60842 5.5 4.50078 6.502 4.50078 7.73909L4.5 9.58021C5.40426 9.58021 6.13251 10.1821 6.13251 11.0007C6.13251 11.8193 5.40426 12.4786 4.5 12.4786V14.2609C4.5 15.498 5.60685 16.5 6.9734 16.5H17.0266Z"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>

              <div className="text-xs text-[#000000B2] leading-none font-medium">
                {formatDateTime(media.event.createdAt)}
              </div>

              <div className="text-black font-medium text-[15px]">{media.event.title}</div>

              <div className="flex">
                {media.event.hosts.slice(0, 4).map(host => (
                  <div className="rounded-full h-[18px] w-[18px] -ml-2.5 first:ml-0" key={host.id}>
                    <img
                      className="w-full h-full rounded-full"
                      src="/assets/profile.png"
                      alt="Profile"
                    />
                  </div>
                ))}

                {media.event.hosts.length > 4 && (
                  <div className="rounded-full h-[18px] min-w-[18px] -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                    +{media.event.hosts.length - 4}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-sm text-[#000000B2]">{media.description}</div>
          )}
        </div>

        <div>
          <h6 className="text-[#000000B2] text-sm mb-5">
            Uploaded on {formatDateTime(media.createdAt)}
          </h6>

          {/* <button className="py-[14px] px-[17px] leading-none bg-[#2F3248] font-[sohne-breit] text-white rounded-lg text-[10px] tracking-wider block w-fit">
            See article
          </button> */}
        </div>
      </div>
    </>
  );
};
