import { useFormik } from 'formik';
import { EditSubAccountProps, Feature } from 'src/common/types/sub-account';
import { useState } from 'react';
import { ArticlesIcon, ClubsIcon, DashboardIcon, EventsIcon, MembersIcon } from 'src/assets/icons';
type FormValues = {
  features: string[];
};

export const EditAdminAccountAccessTab = ({
  initialData,
  features,
  setCurrentStep,
  disabled,
  collectData,
}: EditSubAccountProps) => {
  const [enabledMainFeatures, setEnabledMainFeatures] = useState<string[]>([]);

  const formik = useFormik<FormValues>({
    initialValues: {
      features: initialData.features,
    },
    onSubmit: values => {
      const data = {
        ...initialData,
        ...values,
      };

      collectData(data);
    },
  });

  const handleMainFeatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainFeature: Feature,
  ) => {
    if (event.target.checked) {
      setEnabledMainFeatures(prev => [...prev, mainFeature.name]);
    } else {
      const updatedMainFeatures = enabledMainFeatures.filter(feat => feat !== mainFeature.name);
      setEnabledMainFeatures(updatedMainFeatures);

      const subFeatureIds = mainFeature.subFeature.map(sf => sf.id);
      const newFeatures = formik.values.features.filter(id => !subFeatureIds.includes(id));
      formik.setFieldValue('features', newFeatures);
    }
  };

  const handleSubFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const subFeatureId = event.target.name;
    if (event.target.checked) {
      formik.setFieldValue('features', [...formik.values.features, subFeatureId]);
    } else {
      formik.setFieldValue(
        'features',
        formik.values.features.filter(id => id !== subFeatureId),
      );
    }
  };

  const getIcon = (name: string) => {
    switch (name) {
      case 'Dashboard & Activity feed':
        return <DashboardIcon />;
      case 'Members':
        return <MembersIcon />;
      case 'Clubs':
        return <ClubsIcon />;
      case 'Articles':
        return <ArticlesIcon />;
      case 'Events':
        return <EventsIcon />;
      default:
        return null;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        {features &&
          features.map((feature, featureKey) => {
            return (
              <div key={featureKey}>
                <div className="flex items-center justify-between text-black py-5 border-y border-[#E8E8E8]">
                  <div className="flex items-center gap-4 leading-none">
                    <span
                      className={`${
                        enabledMainFeatures.includes(feature.name)
                          ? 'text-[#F35800]'
                          : 'text-[#8293b1]'
                      }`}
                    >
                      {getIcon(feature.name)}
                    </span>

                    <span
                      className={`font-bold ${
                        enabledMainFeatures.includes(feature.name) ? '' : 'text-[#00000033]'
                      }`}
                    >
                      {feature.name}
                    </span>
                  </div>

                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      name={`features.${feature.name}`}
                      checked={enabledMainFeatures.includes(feature.name)}
                      onChange={e => handleMainFeatureChange(e, feature)}
                      className="sr-only peer"
                    />

                    <div className="w-[30px] h-[19px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                  </label>
                </div>

                <div>
                  {feature.subFeature.map((subFeature, subFeatureKey) => {
                    return (
                      <div
                        key={subFeatureKey}
                        className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]"
                      >
                        <div className="font-bold">{subFeature.name}</div>

                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            name={subFeature.id}
                            checked={formik.values.features.includes(subFeature.id)}
                            onChange={handleSubFeatureChange}
                            className="sr-only peer"
                            disabled={!enabledMainFeatures.includes(feature.name)}
                          />

                          <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>

      <div className="flex items-center gap-6 mt-4">
        <button
          className="px-[30px] py-5 text-xs text-center font-medium text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit] w-fit"
          disabled={disabled}
          type="submit"
        >
          Save access rights
        </button>

        <button className="text-black underline text-opacity-70" onClick={() => setCurrentStep(1)}>
          Back to the user details
        </button>
      </div>
    </form>
  );
};
