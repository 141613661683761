import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { User } from 'src/common/types/user';
import { queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { MemberSuggestionCard } from './MemberSuggestionCard';

export const ConnectionSuggestions = () => {
  const { accessToken, profile } = useContext(AuthContext);
  const [members, setMembers] = useState<User[]>([]);
  const [showAllMembers, setShowAllMembers] = useState(false);
  const notify = useNotification();

  const { sendRequest: getMembers, error: errorGettingMembers } = useHttp();

  useEffect(() => {
    if (accessToken) {
      profile && getMembersHandler();
    }

    if (errorGettingMembers) {
      notify(errorGettingMembers, 'error');
    }
  }, [accessToken, profile]);

  const getMembersHandler = async () => {
    const params = {
      filter: {
        isApproved: true,
        skipCurrentUser: true,
      },
    };

    await getMembers(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMembers(data.data);
      },
    );
  };

  const toggleShowAllMembers = () => {
    setShowAllMembers(!showAllMembers);
  };

  return (
    <div>
      <h4 className="text-[22px] font-bold mb-5">Connection suggestions</h4>

      <div className="flex flex-col gap-2.5 items-start">
        {members.slice(0, showAllMembers ? members.length : 4).map(member => (
          <MemberSuggestionCard member={member} key={member.id} />
        ))}

        {members.length > 4 && (
          <button
            className="text-sm font-bold text-black underline mt-2.5"
            onClick={toggleShowAllMembers}
          >
            {showAllMembers ? 'Show less suggestions' : 'See all suggestions'}
          </button>
        )}
      </div>
    </div>
  );
};
