import { useContext, useEffect, useState } from 'react';
import { MemberCard } from './MemberCard';
import { InvitePopup } from '../popups/InvitePopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { User } from 'src/common/types/user';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { queryParams } from 'src/common/utils';
import { LoadingSpinner } from '../../common/components/loading-spinner/LoadingSpinner';

import Select from 'react-select';
import { Option } from 'src/common/types/event';
import { Skill } from 'src/common/types/skill';
import { Pagination } from 'src/components/pagination/Pagination';

const orderByOptions = [
  { label: 'Joined Date', value: 'createdAt' },
  { label: 'Name', value: 'name' },
  { label: 'Email', value: 'email' },
];

export const Members = () => {
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const notify = useNotification();
  const [members, setMembers] = useState<User[]>([]);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [skillsOptions, setSkillsOptions] = useState<Option[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const {
    sendRequest: getMembers,
    isLoading: isGettingMembers,
    error: errorGettingMembers,
  } = useHttp();

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [orderBySkillValue, setOrderBySkillValue] = useState<Option | null>({
    value: 'all',
    label: 'All',
  });

  const { sendRequest: getSkills, isLoading: isGettingSkills } = useHttp();

  useEffect(() => {
    if (accessToken) {
      profile && getMembersHandler();
      getSkillsHandler();
    }
  }, [accessToken, profile, orderBySkillValue, orderByValue, currentPage]);

  useEffect(() => {
    if (errorGettingMembers) {
      notify(errorGettingMembers, 'error');
    }
  }, [errorGettingMembers]);

  const getMembersHandler = async () => {
    let skill = '';

    if (orderBySkillValue?.value !== 'all') {
      skill = orderBySkillValue?.value as string;
    }

    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        isApproved: true,
        skill,
        skipCurrentUser: true,
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
      offset,
      limit: perPage,
    };

    await getMembers(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMembers(data.data);
        setTotalCount(data.total);
      },
    );
  };

  const getSkillsHandler = async () => {
    await getSkills(
      {
        url: 'skills',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedSkills = res.map((skill: Skill) => ({
          value: skill.id,
          label: skill.name,
        }));

        const allOption = { value: 'all', label: 'All' };
        setSkillsOptions([allOption, ...transformedSkills]);
      },
    );
  };

  const openInvitePopup = () => {
    setShowInvitePopup(true);
  };

  const closeInvitePopup = () => {
    setShowInvitePopup(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const sendInvitation =
    appConfig?.parentId === null ||
    appConfig?.features['Members'].subFeatures.includes('Send invitations');

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
            COMMUNITY
          </span>
          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Members</h1>
        </div>

        {sendInvitation && (
          <button
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            onClick={openInvitePopup}
          >
            Invite Someone
          </button>
        )}
      </div>

      {showInvitePopup && <InvitePopup onClose={closeInvitePopup} />}

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Skills</span>

            <Select
              value={orderBySkillValue}
              onChange={selectedOptions => {
                setOrderBySkillValue(selectedOptions);
              }}
              isSearchable={false}
              options={skillsOptions}
              isDisabled={isGettingSkills}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>

            <Select
              value={orderByValue}
              onChange={selectedOptions => {
                setOrderByValue(selectedOptions);
              }}
              options={orderByOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="py-3 px-4 bg-[#FFEF96] w-fit bg-opacity-10 border border-[#FFEF96] rounded-md text-[15px] mb-5">
        <span> Showing 8 members using one filter (position)</span>

        <button className="underline ml-4 text-[#614600]">Clear filters</button>
      </div> */}

      {isGettingMembers && <LoadingSpinner />}

      <div className="flex flex-wrap gap-3 transition-all">
        {members.map(member => (
          <MemberCard member={member} key={member.id} />
        ))}
      </div>

      {members.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!members.length ? <div className={`text-center block p-8`}>No records found</div> : null}
    </>
  );
};
