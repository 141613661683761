export default function ClubAdminTab() {
  return (
    <>
      {/* <div className="py-[40px]">
        <h3 className="text-[22px] font-bold font-[sohne-breit] text-black mb-5 leading-none">
          Article suggestions
        </h3>

        <div className="bg-white rounded-lg p-[30px] flex justify-between items-center border border-[#E5E5E5] shadow-md">
          <div>
            <h3 className="underline font-bold mb-[17px] text-[19px] text-black leading-none">
              How to invest your first 100k
            </h3>

            <p className="text-sm text-black text-opacity-70">
              Suggested by <span className="underline">John Malkovitch</span> on August 12, 2023
              2:34pm
            </p>
          </div>

          <div className="flex gap-[47px] items-center">
            <div className="flex">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>

            <div className="flex gap-[5px]">
              <button className="bg-[#5AA0001A] rounded-lg text-[#213A00] p-[10px] text-[13px] leading-none">
                Accept
              </button>

              <button className="bg-[#C51C001A] rounded-lg text-[#600E00] p-[10px] text-[13px] leading-none">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
