import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { useContext, useEffect, useState } from 'react';
import { AboutMeTab } from './AboutMeTab';
import { AboutCompanyTab } from './AboutCompanyTab';
import { SocialAccountsTab } from './SocialAccountsTab';
import { AuthContext } from 'src/contexts';
import { Skill } from 'src/common/types/skill';
import { getInitials } from 'src/common/utils';
import { ProfilePicturePopup } from '../popups/ProfilePicturePopup';
import { Link, useLocation } from 'react-router-dom';
import { useNotification } from 'src/common/hooks';
import { ToastContainer } from 'react-toastify';
import { DeleteUserPopup } from '../popups/DeleteUserPopup';

export const Profile = () => {
  const { profile, appConfig, logout } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('about-me');
  const [showProfilePicturePopup, setShowProfilePicturePopup] = useState(false);
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
  const notify = useNotification();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get('error');
    const successParam = searchParams.get('success');

    if (errorParam) {
      notify(decodeURIComponent(errorParam), 'error');
    }

    if (successParam) {
      notify(decodeURIComponent(successParam), 'success');
    }
  }, [location.search]);

  const tabs = [
    {
      key: 'about-me',
      label: 'About me',
    },
    {
      key: 'about-company',
      label: 'About my company',
    },
    {
      key: 'social-accounts',
      label: 'Social Accounts',
    },
  ];

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex flex-col gap-4">
        <div className="flex justify-center text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight">
          MY PROFILE
        </div>

        <div className="flex justify-center">
          <div className="rounded-full h-[70px] w-[70px] relative flex items-center justify-center bg-blue-200 text-2xl font-bold">
            {profile?.avatar ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={profile.avatar}
                  className="object-cover w-full h-full rounded-full"
                  alt="Profile Avatar"
                />
              </div>
            ) : (
              <div>{getInitials(profile?.name)}</div>
            )}

            <button onClick={() => setShowProfilePicturePopup(true)}>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 bottom-0 my-auto -right-3"
              >
                <path
                  d="M0 8C0 3.58172 3.58172 0 8 0H18C22.4183 0 26 3.58172 26 8V18C26 22.4183 22.4183 26 18 26H8C3.58172 26 0 22.4183 0 18V8Z"
                  fill="#E5E5E5"
                />
                <path
                  d="M16.8816 11.3809L9.32238 18.4018L6.5 19L7.15158 16.3957L14.7554 9.38672L16.8816 11.3809Z"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15.593 8.60248L17.3291 7L19.4999 9.0029L17.7638 10.6062"
                  stroke="black"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>

        {showProfilePicturePopup && (
          <ProfilePicturePopup onClose={() => setShowProfilePicturePopup(false)} />
        )}

        {showDeleteAccountPopup && (
          <DeleteUserPopup
            userId={profile?.id}
            onClose={() => setShowDeleteAccountPopup(false)}
            onDelete={logout}
          />
        )}

        <div className="flex justify-center md:max-w-[70%] relative mx-auto">
          <h1 className="text-4xl font-semibold leading-none font-[sohne-breit] tracking-wide text-center">
            {profile?.name ?? 'Michelle Young'}
          </h1>

          <span className="font-[sohne-breit] text-white text-xs py-0.5 px-1.5 rounded-sm bg-[#CD9400] absolute -right-16 top-2.5">
            Gold
          </span>
        </div>

        <div className="flex items-center justify-center gap-6">
          <div className="text-lg text-black font-extralight text-opacity-70">
            {profile?.company?.role}
          </div>

          <div className="flex items-center gap-1">
            {profile?.skills?.map((skill: Skill) => (
              <div
                className="border py-1.5 px-2.5 text-xs rounded-lg leading-none text-[#234685] border-[#325EAE33] border-opacity-20 bg-white"
                key={skill?.id}
              >
                {skill?.name}
              </div>
            ))}
          </div>

          <Link
            to={'/members/' + profile?.id}
            className="pl-6 border-l border-[#D6D6D6] underline text-black text-opacity-70"
          >
            View as others
          </Link>

          <div className="pl-6 border-l border-[#D6D6D6]">
            <button
              className=" px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#C51C001A] text-[#600E00]"
              onClick={() => setShowDeleteAccountPopup(true)}
            >
              Delete account
            </button>
          </div>
        </div>

        <div className="max-w-[570px] w-full mx-auto mt-2">
          <Tabs value={activeTab}>
            <TabsHeader
              placeholder="Empty"
              className="items-center justify-center gap-10 p-0 bg-transparent rounded-none"
              indicatorProps={{
                className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
              }}
            >
              {tabs.map(({ key, label }) => (
                <Tab
                  placeholder="Empty"
                  key={key}
                  value={key}
                  onClick={() => setActiveTab(key)}
                  className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black ${
                    activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                  }`}
                >
                  <div className="flex items-center gap-1">
                    <span>{label}</span>
                    {key === 'social-accounts' && (
                      <span className="text-[11px] text-black text-opacity-100 bg-[#E5E5E5] font-medium px-2 h-[17px] inline-flex justify-center items-center rounded-md">
                        {appConfig?.connectedAccounts.length} / 2
                      </span>
                    )}
                  </div>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody placeholder="Empty">
              {tabs.map(({ key }) => (
                <TabPanel key={key} value={key} className="p-0">
                  <TabContent activeTab={key} />
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      </div>
    </>
  );
};
const TabContent = ({ activeTab }: { activeTab: string }) => {
  switch (activeTab) {
    case 'about-me':
      return <AboutMeTab />;
    case 'about-company':
      return <AboutCompanyTab />;
    case 'social-accounts':
      return <SocialAccountsTab />;
    default:
      return null; // or a default component when none of the tabs match
  }
};
