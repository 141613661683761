import { useState } from 'react';
import { StepStatus } from 'src/common/components/StepStatus';
import ForwardArrowSvg from 'src/common/components/svg/ArrowRight';

import { CreateClubData } from 'src/common/types/clubs';
import { ClubInfoTab } from './ClubInfoTab';
import ClubMembersTab from './ClubMembersTab';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function CreateClub() {
  const [currentStep, setCurrentStep] = useState(1);
  const [event, setEvent] = useState<CreateClubData>({
    info: {
      title: '',
      description: '',
      topics: [],
    },
    members: [],
  });

  const navigate = useNavigate();

  const stepsAppearance = {
    default: {
      textClasses: 'text-black text-opacity-50',
      backgroundClasses: '',
      borderClasses: 'border border-black border-opacity-50',
    },
    active: {
      textClasses: 'text-black',
      backgroundClasses: 'bg-[#E5E5E5]',
      borderClasses: '',
    },
    completed: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-[#69BB00]',
      borderClasses: '',
    },
  };

  const moveToStep = (step: number) => {
    if (step > currentStep) {
      return;
    }

    setCurrentStep(step);
  };

  const collectData = (event: CreateClubData) => {
    setEvent(event);
  };

  return (
    <>
      <ToastContainer autoClose={2000} />

      <div className="flex items-end justify-between mb-12">
        <div className="relative">
          <button
            className="h-[30px] w-[30px] rounded-lg flex items-center justify-center bg-[#E5E5E5] absolute top-7 -left-11"
            onClick={() => navigate(-1)}
          >
            <svg
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M14 6.00016H2M2 6.00016L6.64516 10.6668M2 6.00016L6.64516 1.3335"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>

          <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
            COMMUNITY
          </span>

          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Create club</h1>
        </div>
      </div>

      <div className="flex items-center gap-4 mx-auto my-10 w-fit font-[sohne]">
        <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(1)}>
          <StepStatus stepNumber={1} currentStep={currentStep} appearance={stepsAppearance} />

          <span className="font-normal text-gray-500">General details</span>
        </div>

        <ForwardArrowSvg className="w-5 h-5 text-black text-opacity-50" />

        <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(2)}>
          <StepStatus stepNumber={2} currentStep={currentStep} appearance={stepsAppearance} />

          <span className="font-normal text-gray-500">Invite members</span>
        </div>
      </div>

      {currentStep === 1 && (
        <ClubInfoTab
          setCurrentStep={setCurrentStep}
          initialData={event}
          collectData={collectData}
        />
      )}
      {currentStep === 2 && (
        <ClubMembersTab
          setCurrentStep={setCurrentStep}
          initialData={event}
          collectData={collectData}
        />
      )}
    </>
  );
}
