import { useFormik } from 'formik';
import { CreateSubAccountProps, Feature } from 'src/common/types/sub-account';
import { useState } from 'react';
import { DashboardIcon, MembersIcon, ArticlesIcon, EventsIcon, ClubsIcon } from 'src/assets/icons';
type FormValues = {
  features: string[];
};

export const SubAccountAccessTab = ({
  initialData,
  features,
  setCurrentStep,
  disabled,
  collectData,
}: CreateSubAccountProps) => {
  const [enabledMainFeatures, setEnabledMainFeatures] = useState<string[]>([]);

  const formik = useFormik<FormValues>({
    initialValues: {
      features: initialData.features,
    },
    onSubmit: values => {
      const data = {
        ...initialData,
        ...values,
      };

      collectData(data);
    },
  });

  const handleMainFeatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainFeature: Feature,
  ) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      // Add the main feature to the list of enabled main features
      setEnabledMainFeatures(prev => [...prev, mainFeature.name]);

      // Enable all sub-features of the main feature
      const subFeatureIds = mainFeature.subFeature.map(sf => sf.id);
      formik.setFieldValue('features', [...formik.values.features, ...subFeatureIds]);
    } else {
      // Remove the main feature from the list of enabled main features
      const updatedMainFeatures = enabledMainFeatures.filter(feat => feat !== mainFeature.name);
      setEnabledMainFeatures(updatedMainFeatures);

      // Disable all sub-features of the main feature
      const subFeatureIds = mainFeature.subFeature.map(sf => sf.id);
      const newFeatures = formik.values.features.filter(id => !subFeatureIds.includes(id));
      formik.setFieldValue('features', newFeatures);
    }
  };

  const handleSubFeatureChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    mainFeature: Feature,
  ) => {
    const subFeatureId = event.target.name;
    const isChecked = event.target.checked;

    if (isChecked) {
      // If sub-feature is checked, add the sub-feature ID
      formik.setFieldValue('features', [...formik.values.features, subFeatureId]);

      // Ensure the main feature is also enabled
      if (!enabledMainFeatures.includes(mainFeature.name)) {
        setEnabledMainFeatures(prev => [...prev, mainFeature.name]);
      }
    } else {
      // If sub-feature is unchecked, remove only the sub-feature ID
      formik.setFieldValue(
        'features',
        formik.values.features.filter(id => id !== subFeatureId),
      );

      // Check if any other sub-features of this main feature are still checked. If none are, disable the main feature.
      const areAnySubFeaturesStillChecked = mainFeature.subFeature.some(sub =>
        formik.values.features.includes(sub.id),
      );
      if (!areAnySubFeaturesStillChecked) {
        setEnabledMainFeatures(prev => prev.filter(feat => feat !== mainFeature.name));
      }
    }
  };

  const getIcon = (name: string) => {
    switch (name) {
      case 'Dashboard & Activity feed':
        return <DashboardIcon />;
      case 'Members':
        return <MembersIcon />;
      case 'Clubs':
        return <ClubsIcon />;
      case 'Articles':
        return <ArticlesIcon />;
      case 'Events':
        return <EventsIcon />;
      default:
        return null;
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        {features &&
          features.map((feature, featureKey) => {
            return (
              <div key={featureKey}>
                <div className="flex items-center justify-between text-black py-5 border-y border-[#E8E8E8]">
                  <div className="flex items-center gap-4 leading-none">
                    <span
                      className={`${
                        enabledMainFeatures.includes(feature.name)
                          ? 'text-[#F35800]'
                          : 'text-[#8293b1]'
                      }`}
                    >
                      {getIcon(feature.name)}
                    </span>

                    <span
                      className={`font-bold ${
                        enabledMainFeatures.includes(feature.name) ? '' : 'text-[#00000033]'
                      }`}
                    >
                      {feature.name}
                    </span>
                  </div>

                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      name={`features.${feature.name}`}
                      checked={enabledMainFeatures.includes(feature.name)}
                      onChange={e => handleMainFeatureChange(e, feature)}
                      className="sr-only peer"
                    />

                    <div className="w-[30px] h-[19px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                  </label>
                </div>

                <div>
                  {enabledMainFeatures.includes(feature.name) &&
                    feature.subFeature.map((subFeature, subFeatureKey) => {
                      return (
                        <div
                          key={subFeatureKey}
                          className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]"
                        >
                          <div
                            className={`font-bold ${
                              formik.values.features.includes(subFeature.id)
                                ? ''
                                : 'text-[#00000033]'
                            }`}
                          >
                            {subFeature.name}
                          </div>

                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              name={subFeature.id}
                              checked={formik.values.features.includes(subFeature.id)}
                              onChange={e => handleSubFeatureChange(e, feature)}
                              className="sr-only peer"
                            />

                            <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                          </label>
                        </div>
                      );
                    })}

                  {/* <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
                    <div className="font-bold">Use chat</div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        name="members.features.useChat"
                        checked={formik.values.members.features.useChat}
                        type="checkbox"
                        onChange={formik.handleChange}
                        className="sr-only peer"
                      />

                      <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                    </label>
                  </div>

                  <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
                    <div className="font-bold">Use meetings</div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        name="members.features.useMeetings"
                        checked={formik.values.members.features.useMeetings}
                        type="checkbox"
                        onChange={formik.handleChange}
                        className="sr-only peer"
                      />

                      <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                    </label>
                  </div>

                  <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
                    <div className="font-bold">Use circles</div>

                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        name="members.features.useCircles"
                        checked={formik.values.members.features.useCircles}
                        type="checkbox"
                        onChange={formik.handleChange}
                        className="sr-only peer"
                      />

                      <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
                    </label>
                  </div> */}
                </div>
              </div>
            );
          })}

        {/* <div>
          <div className="flex items-center justify-between text-black py-5 border-y border-[#E8E8E8]">
            <div className="flex items-center gap-4 leading-none">
              <svg
                width="22"
                height="15"
                viewBox="0 0 22 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M17.4429 4.32949C18.0158 3.5673 18.5848 3.26348 19.1377 3.42339C20.0312 3.68989 20.4319 5.09169 20.476 5.25159C20.492 5.31022 20.5 5.36885 20.5 5.43282V9.37173C20.5 10.8162 19.6145 11.9941 18.5287 11.9941H4.05631C2.64593 11.9941 1.5 10.4697 1.5 8.59354V5.19296C1.5 5.11301 1.51603 5.02773 1.54007 4.95311C1.59616 4.80387 2.10502 3.50334 3.00654 3.38074C3.56748 3.30612 4.1124 3.70055 4.61725 4.56402M6.30409 11.9835V13.9822M15.72 11.9835V13.9822M4.7054 7.4156H17.3267V3.08759C17.3267 1.94696 16.6295 1.01953 15.772 1.01953H6.07571C5.32244 1.01953 4.7054 1.83503 4.7054 2.84241V7.4156Z"
                  stroke="#F35800"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="font-bold">Clubs</span>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="clubs.allowed"
                checked={formik.values.clubs.allowed}
                onChange={formik.handleChange}
                className="sr-only peer"
              />

              <div className="w-[30px] h-[19px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>

          <div>
            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Create and join clubs</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="clubs.features.createAndJoinClubs"
                  checked={formik.values.clubs.features.createAndJoinClubs}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>

            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Participate in joined clubs</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="clubs.features.participateInJoinedClubs"
                  checked={formik.values.clubs.features.participateInJoinedClubs}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between text-black py-5 border-y border-[#E8E8E8]">
            <div className="flex items-center gap-4 leading-none">
              <svg
                width="22"
                height="17"
                viewBox="0 0 22 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.7">
                  <path
                    d="M12.7572 1.71875V3.83625"
                    stroke="#F35800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.7572 13.5391V15.3101"
                    stroke="#F35800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.7572 10.5348V6.31641"
                    stroke="#F35800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.367 15.5C19.098 15.5 20.5 14.2247 20.5 12.6503V10.3818C19.3546 10.3818 18.4322 9.54274 18.4322 8.5009C18.4322 7.45906 19.3546 6.61909 20.5 6.61909L20.499 4.34975C20.499 2.77527 19.096 1.5 17.366 1.5H4.63397C2.90399 1.5 1.50099 2.77527 1.50099 4.34975L1.5 6.693C2.64539 6.693 3.56785 7.45906 3.56785 8.5009C3.56785 9.54274 2.64539 10.3818 1.5 10.3818V12.6503C1.5 14.2247 2.90201 15.5 4.63298 15.5H17.367Z"
                    stroke="#F35800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>

              <span className="font-bold">Events</span>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="events.allowed"
                checked={formik.values.events.allowed}
                onChange={formik.handleChange}
                className="sr-only peer"
              />

              <div className="w-[30px] h-[19px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>

          <div>
            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Create and join events</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="events.features.createAndJoinEvents"
                  checked={formik.values.events.features.createAndJoinEvents}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>

            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Register to new events</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="events.features.registerToNewEvents"
                  checked={formik.values.events.features.registerToNewEvents}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between text-black py-5 border-y border-[#E8E8E8]">
            <div className="flex items-center gap-4 leading-none">
              <svg
                width="16"
                height="21"
                viewBox="0 0 16 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15 5.08574C15 2.5261 13.3055 1.5 10.8659 1.5H5.13677C2.77213 1.5 1 2.45614 1 4.91504V18.6153C1 19.2907 1.70361 19.716 2.27355 19.3858L8.02348 16.0547L13.7237 19.3802C14.2946 19.7123 15 19.2869 15 18.6106V5.08574Z"
                  stroke="#F35800"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="font-bold">Articles</span>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="articles.allowed"
                checked={formik.values.articles.allowed}
                onChange={formik.handleChange}
                className="sr-only peer"
              />

              <div className="w-[30px] h-[19px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[15px] after:w-[15px] after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>

          <div>
            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Create and edit articles</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="articles.features.createAndEditArticles"
                  checked={formik.values.articles.features.createAndEditArticles}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>

            <div className="flex items-center justify-between text-black bg-[#E8E8E8] pl-[15px] border-l border-l-[#F35800] py-4 border-y border-y-[#D6D6D6] first:border-t-0 pr-[5px]">
              <div className="font-bold">Publish and un publish articles</div>

              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  name="articles.features.publishAndUnPublishArticles"
                  checked={formik.values.articles.features.publishAndUnPublishArticles}
                  type="checkbox"
                  onChange={formik.handleChange}
                  className="sr-only peer"
                />

                <div className="w-[22px] h-[15px] bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:absolute after:inset-y-0 after:my-auto after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-[11px] after:w-[11px] after:transition-all peer-checked:bg-[#F3580033]"></div>
              </label>
            </div>
          </div>
        </div> */}
      </div>

      <div className="flex items-center gap-6 mt-4">
        <button
          className="px-[30px] py-5 text-xs text-center font-medium text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit] w-fit"
          disabled={disabled}
          type="submit"
        >
          Save access rights
        </button>

        <button className="text-black underline text-opacity-70" onClick={() => setCurrentStep(1)}>
          Back to the user details
        </button>
      </div>
    </form>
  );
};
