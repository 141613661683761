import BackArrowSvg from 'src/common/components/svg/ArrowLeft';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SignupProfessionProps } from 'src/common/types/signup';

export const SignupProfession = ({
  setCurrentStep,
  initialData,
  collectSurvey,
}: SignupProfessionProps) => {
  const formik = useFormik({
    initialValues: initialData.profession,
    validationSchema: Yup.object({
      reasonForMembership: Yup.string().trim().required(),
    }),
    onSubmit: values => {
      const survey = { ...initialData, profession: values };
      collectSurvey(survey);
    },
  });
  return (
    <div className="w-[520px] mx-auto bg-white rounded-lg text-black py-14 px-12">
      <h2 className="mb-8 text-3xl font-medium text-center font-[sohne-breit]">
        Your professional Background
      </h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-7">
          <label className="space-y-2">
            <div className="font-bold">Why you want to become a member?</div>

            <textarea
              name="reasonForMembership"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.reasonForMembership}
              placeholder="A few words about why you think TMRW100 is for you..."
              className={`w-full rounded-md min-h-[120px] p-4 border-gray-300 focus:border-gray-300 ${
                formik.touched.reasonForMembership && formik.errors.reasonForMembership
                  ? '!border-b-[#F72300] bg-[#FFF2F2]'
                  : '!border-b-[#005AFF]'
              }`}
            ></textarea>
          </label>

          <button
            type="submit"
            className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
          >
            Apply for membership
          </button>
        </div>
      </form>

      <button
        className="w-full flex items-center justify-center gap-2.5 text-black text-opacity-80 mt-2"
        onClick={() => setCurrentStep(2)}
      >
        <BackArrowSvg className="w-5 h-5 " />
        <span className="underline">Go back to the previous step</span>
      </button>
    </div>
  );
};
