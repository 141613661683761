import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { useContext, useEffect, useState } from 'react';
import { GeneralTab } from './MemberGeneralTab';
import { MemberClubsTab } from './MemberClubsTab';
import { EventsTab } from './MemberEventsTab';
import { ActivityTab } from './MemberActivityTab';
import { BlogsTab } from './MemberBlogsTab';
import { AuthContext } from 'src/contexts';
import { User } from 'src/common/types/user';
import { useHttp } from 'src/common/hooks';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { AddMemberToCirclePopup } from '../popups/AddMemberToCirclePopup';
import { MemberMeetingPopup } from '../popups/MemberMeetingPopup';
import { getInitials } from 'src/common/utils';

export const MemberDetail = () => {
  const { id } = useParams();
  const [member, setMember] = useState<User>();

  const [eventsCount, setEventsCount] = useState<number>(0);
  const [clubsCount, setClubsCount] = useState<number>(0);
  const [activityCount, setActivityCount] = useState<number>(0);
  const [blogCount, setBlogCount] = useState<number>(0);

  const { accessToken } = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState('general');

  const [showAddToCirclePopup, setShowAddToCirclePopup] = useState(false);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);

  const { sendRequest: getMember, isLoading: isGettingMember } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getMemberHandler();
    }
  }, [accessToken]);

  const getMemberHandler = async () => {
    await getMember(
      {
        url: 'user/' + id,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMember(data);
      },
    );
  };

  const tabs = [
    {
      key: 'general',
      label: 'General',
    },
    {
      key: 'clubs',
      label: 'Clubs',
    },
    {
      key: 'events',
      label: 'Events',
    },
    {
      key: 'activity',
      label: 'Activity Feed',
    },
    {
      key: 'blog',
      label: 'Blog',
    },
  ];

  const addToCircleHandler = () => {
    setShowAddToCirclePopup(true);
  };

  const TabContent = ({ activeTab, member }: { activeTab: string; member: User }) => {
    switch (activeTab) {
      case 'general':
        return <GeneralTab member={member} />;
      case 'clubs':
        return <MemberClubsTab member={member} setCount={setClubsCount} />;
      case 'events':
        return <EventsTab member={member} setCount={setEventsCount} />;
      case 'activity':
        return <ActivityTab member={member} setCount={setActivityCount} />;
      case 'blog':
        return <BlogsTab member={member} setCount={setBlogCount} />;
      default:
        return null; // or a default component when none of the tabs match
    }
  };

  return (
    <>
      {member && (
        <div className="max-w-[1300px] w-4/5 mx-auto relative">
          <div className="absolute flex items-center justify-center w-16 h-16 text-lg font-bold bg-blue-400 rounded-full top-6 -left-20">
            {member.avatar ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={member.avatar}
                  className="object-cover w-full h-full rounded-full"
                  alt="Profile Avatar"
                />
              </div>
            ) : (
              <div>{getInitials(member.name)}</div>
            )}
          </div>

          <div className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight">
            COMMUNITY / MEMBERS
          </div>

          <div className="flex items-start justify-between mt-3 mb-10">
            <div className="flex flex-col gap-4">
              <div>
                <div className="flex items-start gap-4">
                  <h1 className="text-4xl font-semibold leading-none font-[sohne-breit] tracking-wide max-w-[480px]">
                    {member.name ?? 'Michelle Young'}
                  </h1>

                  <span className="font-[sohne-breit] text-white text-[11px] py-0.5 px-1.5 rounded-sm bg-[#CD9400] relative top-3">
                    Gold
                  </span>
                </div>

                <div className="font-extralight text-lg mt-1.5 text-black text-opacity-70">
                  {member?.company?.role}
                </div>
              </div>

              <div className="flex gap-1">
                {member?.skills?.map((skill, index) => (
                  <div
                    className="border py-1.5 px-2.5 text-xs rounded-lg leading-none text-[#234685] border-[#325EAE33] border-opacity-20 bg-white"
                    key={index}
                  >
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-5">
              <button>
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M18.0714 18.0699C15.0152 21.1263 10.4898 21.7867 6.78642 20.074C6.23971 19.8539 5.79148 19.676 5.36537 19.676C4.17849 19.683 2.70117 20.8339 1.93336 20.067C1.16555 19.2991 2.31726 17.8206 2.31726 16.6266C2.31726 16.2004 2.14642 15.7602 1.92632 15.2124C0.212831 11.5096 0.874109 6.98269 3.93026 3.92721C7.8316 0.0244319 14.17 0.0244322 18.0714 3.9262C21.9797 7.83501 21.9727 14.1681 18.0714 18.0699Z"
                      stroke="#00369A"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>

              <button onClick={() => setShowMeetingPopup(true)}>
                <svg
                  width="22"
                  height="26"
                  viewBox="0 0 22 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 24.4498L1.04 18.7798L1 7.1998M11 24.4498V12.8498M11 24.4498L20.96 18.7798L21 7.1998M1 7.1998L11 12.8498M1 7.1998L11 1.5498M11 12.8498L21 7.1998M21 7.1998L11.01 1.5498"
                    stroke="#00369A"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <button
                className="px-4 py-[12px] text-[11px] text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
                onClick={() => addToCircleHandler()}
              >
                Add to my circles
              </button>
            </div>
          </div>

          {showAddToCirclePopup && (
            <AddMemberToCirclePopup
              onClose={() => setShowAddToCirclePopup(false)}
              member={member}
            />
          )}

          {showMeetingPopup && (
            <MemberMeetingPopup member={member} onClose={() => setShowMeetingPopup(false)} />
          )}

          {/* <div className="flex items-center mt-5 mb-10">
            <div className="relative">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
              <div className="absolute top-0 rounded-full h-7 w-7 left-5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
              <div className="absolute top-0 rounded-full h-7 w-7 left-10">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>
            <div className="ml-12 text-black text-opacity-70">
              <span className="underline">Alex Jones</span>,{' '}
              <span className="underline">Natasha Michaelson</span> and{' '}
              <span className="underline">13 others</span> are mutual connections
            </div>
          </div> */}

          <Tabs value={activeTab}>
            <TabsHeader
              placeholder="Empty"
              className="items-center gap-10 p-0 bg-transparent rounded-none"
              indicatorProps={{
                className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
              }}
            >
              {tabs.map(({ key, label }) => (
                <Tab
                  placeholder="Empty"
                  key={key}
                  value={key}
                  onClick={() => setActiveTab(key)}
                  className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black ${
                    activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                  }`}
                >
                  <div className="flex items-center gap-1">
                    <span>{label}</span>

                    {key !== 'general' && (
                      <span className="text-[10px] text-black text-opacity-100 bg-[#E5E5E5] font-medium w-4 h-4 inline-flex justify-center items-center rounded-md">
                        {key === 'activity' && activityCount}
                        {key === 'events' && eventsCount}
                        {key === 'clubs' && clubsCount}
                        {key === 'blog' && blogCount}
                      </span>
                    )}
                  </div>
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody placeholder="Empty">
              {tabs.map(({ key }) => (
                <TabPanel key={key} value={key} className="p-0">
                  <TabContent activeTab={key} member={member} />
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      )}

      {isGettingMember && <LoadingSpinner />}
    </>
  );
};
