import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { Blog } from 'src/common/types/blog';
import { User } from 'src/common/types/user';
import { formatDateTime, queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

interface BlogsTabProps {
  member?: User;
  setCount: (count: number) => void;
}
export const BlogsTab = ({ member, setCount }: BlogsTabProps) => {
  const { accessToken } = useContext(AuthContext);
  const { sendRequest: getBlogs } = useHttp();
  const [blogs, setBlogs] = useState<Blog[]>([]);

  useEffect(() => {
    if (accessToken) {
      getBlogsHandler();
    }
  }, [accessToken]);

  const getBlogsHandler = async () => {
    const params = {
      filter: {
        userId: member?.directusUserId,
      },
    };

    await getBlogs(
      {
        url: 'directus/articles?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setBlogs(res.data);
        setCount(res.data.length);
      },
    );
  };

  return (
    <>
      {!blogs.length && <div className={`text-center block p-8`}>No records found</div>}

      {blogs.map(blog => (
        <div
          className="p-2.5 bg-white rounded-2xl border border-[#E5E5E5] mb-2.5 shadow-md flex"
          key={blog.id}
        >
          <div className="py-[40px] pl-[55px] pr-[20px] max-w-[70%] w-full">
            <div className="text-sm leading-none text-black mb-2.5">
              {formatDateTime(blog.created_at)}
            </div>

            <div className="max-w-[60%] text-black">
              <h3 className="text-[22px] font-bold leading-tight underline w-fit mb-3.5">
                {blog.title}
              </h3>

              {/* <p>{blog.body}</p> */}
            </div>
          </div>

          <div className="relative min-h-full max-w-[30%] w-full rounded-lg overflow-hidden flex items-center px-[30px]">
            <img
              src={blog.image || '/assets/bitcoin-placeholder.jpg'}
              alt="Image"
              className="absolute inset-0 w-full h-full"
            />

            {/* <div className="relative z-10 -mb-6 text-white">
              <h6 className="text-[13px] mb-1.5 font-[sohne-breit]">With</h6>

              <p className="mb-3 text-sm">
                <span className="underline">Alex Jones,</span>
                <span className="underline"> Natasha</span>
                <span> and</span>
                <span className="underline"> Michaelson</span>
                <span className="underline"> Johnny Mitchell</span>
              </p>

              <div className="flex">
                <div className="rounded-full h-7 w-7">
                  <img
                    className="w-full h-full rounded-full"
                    src="/assets/profile.png"
                    alt="Profile"
                  />
                </div>

                <div className="rounded-full h-7 w-7 -ml-2.5">
                  <img
                    className="w-full h-full rounded-full"
                    src="/assets/profile.png"
                    alt="Profile"
                  />
                </div>

                <div className="rounded-full h-7 w-7 -ml-2.5">
                  <img
                    className="w-full h-full rounded-full"
                    src="/assets/profile.png"
                    alt="Profile"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      ))}
    </>
  );
};
