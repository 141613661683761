import { useContext, useEffect } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { Club } from 'src/common/types/clubs';
import { AuthContext } from 'src/contexts';

interface ClubLeavePopupProps {
  club: Club;
  onClose: () => void;
  onLeave: () => void;
}

export const ClubLeavePopup = ({ onClose, onLeave, club }: ClubLeavePopupProps) => {
  const { accessToken } = useContext(AuthContext);
  const { sendRequest: leaveClub, isLoading: isLeavingClub, error: errorLeavingClub } = useHttp();
  const notify = useNotification();

  useEffect(() => {
    if (errorLeavingClub) {
      notify(errorLeavingClub, 'error');
    }
  }, [errorLeavingClub]);

  const handleLeaveClub = () => {
    leaveClub(
      {
        url: `clubs/detach-user`,
        method: 'POST',
        accessToken,
        payload: { clubId: club.id },
      },
      handleLeaveResponse,
    );
  };

  const handleLeaveResponse = () => {
    onLeave();

    notify(`You have left “${club.title}” club.`, 'success');
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50">
      <div className="w-[600px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col">
        <button className="absolute right-3 top-3" onClick={onClose}>
          <svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
              fill="#E5E5E5"
            />
            <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>

        <h2 className="text-[30px] font-bold font-[sohne-breit] tracking-wide mb-5 text-center leading-[1]">
          Are you sure you want to <span className="highlight-marker">leave</span>
          <span> “{club.title}” Club?</span>
        </h2>

        <p className="mb-10 leading-tight text-center text-black text-md text-opacity-70">
          You won&apos;t be able to access the club&apos;s content after leaving.
        </p>

        <div className="flex gap-6 mx-auto">
          <button
            onClick={handleLeaveClub}
            disabled={isLeavingClub}
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#FF0000] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Yes, leave club
          </button>

          <button
            onClick={onClose}
            disabled={isLeavingClub}
            className="text-black underline text-opacity-70"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
