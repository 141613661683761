import React, { useState, useRef, useContext } from 'react';
import ReactCrop, { Crop, centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { AuthContext } from 'src/contexts';

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
};

interface ProfilePictureUploadProps {
  onCancel: () => void;
  onSave: (blob: Blob) => void;
  disabled: boolean;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
  onCancel,
  onSave,
  disabled,
}) => {
  const [imgSrc, setImgSrc] = useState('');
  const [fileName, setFileName] = useState<string>('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const [aspect] = useState<number | undefined>(1 / 1);
  const { profile } = useContext(AuthContext);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'];

      if (allowedTypes.includes(selectedFile.type)) {
        setCrop(undefined);
        setFileName(selectedFile.name);

        const reader = new FileReader();
        reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
        reader.readAsDataURL(selectedFile);
      }
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onDownloadCropClick = () => {
    if (completedCrop && imgRef.current) {
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
      const canvas = document.createElement('canvas');
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(
          imgRef.current,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height,
        );
        canvas.toBlob(blob => {
          if (blob) {
            onSave(blob);
          }
        }, 'image/jpeg');
      }
    }
  };

  return (
    <div>
      <h2 className="text-[34px] font-bold font-[sohne-breit] tracking-wide mb-10 leading-none">
        {profile?.avatar ? 'Change' : 'Upload'} <br /> profile photo
      </h2>

      <div className="bg-[#E5E5E533] border border-[#00000033] border-dotted p-5 rounded-lg">
        <h5 className="font-bold text-black mb-1.5">Upload new profile photo</h5>

        <h6 className="text-black text-opacity-70 text-sm leading-none mb-1.5">
          JPG, GIF or PNG. Max size of 2MB
        </h6>

        <label className="text-sm font-bold cursor-pointer">
          <input type="file" className="hidden sr-only" onChange={onSelectFile} accept="image/*" />

          <span
            className={`text-black text-opacity-50 mr-1.5 ${
              imgSrc
                ? 'bg-[#F358000D] !text-[#F35800] p-[5px] border border-[#F358004D] rounded-lg'
                : ''
            }`}
          >
            {fileName ? fileName : 'No image selected'}
          </span>

          <span className={`underline ${imgSrc ? 'text-[#000000B2]' : 'text-[#005AFF]'}`}>
            {imgSrc ? 'Browse for another image' : 'Browse for an image'}
          </span>
        </label>
      </div>

      {imgSrc && (
        <div className="mt-10">
          <h4 className="mb-3 text-lg font-bold text-black">
            Drag the circle around to change position
          </h4>

          <div className="flex items-center justify-center overflow-hidden rounded-xl">
            <ReactCrop
              crop={crop}
              keepSelection={true}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={c => setCompletedCrop(c)}
              aspect={1 / 1}
              circularCrop={true}
              minHeight={150}
              minWidth={150}
              style={{ maxHeight: '825px', marginInline: 'auto' }}
            >
              <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
            </ReactCrop>
          </div>
        </div>
      )}

      {completedCrop && (
        <div className="flex items-center gap-4 mt-4">
          <button
            disabled={disabled}
            onClick={onDownloadCropClick}
            className="px-6 py-4 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit]"
          >
            Save
          </button>

          <button
            disabled={disabled}
            onClick={onCancel}
            className="text-black underline text-opacity-70"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfilePictureUpload;
