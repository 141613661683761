import { useContext, useEffect, useState } from 'react';
import { InvitePopup } from '../../popups/InvitePopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { MembersRequest } from './MembersRequest';
import { MembersApproved } from './MembersApproved';
import { PendingInvites } from './PendingInvites';
import { MemberFilter } from './MemberFilter';
import { MembersFilter } from 'src/common/types/filters';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

interface AllMembersProps {
  initialActiveTab: string;
}

export const AllMembers = ({ initialActiveTab }: AllMembersProps) => {
  const [tabs, setTabs] = useState<{ key: string; label: string }[]>([]);
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  const [showInvitePopup, setShowInvitePopup] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [requestCount, setRequestCount] = useState(0);
  const [membersCount, setMembersCount] = useState(0);
  const [pendingInvitesCount, setPendingInvitesCount] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const navigate = useNavigate();

  const { appConfig } = useContext(AuthContext);

  const [date, setDate] = useState<Date | null>();
  const getApprovedMembers = () => setDate(new Date());

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleTabChange = (tabKey: string) => {
    console.log('hmm');

    setActiveTab(tabKey);
    navigate(`/admin/members/${tabKey}`);
  };

  useEffect(() => {
    const newTabs: { key: string; label: string }[] = [];

    if (
      appConfig?.isSuperAdmin ||
      (appConfig?.features['Members'] &&
        appConfig.features['Members'].subFeatures.includes('Approve and Reject members'))
    ) {
      newTabs.push({
        key: 'requests',
        label: 'Requests',
      });
    }

    if (
      appConfig?.isSuperAdmin ||
      (appConfig?.features['Members'] &&
        appConfig.features['Members'].subFeatures.includes('Resend Pending invite'))
    ) {
      newTabs.push({
        key: 'pending',
        label: 'Pending Invitations',
      });
    }

    if (
      appConfig?.isSuperAdmin ||
      (appConfig?.features['Members'] &&
        appConfig.features['Members'].subFeatures.includes('View all members'))
    ) {
      newTabs.push({
        key: 'approved',
        label: 'Members',
      });
    }

    setTabs(newTabs);

    if (!newTabs.some(tab => tab.key === initialActiveTab)) {
      const firstAllowedTab = newTabs[0]?.key;
      handleTabChange(firstAllowedTab);
      location.reload();
    }
  }, [appConfig]);

  const [memberFilter, setMemberFilter] = useState<MembersFilter>({
    parent: null,
    createdAt: null,
  });

  const openInvitePopup = () => {
    setShowInvitePopup(true);
  };

  const closeInvitePopup = () => {
    setShowInvitePopup(false);
  };

  const collectData = (filters: MembersFilter) => {
    setMemberFilter(filters);

    setShowFilter(false);
  };

  return (
    <>
      <ToastContainer autoClose={8000} />

      {showInvitePopup && <InvitePopup onClose={closeInvitePopup} />}

      <div>
        <div>
          <div className="flex items-center justify-between mb-6">
            <div>
              <h1 className="text-4xl font-bold leading-none">Members</h1>
            </div>

            <button
              className="px-6 py-[17px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
              onClick={openInvitePopup}
            >
              Invite Someone
            </button>
          </div>

          <Tabs value={activeTab}>
            <TabsHeader
              placeholder="Empty"
              className="items-center justify-between p-0 mb-12 bg-transparent rounded-none"
              indicatorProps={{
                className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
              }}
            >
              <div className="flex justify-between gap-10">
                {tabs.map(({ key, label }) => (
                  <Tab
                    placeholder="Empty"
                    key={key}
                    value={key}
                    onClick={() => handleTabChange(key)}
                    className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black z-0 ${
                      activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                    }`}
                  >
                    {label}
                    {key === 'requests' && requestCount > 0 && <span> ({requestCount})</span>}
                    {key === 'approved' && membersCount > 0 && <span> ({membersCount})</span>}
                    {key === 'pending' && pendingInvitesCount > 0 && (
                      <span> ({pendingInvitesCount})</span>
                    )}
                  </Tab>
                ))}
              </div>
              <div className="flex items-center gap-4">
                <div className="relative flex items-center">
                  <input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="w-[300px] h-[50px] text-md text-black text-opacity-70 placeholder:text-black placeholder:text-opacity-70 px-4 py-2 pr-10 rounded-md border border-[#D6D6D6] focus:outline-none"
                    placeholder="Search for a member... "
                  />

                  <div className="absolute right-0 px-3 py-2">
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.1588 11.6588C9.06357 13.754 5.66659 13.754 3.5714 11.6588C1.4762 9.56357 1.4762 6.16659 3.5714 4.0714C5.66659 1.9762 9.06357 1.9762 11.1588 4.0714C13.254 6.16659 13.254 9.56357 11.1588 11.6588ZM11.1588 11.6588L14 14.5"
                        stroke="black"
                        strokeWidth="1.6"
                        strokeLinejoin="round"
                        strokeLinecap="square"
                      />
                    </svg>
                  </div>
                </div>

                <button
                  className={`w-[56px] h-[50px] flex items-center justify-center bg-[#F0F4F2] rounded-md transition-all ${
                    showFilter ? 'border border-[#F35800] text-[#F35800]' : 'text-black'
                  }`}
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7 12.5L15 12.5" stroke="currentColor" strokeWidth="1.6" />
                    <path d="M1 12.5H4" stroke="currentColor" strokeWidth="1.6" />
                    <path d="M12 4.5L15 4.5" stroke="currentColor" strokeWidth="1.6" />
                    <path d="M1 4.5H9" stroke="currentColor" strokeWidth="1.6" />
                    <path d="M4 15.5L4 9.5" stroke="currentColor" strokeWidth="1.6" />
                    <path d="M12 7.5L12 1.5" stroke="currentColor" strokeWidth="1.6" />
                  </svg>
                </button>
              </div>
            </TabsHeader>

            <TabsBody placeholder="Empty">
              {tabs.map(({ key }) => (
                <TabPanel key={key} value={key} className="p-0">
                  {key === 'requests' ? (
                    <MembersRequest
                      getApprovedMembers={getApprovedMembers}
                      filters={memberFilter}
                      totalCount={requestCount}
                      setTotalCount={setRequestCount}
                      searchValue={searchValue}
                    />
                  ) : key === 'approved' ? (
                    <MembersApproved
                      date={date as Date}
                      filters={memberFilter}
                      totalCount={membersCount}
                      setTotalCount={setMembersCount}
                      searchValue={searchValue}
                    />
                  ) : (
                    key === 'pending' && (
                      <PendingInvites
                        totalCount={pendingInvitesCount}
                        setTotalCount={setPendingInvitesCount}
                        searchValue={searchValue}
                      />
                    )
                  )}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>

        <MemberFilter
          show={showFilter}
          onClose={() => setShowFilter(false)}
          collectData={collectData}
        />
      </div>
    </>
  );
};
