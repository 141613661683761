import { SignupDetailsProps } from 'src/common/types/signup';

export const SignupDetails = ({ setCurrentStep }: SignupDetailsProps) => {
  return (
    <div className="w-[520px] mx-auto bg-white rounded-lg text-black py-14 px-12">
      <h2 className="mb-8 text-3xl font-medium text-center font-[sohne-breit]">Account details</h2>

      <div className="flex justify-between gap-4">
        <button className="font-normal text-[12.3px] rounded-lg border border-[#005AFF] px-4 py-3 flex items-center gap-2 font-[sohne-breit]">
          <img src="/assets/google.svg" alt="Google" />
          <span>Register with Google</span>
        </button>

        <button className="font-normal text-[12.3px] rounded-lg border border-[#005AFF] px-4 py-3 flex items-center gap-2 font-[sohne-breit]">
          <img src="/assets/linkedin.svg" alt="Linkedin" />
          <span>Register with Linkedin</span>
        </button>
      </div>

      <div className="flex items-center mt-6 text-xs mb-7">
        <div className="flex-grow h-0.5 bg-gray-300"></div>
        <div className="mx-4 text-gray-500">OR</div>
        <div className="flex-grow h-0.5 bg-gray-300"></div>
      </div>

      <div className="flex flex-col gap-7">
        <label className="space-y-2">
          <div className="font-bold">Your full name</div>

          <input
            type="text"
            placeholder="Enter your full name..."
            className="w-full rounded-md h-[60px] border-gray-300 border-b-[#005AFF] focus:border-[#005AFF]"
          />
        </label>

        <label className="space-y-2">
          <div className="font-bold">Your email address</div>

          <input
            type="email"
            placeholder="Enter your email address..."
            className="w-full rounded-md h-[60px] border-gray-300 border-b-[#005AFF] focus:border-[#005AFF]"
          />
        </label>

        <label className="space-y-2">
          <div className="font-bold">Password</div>

          <input
            type="password"
            placeholder="Enter your password..."
            className="w-full rounded-md h-[60px] border-gray-300 border-b-[#005AFF] focus:border-[#005AFF]"
          />
        </label>

        <button className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]">
          Save account details
        </button>
      </div>
    </div>
  );
};
