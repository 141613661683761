import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

export function HomePage() {
  const navigate = useNavigate();
  const { logout, userAuth0, loginWithRedirect, appConfig } = useContext(AuthContext);
  const [signupUrl] = useState('');

  const loginHandler = () => loginWithRedirect();
  const logoutHandler = () => {
    logout();
  };

  const signupHandler = async () => {
    loginWithRedirect({
      appState: { returnTo: '/admin/signup' },
      screen_hint: 'signup',
    });
  };

  useEffect(() => {
    if (appConfig && appConfig.isAdmin) {
      navigate('/admin/members');
    } else if (appConfig) {
      navigate('/dashboard');
    }
  }, [appConfig]);

  return (
    <>
      <div
        className="relative w-full min-h-screen bg-center bg-no-repeat bg-cover"
        style={{
          backgroundImage:
            'radial-gradient(transparent, #070E34E5), url("/assets/homepage-bg.png")',
        }}
      >
        <div className="flex justify-between p-[50px]">
          <svg
            width="131"
            height="35"
            viewBox="0 0 131 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M57.8969 3.38617V15.6524H55.0942V3.38617H48V1.11914H64.9911V3.38617H57.8969Z"
              fill="white"
            />
            <path
              d="M75.6773 15.6524L69.6105 3.46714V15.6524H66.8752V1.11914H71.2106L76.8597 12.4948L82.5088 1.11914H86.7566V15.6524H84.0179V3.46714L77.9545 15.6524H75.6773Z"
              fill="white"
            />
            <path
              d="M92.099 15.6524H89.2964V1.11914H99.0855C102.525 1.11914 104.89 2.6388 104.89 5.57224C104.89 7.82058 103.465 9.25616 101.211 9.80423L105.284 15.6555H102.087L98.2534 10.0284H92.099V15.6555V15.6524ZM98.9305 7.79878C100.945 7.79878 102.127 6.98913 102.127 5.57224C102.127 4.15534 100.945 3.34569 98.9305 3.34569H92.099V7.79878H98.9305Z"
              fill="white"
            />
            <path
              d="M119.427 1.11914L123.631 13.0428L128.054 1.11914H130.968L125.363 15.6524H121.991L117.895 3.91245L113.755 15.6524H110.383L104.798 1.11914H107.732L112.155 13.0428L116.382 1.11914H119.427Z"
              fill="white"
            />
            <path
              d="M57.9583 18.1445V32.7806H54.8491V22.7596H48.7014V20.8164L52.3462 20.6545C54.5324 20.5517 55.4318 19.8635 55.6036 18.1445H57.9616H57.9583Z"
              fill="white"
            />
            <path
              d="M77.4949 25.5108C77.4949 29.8237 73.6412 33.0001 68.5378 33.0001C63.4345 33.0001 59.5808 29.8206 59.5808 25.5108C59.5808 21.2009 63.4142 18.0215 68.5378 18.0215C73.6614 18.0215 77.4949 21.2009 77.4949 25.5108ZM62.4306 25.5108C62.4306 28.6093 64.9267 30.7144 68.5412 30.7144C72.1557 30.7144 74.6282 28.6093 74.6282 25.5108C74.6282 22.4123 72.1321 20.3072 68.5412 20.3072C64.9503 20.3072 62.4306 22.4123 62.4306 25.5108Z"
              fill="white"
            />
            <path
              d="M96.1978 25.5108C96.1978 29.8237 92.3441 33.0001 87.2407 33.0001C82.1373 33.0001 78.2837 29.8206 78.2837 25.5108C78.2837 21.2009 82.1171 18.0215 87.2407 18.0215C92.3643 18.0215 96.1978 21.2009 96.1978 25.5108ZM81.1335 25.5108C81.1335 28.6093 83.6296 30.7144 87.2441 30.7144C90.8586 30.7144 93.3311 28.6093 93.3311 25.5108C93.3311 22.4123 90.835 20.3072 87.2441 20.3072C83.6532 20.3072 81.1335 22.4123 81.1335 25.5108Z"
              fill="white"
            />
            <path
              d="M21.0218 1.36206V15.5369M21.0218 19.1398V33.3147M40.759 16.0287L35.1701 4.8228C34.0253 2.64799 31.7945 1.29688 29.3582 1.29688H12.6503C10.2022 1.29688 7.97139 2.65391 6.81488 4.85836L1.2906 16.0109C0.903134 16.7458 0.903134 17.6228 1.2906 18.3576C1.67806 19.0984 2.40602 20.5917 3.24552 22.3161C4.44313 24.7754 5.79338 27.5547 6.83249 29.5339C7.97726 31.7147 10.2081 33.0717 12.6562 33.0717H29.3582C31.8004 33.0717 34.0253 31.7206 35.1877 29.5221L40.7531 18.3458C41.1347 17.6109 41.1347 16.7398 40.759 16.0287Z"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
          </svg>

          <div className="flex items-center gap-5">
            <h6 className="underline text-[#FFFFFF] text-opacity-60 tracking-wider">
              About the TMRW Foundation
            </h6>

            {userAuth0 && (
              <button className="text-white" onClick={logoutHandler}>
                Log out
              </button>
            )}
            {!userAuth0 && (
              <>
                <button className="text-white" onClick={loginHandler}>
                  Login
                </button>

                {/* <button className="text-white rounded " onClick={signupHandler}>*/}
                {/*  Create Admin*/}
                {/*</button>*/}
              </>
            )}

            {/* 
            {!userAuth0 && (
              <button className="text-white" onClick={signupHandler}>
                Sign up
              </button>
            )} */}
          </div>
        </div>

        <div className="min-h-[76vh] flex flex-col justify-center max-w-[86%] mx-auto">
          <h1 className="text-white font-extrabold font-[sohne-breit] text-[65px]">
            Welcome to TMRW100!
          </h1>

          <p className="text-white text-[22px] mb-10 sm:max-w-[635px]">
            We&apos;re excited to share our vision with those who seek to shape a better TMRW. Join
            us in creating solutions for a more empathetic, connected future.
          </p>

          <div className="flex items-center gap-5">
            <button className="bg-[#005AFF] text-white px-[30px] py-[20px] leading-none rounded-lg text-sm">
              Get in touch with us
            </button>

            <h6 className="underline text-[#FFFFFF] text-opacity-60 tracking-wider">
              Find more about TMRW100
            </h6>
          </div>
        </div>
      </div>
    </>
  );
}
