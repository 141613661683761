export const StepStatus = ({ stepNumber, currentStep, appearance }: StepStatusProps) => {
  const isCompleted = currentStep > stepNumber;
  return (
    <span
      className={`flex items-center justify-center text-xs font-bold rounded-full w-7 h-7 ${
        isCompleted
          ? `${appearance.completed.textClasses} ${appearance.completed.backgroundClasses} ${appearance.completed.borderClasses}`
          : stepNumber === currentStep
          ? `${appearance.active.textClasses} ${appearance.active.backgroundClasses} ${appearance.active.borderClasses}`
          : `${appearance.default.textClasses}  ${appearance.default.backgroundClasses} ${appearance.default.borderClasses}`
      }`}
    >
      {isCompleted ? (
        <svg
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.25 3.65789L4.33333 6.5L9.25 1.5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      ) : (
        stepNumber
      )}
    </span>
  );
};

interface StepStatusProps {
  stepNumber: number;
  currentStep: number;
  appearance: {
    default: Classes;
    active: Classes;
    completed: Classes;
  };
}

interface Classes {
  textClasses: string;
  backgroundClasses: string;
  borderClasses: string;
}
