import { useContext, useEffect, useState } from 'react';
import { useHttp } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { User } from 'src/common/types/user';
import { Pagination } from 'src/components/pagination/Pagination';
import { formatDate, queryParams } from 'src/common/utils';
import { Link } from 'react-router-dom';
import { MembersFilter } from 'src/common/types/filters';

interface MembersApprovedProps {
  date: Date | null;
  setTotalCount: (totalCount: number) => void;
  filters: MembersFilter;
  totalCount: number;
  searchValue: string;
}

export const MembersApproved = ({
  date,
  totalCount,
  setTotalCount,
  searchValue,
  filters,
}: MembersApprovedProps) => {
  const { accessToken } = useContext(AuthContext);
  const [members, setMembers] = useState<User[]>([]);
  const [startingIndex, setStartingIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const { sendRequest: approvedMembers } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getApprovedMembers();
    }
  }, [accessToken, filters, date, currentPage, searchValue]);

  const getApprovedMembers = async () => {
    if (searchValue !== '') {
      setCurrentPage(1);
    }

    const offset = (currentPage - 1) * perPage;
    const params = {
      filter: {
        isApproved: true,
        referralId: filters.parent?.value,
      },
      search: searchValue,
      offset,
      limit: perPage,
    };

    await approvedMembers(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMembers(data.data);
        setTotalCount(data.total);

        setStartingIndex((currentPage - 1) * perPage);
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full text-left text-black">
          <thead className="text-opacity-70">
            <tr>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Id</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Name &amp; Email</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">
                  Company &amp; Position
                </span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Level</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="text-right border-b border-black border-opacity-70">Invitees</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="text-right border-b border-black border-opacity-70">Events</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="text-right border-b border-black border-opacity-70">Clubs</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Invited by</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Member since</span>
              </th>
              {/* <th></th> */}
            </tr>
          </thead>

          <tbody className="leading-tight text-[14px]">
            {members.map((member, index) => {
              return (
                <tr className="border-b" key={member.id}>
                  <td className="px-6 py-4">{startingIndex + index + 1}</td>

                  <td className="px-6 py-4 max-w-[200px]">
                    <Link to={`/admin/members/${member.id}`}>
                      <div className="block text-lg font-bold underline truncate">
                        {member.name ?? 'John Doe'}
                      </div>

                      <div className="text-[13px] truncate">{member.email}</div>
                    </Link>
                  </td>

                  <td className="px-6 py-4 max-w-[114px]">
                    <div className="text-lg font-bold underline truncate">
                      {member.company && member.company.name}
                    </div>

                    <div className="text-[13px] truncate">
                      {member.company ? member.company.role : 'N/A'}
                    </div>
                  </td>

                  <td className="px-6 py-4">
                    <span className="font-[sohne-breit] text-white text-[11px] py-0.5 px-1.5 rounded-sm bg-[#CD9400]">
                      Gold
                    </span>
                  </td>

                  <td className="px-6 py-4 text-right">
                    {member?.invitesCount && member?.invitesCount > 0 ? member.invitesCount : 'N/A'}
                  </td>

                  <td className="px-6 py-4 text-right">
                    {member?.eventsCount && member?.eventsCount > 0 ? member.eventsCount : 'N/A'}
                  </td>

                  <td className="px-6 py-4 text-right">
                    {member?.clubsCount && member?.clubsCount > 0 ? member.clubsCount : 'N/A'}
                  </td>

                  <td className="px-6 py-4 max-w-[114px] truncate">
                    {member.parent?.name ?? 'Jane Smith'}
                  </td>

                  <td className="px-6 py-4 max-w-[114px] truncate">
                    {formatDate(member?.createdAt)}
                  </td>

                  {/* <td className="px-6 py-4 space-x-2">
                    <button className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#F0F4F2] text-black">
                      Edit
                    </button>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          onPageChange={(page: number) => handlePageChange(page)}
        />
      </div>
    </>
  );
};
