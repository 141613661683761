import { Link } from 'react-router-dom';
import { Event } from 'src/common/types/event';
import { formatDateTime, getInitials, handleAddToCalendar } from 'src/common/utils';

interface FeaturedEventCardProps {
  event: Event;
}

export const FeaturedEventCard = ({ event }: FeaturedEventCardProps) => {
  return (
    <div
      className="min-w-[497px] p-2.5 min-h-[541px] bg-cover bg-center rounded-xl shadow-md border border-[#E5E5E5] text-white col-span-2"
      style={{
        backgroundImage:
          'linear-gradient(to right bottom, rgb(0 37 105 / 90%), rgb(0 4 24 / 90%)), url(/assets/bitcoin-placeholder.jpg)',
      }}
    >
      <div className="text-[9px] font-[sohne-breit] text-white mb-16">FEATURED EVENT</div>

      <div className="mx-[50px]">
        <div className="p-2.5 text-[15px] rounded-lg leading-none bg-white bg-opacity-20 w-fit text-white font-medium mb-2.5">
          {formatDateTime(event.startTime)}
        </div>

        <Link
          to={`/events/detail/${event.id}`}
          className="text-[35px] font-bold leading-tight underline w-fit mb-2.5 text-white block"
        >
          {event.title}
        </Link>

        <div className="flex items-center gap-1.5 mb-[30px] text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>

          <span className="text-sm">Paris Salon</span>
        </div>

        <p className="mb-5 max-w-[70%]">
          {event.description.length > 50 ? ( // if description is a long string
            <>
              {event.description.slice(0, 50)}
              <span className="text-white">...</span>
            </>
          ) : (
            event.description
          )}
        </p>

        <div className="flex flex-wrap gap-1 text-[#325EAE] mb-[30px]">
          {event.topics.map((topic, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#BED0F033] font-medium capitalize text-[#A4B8DD]"
              key={index}
            >
              {topic.name}
            </div>
          ))}
        </div>

        <div className="flex mb-2.5">
          {event.hosts.slice(0, 4).map(host => (
            <div
              className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
              key={host.id}
            >
              {host.user.avatar ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img
                    src={host.user.avatar}
                    className="object-cover w-full h-full rounded-full"
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div>{getInitials(host.user.name)}</div>
              )}
            </div>
          ))}

          {event.hosts.length > 4 && (
            <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
              +{event.hosts.length - 4}
            </div>
          )}
        </div>

        <div className="text-sm mb-[22px]">
          {event.hosts.slice(0, 4).map((host, index) => (
            <span key={index}>
              {index > 0 && index === event.hosts.length - 1 ? ' and ' : index > 0 ? ', ' : ''}
              <span className="underline">{host?.user.name ?? 'John Doe'}</span>
            </span>
          ))}
        </div>

        <div className="flex gap-[17px] items-center">
          <Link
            to={`/events/detail/${event.id}`}
            className="px-[30px] py-[17px] text-[10px] font-[sohne-breit] tracking-widest text-black bg-white rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity mb-1.5"
          >
            Register
          </Link>

          <button
            className="text-sm font-bold underline"
            onClick={() => handleAddToCalendar(event)}
          >
            Add to my calender
          </button>
        </div>
      </div>
    </div>
  );
};
