import { useFormik } from 'formik';
import { BlogSettingTabsProps } from 'src/common/types/blog';
import * as Yup from 'yup';

export const BlogsCustomSettingTab = ({
  collectData,
  setCurrentStep,
  initialData,
}: BlogSettingTabsProps) => {
  const validationSchema = Yup.object({
    prompt: Yup.string().required('Please enter a prompt.'),
  });

  const formik = useFormik({
    initialValues: {
      prompt: initialData.prompt,
    },
    validationSchema,
    onSubmit: values => {
      const blog = { ...initialData, prompt: values.prompt };

      collectData(blog);

      setCurrentStep(4);
    },
  });

  return (
    <>
      <div className="text-black">
        <h3 className="text-lg font-bold">Write your prompt here</h3>

        <h4 className="text-sm text-black text-opacity-70 mb-3.5">
          Make sure that the prompt yields a satisfying result, before publishing
        </h4>

        <form onSubmit={formik.handleSubmit}>
          <textarea
            name="prompt"
            placeholder="Enter your prompt here (eg: write me an article that summarizes the discussion)"
            className={`w-full rounded-md min-h-[86px] p-4 border-gray-300 border-b-[#005AFF] ${
              formik.touched.prompt && formik.errors.prompt ? 'border-b-red-500' : ''
            }`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>

          <div className="flex gap-6 mt-4">
            <button
              type="submit"
              className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              Next
            </button>

            <button
              className="text-black underline text-opacity-70"
              onClick={() => setCurrentStep(2)}
            >
              Back to the previous step
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
