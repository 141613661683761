import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BlogStepStatus } from 'src/common/components/BlogStepStatus';
import { BlogMediaTab } from './BlogMediaTab';
import { BlogReviewTranscriptTab } from './BlogReviewTranscript';
import { BlogSettingsTab } from './BlogSettingsTab';
import { BlogGeneratedArticleTab } from './BlogGeneratedArticleTab';
import { BlogPublishArticleTab } from './BlogPublishArticleTab';
import { BlogTabData } from 'src/common/types/blog';
import { ToastContainer } from 'react-toastify';
import { BlogImageTab } from './BlogImageTab';

export const CreateBlog = () => {
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  const [disabled, setDisabled] = useState(false);

  const [blog, setBlog] = useState<BlogTabData>({
    media: {
      file: null,
      recordingOf: 'event',
      event: null,
      description: '',
    },
    basic: {
      tone: null,
      theme: null,
      readers: null,
      length: 'brief',
      background_info: false,
    },
    transcript: '',
    prompt: '',
    generate: {
      title: '',
      body: '',
    },
    gallery: {
      image: null,
      tags: null,
    },
  });

  const stepsAppearance = {
    default: {
      textClasses: 'text-black',
      backgroundClasses: '',
      borderClasses: 'border border-black',
      titleClasses: 'text-black',
    },
    active: {
      textClasses: 'text-[#F35800]',
      backgroundClasses: 'bg-[#F358001A]',
      borderClasses: 'border border-[#F35800]',
      titleClasses: 'text-black font-bold',
    },
    completed: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-[#69BB00]',
      borderClasses: '',
      titleClasses: 'text-black',
    },
  };

  const moveToStep = (step: number) => {
    if (disabled || step > currentStep) {
      return;
    }

    setCurrentStep(step);
  };

  const collectData = (event: BlogTabData) => {
    setBlog(event);
  };

  return (
    <>
      <ToastContainer autoClose={2000} />

      <div className="flex items-end justify-between mb-12">
        <div className="relative">
          <button
            className="h-[30px] w-[30px] rounded-lg flex items-center justify-center bg-[#E5E5E5] absolute top-7 -left-11"
            onClick={() => navigate(-1)}
          >
            <svg
              width="15"
              height="12"
              viewBox="0 0 15 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M14 6.00016H2M2 6.00016L6.64516 10.6668M2 6.00016L6.64516 1.3335"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>

          <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
            MEDIA ENGINE / BLOG
          </span>

          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Create article</h1>
        </div>
      </div>

      <div className="relative rounded-lg shadow-lg flex min-h-[30vh] w-fit mx-auto border border-[#E8E8E8]">
        <div className="bg-[#FFFFFFB2] min-w-[300px] max-w-[301px] rounded-l-lg py-[60px] pl-[55px] pr-[42px] text-black border-r border-[#E8E8E8]">
          <h2 className="font-[sohne-breit] font-bold leading-none mb-5 text-[22px]">
            Create an article
          </h2>

          <p className="text-[14px] mb-10">
            You can easily create an article from a recording or transcript that you upload. Follow
            the next steps lorem ipsum
          </p>

          <div className="space-y-5">
            <div
              onClick={() => moveToStep(1)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Upload file"
                stepNumber={1}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>

            <div
              onClick={() => moveToStep(2)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Review transcript"
                stepNumber={2}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>

            <div
              onClick={() => moveToStep(3)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Generation Settings"
                stepNumber={3}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>

            <div
              onClick={() => moveToStep(4)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Generate article"
                stepNumber={4}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>

            <div
              onClick={() => moveToStep(5)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Image, video & tags"
                stepNumber={5}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>

            <div
              onClick={() => moveToStep(6)}
              className={`${disabled ? 'cursor-wait' : 'cursor-pointer'}`}
            >
              <BlogStepStatus
                title="Publish"
                stepNumber={6}
                currentStep={currentStep}
                appearance={stepsAppearance}
              />
            </div>
          </div>
        </div>

        <div className="bg-white rounded-r-lg px-[55px] py-[60px] overflow-y-scroll custom-scrollbar w-[550px]">
          {currentStep === 1 && (
            <BlogMediaTab
              disabled={disabled}
              setDisabled={setDisabled}
              setCurrentStep={setCurrentStep}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}

          {currentStep === 2 && (
            <BlogReviewTranscriptTab
              disabled={disabled}
              setDisabled={setDisabled}
              setCurrentStep={setCurrentStep}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}

          {currentStep === 3 && (
            <BlogSettingsTab
              disabled={disabled}
              setDisabled={setDisabled}
              setCurrentStep={setCurrentStep}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}

          {currentStep === 4 && (
            <BlogGeneratedArticleTab
              disabled={disabled}
              setDisabled={setDisabled}
              setCurrentStep={setCurrentStep}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}

          {currentStep === 5 && (
            <BlogImageTab
              setDisabled={setDisabled}
              setCurrentStep={setCurrentStep}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}

          {currentStep === 6 && (
            <BlogPublishArticleTab
              disabled={disabled}
              setDisabled={setDisabled}
              setCurrentStep={() => console.log('hh')}
              initialData={blog}
              collectData={collectData}
              onCancel={() => console.log('hh')}
            />
          )}
        </div>
      </div>
    </>
  );
};
