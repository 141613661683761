import { BlogTabData, CreateBlogProps } from 'src/common/types/blog';

export const BlogReviewTranscriptTab = ({
  setCurrentStep,
  initialData,
  collectData,
}: CreateBlogProps) => {
  const handleTranscriptChange = (e: { target: { textContent: any } }) => {
    const blog = { ...initialData, transcript: e.target.textContent } as BlogTabData;
    collectData(blog);
  };

  return (
    <div className="flex flex-col justify-between h-full gap-10">
      <div>
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 2
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[82%]">
          Review generated transcript
        </h2>
      </div>

      <div className="flex-1 flex gap-y-[30px] flex-col max-h-[50vh] overflow-y-scroll custom-scrollbar relative">
        <div className="flex items-start gap-x-8 max-h-fit">
          <span>
            <svg
              width="9"
              height="17"
              viewBox="0 0 9 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="1.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
              <circle cx="1.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
              <circle cx="1.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
              <circle cx="7.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
              <circle cx="7.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
              <circle cx="7.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
            </svg>
          </span>

          <p
            className="-mt-1.5"
            contentEditable={true}
            onBlur={handleTranscriptChange}
            dangerouslySetInnerHTML={{ __html: initialData.transcript }}
            style={{ outline: 'none', border: 'none', padding: '0' }}
          ></p>
        </div>
      </div>

      <div className="flex gap-6">
        <button
          onClick={() => setCurrentStep(3)}
          type="submit"
          className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
        >
          Next
        </button>

        <button className="text-black underline text-opacity-70" onClick={() => setCurrentStep(1)}>
          Back to the previous step
        </button>
      </div>
    </div>
  );
};
