import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { CreateClubProps } from 'src/common/types/clubs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp } from 'src/common/hooks';
import { Option } from 'src/common/types/event';
import { Topic } from 'src/common/types/topic';

export const ClubInfoTab = ({ setCurrentStep, initialData, collectData }: CreateClubProps) => {
  const { sendRequest: getTopics } = useHttp();
  const { accessToken } = useContext(AuthContext);
  const [topicsOptions, setTopicOptions] = useState<Option[]>();

  useEffect(() => {
    if (accessToken) {
      getTopicsHandler();
    }
  }, [accessToken]);

  const getTopicsHandler = async () => {
    await getTopics(
      {
        url: 'topics',
        method: 'GET',
        accessToken,
      },
      (topics: any) => {
        if (topics) {
          const options = topics.map((topic: Topic) => ({ label: topic.name, value: topic.id }));

          setTopicOptions(options);
        }
      },
    );
  };

  const formik = useFormik({
    initialValues: initialData.info,
    validationSchema: Yup.object({
      title: Yup.string().trim().required().max(50),
      description: Yup.string().trim().required(),
      topics: Yup.array().min(1).required(),
    }),
    onSubmit: values => {
      const event = { ...initialData, info: values };
      collectData(event);
      setCurrentStep(2);
    },
  });

  return (
    <>
      <div className="max-w-[570px] w-full mx-auto mt-7">
        <div className="bg-white border border-[#E5E5E5] rounded-2xl p-14 text-black shadow">
          <h1 className="font-[sohne-breit] text-2xl mb-3.5">General details</h1>
          <p className="text-black text-opacity-70 leading-snug mb-[40px]">
            The Internet of Life® is inspired by the power of reality and how people come together,
            collaborate, and communicate. It represents the third evolution of the internet.
          </p>

          <form onSubmit={formik.handleSubmit}>
            <div className="flex flex-col gap-7">
              <label className="space-y-2">
                <div>
                  <div className="font-bold">Club title</div>
                  <div className="text-sm text-black text-opacity-70">
                    Example: Scientific discoveries club
                  </div>
                </div>

                <input
                  type="text"
                  name="title"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.title}
                  placeholder="Enter a descriptive short title for the club"
                  className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                    formik.touched.title && formik.errors.title
                      ? '!border-b-[#F72300] bg-[#FFF2F2]'
                      : '!border-b-[#005AFF]'
                  }`}
                />
              </label>

              <label className="space-y-2">
                <div className="font-bold">Short description</div>

                <textarea
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  placeholder="Enter a short description for the club"
                  className={`w-full rounded-md min-h-[120px] p-4 border-gray-300 focus:border-gray-300 ${
                    formik.touched.description && formik.errors.description
                      ? '!border-b-[#F72300] bg-[#FFF2F2]'
                      : '!border-b-[#005AFF]'
                  }`}
                ></textarea>
              </label>

              <label className="space-y-2">
                <div className="font-bold">Club topics</div>

                <Select
                  value={formik.values.topics}
                  name="topics"
                  onChange={selectedOptions => {
                    // Formik's handleChange expects an event object with a 'target' property,
                    // so we need to manually create a fake event object for Formik.
                    const fakeEvent = {
                      target: {
                        name: 'topics',
                        value: selectedOptions,
                      },
                    };
                    formik.handleChange(fakeEvent);
                  }}
                  options={topicsOptions}
                  isMulti
                  classNames={{
                    control: () =>
                      `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                        formik.errors.topics && formik.touched.topics
                          ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                          : ''
                      }`,
                    indicatorSeparator: () => '!my-0',
                  }}
                />
              </label>

              {/* <div className="space-y-2 bg-[#E5E5E5] bg-opacity-40 p-5 rounded-md border border-[#00000033] border-dotted">
                <div className="font-bold">Club image</div>

                <label className="text-sm font-bold cursor-pointer">
                  <input type="file" className="hidden" />
                  <span className="text-black text-opacity-50 mr-1.5">No image selected </span>
                  <span className="underline text-[#005AFF]">Browse for an image</span>
                </label>
              </div> */}

              <button
                className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
                type="submit"
              >
                Save General Details
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
