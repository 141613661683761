import { useContext, useEffect, useState } from 'react';

import { useHttp, useNotification } from 'src/common/hooks';
import { CreateBlogProps } from 'src/common/types/blog';
import { AuthContext } from 'src/contexts';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

export const BlogPublishArticleTab = ({ initialData, onCancel }: CreateBlogProps) => {
  const [publish, setPublish] = useState('later');
  const [dateTime, setDateTime] = useState<Date>();
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const [shareOnTwitter, setShareOnTwitter] = useState(false);
  const [shareOnLinkedin, setShareOnLinkedIn] = useState(false);
  const navigate = useNavigate();

  const notify = useNotification();
  const {
    sendRequest: publishArticle,
    isLoading: isPublishingArticle,
    error: errorPublishingArticle,
  } = useHttp();

  useEffect(() => {
    if (errorPublishingArticle) {
      notify(errorPublishingArticle, 'error');
    }
  }, [errorPublishingArticle]);

  const handlePublishArticle = () => {
    const formData = new FormData();
    formData.append('title', initialData.generate.title);
    formData.append('body', initialData.generate.body);
    formData.append('status', 'published');
    formData.append('userId', profile?.id);
    formData.append('image', initialData.gallery.image || '');
    formData.append('tags', JSON.stringify(initialData.gallery.tags?.map(tag => tag.label)));
    formData.append('shareOnTwitter', shareOnTwitter.toString());
    formData.append('shareOnLinkedin', shareOnLinkedin.toString());

    publishArticle(
      {
        url: 'directus/articles',
        method: 'POST',
        accessToken,
        payload: formData,
      },
      handlePublishArticleSuccess,
    );
  };

  const handlePublishArticleSuccess = () => {
    notify('Article published successfully.', 'success');

    setTimeout(() => {
      navigate('/blogs');
    }, 3000);
  };

  const handleDateChange = (date: Date) => {
    setDateTime(date);
  };

  return (
    <div className="flex flex-col h-full gap-y-10">
      <div>
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 6
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[80%]">
          Publish the article or review it
        </h2>
      </div>

      <label className="space-y-2">
        <div className="font-bold">Publish article</div>

        <div className="flex">
          <div
            className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-l-lg ${
              publish == 'later'
                ? '!text-[#F35800] border-[#F35800] bg-white shadow-current !font-bold'
                : 'text-opacity-50'
            }`}
            onClick={() => setPublish('later')}
          >
            Later
          </div>

          <div
            className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-r-lg ${
              publish == 'now'
                ? '!text-[#F35800] border-[#F35800] bg-white shadow-current font-bold'
                : 'text-opacity-50'
            }`}
            onClick={() => setPublish('now')}
          >
            Now
          </div>
        </div>
      </label>

      {publish === 'later' && (
        <label className="space-y-2">
          <div className="font-bold leading-none">Choose publish date and time</div>

          <DatePicker
            selected={dateTime}
            onChange={handleDateChange}
            dateFormat="yyyy/MM/dd h:mm aa"
            placeholderText="Choose a date"
            showTimeSelect
            className={`w-full rounded-md !min-h-[60px] border-gray-300 focus:border-gray-300 !border-b-[#005AFF]`}
          />
        </label>
      )}

      <div>
        {appConfig?.connectedAccounts.includes('twitter') && (
          <div className="flex items-center justify-between border-y first-of-type:border-b-0 border-[#E8E8E8] py-[30px]">
            <div className="flex gap-2.5 items-center">
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.8134 0.199219H14.1135L9.08844 6.3843L15 14.8008H10.3713L6.74593 9.69624L2.59769 14.8008H0.296204L5.67098 8.18516L0 0.199219H4.74621L8.02322 4.86499L11.8134 0.199219ZM11.0061 13.3182H12.2806L4.05368 1.60397H2.686L11.0061 13.3182Z"
                  fill="black"
                />
              </svg>

              <h6 className="font-bold text-black">When posting, share on Twitter</h6>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                name="shareOnTwitter"
                checked={shareOnTwitter}
                onChange={() => setShareOnTwitter(!shareOnTwitter)}
                type="checkbox"
                className="sr-only peer"
              />

              <div className="w-11 h-6 bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>
        )}

        {appConfig?.connectedAccounts.includes('linkedin') && (
          <div className="flex items-center justify-between border-y first-of-type:border-b-0 border-[#E8E8E8] py-[30px]">
            <div className="flex gap-2.5 items-center">
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.2229 0.5H1.77707C1.30576 0.5 0.853757 0.687289 0.520492 1.02066C0.187226 1.35404 0 1.8062 0 2.27766V14.7223C0 15.1938 0.187226 15.646 0.520492 15.9793C0.853757 16.3127 1.30576 16.5 1.77707 16.5H14.2229C14.6941 16.4997 15.146 16.3123 15.4792 15.979C15.8124 15.6457 15.9997 15.1937 16 14.7223V2.27766C16 1.8062 15.8128 1.35404 15.4795 1.02066C15.1462 0.687289 14.6942 0.5 14.2229 0.5ZM4.84088 14.2721H2.45592V6.57349H4.84088V14.2721ZM3.63675 5.56495C3.35521 5.56705 3.0794 5.48546 2.84429 5.33052C2.60918 5.17558 2.42537 4.95428 2.31617 4.69469C2.20698 4.4351 2.17732 4.14892 2.23095 3.87244C2.28459 3.59596 2.41911 3.34165 2.61744 3.14176C2.81578 2.94187 3.06899 2.80541 3.34497 2.74969C3.62095 2.69397 3.90725 2.7215 4.16757 2.82878C4.42788 2.93607 4.65048 3.11828 4.80712 3.3523C4.96376 3.58633 5.04738 3.86161 5.04739 4.14325C5.04809 4.32925 5.01215 4.51358 4.94164 4.68569C4.87112 4.8578 4.76741 5.01434 4.63642 5.14636C4.50543 5.27837 4.34973 5.38329 4.1782 5.45511C4.00668 5.52693 3.82269 5.56426 3.63675 5.56495ZM13.7781 14.2774H11.4048V10.2337C11.4048 9.12451 10.9812 8.50477 10.1064 8.50477C9.15329 8.50477 8.65343 9.14994 8.65343 10.2337V14.2774H6.3659V6.57349H8.65343V7.60852C8.89771 7.21415 9.23992 6.88978 9.64674 6.667C10.0536 6.44422 10.5111 6.33061 10.9748 6.33725C12.6089 6.33725 13.7781 7.3352 13.7781 9.39889V14.2774Z"
                  fill="#0B67C2"
                />
              </svg>

              <h6 className="font-bold text-black">When posting, share on LinkedIn</h6>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                name="shareOnLinkedIn"
                checked={shareOnLinkedin}
                onChange={() => setShareOnLinkedIn(!shareOnLinkedin)}
                type="checkbox"
                className="sr-only peer"
              />

              <div className="w-11 h-6 bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>
        )}
      </div>

      <div className="flex gap-6">
        <button
          onClick={handlePublishArticle}
          disabled={isPublishingArticle}
          type="submit"
          className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
        >
          Publish
        </button>

        <button className="text-black underline text-opacity-70" onClick={onCancel}>
          Save it for later
        </button>
      </div>
    </div>
  );
};
