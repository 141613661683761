import { useContext } from 'react';
import { Blog } from 'src/common/types/blog';
import { formatDate } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

interface BlogCardProps {
  blog: Blog;
}

export const BlogCard = ({ blog }: BlogCardProps) => {
  const { appConfig } = useContext(AuthContext);

  const publishAndUnPublish =
    appConfig?.parentId === null ||
    appConfig?.features['Articles'].subFeatures.includes('Publish and unpublish articles');

  return (
    <div className="bg-white rounded-xl border border-[#E5E5E5] p-2.5 min-w-[308px] max-w-[308px] flex flex-col">
      <div
        className="h-[120px] w-full rounded-xl bg-cover bg-center bg-no-repeat mb-8"
        style={{
          backgroundImage: `url(${blog.image || '/assets/bitcoin-placeholder.jpg'})`,
        }}
      ></div>

      <div className="px-[20px] flex flex-col justify-between flex-1">
        <div>
          <div className="text-[13px] rounded-lg bg-[#5AA000] text-white p-[5px] w-fit leading-none mb-2.5 capitalize">
            {blog.status}
          </div>

          <div className="text-sm text-black leading-none mb-2.5">
            {formatDate(blog.created_at)}
          </div>

          <h4 className="underline max-w-[70%] text-[22px] text-black font-bold leading-none mb-2.5">
            {blog.title}
          </h4>

          <div className="flex mb-4">
            <div className="rounded-full h-7 w-7">
              <img className="w-full h-full rounded-full" src="/assets/profile.png" alt="Profile" />
            </div>

            <div className="rounded-full h-7 w-7 -ml-2.5">
              <img className="w-full h-full rounded-full" src="/assets/profile.png" alt="Profile" />
            </div>

            <div className="rounded-full h-7 w-7 -ml-2.5">
              <img className="w-full h-full rounded-full" src="/assets/profile.png" alt="Profile" />
            </div>
          </div>
        </div>

        <div>
          <div className="rounded-xl bg-[#E5E5E566] p-[15px] relative mb-5">
            <svg
              width="19"
              height="17"
              viewBox="0 0 19 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute right-[4px] top-[4px]"
            >
              <path
                d="M0 8C0 3.58172 3.58172 0 8 0H11C15.4183 0 19 3.58172 19 8V9C19 13.4183 15.4183 17 11 17H8C3.58172 17 0 13.4183 0 9V8Z"
                fill="#F7ED00"
              />
              <g opacity="0.7">
                <g opacity="0.7">
                  <path
                    d="M10.5174 4.625V5.835"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5174 11.3789V12.3909"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M10.5174 9.66245V7.25195"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.1862 12.5C14.1883 12.5 15 11.7713 15 10.8716V9.57532C14.3369 9.57532 13.8028 9.09585 13.8028 8.50051C13.8028 7.90518 14.3369 7.4252 15 7.4252L14.9994 6.12843C14.9994 5.22872 14.1872 4.5 13.1856 4.5H5.8144C4.81284 4.5 4.00057 5.22872 4.00057 6.12843L4 7.46743C4.66312 7.46743 5.19717 7.90518 5.19717 8.50051C5.19717 9.09585 4.66312 9.57532 4 9.57532V10.8716C4 11.7713 4.81169 12.5 5.81383 12.5H13.1862Z"
                    stroke="black"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>

            <div className="text-xs text-[#000000B2] leading-none font-medium">
              {formatDate(blog.created_at)}
            </div>

            <div className="text-black font-medium text-[15px]">How to invest your first 100k</div>

            <div className="flex">
              <div className="rounded-full h-[18px] w-[18px]">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-[18px] w-[18px] -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-[18px] w-[18px] -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-[18px] w-[18px] -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mb-[30px]">
            <div className="flex gap-2.5">
              <button
                disabled={!publishAndUnPublish}
                className="bg-[#2F3248] font-[sohne-breit] text-white px-[17px] py-[15px] leading-none text-[10px] rounded-lg"
              >
                Share
              </button>

              {/* <button className="text-sm text-black underline">Edit</button> */}
            </div>

            {/* <button className="pl-2">
              <svg
                width="4"
                height="20"
                viewBox="0 0 4 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="2" cy="2" r="2" fill="black" fillOpacity="0.7" />
                <circle cx="2" cy="10" r="2" fill="black" fillOpacity="0.7" />
                <circle cx="2" cy="18" r="2" fill="black" fillOpacity="0.7" />
              </svg>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
