import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import {useNavigate} from "react-router-dom";

export const SignupFormSubAccount = () => {
  const navigate = useNavigate();
  const { profile, appConfig, accessToken, fetchProfile, fetchAppConfig } = useContext(AuthContext);
  const notify = useNotification();
  const {
    sendRequest: createUser,
  } = useHttp();

  const createNewUser = async () => {
    await createUser(
      {
        url: 'user/sub-account/update-subauth',
        method: 'PATCH',
        accessToken,
        payload: {},
      },
      (data: any) => {
        notify('Sub Account created!', 'success');
        fetchProfile();
        fetchAppConfig();
        navigate('/dashboard')
      },
    );
  };

  useEffect(() => {
    if (accessToken && appConfig && !appConfig.isRegistered) {
      createNewUser();
    }
  }, [accessToken, appConfig]);

  return (
    <>
      <div>in progress</div>
      <LoadingSpinner />
    </>
  );
};
