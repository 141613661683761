import { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { HomePage } from 'src/pages';
import { AuthContext } from 'src/contexts';
import { SignupPage } from 'src/pages/SignupPage';
import { SignupForm } from 'src/components/signup/SignupForm';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { DashboardPage } from 'src/pages/DashboardPage';
import { Members } from 'src/components/members/Members';
import { AdminPage } from 'src/pages/AdminPage';
import { AllMembers } from 'src/components/admin/all-members/AllMembers';
import { Locked } from '../components/signup/Locked';
import { MemberDetail } from 'src/components/members/MemberDetail';
import { HandleSignupPage } from '../pages/HandleSignupPage';
import { Profile } from 'src/components/profile/Profile';
import { Events } from 'src/components/events/Events';
import { CreateEvent } from 'src/components/events/CreateEvent';
import { EventDetail } from 'src/components/events/EventDetail';
import Clubs from 'src/components/clubs/Clubs';
import ClubDetail from 'src/components/clubs/ClubDetail';
import CreateClub from 'src/components/clubs/CreateClub';
import Circles from 'src/components/circles/Circles';
import ActivityFeed from 'src/components/activity/ActivityFeed';
import Blogs from 'src/components/blogs/Blogs';
import { SignupFormSuperAdmin } from '../components/signup/SignupFormSuperAdmin';
import { AdminMemberDetail } from 'src/components/admin/all-members/AdminMemberDetail';
import { Recordings } from 'src/components/recordings/Recordings';
import { Dashboard } from 'src/components/dashboard/Dashboard';
import { CreateBlog } from 'src/components/blogs/CreateBlog';
import { Preferences } from 'src/components/preferences/Preferences';
import { SignupFormSubAccount } from 'src/components/signup/SignupFormSubAccount';
import { HandleSubAccountPage } from 'src/pages/HandleSubAccountPage';
import { AdminPreferences } from 'src/components/admin/preferences/AdminPreferences';
import { HandleAdminAccount } from 'src/components/signup/HandleAdminAccount';
import { SignupFormAdminAccount } from 'src/components/signup/SignupFormAdminAccount';

export default function AppRouter() {
  const { appConfig, accessToken } = useContext(AuthContext);

  const protectedPageTemplate = appConfig ? (
    appConfig.isRegistered && appConfig.isApproved ? (
      <DashboardPage />
    ) : appConfig.isInvited && !appConfig.isAdmin ? (
      <Navigate to="/signup" replace />
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <LoadingSpinner />
  );

  const adminPageTemplate = appConfig ? (
    appConfig.isAdmin && appConfig.isApproved ? (
      <AdminPage />
    ) : (
      <Navigate to="/" replace />
    )
  ) : (
    <LoadingSpinner />
  );

  return (
    <Routes>
      <Route
        path="/signup"
        element={
          appConfig ? (
            appConfig.isInvited && !appConfig.isAdmin ? (
              appConfig.isRegistered && appConfig.isSurveyCompleted ? (
                <Navigate to="/locked" replace />
              ) : (
                <SignupPage />
              )
            ) : (
              <Navigate to="/" replace />
            )
          ) : (
            <LoadingSpinner />
          )
        }
      >
        <Route index element={<SignupForm />} />
      </Route>

      <Route path="/admin/signup" element={<SignupPage />}>
        <Route index element={<SignupFormSuperAdmin />} />
      </Route>

      <Route path="/complete-sub-account-signup" element={<SignupPage />}>
        <Route index element={<SignupFormSubAccount />} />
      </Route>

      <Route path="/complete-admin-signup" element={<SignupPage />}>
        <Route index element={<SignupFormAdminAccount />} />
      </Route>

      <Route path="/" element={accessToken ? protectedPageTemplate : null}>
        <Route index element={<HomePage />} />
      </Route>

      <Route path="/activities" element={protectedPageTemplate}>
        <Route index element={<ActivityFeed />} />
      </Route>

      <Route path="/blogs" element={protectedPageTemplate}>
        <Route index element={<Blogs />} />
        <Route path="create" element={<CreateBlog />} />
      </Route>

      <Route path="/members" element={protectedPageTemplate}>
        <Route index element={<Members />} />
        <Route path=":id" element={<MemberDetail />} />
      </Route>

      <Route path="/events" element={protectedPageTemplate}>
        <Route index element={<Events />} />
        <Route path="detail/:id" element={<EventDetail />} />
        <Route path="create" element={<CreateEvent />} />
      </Route>

      <Route path="/clubs" element={protectedPageTemplate}>
        <Route index element={<Clubs />} />
        <Route path=":id" element={<ClubDetail />} />
        <Route path="create" element={<CreateClub />} />
      </Route>

      <Route path="/circles" element={protectedPageTemplate}>
        <Route index element={<Circles />} />
      </Route>

      <Route path="/dashboard" element={protectedPageTemplate}>
        <Route index element={<Dashboard />} />
      </Route>

      <Route path="/media" element={protectedPageTemplate}>
        <Route index element={<Recordings />} />
      </Route>

      <Route path="/profile" element={protectedPageTemplate}>
        <Route index element={<Profile />} />
      </Route>

      <Route path="/preferences" element={protectedPageTemplate}>
        <Route index element={<Preferences />} />
      </Route>

      <Route
        path="/locked"
        element={
          appConfig ? (
            !appConfig.isInvited ? (
              <Navigate to="/" replace />
            ) : appConfig.isRegistered && appConfig.isApproved ? (
              <Navigate to="/members" replace />
            ) : (
              <SignupPage />
            )
          ) : (
            <LoadingSpinner />
          )
        }
      >
        <Route index element={<Locked />} />
      </Route>

      <Route path="/admin/members" element={adminPageTemplate}>
        <Route index element={<Navigate to="requests" replace />} />
        <Route path="requests" element={<AllMembers initialActiveTab="requests" />} />
        <Route path="pending" element={<AllMembers initialActiveTab="pending" />} />
        <Route path="approved" element={<AllMembers initialActiveTab="approved" />} />
        <Route path=":id" element={<AdminMemberDetail />} />
      </Route>

      <Route path="/admin/preferences" element={adminPageTemplate}>
        <Route index element={<AdminPreferences />} />
      </Route>

      <Route element={null}>
        <Route path="/activate" element={<HandleSignupPage />} />
      </Route>

      <Route element={null}>
        <Route path="/verify-sub-account" element={<HandleSubAccountPage />} />
      </Route>

      <Route element={null}>
        <Route path="/verify-admin-account" element={<HandleAdminAccount />} />
      </Route>

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
