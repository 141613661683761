import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import CreatableSelect from 'react-select/creatable';
import { BlogMediaTabsProps, BlogTabData } from 'src/common/types/blog';
import { Option } from 'src/common/types/event';
import { AuthContext } from 'src/contexts';
import { useHttp } from 'src/common/hooks';
import { Topic } from 'src/common/types/topic';

export const BlogImageTab = ({ collectData, initialData, setCurrentStep }: BlogMediaTabsProps) => {
  const [image, setImage] = useState<File>();
  const [imageUrl, setImageUrl] = useState<string | undefined>();
  const { sendRequest: getTopics } = useHttp();
  const { accessToken } = useContext(AuthContext);
  const [topicsOptions, setTopicOptions] = useState<Option[]>();

  useEffect(() => {
    if (accessToken) {
      getTopicsHandler();
    }
  }, [accessToken]);

  const getTopicsHandler = async () => {
    await getTopics(
      {
        url: 'topics',
        method: 'GET',
        accessToken,
      },
      (topics: any) => {
        if (topics) {
          const options = topics.map((topic: Topic) => ({ label: topic.name, value: topic.key }));

          setTopicOptions(options);
        }
      },
    );
  };

  const formik = useFormik({
    initialValues: initialData.gallery,
    validationSchema: Yup.object({
      tags: Yup.array().min(1).required(),
    }),
    onSubmit: async values => {
      if (!image) return;
      const blog = {
        ...initialData,
        gallery: {
          image,
          tags: values.tags,
        },
      } as BlogTabData;

      collectData(blog);

      setCurrentStep(6);
    },
  });

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

      if (allowedTypes.includes(selectedFile.type)) {
        setImage(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        setImageUrl(url);
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-10">
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 5
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[80%]">
          Choose image, video and tags
        </h2>
      </div>

      <div className="flex flex-col mb-10 gap-y-10">
        <div className="bg-[#E5E5E533] border border-[#00000033] border-dotted p-5 rounded-lg">
          {imageUrl && (
            <img
              src={imageUrl}
              alt="Selected Image"
              className="h-[268px] rounded-[15px] w-full mb-3 object-cover bg-center"
            />
          )}

          <h5 className="font-bold text-black mb-1.5">
            Upload {image && 'another'} background image
          </h5>

          <h6 className="text-black text-opacity-70 text-sm leading-none mb-1.5">
            jpg, png, and gif are accepted
          </h6>

          <label className="flex items-center text-sm font-bold cursor-pointer">
            <input
              type="file"
              className="hidden sr-only"
              onChange={onSelectFile}
              accept="image/*"
            />

            <div className={`underline ${image ? 'text-[#000000B2]' : 'text-[#005AFF]'}`}>
              {image ? 'Browse for another image' : 'Browse for an image'}
            </div>
          </label>
        </div>

        <label className="space-y-2">
          <div>
            <div className="font-bold">Video Url</div>
            <div className="text-sm text-black text-opacity-70">
              Video url from Youtube, Vimeo ar any other video provider. The video will be used
              inside the article
            </div>
          </div>

          <input
            type="text"
            name="title"
            placeholder="Insert video url (https://.....)"
            className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 !border-b-[#005AFF]`}
          />
        </label>

        <label className="space-y-2">
          <div className="font-bold leading-none">Article tags</div>

          {/* <MultiSelect
            options={options}
            onChange={options => console.log(options)}
            placeholder="Choose or add one or more tags"
          /> */}

          <CreatableSelect
            name="topics"
            onChange={selectedOptions => {
              const fakeEvent = {
                target: {
                  name: 'tags',
                  value: selectedOptions,
                },
              };
              formik.handleChange(fakeEvent);
            }}
            options={topicsOptions}
            isMulti
            classNames={{
              control: () =>
                `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                  formik.errors.tags && formik.touched.tags
                    ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                    : ''
                }`,
              indicatorSeparator: () => '!my-0',
            }}
          />
        </label>
      </div>

      <button
        type="submit"
        className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity w-full"
      >
        Save background & tags
      </button>
    </form>
  );
};
