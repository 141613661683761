import { ReactNode, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getInitials } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

export const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { logout, profile, appConfig } = useContext(AuthContext);

  const logoutHandler = () => {
    logout();
  };
  return (
    <>
      <div className="h-screen flex bg-[#F1F2F4]">
        <div className="flex flex-col justify-between min-w-[260px] h-full bg-[#0B1444] pt-[50px] overflow-y-scroll custom-scrollbar">
          <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between ml-10 mr-5">
              <svg
                width="117"
                height="32"
                viewBox="0 0 117 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M51.7822 2.60176V13.5497H49.2808V2.60176H42.949V0.578369H58.114V2.60176H51.7822Z"
                  fill="white"
                />
                <path
                  d="M67.6518 13.5497L62.237 2.67402V13.5497H59.7957V0.578369H63.6651L68.7071 10.7315L73.7491 0.578369H77.5403V13.5497H75.096V2.67402L69.6842 13.5497H67.6518Z"
                  fill="white"
                />
                <path
                  d="M82.3088 13.5497H79.8074V0.578369H88.5444C91.6141 0.578369 93.7247 1.93471 93.7247 4.55289C93.7247 6.5596 92.4529 7.84089 90.4415 8.33007L94.0765 13.5525H91.2232L87.8018 8.53018H82.3088V13.5525V13.5497ZM88.4061 6.54014C90.204 6.54014 91.2593 5.8175 91.2593 4.55289C91.2593 3.28827 90.204 2.56563 88.4061 2.56563H82.3088V6.54014H88.4061Z"
                  fill="white"
                />
                <path
                  d="M106.7 0.578369L110.452 11.2206L114.4 0.578369H117L111.997 13.5497H108.988L105.332 3.07147L101.637 13.5497H98.6272L93.6423 0.578369H96.261L100.209 11.2206L103.982 0.578369H106.7Z"
                  fill="white"
                />
                <path
                  d="M51.8374 17.7732V30.8363H49.0624V21.8922H43.5754V20.1579L46.8285 20.0134C48.7798 19.9217 49.5825 19.3074 49.7359 17.7732H51.8404H51.8374Z"
                  fill="white"
                />
                <path
                  d="M69.2737 24.3497C69.2737 28.1991 65.8342 31.0341 61.2793 31.0341C56.7244 31.0341 53.2849 28.1964 53.2849 24.3497C53.2849 20.503 56.7064 17.6653 61.2793 17.6653C65.8523 17.6653 69.2737 20.503 69.2737 24.3497ZM55.8285 24.3497C55.8285 27.1152 58.0563 28.994 61.2823 28.994C64.5084 28.994 66.7152 27.1152 66.7152 24.3497C66.7152 21.5842 64.4873 19.7053 61.2823 19.7053C58.0773 19.7053 55.8285 21.5842 55.8285 24.3497Z"
                  fill="white"
                />
                <path
                  d="M85.9671 24.3497C85.9671 28.1991 82.5276 31.0341 77.9727 31.0341C73.4178 31.0341 69.9783 28.1964 69.9783 24.3497C69.9783 20.503 73.3997 17.6653 77.9727 17.6653C82.5456 17.6653 85.9671 20.503 85.9671 24.3497ZM72.5218 24.3497C72.5218 27.1152 74.7497 28.994 77.9757 28.994C81.2017 28.994 83.4085 27.1152 83.4085 24.3497C83.4085 21.5842 81.1807 19.7053 77.9757 19.7053C74.7707 19.7053 72.5218 21.5842 72.5218 24.3497Z"
                  fill="white"
                />
                <path
                  d="M19.0016 2.05701V14.4531M19.0016 17.6039V30M36.7473 14.8832L31.7224 5.08347C30.6931 3.18157 28.6873 2 26.4968 2H11.4748C9.27374 2 7.26798 3.18675 6.22816 5.11457L1.26128 14.8677C0.912908 15.5103 0.912908 16.2773 1.26128 16.9199C1.60964 17.5676 2.26415 18.8736 3.01895 20.3816C4.09572 22.5323 5.30973 24.9628 6.24399 26.6937C7.27326 28.6008 9.27902 29.7875 11.4801 29.7875H26.4968C28.6926 29.7875 30.6931 28.606 31.7382 26.6833L36.742 16.9095C37.0851 16.2669 37.0851 15.5051 36.7473 14.8832Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeMiterlimit="10"
                />
              </svg>

              <div className="bg-[#454B6D] rounded-[15px] h-[36px] w-[36px] flex items-center justify-center">
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.44586 14.5319C5.68989 15.0978 6.29327 15.5 7.00027 15.5C7.70728 15.5 8.30898 15.0993 8.55301 14.5319M4.81574 11.8998H3.00561C2.51755 11.8998 2.07129 11.6918 1.78381 11.3278C1.49967 10.9653 1.42445 10.5142 1.57822 10.089L2.65795 8.04423C2.87858 7.44012 2.98889 6.81155 2.98889 6.17532V5.47639C2.98889 3.28326 4.60347 1.5 7.00027 1.5C9.39707 1.5 11.0117 3.28326 11.0117 5.47639V6.17532C11.0117 6.81155 11.122 7.43859 11.3409 8.0427L12.4206 10.0875C12.5761 10.5126 12.5009 10.9638 12.2151 11.3278C11.9293 11.6918 11.4847 11.8998 10.9949 11.8998H4.81574Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>

            <div className="px-5">
              <NavLink
                to="/dashboard"
                className={({
                  isActive,
                }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                    ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.7"
                    d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-[15px]">Dashboard</span>
              </NavLink>

              {(appConfig?.parentId === null ||
                appConfig?.features['Dashboard & Activity feed']) && (
                <>
                  <NavLink
                    to="/activities"
                    className={({
                      isActive,
                    }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                  ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                  >
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.7">
                        <path
                          d="M12.7983 6.37145L5.93821 6.37145"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.0809 11.0382L5.93821 11.0382"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.17322 15.6285L5.9382 15.6285"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M21 15.6857L21 6.31429C21 3.04762 18.6879 1 15.4148 1L6.58516 0.999999C3.31208 0.999999 1 3.03809 1 6.31428L0.999999 15.6857C0.999999 18.9619 3.31208 21 6.58516 21L15.4148 21C18.6879 21 21 18.9619 21 15.6857Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>

                    <span className="text-[15px]">Activity Feed</span>
                  </NavLink>
                </>
              )}
            </div>

            <div className="px-5">
              <div className="pl-[30px] pr-4 text-white text-opacity-50 text-sm font-medium mb-4 font-[sohne-breit] tracking-wide">
                COMMUNITY
              </div>

              {(appConfig?.parentId === null || appConfig?.features['Members']) && (
                <NavLink
                  to="/members"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                  ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.60201 13.1785C11.291 13.1785 14.444 13.7375 14.444 15.9705C14.444 18.2035 11.312 18.7785 7.60201 18.7785C3.91201 18.7785 0.76001 18.2245 0.76001 15.9905C0.76001 13.7565 3.89101 13.1785 7.60201 13.1785Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.60206 9.99144C5.18006 9.99144 3.21606 8.02844 3.21606 5.60644C3.21606 3.18444 5.18006 1.22144 7.60206 1.22144C10.0231 1.22144 11.9871 3.18444 11.9871 5.60644C11.9961 8.01944 10.0461 9.98244 7.63306 9.99144H7.60206Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.4937 8.85321C16.0947 8.62821 17.3277 7.25421 17.3307 5.59121C17.3307 3.95221 16.1357 2.59221 14.5687 2.33521"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.606 12.7039C18.157 12.9349 19.24 13.4789 19.24 14.5989C19.24 15.3699 18.73 15.8699 17.906 16.1829"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>

                  <span className="text-[15px]">Members</span>
                </NavLink>
              )}

              {(appConfig?.parentId === null || appConfig?.features['Members']) && (
                <NavLink
                  to="/circles"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                  ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      opacity="0.7"
                      cx="10"
                      cy="10"
                      r="8.75"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <circle
                      opacity="0.7"
                      cx="9.99999"
                      cy="10.0001"
                      r="4.27941"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                  </svg>

                  <span className="text-[15px]">My Circles</span>
                </NavLink>
              )}

              {(appConfig?.parentId === null || appConfig?.features['Clubs']) && (
                <NavLink
                  to="/clubs"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.7"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.1043 2.17701L12.9317 5.82776C13.1108 6.18616 13.4565 6.43467 13.8573 6.49218L17.9453 7.08062C18.9554 7.22644 19.3573 8.45055 18.6263 9.15194L15.6702 11.9924C15.3797 12.2718 15.2474 12.6733 15.3162 13.0676L16.0138 17.0778C16.1856 18.0698 15.1298 18.8267 14.227 18.3574L10.5732 16.4627C10.215 16.2768 9.78602 16.2768 9.42679 16.4627L5.773 18.3574C4.87023 18.8267 3.81439 18.0698 3.98724 17.0778L4.68385 13.0676C4.75257 12.6733 4.62033 12.2718 4.32982 11.9924L1.37368 9.15194C0.642715 8.45055 1.04464 7.22644 2.05466 7.08062L6.14265 6.49218C6.54354 6.43467 6.89028 6.18616 7.06937 5.82776L8.89574 2.17701C9.34765 1.27433 10.6523 1.27433 11.1043 2.17701Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="text-[15px]">Clubs</span>
                </NavLink>
              )}

              {(appConfig?.parentId === null || appConfig?.features['Events']) && (
                <NavLink
                  to="/events"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                     ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        d="M12.8497 2.25024V4.67024"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8497 15.76V17.784"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.8497 12.3247V7.50366"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.7021 18C19.5242 18 21 16.5426 21 14.7431V12.1506C19.7943 12.1506 18.8233 11.1917 18.8233 10.001C18.8233 8.81035 19.7943 7.85039 21 7.85039L20.999 5.25686C20.999 3.45745 19.5221 2 17.7011 2H4.29892C2.47789 2 1.00104 3.45745 1.00104 5.25686L1 7.93485C2.20567 7.93485 3.17668 8.81035 3.17668 10.001C3.17668 11.1917 2.20567 12.1506 1 12.1506V14.7431C1 16.5426 2.4758 18 4.29787 18H17.7021Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>

                  <span className="text-[15px]">Events</span>
                </NavLink>
              )}
            </div>

            <div className="px-5">
              <div className="pl-[30px] pr-4 text-white text-opacity-50 text-sm font-medium mb-4 font-[sohne-breit] tracking-wide">
                LIBRARY
              </div>

              {(appConfig?.parentId === null || appConfig?.features['Articles']) && (
                <NavLink
                  to="/blogs"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="20"
                    height="22"
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.7"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.7694 5.18127C17.7694 2.43039 15.8887 1.32764 13.181 1.32764H6.82207C4.19751 1.32764 2.23059 2.35521 2.23059 4.99781V19.7216C2.23059 20.4474 3.01154 20.9046 3.64412 20.5497L10.0261 16.9697L16.3529 20.5437C16.9865 20.9006 17.7694 20.4434 17.7694 19.7166V5.18127Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="text-[15px]">Blog</span>
                </NavLink>
              )}

              <NavLink
                to="/media"
                className={({
                  isActive,
                }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
              >
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.7">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11 1.5C16.2459 1.5 20.5 5.75315 20.5 11C20.5 16.2469 16.2459 20.5 11 20.5C5.75315 20.5 1.5 16.2469 1.5 11C1.5 5.75315 5.75315 1.5 11 1.5Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14 10.9951C14 10.184 9.84253 7.58912 9.37091 8.0557C8.8993 8.52228 8.85395 13.424 9.37091 13.9346C9.88788 14.4469 14 11.8063 14 10.9951Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                <span className="text-[15px]">Recordings</span>
              </NavLink>
            </div>
          </div>

          <div className="flex flex-col gap-10">
            <NavLink to="/profile">
              <div className="pl-12 ">
                <div className="relative w-[52px] h-[52px] mb-4">
                  <div className="w-[46px] h-[46px] absolute bottom-0 left-0 bg-blue-200 rounded-full flex items-center justify-center font-bold">
                    {profile?.avatar ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          src={profile.avatar}
                          className="object-cover w-full h-full rounded-full"
                          alt="Profile Avatar"
                        />
                      </div>
                    ) : (
                      <div>{getInitials(profile?.name)}</div>
                    )}
                  </div>

                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute z-0"
                  >
                    <path
                      opacity="0.5"
                      d="M45.0643 46.1579L51.372 31.9137C52.544 29.0896 52.0538 25.7893 50.0874 23.3091L36.6352 6.32259C34.6659 3.83394 31.5614 2.6023 28.5019 3.10961L13.303 6.00975C12.2832 6.17885 11.438 6.84963 11.0408 7.80225C10.6407 8.76333 9.7843 10.6404 8.79546 12.8162C7.38967 15.908 5.80076 19.4084 4.7274 21.9732C3.54417 24.8057 4.03437 28.1117 6.0036 30.5975L19.453 47.5812C21.4166 50.0614 24.5127 51.2958 27.5694 50.797L42.8246 47.9222C43.8416 47.7503 44.6811 47.0795 45.0643 46.1579ZM45.0643 46.1579H45.0671"
                      stroke="#F3BB2B"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M51.6434 24.9943L44.5289 11.0036C43.0697 8.29079 40.2308 6.60303 37.125 6.60303H15.8413C12.7242 6.60303 9.88237 8.29641 8.40895 11.0485L1.36835 24.969C0.877215 25.8873 0.877215 26.9798 1.36835 27.8981C1.86517 28.8248 2.78783 30.6867 3.85811 32.8434C5.37979 35.91 7.10588 39.381 8.42599 41.8495C9.88521 44.5735 12.727 46.2697 15.8442 46.2697H37.1193C40.2279 46.2697 43.0669 44.5847 44.5431 41.8383L51.6348 27.8896C52.1231 26.9713 52.1175 25.8817 51.6434 24.9943Z"
                      stroke="#F3BB2B"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                    />
                    <path
                      opacity="0.5"
                      d="M36.4932 51.7439L48.5739 41.835C50.9017 39.8358 51.9439 36.6635 51.2954 33.5645L46.8578 12.337C46.2093 9.22952 43.9721 6.73399 40.9958 5.81473L26.0351 1.58783C25.0439 1.28047 23.982 1.50041 23.1947 2.17717C22.4018 2.85674 20.7876 4.15386 18.9186 5.654C16.2623 7.7886 13.2577 10.2052 11.1394 12.0183C8.80032 14.0204 7.75253 17.1983 8.40103 20.3058L12.8386 41.5305C13.4871 44.6295 15.7129 47.1222 18.6835 48.0471L33.684 52.3191C34.6751 52.6208 35.7314 52.3981 36.4903 51.7467H36.4932V51.7439Z"
                      stroke="#F3BB2B"
                      strokeMiterlimit="10"
                    />
                  </svg>
                </div>

                <div className="flex items-center gap-3">
                  <h3 className="text-lg leading-tight text-white max-w-[150px] truncate">
                    {profile?.name ?? 'Michael Johnson'}
                  </h3>

                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.4">
                      <path
                        d="M12.1122 9.45169H5.88763"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.60175 6.95166L12.1123 9.45145L9.60175 11.9517"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <rect
                        x="0.75"
                        y="1.20166"
                        width="16.5"
                        height="16.5"
                        rx="6.25"
                        stroke="white"
                        strokeWidth="1.5"
                      />
                    </g>
                  </svg>
                </div>

                <p className="text-[#F3BB2B] text-xs">Gold Level member</p>
              </div>
            </NavLink>

            <div>
              {appConfig?.parentId === null && (
                <Link
                  to="/preferences"
                  className="flex items-center justify-between w-full px-12 py-4 text-white bg-black border-t border-white bg-opacity-5 border-opacity-20"
                >
                  <span>Preferences</span>

                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.7526 6.45689L17.1577 5.44546C16.6545 4.58964 15.5391 4.2944 14.6644 4.78546V4.78546C14.2481 5.02577 13.7513 5.09395 13.2835 4.97496C12.8158 4.85597 12.4156 4.55959 12.1712 4.15117C12.0139 3.89157 11.9294 3.59589 11.9262 3.29403V3.29403C11.9404 2.81007 11.754 2.34108 11.4096 1.99392C11.0652 1.64675 10.592 1.45097 10.0978 1.45117H8.89928C8.41516 1.45117 7.95099 1.64018 7.60948 1.97639C7.26798 2.31259 7.07729 2.76827 7.07962 3.2426V3.2426C7.06527 4.22192 6.25085 5.00842 5.25121 5.00831C4.94312 5.00518 4.64134 4.92239 4.37638 4.76832V4.76832C3.50169 4.27725 2.38637 4.57249 1.8831 5.42832L1.24447 6.45689C0.741798 7.31164 1.03903 8.40371 1.90934 8.89974V8.89974C2.47506 9.21975 2.82355 9.81115 2.82355 10.4512C2.82355 11.0912 2.47506 11.6826 1.90934 12.0026V12.0026C1.04013 12.4953 0.742579 13.5847 1.24447 14.4369V14.4369L1.8481 15.4569C2.08391 15.8738 2.47955 16.1814 2.94748 16.3117C3.41541 16.442 3.91705 16.3842 4.34138 16.1512V16.1512C4.75853 15.9127 5.25563 15.8473 5.72219 15.9697C6.18874 16.092 6.58609 16.3919 6.82592 16.8026C6.98317 17.0622 7.06767 17.3579 7.07087 17.6597V17.6597C7.07087 18.6491 7.88948 19.4512 8.89928 19.4512H10.0978C11.1042 19.4512 11.9214 18.6543 11.9262 17.6683V17.6683C11.9239 17.1925 12.1158 16.7355 12.4592 16.3991C12.8026 16.0626 13.269 15.8746 13.7546 15.8769C14.062 15.8849 14.3625 15.9674 14.6295 16.1169V16.1169C15.5019 16.6094 16.6165 16.3182 17.1227 15.4655V15.4655L17.7526 14.4369C17.9964 14.0269 18.0633 13.5385 17.9386 13.08C17.8138 12.6215 17.5076 12.2307 17.0877 11.994V11.994C16.6679 11.7574 16.3617 11.3665 16.2369 10.908C16.1121 10.4495 16.1791 9.96119 16.4229 9.55117C16.5814 9.27996 16.8109 9.05509 17.0877 8.89974V8.89974C17.9528 8.40398 18.2494 7.31829 17.7526 6.46546V6.46546V6.45689Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <ellipse
                        cx="9.50293"
                        cy="10.451"
                        rx="2.51953"
                        ry="2.46857"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </Link>
              )}

              {appConfig?.isAdmin && (
                <Link
                  to="/admin/members"
                  className="flex items-center justify-between w-full px-12 py-4 text-white bg-black border-t border-white bg-opacity-10 border-opacity-20"
                >
                  <span>Open admin</span>

                  <svg
                    width="18"
                    height="19"
                    viewBox="0 0 18 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        d="M12.1123 9.45169H5.8877"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.60181 6.95166L12.1124 9.45145L9.60181 11.9517"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <rect x="0.5" y="0.95166" width="17" height="17" rx="6.5" stroke="white" />
                    </g>
                  </svg>
                </Link>
              )}

              <button
                className="flex items-center justify-between w-full px-12 py-4 text-white bg-black border-t border-white bg-opacity-20 border-opacity-20"
                onClick={logoutHandler}
              >
                <span>Logout</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="h-screen w-full overflow-y-scroll custom-scrollbar py-[40px]">
          <div className="max-w-[1265px] w-[87%] mx-auto">{children}</div>
        </div>
      </div>
    </>
  );
};

interface DashboardLayoutProps {
  children: ReactNode;
}
