import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CreateSubAccountProps } from 'src/common/types/sub-account';

export const SubAdminDetailsTab = ({
  initialData,
  setCurrentStep,
  disabled,
  collectData,
  onClose,
}: CreateSubAccountProps) => {
  const formik = useFormik({
    initialValues: initialData.detail,
    validationSchema: Yup.object({
      name: Yup.string().trim().required().max(50),
      email: Yup.string().email().required(),
    }),
    onSubmit: values => {
      const data = { ...initialData, detail: values };
      collectData(data);
      setCurrentStep(2);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col text-black gap-7">
        <label className="space-y-2">
          <div>
            <div className="font-bold">Full Name</div>

            <div className="text-sm text-black text-opacity-70">
              The full name of the person that will have access to the admin account. This is useful
              for you to keep track of the admin account.
            </div>
          </div>

          <input
            type="text"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Enter full name of the person"
            className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
              formik.touched.name && formik.errors.name
                ? '!border-b-[#F72300] bg-[#FFF2F2]'
                : '!border-b-[#005AFF]'
            }`}
          />
        </label>

        <label className="space-y-2">
          <div>
            <div className="font-bold">Email address</div>

            <div className="text-sm text-black text-opacity-70">
              Email of the person that will have access to the admin account. We will send an email
              to this address with instructions on how to access the admin account.
            </div>
          </div>

          <input
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder="Enter email address"
            className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
              formik.touched.email && formik.errors.email
                ? '!border-b-[#F72300] bg-[#FFF2F2]'
                : '!border-b-[#005AFF]'
            }`}
          />
        </label>

        <div className="flex items-center gap-6 mt-4">
          <button
            className="px-[30px] py-5 text-xs text-center font-medium text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit] w-fit"
            disabled={disabled}
            type="submit"
          >
            Save details & set access
          </button>

          <button className="text-black underline text-opacity-70" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  );
};
