import moment from 'moment-timezone';
import { Event } from '../types/event';
import { DateArray, EventAttributes, createEvent } from 'ics';

/* eslint-disable no-useless-escape */
export function isEmptyObject(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export function extractSubString([beg, end]: Array<string>) {
  const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
  const normalise = (str: string) => str.slice(beg.length, end.length * -1);
  return function (str: string) {
    const result = str.match(matcher);

    if (result) {
      return result.map(normalise);
    } else {
      return null;
    }
  };
}

export function replaceAllSubstr(str: string, mapObj: Record<string, any>) {
  const re = new RegExp(Object.keys(mapObj).join('|'), 'gi');

  return str.replace(re, function (matched) {
    return mapObj[matched];
  });
}

export const setToLocalStorage = (itemName: string, value: unknown) => {
  window.localStorage.setItem(itemName, JSON.stringify(value));
};

export const removeFromLocalStorage = (itemName: string) =>
  window.localStorage.removeItem(itemName);

export const getFromLocalStorage = (itemName: string) => window.localStorage.getItem(itemName);

export const isValidURL = (string: string) => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
  return res !== null;
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const removeNonNumeric = (input: string) => input.replace(/[^0-9]/g, '');

export const commaSeparator = (input: string) => {
  const removedNonNumeric = removeNonNumeric(input);
  return removedNonNumeric.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const cutEmail = (email: string) => {
  return email.length > 20 ? email.substring(0, 17) + '...' : email;
};

export const validateEmail = (email: string) =>
  Boolean(
    email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ),
  );

// using in Datepicker
export const getMinMaxDateFromNow = (rangeValue: number) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - rangeValue);
  return date;
};

export const getMinDate = () => {
  const date = new Date(1970, 0, 1);
  return date;
};

export const queryParams = (params: any) => {
  const searchParams = new URLSearchParams();

  const buildParams = (obj: any, prefix?: any) => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        const paramKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === 'object' && value !== null) {
          buildParams(value, paramKey);
        } else {
          searchParams.append(paramKey, value);
        }
      }
    }
  };

  buildParams(params);

  return searchParams.toString();
};

export const formatDate = (inputDate: string) => {
  const date = new Date(inputDate);

  let hour = date.getHours();
  const ampm = hour >= 12 ? 'pm' : 'am';
  hour = hour % 12;
  hour = hour ? hour : 12;

  const minute = String(date.getMinutes()).padStart(2, '0');

  return `${date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })}, ${hour}:${minute}${ampm}`;
};

export const formatDateTime = (inputDate: string) => {
  const momentObj = moment(inputDate);
  const formattedDateTime = momentObj.format('DD MMM YYYY, hh:mm A');
  return formattedDateTime;
};

export const getInitials = (name: string) => {
  if (name) {
    return name
      .split(' ')
      .slice(0, 2)
      .map((word: string) => word.charAt(0))
      .join('')
      .toUpperCase();
  } else {
    return 'NA';
  }
};

export const handleAddToCalendar = (event: Event) => {
  const startTimeArray = formatDateTimeArray(event.startTime);
  const endTimeArray = formatDateTimeArray(event.endTime);

  const attendees = event.hosts
    .filter(host => host.user.id !== event.user.id)
    .map(host => ({
      name: host.user.name,
      email: host.user.email,
    }));

  const calendarEvent: EventAttributes = {
    start: startTimeArray,
    end: endTimeArray,
    title: event.title,
    description: event.description,
    location: event.room?.roomName || '',
    url: event.meetingLink,
    status: 'CONFIRMED',
    categories: event.topics.map(topic => topic.name),
    organizer: { name: event?.user?.name, email: event?.user?.email },
    attendees,
  };

  createEvent(calendarEvent, (error, value) => {
    if (error) {
      console.error(error);
    } else {
      downloadIcsFile(value, `${event.title.replace(/\s+/g, '_')}.ics`);
    }
  });
};

const formatDateTimeArray = (dateTimeString: string): DateArray => {
  const momentObject = moment(dateTimeString);

  return [
    momentObject.year(),
    momentObject.month() + 1, // Months are zero-based, so add 1
    momentObject.date(),
    momentObject.hours(),
    momentObject.minutes(),
  ];
};

const downloadIcsFile = (icsData: string, fileName: string) => {
  const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(link.href);
};
