import { Link } from 'react-router-dom';
import { Skill } from 'src/common/types/skill';
import { User } from 'src/common/types/user';
import { AddMemberToCirclePopup } from '../popups/AddMemberToCirclePopup';
import { MemberMeetingPopup } from '../popups/MemberMeetingPopup';
import { useContext, useState } from 'react';
import { getInitials } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

interface EventMemberCardProps {
  user: User;
}

export const EventMemberCard = ({ user }: EventMemberCardProps) => {
  const memberType = 'Gold Level Member';
  const [showAddToCirclePopup, setShowAddToCirclePopup] = useState(false);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);
  const { appConfig } = useContext(AuthContext);

  const useMeeting =
    appConfig?.parentId === null ||
    appConfig?.features['Members'].subFeatures.includes('Use meetings');

  const userChat =
    appConfig?.parentId === null || appConfig?.features['Members'].subFeatures.includes('Use chat');

  const userCircle =
    appConfig?.parentId === null ||
    appConfig?.features['Members'].subFeatures.includes('Use circles');

  return (
    <div
      className="relative rounded-3xl w-[300px] h-fit border border-[#E5E5E5] bg-white mt-3"
      key={user?.id}
    >
      {showAddToCirclePopup && userCircle && (
        <AddMemberToCirclePopup onClose={() => setShowAddToCirclePopup(false)} member={user} />
      )}

      {showMeetingPopup && useMeeting && (
        <MemberMeetingPopup member={user} onClose={() => setShowMeetingPopup(false)} />
      )}

      <Link to={'/members/' + user?.id}>
        <div className="absolute rounded-full left-6 -top-4 w-[70px] h-[70px] overflow-hidden border-[5px] border-white bg-blue-400 flex items-center justify-center font-bold text-lg">
          {user?.avatar ? (
            <div className="flex items-center justify-center w-full h-full">
              <img
                src={user.avatar}
                className="object-cover w-full h-full rounded-full"
                alt="Profile Avatar"
              />
            </div>
          ) : (
            <div>{getInitials(user?.name)}</div>
          )}
        </div>
      </Link>

      <div className="flex flex-col justify-between h-full">
        <Link
          to={
            appConfig?.parentId === null || appConfig?.features['Members'] ? '/members/' + 1 : '#'
          }
        >
          <div className="px-10 pt-16">
            <h3
              className="text-2xl font-bold mt-3.5 mb-1.5 leading-none whitespace-pre-wrap cursor-pointer"
              dangerouslySetInnerHTML={{
                __html: user?.name.replace(/\s/, '<br>'),
              }}
            ></h3>

            {/* use this class for silver level member 'text-[#76835D]' */}
            <p className={`text-xs mb-3.5 font-[sohne-breit] text-[#CD9400]`}>{memberType}</p>

            <h6 className="text-sm leading-tight !border-0 mb-[30px]">{user?.company?.role}</h6>

            <div className="flex flex-wrap gap-2 text-[#325EAE] mb-[25px]">
              {user?.skills?.map((skill: Skill) => (
                <div
                  className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE]"
                  key={skill.id}
                >
                  {skill.name}
                </div>
              ))}
            </div>
          </div>
        </Link>

        {user?.bio && (
          <div className="py-[20px] px-[40px] border-t border-[#D6D6D6]">
            <p>{user?.bio?.length > 90 ? user?.bio?.slice(0, 90) + '...' : user?.bio}</p>
          </div>
        )}

        <div className="bg-[#005AFF05] rounded-bl-lg rounded-br-lg flex justify-between h-[50px] text-[#00039A] border-t border-[#D6D6D6]">
          <div className="flex">
            <div className="border-r border-[#E5E5E5] px-3 py-2.5 flex items-center">
              <button disabled={!userChat}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.6571 14.6559C12.2122 17.1011 8.59182 17.6294 5.62913 16.2592C5.19177 16.0831 4.83319 15.9408 4.4923 15.9408C3.54279 15.9464 2.36093 16.8671 1.74669 16.2536C1.13244 15.6393 2.05381 14.4565 2.05381 13.5012C2.05381 13.1603 1.91713 12.8081 1.74106 12.3699C0.370265 9.4077 0.899287 5.78615 3.34421 3.34177C6.46528 0.219546 11.536 0.219546 14.6571 3.34096C17.7838 6.46801 17.7782 11.5345 14.6571 14.6559Z"
                      stroke="#00369A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.1515 9.33042H12.1587"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.94431 9.33042H8.95151"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73716 9.33042H5.74436"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <div className="border-r border-[#E5E5E5] px-3 py-2.5 flex items-center">
              <button disabled={!useMeeting} onClick={() => setShowMeetingPopup(true)}>
                <svg
                  width="16"
                  height="18"
                  viewBox="0 0 16 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 17.0446L1.028 13.0756L1 4.96965M8 17.0446V8.92465M8 17.0446L14.972 13.0756L15 4.96965M1 4.96965L8 8.92465M1 4.96965L8 1.01465M8 8.92465L15 4.96965M15 4.96965L8.007 1.01465"
                    stroke="#00369A"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="border-l border-[#E5E5E5] px-3 py-2.5 flex items-center">
            <button disabled={!userCircle} onClick={() => setShowAddToCirclePopup(true)}>
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.375" cy="10.0298" r="8.75" stroke="#005AFF" strokeWidth="1.5" />
                <circle cx="10.3751" cy="10.0592" r="4.27941" stroke="#005AFF" strokeWidth="1.5" />
                <rect x="12.125" y="3.52979" width="9" height="13" rx="3" fill="#FAFCFF" />
                <path
                  d="M16.625 7.52979V12.5298"
                  stroke="#005AFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M14.125 10.0298H19.125"
                  stroke="#005AFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
