import { usePagination, DOTS } from './usePagination';

interface PaginationProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
}

export const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}: PaginationProps) => {
  const paginationRange = usePagination({
    totalCount,
    siblingCount,
    currentPage,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="inline-flex items-center text-black text-md border border-[#E5E5E5] rounded-md">
      <li
        onClick={() => onPageChange(1)}
        className={`flex items-center gap-3 px-7 py-4 border-r border-[#E5E5E5] font-normal cursor-pointer`}
      >
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 16.5L1 9M9 1.5L1 9M1 9H15"
            stroke="black"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>

        <span>First Page</span>
      </li>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li
              key={index}
              className="flex items-center justify-center w-[60px] gap-3 py-4 border-r border-[#E5E5E5] font-normal cursor-pointer"
            >
              &#8230;
            </li>
          );
        }

        // Render our Page Pills
        return (
          <li
            key={index}
            onClick={() => onPageChange(+pageNumber)}
            className={`flex items-center justify-center w-[60px] gap-3 py-4 border-r border-[#E5E5E5] font-normal cursor-pointer ${
              currentPage === +pageNumber ? 'border-b-2 border-b-[#F35800]' : ''
            }`}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        onClick={() => onPageChange(+lastPage)}
        className={`flex items-center gap-3 px-7 py-4 border-[#E5E5E5] font-normal cursor-pointer`}
      >
        <span>Last Page</span>

        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 16.5L15 9M7 1.5L15 9M15 9H1"
            stroke="black"
            strokeLinecap="square"
            strokeLinejoin="round"
          />
        </svg>
      </li>
    </ul>
  );
};
