import { Link } from 'react-router-dom';
import { Activity } from 'src/common/types/activity';
import { Club } from 'src/common/types/clubs';
import { formatDateTime, getInitials } from 'src/common/utils';

export const ClubCreatedActivity = ({
  activity,
  showUserInfo = true,
}: ClubCreatedActivityProps) => {
  const convertJsonToClub = (jsonData: any): Club => {
    return {
      id: jsonData.id,
      user: jsonData.user,
      title: jsonData.title,
      description: jsonData.description,
      topics: jsonData?.topics ?? [],
      members: jsonData?.members ?? [],
      events: [],
      createdAt: jsonData.createdAt,
      updatedAt: jsonData.updatedAt,
    };
  };

  const club = convertJsonToClub(activity.data) as Club;

  return (
    <div className="rounded-2xl bg-white border border-[#E5E5E5] shadow-md mb-[10px]">
      <div className="bg-[#F8F9FB80] p-[10px] border-b border-[#E5E5E5] flex items-center rounded-t-2xl">
        <svg
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-[7px]"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V16C34 20.4183 30.4183 24 26 24H8C3.58172 24 0 20.4183 0 16V8Z"
            fill="#F7ED00"
          />
          <path
            opacity="0.7"
            d="M22.7647 9.3088C23.2773 8.66201 23.7864 8.40419 24.2811 8.53989C25.0806 8.76604 25.4391 9.95559 25.4785 10.0913C25.4928 10.141 25.5 10.1908 25.5 10.2451V13.5876C25.5 14.8133 24.7077 15.8129 23.7362 15.8129H10.7872C9.52531 15.8129 8.5 14.5193 8.5 12.9272V10.0415C8.5 9.97368 8.51434 9.90132 8.53585 9.83799C8.58604 9.71135 9.04133 8.60773 9.84795 8.5037C10.3499 8.44038 10.8374 8.77508 11.2891 9.50781M12.7984 15.8039V17.5M21.2231 15.8039V17.5M11.368 11.9276H22.6607V8.25493C22.6607 7.28701 22.0369 6.5 21.2697 6.5H12.5941C11.9201 6.5 11.368 7.19202 11.368 8.04688V11.9276Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="text-[14px] text-black text-opacity-70 mr-5 leading-none">
          New club created
        </span>

        <span className="text-[13px] text-black leading-none">
          {formatDateTime(club?.createdAt ?? '')}
        </span>
      </div>

      {showUserInfo && (
        <div className="relative border-b border-[#E5E5E5]">
          <div className="absolute rounded-full -left-5 w-[60px] h-[60px] overflow-hidden border-[5px] border-white my-auto inset-y-0 flex items-center justify-center bg-blue-200 font-bold text-lg">
            {activity.user?.avatar ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={activity.user?.avatar}
                  className="object-cover w-full h-full rounded-full"
                  alt="Profile Avatar"
                />
              </div>
            ) : (
              <div>{getInitials(activity.user?.name)}</div>
            )}
          </div>

          <div className="py-[27px] pl-[50px]">
            <div className="flex items-center mb-1 leading-none">
              <span className="font-bold text-black text-[20px] mr-2.5">
                {club?.user?.name ?? 'Deleted User'}
              </span>

              <span className="text-[10px] bg-[#CD9400] p-[5px] rounded-md text-white font-[sohne-breit]">
                Gold
              </span>
            </div>

            <div className="text-base text-black">{activity.user?.company?.role}</div>
          </div>
        </div>
      )}

      <div className="px-[50px] py-[30px] text-[17px] text-black">
        <h3 className="text-[20px] mb-[10px] font-bold leading-none">{club?.title}</h3>

        <p className="mb-[15px]">{club?.description}</p>

        <div className="flex flex-wrap gap-1 text-[#325EAE] items-center mb-[20px]">
          {club.topics.map((topic, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE33] font-medium bg-white capitalize"
              key={index}
            >
              {topic.name}
            </div>
          ))}
        </div>

        {club.members.length > 0 && (
          <div className="flex items-center gap-2.5">
            <div className="flex">
              {club.members.slice(0, 4).map(member => (
                <div
                  className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 flex items-center justify-center font-bold text-xs bg-blue-400"
                  key={member.id}
                >
                  {member?.user?.avatar ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <img
                        src={member?.user?.avatar}
                        className="object-cover w-full h-full rounded-full"
                        alt="Profile Avatar"
                      />
                    </div>
                  ) : (
                    <div>{getInitials(member?.user?.name ?? '')}</div>
                  )}
                </div>
              ))}

              {club.members.length > 4 && (
                <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                  +{club.members.length - 4}
                </div>
              )}
            </div>

            <div className="flex items-center text-sm">
              <div className="text-black mr-2.5">
                <span className="font-black text-[15px]">{club.members.length} </span>
                <span>members</span>
              </div>

              {/* <div className="h-3 border-l border-black border-opacity-50"></div>

              <div className="text-opacity-70 ml-2.5 underline">0 in common</div> */}
            </div>
          </div>
        )}
      </div>

      <div className="bg-[#F8F9FB80] border-t border-[#E5E5E5] flex justify-between">
        <div className="py-[20px] pl-[30px] flex gap-8">
          <div className="flex items-center gap-[8px]">
            <button>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5536 11.2158C9.54794 11.2158 12.1072 11.6934 12.1072 13.6012C12.1072 15.509 9.56498 16.0002 6.5536 16.0002C3.55846 16.0002 1 15.5269 1 13.6183C1 11.7096 3.54141 11.2158 6.5536 11.2158Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.55276 8.49274C4.58684 8.49274 2.99268 6.81563 2.99268 4.74637C2.99268 2.67711 4.58684 1 6.55276 1C8.51787 1 10.112 2.67711 10.112 4.74637C10.1193 6.80794 8.53654 8.48505 6.57792 8.49274H6.55276Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.1482 7.51989C13.4477 7.32766 14.4485 6.15377 14.451 4.73297C14.451 3.33267 13.481 2.17074 12.2091 1.95117"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8618 10.8105C15.1208 11.0079 15.9998 11.4727 15.9998 12.4296C15.9998 13.0883 15.5859 13.5155 14.917 13.7829"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <span className="text-black font-bold leading-none text-[15px]">
              {club.members.length}
            </span>
          </div>
        </div>

        <div className="flex">
          <Link
            to={`/clubs/${club.id}`}
            className="border-l border-[#E5E5E5] px-[25px] flex items-center justify-center"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.23804 1L11.238 6L6.23804 11"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
              <path
                d="M11.2382 6H0.761963"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

interface ClubCreatedActivityProps {
  activity: Activity;
  showUserInfo?: boolean;
}
