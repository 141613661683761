import { useContext, useEffect } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { appConfig as appConfig1 } from 'src/config/appConfig';

export const SocialAccountsTab = () => {
  const { accessToken, profile, appConfig, fetchAppConfig } = useContext(AuthContext);
  const notify = useNotification();

  const {
    sendRequest: disconnectLinkedin,
    isLoading: disconnectingAccount,
    error: errorDisconnectingLinkedin,
  } = useHttp();

  useEffect(() => {
    if (errorDisconnectingLinkedin) {
      notify(errorDisconnectingLinkedin, 'error');
      fetchAppConfig();
    }
  }, [errorDisconnectingLinkedin]);

  const handleAccountDisconnect = async (type: string) => {
    await disconnectLinkedin(
      {
        url: `social-media/${type}`,
        method: 'DELETE',
        accessToken,
      },
      () => {
        notify('Linkedin disconnected successfully', 'success');
        fetchAppConfig();
      },
    );
  };

  const handleLinkedinLogin = () => {
    const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID || '86r3ygy46eikry';
    const callbackUrl =
      process.env.REACT_APP_LINKEDIN_CALLBACK_URL ||
      'https://be.tmrw100dev.assetize.today/auth/linkedin/callback';

    const scopes = ['openid', 'email', 'profile', 'w_member_social'];

    const scopesString = scopes.join('%20');

    const linkedinAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${callbackUrl}&state=${profile?.id}&scope=${scopesString}`;

    window.location.href = linkedinAuthUrl;
  };

  return (
    <>
      <div className="bg-white border border-[#E5E5E5] rounded-2xl p-16 shadow-lg">
        <h1 className="font-[sohne-breit] text-black text-2xl mb-10">Social accounts</h1>

        <div className="py-[30px] first-of-type:border-t border-b border-[#E5E5E5] flex items-center justify-between">
          <div className="flex items-center gap-2.5">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.0008 0.5H1.99921C1.46898 0.5 0.960477 0.7107 0.585553 1.08575C0.210629 1.4608 0 1.96947 0 2.49987V16.5001C0 17.0305 0.210629 17.5392 0.585553 17.9143C0.960477 18.2893 1.46898 18.5 1.99921 18.5H16.0008C16.5309 18.4997 17.0392 18.2889 17.4141 17.9139C17.789 17.5389 17.9997 17.0304 18 16.5001V2.49987C18 1.96947 17.7894 1.4608 17.4144 1.08575C17.0395 0.7107 16.531 0.5 16.0008 0.5ZM5.44599 15.9936H2.76291V7.33268H5.44599V15.9936ZM4.09134 6.19807C3.77461 6.20043 3.46432 6.10864 3.19982 5.93434C2.93532 5.76003 2.72854 5.51107 2.60569 5.21903C2.48285 4.92699 2.44948 4.60503 2.50982 4.29399C2.57016 3.98296 2.72149 3.69685 2.94462 3.47198C3.16775 3.2471 3.45262 3.09358 3.76309 3.0309C4.07357 2.96821 4.39566 2.99918 4.68851 3.11988C4.98137 3.24058 5.23178 3.44557 5.40801 3.70884C5.58423 3.97212 5.67831 4.28181 5.67831 4.59866C5.6791 4.80791 5.63867 5.01527 5.55934 5.2089C5.48001 5.40253 5.36334 5.57863 5.21597 5.72715C5.06861 5.87567 4.89344 5.9937 4.70048 6.0745C4.50751 6.1553 4.30053 6.19729 4.09134 6.19807ZM15.5004 15.9996H12.8304V11.4504C12.8304 10.2026 12.3539 9.50536 11.3697 9.50536C10.2975 9.50536 9.73511 10.2312 9.73511 11.4504V15.9996H7.16164V7.33268H9.73511V8.49709C10.0099 8.05342 10.3949 7.6885 10.8526 7.43787C11.3102 7.18724 11.825 7.05944 12.3467 7.06691C14.1851 7.06691 15.5004 8.1896 15.5004 10.5113V15.9996Z"
                fill="#0B67C2"
              />
            </svg>

            <span className="text-lg font-bold leading-none text-black text-opacity-50">
              LinkedIn
            </span>
          </div>

          {!appConfig?.connectedAccounts.includes('linkedin') ? (
            <button
              onClick={handleLinkedinLogin}
              className="px-[17px] py-[13px] text-[10px] font-[sohne-breit] tracking-widest text-black rounded-lg focus:outline-none border border-black leading-none"
            >
              Connect
            </button>
          ) : (
            <button
              className="text-[14px] font-bold tracking-widest text-black rounded-lg focus:outline-none underline leading-none"
              disabled={disconnectingAccount}
              onClick={() => handleAccountDisconnect('linkedin')}
            >
              Disconnect
            </button>
          )}
        </div>

        <div className="py-[30px] first-of-type:border-t border-b border-[#E5E5E5] flex items-center justify-between">
          <div className="flex items-center gap-2.5">
            <svg
              width="21"
              height="19"
              viewBox="0 0 21 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21 2.6256C20.2138 2.99352 19.3797 3.23495 18.5256 3.34184C19.4256 2.77378 20.0988 1.87928 20.4195 0.825491C19.5742 1.35565 18.6492 1.72915 17.6845 1.9298C17.0347 1.20212 16.1759 0.72054 15.2408 0.559455C14.3058 0.398371 13.3465 0.566746 12.5112 1.03857C11.6759 1.51039 11.011 2.2594 10.6193 3.1699C10.2275 4.08039 10.1308 5.1017 10.3439 6.07604C8.63109 5.98575 6.95544 5.5161 5.42584 4.69761C3.89624 3.87912 2.54692 2.7301 1.46554 1.32522C0.914789 2.32622 0.746031 3.51108 0.993597 4.63875C1.24116 5.76641 1.88646 6.75218 2.79819 7.39549C2.11437 7.37332 1.44551 7.17862 0.847588 6.82768C0.847588 6.84674 0.847588 6.86581 0.847588 6.88487C0.848056 7.9347 1.19241 8.95206 1.8223 9.76455C2.45218 10.577 3.32885 11.1347 4.30372 11.3429C3.6693 11.5248 3.0039 11.5514 2.35828 11.4206C2.63332 12.324 3.16906 13.1141 3.89054 13.6803C4.61203 14.2464 5.48316 14.5604 6.38205 14.5782C4.85609 15.8421 2.97173 16.5274 1.03207 16.524C0.687194 16.5241 0.342601 16.5032 0 16.4614C1.85185 17.7117 3.98821 18.4132 6.18521 18.4924C8.38221 18.5717 10.559 18.0258 12.4874 16.912C14.4158 15.7981 16.0249 14.1574 17.146 12.1615C18.2672 10.1657 18.8591 7.88839 18.8597 5.56815C18.8597 5.37207 18.8597 5.17599 18.8468 4.97991C19.6917 4.33681 20.4209 3.53951 21 2.6256Z"
                fill="#41C3FF"
              />
            </svg>

            <span className="text-lg leading-none text-black text-opacity-50">Twitter</span>
          </div>

          {!appConfig?.connectedAccounts.includes('twitter') ? (
            <a
              href={`${appConfig1.serviceUrl}/auth/twitter?userId=${profile?.id}`}
              className="px-[17px] py-[13px] text-[10px] font-[sohne-breit] tracking-widest text-black rounded-lg focus:outline-none border border-black leading-none"
            >
              Connect
            </a>
          ) : (
            <button
              className="text-[14px] font-bold tracking-widest text-black rounded-lg focus:outline-none underline leading-none"
              disabled={disconnectingAccount}
              onClick={() => handleAccountDisconnect('twitter')}
            >
              Disconnect
            </button>
          )}
        </div>
      </div>
    </>
  );
};
