import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHttp, useNotification } from 'src/common/hooks';
import { Club } from 'src/common/types/clubs';
import { User } from 'src/common/types/user';
import { getInitials, queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { Pagination } from '../pagination/Pagination';

interface ClubTabProps {
  member: User;
  setCount: (count: number) => void;
}

export const MemberClubsTab = ({ member, setCount }: ClubTabProps) => {
  const notify = useNotification();
  const { accessToken, profile } = useContext(AuthContext);

  const [clubs, setClubs] = useState<Club[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const { sendRequest: getClubs, isLoading: isGettingClubs, error: errorGettingClubs } = useHttp();

  useEffect(() => {
    getClubsHandler();
  }, [currentPage]);

  useEffect(() => {
    if (errorGettingClubs) {
      notify(errorGettingClubs, 'error');
    }
  }, [errorGettingClubs]);

  const getClubsHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        userId: member.parentId ?? member.id,
      },
      offset,
      limit: perPage,
    };

    await getClubs(
      {
        url: 'clubs?' + queryParams(params),
        method: 'GET',
        accessToken,
      },

      (res: any) => {
        setClubs(res.data);
        setTotalCount(res.total);
        setCount(res.total);
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {clubs.map(club => (
        <div
          className="py-[50px] px-[55px] rounded-2xl border border-[#E5E5E5] mb-2.5 shadow-md bg-cover bg-center"
          style={{
            backgroundImage:
              'linear-gradient(to right, rgba(255, 255, 255), rgba(255, 255, 255, 97%), rgb(251 251 251 / 40%)), url("/assets/1.png")',
          }}
          key={club.id}
        >
          <div className="flex items-start justify-between mb-5">
            <div className="max-w-[50%] text-black">
              <h3 className="text-[22px] font-bold text-opacity-50 leading-tight underline w-fit mb-3.5">
                {club.title}
              </h3>

              <p>
                {club.description.length > 100
                  ? club.description.slice(0, 100) + '...'
                  : club.description}
              </p>
            </div>

            <Link
              to={`/clubs/${club.id}`}
              className="px-[13px] py-[10px] text-[10px] font-[sohne-breit] tracking-widest text-white bg-[#2F3248] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              See Club details
            </Link>
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm text-black">
              <span className="mr-2 text-opacity-70">Member since</span>
              <span>
                {moment(
                  club.members.find(member => member.user?.id === profile?.id)?.createdAt,
                ).format('LL')}
              </span>
            </div>

            <div className="flex items-center gap-6">
              <div className="flex">
                {club.members.slice(0, 4).map(member => (
                  <div
                    className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
                    key={member.id}
                  >
                    {member.user?.avatar ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          src={member.user?.avatar}
                          className="object-cover w-full h-full rounded-full"
                          alt="Profile Avatar"
                        />
                      </div>
                    ) : (
                      <div>{getInitials(member.user?.name ?? '')}</div>
                    )}
                  </div>
                ))}

                {club.members.length > 4 && (
                  <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                    +{club.members.length - 4}
                  </div>
                )}
              </div>

              <div className="flex items-center text-sm">
                <div className="text-black mr-2.5">
                  <span className="font-black">{club.members.length} </span>
                  <span>members</span>
                </div>

                <div className="h-3 border-l border-black border-opacity-50"></div>

                <div className="text-opacity-70 ml-2.5 underline">
                  {club.commonMembers} in common
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      {clubs.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!clubs.length && !isGettingClubs && (
        <div className={`text-center block p-8`}>No records found</div>
      )}
    </>
  );
};
