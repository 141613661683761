import { Outlet } from 'react-router-dom';
import { AdminLayout } from '../layouts/AdminLayout';

export const AdminPage = () => {
  return (
    <>
      <div className="hidden h-full md:block">
        <AdminLayout>
          <Outlet />
        </AdminLayout>
      </div>

      <div className="flex flex-col items-center justify-center h-full px-4 md:hidden">
        <p className="font-linear-sans mt-5 text-[22px] text-black font-semibold">
          Sorry, for a better experience, we kindly ask you to use a desktop or laptop device.
        </p>
      </div>
    </>
  );
};
