export default function ClubArticleTab() {
  return (
    <>
      <div className="pt-[40px]">
        <div className="flex gap-2.5 flex-wrap mb-10">
          {/* <div className="py-[50px] pl-[40px] pr-[60px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px]">
            <div className="mb-2.5 text-sm leading-none tracking-wider">August 14, 2023</div>

            <h3 className="underline font-bold leading-tight mb-[15px] text-[22px]">
              How to invest your first 100k
            </h3>

            <div className="flex mb-5">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>

            <button className="text-sm font-bold underline">Read article</button>
          </div>

          <div className="py-[50px] pl-[40px] pr-[60px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px]">
            <div className="mb-2.5 text-sm leading-none tracking-wider">August 14, 2023</div>

            <h3 className="underline font-bold leading-tight mb-[15px] text-[22px]">
              How to invest your first 100k
            </h3>

            <div className="flex mb-5">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>

            <button className="text-sm font-bold underline">Read article</button>
          </div> */}

          <div className="py-[50px] px-[40px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px] relative">
            <h3 className="font-bold leading-tight mb-[7px] text-[22px]">Suggest an article</h3>

            <p className="mb-2.5">You can suggest an article by clicking the button below</p>

            <div className="relative w-full">
              <button className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg whitespace-nowrap">
                Suggest article
              </button>

              <svg
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -right-[40px] -top-6"
              >
                <path
                  d="M31.6225 2.11972C50.0737 3.93266 41.1436 30.5561 18.3083 45.5494"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M21.7177 34L12.7651 46.3087L27.4656 51.4656"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
