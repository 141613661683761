import { Link } from 'react-router-dom';
import { User } from 'src/common/types/user';
import { AddMemberToCirclePopup } from '../popups/AddMemberToCirclePopup';
import { useContext, useState } from 'react';
import { MemberMeetingPopup } from '../popups/MemberMeetingPopup';
import { getInitials } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

interface MemberCardProps {
  member: User;
}

export const MemberCard = ({ member }: MemberCardProps) => {
  member.name ??= 'John Doe';
  const memberType = 'Gold Level Member';
  const role = member?.company?.role;
  const [showAddToCirclePopup, setShowAddToCirclePopup] = useState(false);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);
  const { appConfig } = useContext(AuthContext);

  const useMeeting =
    appConfig?.parentId === null ||
    appConfig?.features['Members'].subFeatures.includes('Use meetings');

  const userChat =
    appConfig?.parentId === null || appConfig?.features['Members'].subFeatures.includes('Use chat');

  const userCircle =
    appConfig?.parentId === null ||
    appConfig?.features['Members'].subFeatures.includes('Use circles');

  return (
    <div className="relative rounded-3xl w-[200px] min-h-[349px] border border-[#E5E5E5] bg-white mt-3">
      {showAddToCirclePopup && (
        <AddMemberToCirclePopup onClose={() => setShowAddToCirclePopup(false)} member={member} />
      )}

      {showMeetingPopup && useMeeting && (
        <MemberMeetingPopup member={member} onClose={() => setShowMeetingPopup(false)} />
      )}

      <Link to={'/members/' + member.id}>
        <div className="absolute rounded-full left-6 -top-3 w-[70px] h-[70px] overflow-hidden border-[5px] border-white flex items-center justify-center bg-blue-400 font-bold text-lg">
          {member.avatar ? (
            <div className="flex items-center justify-center w-full h-full">
              <img
                src={member.avatar}
                className="object-cover w-full h-full rounded-full"
                alt="Profile Avatar"
              />
            </div>
          ) : (
            <div>{getInitials(member.name)}</div>
          )}
        </div>
      </Link>

      <div className="flex flex-col justify-between h-full">
        <Link to={'/members/' + member.id}>
          <div className="px-4 pt-16">
            <h3
              className="mt-2 mb-1 text-lg font-bold leading-none whitespace-pre-wrap cursor-pointer"
              dangerouslySetInnerHTML={{ __html: member.name.replace(/\s/, '<br>') }}
            ></h3>

            {/* use this class for silver level member 'text-[#76835D]' */}
            <p className={`text-xs mb-2 font-[sohne-breit] text-[#CD9400]`}>{memberType}</p>

            <h4 className="mt-3 text-sm font-light leading-tight ">{role}</h4>
          </div>
        </Link>

        <div>
          {member.skills.length > 0 && (
            <div className="py-4 flex flex-wrap gap-2 px-4 text-[#325EAE] border-y border-[#E5E5E5]">
              {member.skills.map((skill, index) => (
                <div
                  className="border py-1.5 px-2.5 text-xs rounded-lg leading-none border-[#325EAE]"
                  key={index}
                >
                  {skill.name}
                </div>
              ))}
            </div>
          )}

          <div className="bg-[#005AFF05] rounded-bl-lg rounded-br-lg flex justify-between h-[50px] text-[#00039A]">
            <div className="flex">
              <button
                disabled={!userChat}
                className="border-r border-[#E5E5E5] px-3 py-2.5 flex items-center"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.8">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.6571 14.6559C12.2122 17.1011 8.59182 17.6294 5.62913 16.2592C5.19177 16.0831 4.83319 15.9408 4.4923 15.9408C3.54279 15.9464 2.36093 16.8671 1.74669 16.2536C1.13244 15.6393 2.05381 14.4565 2.05381 13.5012C2.05381 13.1603 1.91713 12.8081 1.74106 12.3699C0.370265 9.4077 0.899287 5.78615 3.34421 3.34177C6.46528 0.219546 11.536 0.219546 14.6571 3.34096C17.7838 6.46801 17.7782 11.5345 14.6571 14.6559Z"
                      stroke="#00369A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.1515 9.33042H12.1587"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.94431 9.33042H8.95151"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.73716 9.33042H5.74436"
                      stroke="#00369A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>

              <button
                className="border-r border-[#E5E5E5] px-3 py-2.5 flex items-center"
                disabled={!useMeeting}
                onClick={() => setShowMeetingPopup(true)}
              >
                <svg
                  width="18"
                  height="13"
                  viewBox="0 0 18 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path
                      d="M9.8999 1.35742H2.6999C1.70579 1.35742 0.899902 2.12493 0.899902 3.07171V9.92885C0.899902 10.8756 1.70579 11.6431 2.6999 11.6431H9.8999C10.894 11.6431 11.6999 10.8756 11.6999 9.92885V3.07171C11.6999 2.12493 10.894 1.35742 9.8999 1.35742Z"
                      fill="white"
                      stroke="#00369A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.3102 9.8601L11.7002 8.21438V4.78581L14.3102 3.1401C15.4982 2.38581 17.1002 3.19153 17.1002 4.56295V8.43724C17.1002 9.80867 15.4982 10.6315 14.3102 9.8601Z"
                      fill="white"
                      stroke="#00369A"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </button>
            </div>

            <button
              className="border-l border-[#E5E5E5] px-3 py-2.5 flex items-center"
              disabled={!userCircle}
              onClick={() => setShowAddToCirclePopup(true)}
            >
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="10.375" cy="10" r="8.75" stroke="#005AFF" strokeWidth="1.5" />
                <circle cx="10.3751" cy="10.0294" r="4.27941" stroke="#005AFF" strokeWidth="1.5" />
                <rect x="12.125" y="3.5" width="9" height="13" rx="3" fill="#FAFCFF" />
                <path
                  d="M16.625 7.5V12.5"
                  stroke="#005AFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M14.125 10H19.125"
                  stroke="#005AFF"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
