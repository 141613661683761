import { User } from '@auth0/auth0-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { useHttp, useNotification } from 'src/common/hooks';
import { Circle } from 'src/common/types/circle';
import { getInitials, queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { Skill } from 'src/common/types/skill';

interface AddMemberToCirclePopupProps {
  member: User;
  onClose: () => void;
}

interface Option {
  value: string;
  label: string;
}

export const AddMemberToCirclePopup = ({ onClose, member }: AddMemberToCirclePopupProps) => {
  const tags = ['Data ', 'AI', 'Analytics'];
  const notify = useNotification();

  const { accessToken, profile } = useContext(AuthContext);
  const [memberCircleNames, setMemberCircleNames] = useState<string[]>([]);
  const [circleOptions, setCircleOptions] = useState<Option[]>([]);

  const {
    sendRequest: getCircles,
    isLoading: isGettingCircles,
    error: errorGettingCircles,
  } = useHttp();

  const {
    sendRequest: attachUser,
    isLoading: isAttachingUser,
    error: errorAttachingUser,
  } = useHttp();

  useEffect(() => {
    if (accessToken) {
      profile && getCirclesHandler();
    }

    if (errorGettingCircles) {
      notify(errorGettingCircles, 'error');
    }

    if (errorAttachingUser) {
      notify(errorAttachingUser, 'error');
    }
  }, [accessToken, profile, errorGettingCircles, errorAttachingUser]);

  const formik = useFormik({
    initialValues: {
      circleId: '',
    },
    validationSchema: Yup.object({
      circleId: Yup.string().trim().required(),
    }),
    onSubmit: async values => {
      attachUser(
        {
          url: 'circle/attach-user',
          method: 'POST',
          payload: { circleId: values.circleId, userId: member.id },
          accessToken,
        },
        handleAttachResponse,
      );
    },
  });

  const handleAttachResponse = () => {
    notify(
      `${member.name} was added to the  “${
        circleOptions.find(circle => circle.value === formik.values.circleId)?.label
      }” circle.`,
      'success',
    );

    onClose();
  };

  const getCirclesHandler = async () => {
    const params = {
      filter: {
        userId: profile?.id,
      },
    };

    await getCircles(
      {
        url: 'circle?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        circlesHandler(res);
      },
    );
  };

  const circlesHandler = (circles: Circle[]): void => {
    const options: Option[] = [];
    const memberCircleNames: string[] = [];

    circles.forEach(circle => {
      const isMemberInCircle = circle.users.some(user => user.userId === member.id);

      if (!isMemberInCircle) {
        options.push({
          value: circle.id,
          label: circle.name,
        });
      } else {
        memberCircleNames.push(circle.name);
      }
    });

    setCircleOptions(options);
    setMemberCircleNames(memberCircleNames);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center flex-col bg-[#0B1444E5] bg-opacity-90 z-50">
      <div className="bg-white rounded-lg w-[490px] shadow-lg">
        <div className="pl-14 py-[30px] border-b border-[#D6D6D6] relative">
          <div className="absolute rounded-full -left-4 w-[60px] h-[60px] overflow-hidden border-[7px] border-white top-5 flex items-center justify-center font-bold text-lg bg-blue-400">
            {member.avatar ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={member.avatar}
                  className="object-cover w-full h-full rounded-full"
                  alt="Profile Avatar"
                />
              </div>
            ) : (
              <div>{getInitials(member?.name ?? '')}</div>
            )}
          </div>

          <div>
            <div className="flex items-center mb-1 leading-none">
              <span className="font-bold text-black text-[20px] mr-2.5 max-w-[320px]">
                {member?.name}
              </span>

              <span className="text-[10px] bg-[#CD9400] p-[5px] rounded-md text-white font-[sohne-breit]">
                Gold
              </span>
            </div>

            <div className="text-base text-black mb-2.5 max-w-[200px] ">
              {member?.company?.name}
            </div>

            <div className="flex flex-wrap gap-1 text-[#325EAE] items-center">
              {member?.skills?.map((skill: Skill) => (
                <div
                  className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE33] font-medium bg-white"
                  key={skill.id}
                >
                  {skill.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="relative flex flex-col px-14 py-[30px]">
          <button className="absolute right-3 top-3" onClick={onClose}>
            <svg
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
                fill="#E5E5E5"
              />
              <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
              <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>

          <h2 className="text-[30px] font-bold font-[sohne-breit] tracking-wide mb-10 leading-[1]">
            Add{' '}
            <span className="highlight-marker max-w-[250px] inline-block truncate">
              {member.name?.split(' ')[0]}
            </span>{' '}
            <br />
            {member.name?.split(' ')[1] && (
              <span className="highlight-marker max-w-[250px] inline-block truncate">
                {member.name?.split(' ')[1]}
              </span>
            )}{' '}
            to a circle
          </h2>

          {memberCircleNames.length > 0 && (
            <div className="p-5 bg-[#1954C11A] border border-[#1954C1] rounded-lg mb-10">
              You already have {member.name} added to {memberCircleNames.length} Circles:{' '}
              {memberCircleNames.map((circleName, index) => (
                <span key={index}>
                  {index > 0 && index === memberCircleNames.length - 1
                    ? ' and '
                    : index > 0
                    ? ', '
                    : ''}
                  <span className="font-bold">
                    {circleName.length > 15 ? `${circleName.substring(0, 15)}...` : circleName}
                  </span>
                </span>
              ))}
            </div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className="mb-6">
              <label className="w-full space-y-3">
                <div className="font-bold">Choose a circle</div>

                <Select
                  isDisabled={isGettingCircles}
                  name="circleId"
                  options={circleOptions}
                  onChange={selectedOptions => {
                    const fakeEvent = {
                      target: {
                        name: 'circleId',
                        value: selectedOptions?.value,
                      },
                    };
                    formik.handleChange(fakeEvent);
                  }}
                  classNames={{
                    control: () =>
                      `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none text-[20px] text-black ${
                        formik.touched.circleId && formik.errors.circleId
                          ? '!border-b-[#F72300] bg-[#FFF2F2]'
                          : '!border-b-[#005AFF]'
                      }`,
                  }}
                />
              </label>
            </div>

            <div className="flex gap-6 mt-5">
              <button
                type="submit"
                disabled={isGettingCircles || isAttachingUser}
                className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
              >
                Add to circle
              </button>

              <button
                disabled={isGettingCircles || isAttachingUser}
                onClick={onClose}
                className="text-black underline text-opacity-70"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
