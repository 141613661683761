import { useState } from 'react';
import { StepStatus } from 'src/common/components/StepStatus';
import ForwardArrowSvg from 'src/common/components/svg/ArrowRight';
import { SubAccountDetailsTab } from './SubAccountDetailsTab';
import { SubAccountAccessTab } from './SubAccountAccessTab';
import { CreateSubAccountData, Feature } from 'src/common/types/sub-account';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';

interface CreateSubAccountPopupProps {
  onClose: () => void;
}

export const CreateSubAccountPopup = ({ onClose }: CreateSubAccountPopupProps) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const { accessToken } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(1);
  const notify = useNotification();

  const [subAccount, setSubAccount] = useState<CreateSubAccountData>({
    detail: {
      name: '',
      email: '',
    },
    features: [],
  });

  const {
    sendRequest: getFeatures,
    isLoading: isGettingFeatures,
    error: errorGettingFeatures,
  } = useHttp();

  const {
    sendRequest: createSubAccount,
    isLoading: isCreatingSubAccount,
    error: errorCreatingSubAccount,
  } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getFeaturesHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    if (errorGettingFeatures) {
      notify(errorGettingFeatures, 'error');
    }

    if (errorCreatingSubAccount) {
      notify(errorCreatingSubAccount, 'error');
    }
  }, [errorGettingFeatures, errorCreatingSubAccount]);

  const getFeaturesHandler = async () => {
    await getFeatures(
      {
        url: 'features',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setFeatures(res);
      },
    );
  };

  const createSubAccountHandler = async (data: CreateSubAccountData) => {
    setSubAccount(data);

    createSubAccount(
      {
        url: 'user/create-sub-account',
        method: 'POST',
        accessToken,
        payload: {
          ...data.detail,
          features: data.features,
        },
      },
      handleCreateResponse,
    );
  };

  const stepsAppearance = {
    default: {
      textClasses: 'text-black text-opacity-50',
      backgroundClasses: '',
      borderClasses: 'border border-black border-opacity-50',
    },
    active: {
      textClasses: 'text-black',
      backgroundClasses: 'bg-[#E5E5E5]',
      borderClasses: '',
    },
    completed: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-[#69BB00]',
      borderClasses: '',
    },
  };

  const moveToStep = (step: number) => {
    if (step > currentStep) {
      return;
    }

    setCurrentStep(step);
  };

  const collectData = (data: CreateSubAccountData) => {
    setSubAccount(data);
  };

  const handleCreateResponse = () => {
    notify(`Sub account created successfully!`, 'success');
    onClose();
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50 flex-col py-10">
        <div className="w-[550px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col overflow-y-scroll custom-scrollbar-1">
          <button className="absolute right-3 top-3" onClick={onClose}>
            <svg
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
                fill="#E5E5E5"
              />
              <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
              <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            </svg>
          </button>

          <h2 className="text-[34px] font-bold font-[sohne-breit] tracking-wide mb-5 text-black leading-8">
            Add a <br /> sub account
          </h2>

          <p className="leading-tight text-black text-md text-opacity-70">
            The person for which you create the sub account will be able to access the platform
            according to your settings.
          </p>

          <div className="flex items-center gap-4 my-10 font-[sohne-breit]">
            <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(1)}>
              <StepStatus stepNumber={1} currentStep={currentStep} appearance={stepsAppearance} />

              <span className="font-normal text-gray-500">User details</span>
            </div>

            <ForwardArrowSvg className="w-5 h-5 text-black text-opacity-50" />

            <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(2)}>
              <StepStatus stepNumber={2} currentStep={currentStep} appearance={stepsAppearance} />

              <span className="font-normal text-gray-500">Define access</span>
            </div>
          </div>

          {currentStep === 1 && (
            <SubAccountDetailsTab
              setCurrentStep={setCurrentStep}
              initialData={subAccount}
              collectData={collectData}
              disabled={isCreatingSubAccount || isGettingFeatures}
              onClose={onClose}
            />
          )}
          {currentStep === 2 && (
            <SubAccountAccessTab
              setCurrentStep={setCurrentStep}
              initialData={subAccount}
              collectData={createSubAccountHandler}
              disabled={isCreatingSubAccount || isGettingFeatures}
              onClose={onClose}
              features={features}
            />
          )}
        </div>
      </div>
    </>
  );
};
