import { useFormik } from 'formik';
import { SignupAboutProps } from 'src/common/types/signup';
import * as Yup from 'yup';
import Select from 'react-select';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp } from 'src/common/hooks';
import { Option } from 'src/common/types/event';
import { Skill } from 'src/common/types/skill';
import { Country } from 'src/common/types/country';

export const SignupAbout = ({ setCurrentStep, initialData, collectSurvey }: SignupAboutProps) => {
  const { accessToken, profile } = useContext(AuthContext);
  const [skillsOptions, setSkillsOptions] = useState<Option[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const { sendRequest: getSkills, isLoading: isGettingSkills } = useHttp();
  const { sendRequest: getCountries, isLoading: isGettingCountries } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getSkillsHandler();
      getCountryHandler();
    }
  }, [accessToken, profile]);

  const formik = useFormik({
    initialValues: initialData.about,
    validationSchema: Yup.object({
      name: Yup.string()
        .required()
        .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces')
        .max(50, 'Name must not exceed 50 characters'),
      company: Yup.string().trim().required().max(50, 'Company must not exceed 50 characters'),
      skills: Yup.array().min(1).required(),
      level: Yup.string().trim().required(),
      countryId: Yup.string().trim().required(),
    }),
    onSubmit: values => {
      const survey = { ...initialData, about: values };
      collectSurvey(survey);
      setCurrentStep(3);
    },
  });

  const getSkillsHandler = async () => {
    await getSkills(
      {
        url: 'skills',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedSkills = res.map((skill: Skill) => ({
          value: skill.id,
          label: skill.name,
        }));

        setSkillsOptions(transformedSkills);
      },
    );
  };

  const getCountryHandler = async () => {
    await getCountries(
      {
        url: 'countries',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setCountries(res);
      },
    );
  };

  return (
    <div className="w-[520px] mx-auto bg-white rounded-lg text-black py-14 px-12">
      <h2 className="mb-8 text-3xl font-medium text-center font-[sohne-breit]">
        Details about you
      </h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-7">
          <label className="space-y-2">
            <div className="font-bold">Your full name</div>

            <input
              type="text"
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="Enter your full name..."
              className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                formik.touched.name && formik.errors.name
                  ? '!border-b-[#F72300] bg-[#FFF2F2]'
                  : '!border-b-[#005AFF]'
              }`}
            />
          </label>

          <label className="space-y-2">
            <div className="font-bold">Company</div>

            <input
              type="text"
              name="company"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.company}
              placeholder="Enter the name of your company..."
              className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                formik.touched.company && formik.errors.company
                  ? '!border-b-[#F72300] bg-[#FFF2F2]'
                  : '!border-b-[#005AFF]'
              }`}
            />
          </label>

          <label className="space-y-2">
            <div className="font-bold">What are few of your skills</div>

            <Select
              value={formik.values.skills}
              isDisabled={isGettingSkills}
              name="skills"
              onChange={selectedOptions => {
                // Formik's handleChange expects an event object with a 'target' property,
                // so we need to manually create a fake event object for Formik.
                const fakeEvent = {
                  target: {
                    name: 'skills',
                    value: selectedOptions,
                  },
                };
                formik.handleChange(fakeEvent);
              }}
              options={skillsOptions}
              isMulti
              classNames={{
                control: () =>
                  `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                    formik.errors.skills && formik.touched.skills
                      ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                      : ''
                  }`,
                indicatorSeparator: () => '!my-0',
              }}
            />
          </label>

          <div className="space-y-2">
            <div className="font-bold">Please check what applies for you</div>

            <div className="flex flex-col gap-1">
              <label
                className={`p-2 py-3 rounded-lg ${
                  formik.touched.level && formik.errors.level ? 'bg-[#FFF2F2]' : 'bg-gray-100'
                }`}
              >
                <input
                  type="radio"
                  name="level"
                  value="expert"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.level === 'expert'}
                  className="w-6 h-6 mr-2 text-orange-800 rounded-full"
                />
                <span className="text-sm">I&apos;m an expert in my field</span>
              </label>

              <label
                className={`p-2 py-3 rounded-lg ${
                  formik.touched.level && formik.errors.level ? 'bg-[#FFF2F2]' : 'bg-gray-100'
                }`}
              >
                <input
                  type="radio"
                  name="level"
                  value="leader"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.level === 'leader'}
                  className="w-6 h-6 mr-2 text-orange-800 rounded-full"
                />
                <span className="text-sm">I&apos;m a leader in my field of level</span>
              </label>

              <label
                className={`p-2 py-3 rounded-lg ${
                  formik.touched.level && formik.errors.level ? 'bg-[#FFF2F2]' : 'bg-gray-100'
                }`}
              >
                <input
                  type="radio"
                  name="level"
                  value="visionary"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.level === 'visionary'}
                  className="w-6 h-6 mr-2 text-orange-800 rounded-full"
                />
                <span className="text-sm">I feel i&apos;m a visionary.</span>
              </label>
            </div>
          </div>

          <label className="space-y-2">
            <div className="font-bold">What country are you from?</div>

            <select
              name="countryId"
              value={formik.values.countryId}
              disabled={isGettingCountries}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                formik.touched.countryId && formik.errors.countryId
                  ? '!border-b-[#F72300] bg-[#FFF2F2]'
                  : '!border-b-[#005AFF]'
              }`}
            >
              <option value="" hidden>
                Select your country...
              </option>

              {countries.map(country => (
                <option value={country.id} key={country.id}>
                  {country.name}
                </option>
              ))}
            </select>
          </label>

          <button
            className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
            type="submit"
          >
            Go to the last step
          </button>
        </div>
      </form>
    </div>
  );
};
