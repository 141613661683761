import React from 'react';

export const DashboardIcon = () => (
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7983 6.87072L5.93821 6.87072"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.0811 11.5387L5.93833 11.5387"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.17334 16.1285L5.93833 16.1285"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 16.1857L21 6.81429C21 3.54762 18.6879 1.5 15.4148 1.5L6.58516 1.5C3.31208 1.5 1 3.53809 1 6.81428L0.999999 16.1857C0.999999 19.4619 3.31208 21.5 6.58516 21.5L15.4148 21.5C18.6879 21.5 21 19.4619 21 16.1857Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MembersIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.60225 13.6777C11.2913 13.6777 14.4443 14.2367 14.4443 16.4697C14.4443 18.7027 11.3123 19.2777 7.60225 19.2777C3.91225 19.2777 0.760254 18.7237 0.760254 16.4897C0.760254 14.2557 3.89125 13.6777 7.60225 13.6777Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.60231 10.4907C5.18031 10.4907 3.21631 8.5277 3.21631 6.1057C3.21631 3.6837 5.18031 1.7207 7.60231 1.7207C10.0233 1.7207 11.9873 3.6837 11.9873 6.1057C11.9963 8.5187 10.0463 10.4817 7.63331 10.4907H7.60231Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4937 9.35198C16.0947 9.12698 17.3277 7.75298 17.3307 6.08998C17.3307 4.45098 16.1357 3.09098 14.5687 2.83398"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.606 13.2031C18.157 13.4341 19.24 13.9781 19.24 15.0981C19.24 15.8691 18.73 16.3691 17.906 16.6821"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClubsIcon = () => (
  <svg width="22" height="15" viewBox="0 0 22 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.7"
      d="M17.4429 4.32949C18.0158 3.5673 18.5848 3.26348 19.1377 3.42339C20.0312 3.68989 20.4319 5.09169 20.476 5.25159C20.492 5.31022 20.5 5.36885 20.5 5.43282V9.37173C20.5 10.8162 19.6145 11.9941 18.5287 11.9941H4.05631C2.64593 11.9941 1.5 10.4697 1.5 8.59354V5.19296C1.5 5.11301 1.51603 5.02773 1.54007 4.95311C1.59616 4.80387 2.10502 3.50334 3.00654 3.38074C3.56748 3.30612 4.1124 3.70055 4.61725 4.56402M6.30409 11.9835V13.9822M15.72 11.9835V13.9822M4.7054 7.4156H17.3267V3.08759C17.3267 1.94696 16.6295 1.01953 15.772 1.01953H6.07571C5.32244 1.01953 4.7054 1.83503 4.7054 2.84241V7.4156Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EventsIcon = () => (
  <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.7">
      <path
        d="M12.7572 1.71875V3.83625"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7572 13.5391V15.3101"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7572 10.5328V6.31445"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.367 15.5C19.098 15.5 20.5 14.2247 20.5 12.6503V10.3818C19.3546 10.3818 18.4322 9.54274 18.4322 8.5009C18.4322 7.45906 19.3546 6.61909 20.5 6.61909L20.499 4.34975C20.499 2.77527 19.096 1.5 17.366 1.5H4.63397C2.90399 1.5 1.50099 2.77527 1.50099 4.34975L1.5 6.693C2.64539 6.693 3.56785 7.45906 3.56785 8.5009C3.56785 9.54274 2.64539 10.3818 1.5 10.3818V12.6503C1.5 14.2247 2.90201 15.5 4.63298 15.5H17.367Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const ArticlesIcon = () => (
  <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 5.08574C15 2.5261 13.3055 1.5 10.8659 1.5H5.13677C2.77213 1.5 1 2.45614 1 4.91504V18.6153C1 19.2907 1.70361 19.716 2.27355 19.3858L8.02348 16.0547L13.7237 19.3802C14.2946 19.7123 15 19.2869 15 18.6106V5.08574Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
