import { useContext, useEffect } from 'react';
import ProfilePictureUpload from 'src/common/components/ProfilePictureUpload';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';

export const ProfilePicturePopup = ({ onClose }: ProfilePicturePopupProps) => {
  const { accessToken, fetchProfile } = useContext(AuthContext);
  const { sendRequest: updatePfp, isLoading: isUpdatingPfp, error: errorUpdatingPfp } = useHttp();
  const notify = useNotification();

  useEffect(() => {
    if (errorUpdatingPfp) {
      notify(errorUpdatingPfp, 'error');
    }
  }, [errorUpdatingPfp]);

  const handleSaveProfilePhoto = async (blob: Blob) => {
    if (!blob) return;

    const formData = new FormData();
    formData.append('file', blob);

    await updatePfp(
      {
        url: 'user/avatar',
        method: 'PUT',
        accessToken,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        payload: formData,
      },
      () => {
        notify('Profile Picture Updated.', 'success');
        fetchProfile();
        onClose();
      },
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50 flex-col p-10">
      <div
        className={`relative bg-white rounded-lg p-10 shadow-lg flex flex-col custom-scrollbar-1 min-w-[490px] max-w-[900px] overflow-y-scroll pt-14`}
      >
        <button className="absolute right-3 top-3" onClick={onClose}>
          <svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
              fill="#E5E5E5"
            />
            <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>

        <ProfilePictureUpload
          onCancel={onClose}
          onSave={handleSaveProfilePhoto}
          disabled={isUpdatingPfp}
        />
      </div>
    </div>
  );
};

interface ProfilePicturePopupProps {
  onClose: () => void;
}
