import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { Activity } from 'src/common/types/activity';
import { queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { ClubCreatedActivity } from '../activity/ClubCreatedActivity';
import { EventCreatedActivity } from '../activity/EventCreatedActivity';
import { Pagination } from '../pagination/Pagination';
import { User } from 'src/common/types/user';

interface ActivityTabProps {
  member?: User;
  setCount: (count: number) => void;
}

export const ActivityTab = ({ member, setCount }: ActivityTabProps) => {
  const notify = useNotification();
  const { accessToken } = useContext(AuthContext);
  const [activities, setActivities] = useState<Activity[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const {
    sendRequest: getActivities,
    isLoading: isGettingActivities,
    error: errorGettingActivities,
  } = useHttp();

  useEffect(() => {
    if (errorGettingActivities) {
      notify(errorGettingActivities, 'error');
    }

    getActivitiesHandler();
  }, [errorGettingActivities, currentPage]);

  const getActivitiesHandler = async () => {
    if (!member) return;

    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        userId: member.id,
      },
      offset,
      limit: perPage,
    };

    await getActivities(
      {
        url: 'activities?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setActivities(res.data);
        setTotalCount(res.total);
        setCount(res.total);
      },
    );
  };

  const ActivityContent = ({ activity }: { activity: Activity }) => {
    switch (activity.action) {
      case 'club_created':
        return <ClubCreatedActivity activity={activity} showUserInfo={false} />;
      case 'event_created':
        return <EventCreatedActivity activity={activity} showUserInfo={false} />;
      default:
        return null;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {/* <div className="border border-[#E5E5E5] rounded-2xl overflow-hidden shadow-md mb-2.5">
        <div className="bg-[#F8F9FB80] p-[10px] border-b border-[#E5E5E5] flex items-center rounded-t-2xl">
          <svg
            width="34"
            height="24"
            viewBox="0 0 34 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-[7px]"
          >
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V16C34 20.4183 30.4183 24 26 24H8C3.58172 24 0 20.4183 0 16V8Z"
              fill="#0B67C2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2229 4H10.7771C10.3058 4 9.85376 4.18729 9.52049 4.52066C9.18723 4.85404 9 5.3062 9 5.77766V18.2223C9 18.6938 9.18723 19.146 9.52049 19.4793C9.85376 19.8127 10.3058 20 10.7771 20H23.2229C23.6941 19.9997 24.146 19.8123 24.4792 19.479C24.8124 19.1457 24.9997 18.6937 25 18.2223V5.77766C25 5.3062 24.8128 4.85404 24.4795 4.52066C24.1462 4.18729 23.6942 4 23.2229 4ZM13.8409 17.7721H11.4559V10.0735H13.8409V17.7721ZM12.6367 9.06495C12.3552 9.06705 12.0794 8.98546 11.8443 8.83052C11.6092 8.67558 11.4254 8.45428 11.3162 8.19469C11.207 7.9351 11.1773 7.64892 11.231 7.37244C11.2846 7.09596 11.4191 6.84165 11.6174 6.64176C11.8158 6.44187 12.069 6.30541 12.345 6.24969C12.6209 6.19397 12.9073 6.2215 13.1676 6.32878C13.4279 6.43607 13.6505 6.61828 13.8071 6.8523C13.9638 7.08633 14.0474 7.36161 14.0474 7.64325C14.0481 7.82925 14.0122 8.01358 13.9416 8.18569C13.8711 8.3578 13.7674 8.51434 13.6364 8.64636C13.5054 8.77837 13.3497 8.88329 13.1782 8.95511C13.0067 9.02693 12.8227 9.06426 12.6367 9.06495ZM22.7781 17.7774H20.4048V13.7337C20.4048 12.6245 19.9812 12.0048 19.1064 12.0048C18.1533 12.0048 17.6534 12.6499 17.6534 13.7337V17.7774H15.3659V10.0735H17.6534V11.1085C17.8977 10.7142 18.2399 10.3898 18.6467 10.167C19.0536 9.94422 19.5111 9.83061 19.9748 9.83725C21.6089 9.83725 22.7781 10.8352 22.7781 12.8989V17.7774Z"
              fill="white"
            />
          </svg>

          <span className="text-[14px] text-black text-opacity-70 mr-5 leading-none">
            Posted on LinkedIn
          </span>

          <span className="text-[13px] text-black leading-none">August 12, 2023 12:15 pm</span>
        </div>

        <div className="py-[40px] bg-white">
          <div className="max-w-[55%] mx-auto">
            <div className="flex gap-5 text-black text-sm mb-[30px]">
              <div className="flex items-center gap-2">
                <img src="/assets/linkedin.svg" alt="Linkedin" />
                <span className="text-opacity-70">Posted on Linkedin</span>
              </div>

              <span>Augest 14, 2021, 10:00 AM</span>
            </div>

            <div className="text-black text-[17px]">
              <p>All PM templates from my Google Drive are now free for everyone 🎁</p>

              <p>
                PPTX:
                <a href="https://lnkd.in/dpG7Vcxk" className="underline">
                  https://lnkd.in/dpG7Vcxk
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="bg-[#F8F9FB80] border-t border-[#E5E5E5] flex justify-between">
          <div className="py-[20px] pl-[55px] flex gap-8">
            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.84 7.06188V15.1219M6.55 15.9419C6.04 15.9419 5.56 15.7519 5.18 15.4119L4.86 15.1219L4.5 15.3619C4.37 15.4619 4.2 15.5119 4.04 15.5119H1.84C1.38 15.4919 1.01 15.1219 1 14.6619V7.38189C1 6.94189 1.37 6.58189 1.81 6.57189H4.02C4.24 6.57189 4.45 6.65189 4.6 6.80189L4.85 7.05189L5.18 6.93188C7 6.28188 8.02 5.09189 8.02 3.65189V0.781885C8.02 0.701885 8.06 0.641885 8.14 0.611885C8.42 0.531885 8.71 0.501885 9 0.501885C9.47 0.481885 9.93 0.621885 10.31 0.881885C10.93 1.41188 11.24 2.21188 11.15 3.01188V5.80189H14.93C16.06 5.80189 16.98 6.72188 16.98 7.85188V8.00188L16.04 13.8919C15.95 15.1219 14.88 16.0319 13.65 15.9419C13.62 15.9419 13.59 15.9419 13.56 15.9419H6.54H6.55Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">14</span>
            </div>

            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.19177 5.7216H10.2917M6.19177 9.37673H12.4493M14.7706 0.5H4.18816C3.33751 0.5 2.52168 0.816237 1.92006 1.37917C1.31844 1.94211 0.9803 2.70566 0.97998 3.50192V12.0076C0.9803 12.8038 1.31844 13.5674 1.92006 14.1303C2.52168 14.6932 3.33751 15.0095 4.18816 15.0095H5.44448V17.5L10.3193 15.0095H14.7718C15.6225 15.0095 16.4383 14.6932 17.0399 14.1303C17.6415 13.5674 17.9797 12.8038 17.98 12.0076V3.50192C17.9798 3.10756 17.8967 2.71708 17.7353 2.35279C17.5739 1.9885 17.3375 1.65753 17.0395 1.37878C16.7415 1.10002 16.3877 0.878943 15.9984 0.728163C15.6091 0.577382 15.1919 0.499852 14.7706 0.5Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">23</span>
            </div>

            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.63995 11.9861L10.1268 15.1331M5.63995 8.11645L10.0283 4.88625M0.979981 9.99903C0.979792 10.5385 1.13772 11.066 1.43379 11.5146C1.72987 11.9633 2.15081 12.3132 2.64339 12.5199C3.13597 12.7266 3.67807 12.7809 4.20117 12.676C4.72426 12.5711 5.20487 12.3116 5.58222 11.9304C5.95957 11.5492 6.21673 11.0634 6.32118 10.5344C6.42564 10.0053 6.3727 9.45684 6.16906 8.95823C5.96542 8.45963 5.62022 8.0333 5.1771 7.73314C4.73399 7.43298 4.21285 7.27247 3.67957 7.27189C3.32527 7.27151 2.97437 7.34176 2.64692 7.47863C2.31947 7.6155 2.02189 7.81631 1.77118 8.06957C1.52048 8.32283 1.32156 8.62359 1.1858 8.95466C1.05005 9.28573 0.980106 9.64061 0.979981 9.99903ZM9.58654 3.22714C9.58635 3.76663 9.74428 4.29406 10.0404 4.74276C10.3364 5.19145 10.7574 5.54127 11.2499 5.74799C11.7425 5.95471 12.2846 6.00904 12.8077 5.90412C13.3308 5.7992 13.8114 5.53974 14.1888 5.15853C14.5661 4.77733 14.8233 4.2915 14.9277 3.76247C15.0322 3.23343 14.9793 2.68494 14.7756 2.18634C14.572 1.68774 14.2268 1.26141 13.7837 0.961248C13.3405 0.661088 12.8194 0.500575 12.2861 0.500002C11.9318 0.49962 11.5809 0.569871 11.2535 0.70674C10.926 0.843609 10.6284 1.04441 10.3777 1.29768C10.127 1.55094 9.92812 1.8517 9.79236 2.18277C9.6566 2.51383 9.58666 2.86872 9.58654 3.22714ZM9.58654 16.7709C9.58635 17.3104 9.74428 17.8378 10.0404 18.2865C10.3364 18.7352 10.7574 19.0851 11.2499 19.2918C11.7425 19.4985 12.2846 19.5528 12.8077 19.4479C13.3308 19.343 13.8114 19.0835 14.1888 18.7023C14.5661 18.3211 14.8233 17.8353 14.9277 17.3063C15.0322 16.7772 14.9793 16.2287 14.7756 15.7301C14.572 15.2315 14.2268 14.8052 13.7837 14.505C13.3405 14.2049 12.8194 14.0444 12.2861 14.0438C11.9318 14.0434 11.5809 14.1137 11.2535 14.2505C10.926 14.3874 10.6284 14.5882 10.3777 14.8415C10.127 15.0947 9.92812 15.3955 9.79236 15.7266C9.6566 16.0576 9.58666 16.4125 9.58654 16.7709Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">2</span>
            </div>
          </div>

          <button className="border-l border-[#E5E5E5] px-[25px] flex items-center justify-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.23804 1L11.238 6L6.23804 11"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
              <path
                d="M11.2382 6H0.761963"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="border border-[#E5E5E5] rounded-2xl overflow-hidden shadow-md mb-2.5">
        <div className="bg-[#F8F9FB80] p-[10px] border-b border-[#E5E5E5] flex items-center rounded-t-2xl">
          <svg
            width="34"
            height="24"
            viewBox="0 0 34 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mr-[7px]"
          >
            <path
              d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V16C34 20.4183 30.4183 24 26 24H8C3.58172 24 0 20.4183 0 16V8Z"
              fill="#0B67C2"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.2229 4H10.7771C10.3058 4 9.85376 4.18729 9.52049 4.52066C9.18723 4.85404 9 5.3062 9 5.77766V18.2223C9 18.6938 9.18723 19.146 9.52049 19.4793C9.85376 19.8127 10.3058 20 10.7771 20H23.2229C23.6941 19.9997 24.146 19.8123 24.4792 19.479C24.8124 19.1457 24.9997 18.6937 25 18.2223V5.77766C25 5.3062 24.8128 4.85404 24.4795 4.52066C24.1462 4.18729 23.6942 4 23.2229 4ZM13.8409 17.7721H11.4559V10.0735H13.8409V17.7721ZM12.6367 9.06495C12.3552 9.06705 12.0794 8.98546 11.8443 8.83052C11.6092 8.67558 11.4254 8.45428 11.3162 8.19469C11.207 7.9351 11.1773 7.64892 11.231 7.37244C11.2846 7.09596 11.4191 6.84165 11.6174 6.64176C11.8158 6.44187 12.069 6.30541 12.345 6.24969C12.6209 6.19397 12.9073 6.2215 13.1676 6.32878C13.4279 6.43607 13.6505 6.61828 13.8071 6.8523C13.9638 7.08633 14.0474 7.36161 14.0474 7.64325C14.0481 7.82925 14.0122 8.01358 13.9416 8.18569C13.8711 8.3578 13.7674 8.51434 13.6364 8.64636C13.5054 8.77837 13.3497 8.88329 13.1782 8.95511C13.0067 9.02693 12.8227 9.06426 12.6367 9.06495ZM22.7781 17.7774H20.4048V13.7337C20.4048 12.6245 19.9812 12.0048 19.1064 12.0048C18.1533 12.0048 17.6534 12.6499 17.6534 13.7337V17.7774H15.3659V10.0735H17.6534V11.1085C17.8977 10.7142 18.2399 10.3898 18.6467 10.167C19.0536 9.94422 19.5111 9.83061 19.9748 9.83725C21.6089 9.83725 22.7781 10.8352 22.7781 12.8989V17.7774Z"
              fill="white"
            />
          </svg>

          <span className="text-[14px] text-black text-opacity-70 mr-5 leading-none">
            Posted on LinkedIn
          </span>

          <span className="text-[13px] text-black leading-none">August 12, 2023 12:15 pm</span>
        </div>

        <div className="py-[40px] bg-white">
          <div className="max-w-[55%] mx-auto">
            <div className="flex gap-5 text-black text-sm mb-[30px]">
              <div className="flex items-center gap-2">
                <img src="/assets/linkedin.svg" alt="Linkedin" />
                <span className="text-opacity-70">Posted on Linkedin</span>
              </div>

              <span>Augest 14, 2021, 10:00 AM</span>
            </div>

            <div className="text-black text-[17px]">
              <p className="mb-5">
                💎 AI Design Patterns and UX Toolkits, with practical guidelines, best practices,
                templates and useful resources to design responsible AI experiences ↓
              </p>

              <div>
                <img src="/assets/placeholder.jpeg" alt="Placeholder" />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[#F8F9FB80] border-t border-[#E5E5E5] flex justify-between">
          <div className="py-[20px] pl-[55px] flex gap-8">
            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.84 7.06188V15.1219M6.55 15.9419C6.04 15.9419 5.56 15.7519 5.18 15.4119L4.86 15.1219L4.5 15.3619C4.37 15.4619 4.2 15.5119 4.04 15.5119H1.84C1.38 15.4919 1.01 15.1219 1 14.6619V7.38189C1 6.94189 1.37 6.58189 1.81 6.57189H4.02C4.24 6.57189 4.45 6.65189 4.6 6.80189L4.85 7.05189L5.18 6.93188C7 6.28188 8.02 5.09189 8.02 3.65189V0.781885C8.02 0.701885 8.06 0.641885 8.14 0.611885C8.42 0.531885 8.71 0.501885 9 0.501885C9.47 0.481885 9.93 0.621885 10.31 0.881885C10.93 1.41188 11.24 2.21188 11.15 3.01188V5.80189H14.93C16.06 5.80189 16.98 6.72188 16.98 7.85188V8.00188L16.04 13.8919C15.95 15.1219 14.88 16.0319 13.65 15.9419C13.62 15.9419 13.59 15.9419 13.56 15.9419H6.54H6.55Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">14</span>
            </div>

            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="19"
                  height="18"
                  viewBox="0 0 19 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.19177 5.7216H10.2917M6.19177 9.37673H12.4493M14.7706 0.5H4.18816C3.33751 0.5 2.52168 0.816237 1.92006 1.37917C1.31844 1.94211 0.9803 2.70566 0.97998 3.50192V12.0076C0.9803 12.8038 1.31844 13.5674 1.92006 14.1303C2.52168 14.6932 3.33751 15.0095 4.18816 15.0095H5.44448V17.5L10.3193 15.0095H14.7718C15.6225 15.0095 16.4383 14.6932 17.0399 14.1303C17.6415 13.5674 17.9797 12.8038 17.98 12.0076V3.50192C17.9798 3.10756 17.8967 2.71708 17.7353 2.35279C17.5739 1.9885 17.3375 1.65753 17.0395 1.37878C16.7415 1.10002 16.3877 0.878943 15.9984 0.728163C15.6091 0.577382 15.1919 0.499852 14.7706 0.5Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">23</span>
            </div>

            <div className="flex items-center gap-[8px]">
              <button>
                <svg
                  width="16"
                  height="20"
                  viewBox="0 0 16 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.63995 11.9861L10.1268 15.1331M5.63995 8.11645L10.0283 4.88625M0.979981 9.99903C0.979792 10.5385 1.13772 11.066 1.43379 11.5146C1.72987 11.9633 2.15081 12.3132 2.64339 12.5199C3.13597 12.7266 3.67807 12.7809 4.20117 12.676C4.72426 12.5711 5.20487 12.3116 5.58222 11.9304C5.95957 11.5492 6.21673 11.0634 6.32118 10.5344C6.42564 10.0053 6.3727 9.45684 6.16906 8.95823C5.96542 8.45963 5.62022 8.0333 5.1771 7.73314C4.73399 7.43298 4.21285 7.27247 3.67957 7.27189C3.32527 7.27151 2.97437 7.34176 2.64692 7.47863C2.31947 7.6155 2.02189 7.81631 1.77118 8.06957C1.52048 8.32283 1.32156 8.62359 1.1858 8.95466C1.05005 9.28573 0.980106 9.64061 0.979981 9.99903ZM9.58654 3.22714C9.58635 3.76663 9.74428 4.29406 10.0404 4.74276C10.3364 5.19145 10.7574 5.54127 11.2499 5.74799C11.7425 5.95471 12.2846 6.00904 12.8077 5.90412C13.3308 5.7992 13.8114 5.53974 14.1888 5.15853C14.5661 4.77733 14.8233 4.2915 14.9277 3.76247C15.0322 3.23343 14.9793 2.68494 14.7756 2.18634C14.572 1.68774 14.2268 1.26141 13.7837 0.961248C13.3405 0.661088 12.8194 0.500575 12.2861 0.500002C11.9318 0.49962 11.5809 0.569871 11.2535 0.70674C10.926 0.843609 10.6284 1.04441 10.3777 1.29768C10.127 1.55094 9.92812 1.8517 9.79236 2.18277C9.6566 2.51383 9.58666 2.86872 9.58654 3.22714ZM9.58654 16.7709C9.58635 17.3104 9.74428 17.8378 10.0404 18.2865C10.3364 18.7352 10.7574 19.0851 11.2499 19.2918C11.7425 19.4985 12.2846 19.5528 12.8077 19.4479C13.3308 19.343 13.8114 19.0835 14.1888 18.7023C14.5661 18.3211 14.8233 17.8353 14.9277 17.3063C15.0322 16.7772 14.9793 16.2287 14.7756 15.7301C14.572 15.2315 14.2268 14.8052 13.7837 14.505C13.3405 14.2049 12.8194 14.0444 12.2861 14.0438C11.9318 14.0434 11.5809 14.1137 11.2535 14.2505C10.926 14.3874 10.6284 14.5882 10.3777 14.8415C10.127 15.0947 9.92812 15.3955 9.79236 15.7266C9.6566 16.0576 9.58666 16.4125 9.58654 16.7709Z"
                    stroke="black"
                    strokeOpacity="0.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </button>

              <span className="text-black font-bold leading-none text-[15px]">2</span>
            </div>
          </div>

          <button className="border-l border-[#E5E5E5] px-[25px] flex items-center justify-center">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.23804 1L11.238 6L6.23804 11"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
              <path
                d="M11.2382 6H0.761963"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      </div> */}

      {activities.map((activity, index) => (
        <ActivityContent key={index} activity={activity} />
      ))}

      {activities.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!activities.length && !isGettingActivities && (
        <div className={`text-center block p-8`}>No records found</div>
      )}
    </>
  );
};
