import { useContext, useEffect, useState } from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';
import { AuthContext } from 'src/contexts';
import { Club } from 'src/common/types/clubs';
import { useHttp } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import ClubUpdateTab from './ClubUpdateTab';
import ClubArticleTab from './ClubArticleTab';
import ClubEventTab from './ClubEventTab';
import ClubAdminTab from './ClubAdminTab';

import { ClubJoinPopup } from '../popups/ClubJoinPopup';
import { SuggestEventPopup } from '../popups/SuggestEventPopup';
import { ClubMeetingPopup } from '../popups/ClubMeetingPopup';
import { ToastContainer } from 'react-toastify';
import { getInitials } from 'src/common/utils';
import { ClubLeavePopup } from '../popups/ClubLeavePopup';

export default function ClubDetail() {
  const { id } = useParams();
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const [club, setClub] = useState<Club | undefined>();

  const navigate = useNavigate();

  const { sendRequest: getClub, isLoading: isGettingClub, error: errorGettingClub } = useHttp();

  const isJoined = club?.members.find(
    member => member.user?.id === (profile?.parentId ?? profile?.id),
  );

  useEffect(() => {
    if (id && accessToken) {
      getClubHandler();
    }
  }, [id]);

  useEffect(() => {
    if (errorGettingClub) {
      navigate('/clubs');
    }
  }, [errorGettingClub]);

  const getClubHandler = async () => {
    getClub(
      {
        url: `clubs/${id}`,
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setClub(res);
      },
    );
  };

  const tabs = [
    {
      key: 'updates',
      label: 'Updates',
    },
    {
      key: 'articles',
      label: 'Articles',
    },
    {
      key: 'events',
      label: 'Events',
    },
    {
      key: 'admin',
      label: 'Admin',
    },
  ];

  const [activeTab, setActiveTab] = useState('updates');
  const [showClubJoinPopup, setShowClubJoinPopup] = useState(false);
  const [showClubLeavePopup, setShowClubLeavePopup] = useState(false);
  const [showClubMeetingPopup, setShowClubMeetingPopup] = useState(false);
  const [showSuggestEventPopup, setShowSuggestEventPopup] = useState(false);

  const openClubJoinPopup = () => {
    setShowClubJoinPopup(true);
  };

  const closePopup = () => {
    setShowClubJoinPopup(false);
  };

  const joinClub = () => {
    setShowClubJoinPopup(false);
    getClubHandler();
  };

  const leaveClub = () => {
    setShowClubLeavePopup(false);
    getClubHandler();
  };

  const TabContent = ({ activeTab }: { activeTab: string }) => {
    switch (activeTab) {
      case 'updates':
        return <ClubUpdateTab club={club as Club} />;
      case 'articles':
        return <ClubArticleTab />;
      case 'events':
        return <ClubEventTab clubEvents={club?.events ?? []} />;
      case 'admin':
        return <ClubAdminTab />;
      default:
        return null;
    }
  };

  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const description = club?.description;
  const limitedDescription = showFullDescription
    ? description
    : description?.split(' ').slice(0, 50).join(' ');

  const createAndJoinClub =
    appConfig?.parentId === null ||
    appConfig?.features['Clubs'].subFeatures.includes('Create and join clubs');

  return (
    <>
      <ToastContainer autoClose={8000} />

      {club && profile && (
        <div>
          <div
            className="h-[120px] rounded-xl bg-cover bg-center mb-[30px]"
            style={{
              backgroundImage: 'url("/assets/bitcoin-placeholder.jpg")',
            }}
          ></div>

          <div className="max-w-[81%] mx-auto">
            <div className="pb-[30px] border-b border-[#E5E5E5] mb-5">
              <div className="relative flex items-center justify-between">
                <div>
                  <div className="text-black text-opacity-50 font-[sohne-breit] mb-[15px] text-sm tracking-widest">
                    Community / Events
                  </div>

                  <h1 className="text-[36px] font-bold leading-none w-fit mb-5 text-black font-[sohne-breit] max-w-[85%] relative">
                    <button
                      className="h-[30px] w-[30px] bg-[#E5E5E5] rounded-lg flex items-center justify-center absolute top-[6px] -left-[45px] my-auto"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.7"
                          d="M14 5.99992H2M2 5.99992L6.64516 10.6666M2 5.99992L6.64516 1.33325"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>

                    {club.title}
                  </h1>
                </div>

                <div className="flex items-center gap-5">
                  {isJoined ? (
                    <div className="flex gap-5">
                      <button>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M18.0714 18.0699C15.0152 21.1263 10.4898 21.7867 6.78642 20.074C6.23971 19.8539 5.79148 19.676 5.36537 19.676C4.17849 19.683 2.70117 20.8339 1.93336 20.067C1.16555 19.2991 2.31726 17.8206 2.31726 16.6266C2.31726 16.2004 2.14642 15.7602 1.92632 15.2124C0.212831 11.5096 0.874109 6.98269 3.93026 3.92721C7.8316 0.0244319 14.17 0.0244322 18.0714 3.9262C21.9797 7.83501 21.9727 14.1681 18.0714 18.0699Z"
                            stroke="#00369A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <button onClick={() => setShowClubMeetingPopup(true)}>
                        <svg
                          width="26"
                          height="18"
                          viewBox="0 0 26 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3144 1.03564H3.6289C2.15354 1.03564 0.95752 2.22423 0.95752 3.69043V14.3096C0.95752 15.7758 2.15354 16.9644 3.6289 16.9644H14.3144C15.7898 16.9644 16.9858 15.7758 16.9858 14.3096V3.69043C16.9858 2.22423 15.7898 1.03564 14.3144 1.03564Z"
                            stroke="#00369A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M20.8591 14.203L16.9856 11.6544V6.34485L20.8591 3.79626C22.6222 2.62816 24.9997 3.8759 24.9997 5.99973V11.9995C24.9997 14.1234 22.6222 15.3977 20.8591 14.203Z"
                            stroke="#00369A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>

                      <DropdownMenu onOpen={() => setShowClubLeavePopup(true)} />

                      {showClubLeavePopup && (
                        <ClubLeavePopup
                          club={club}
                          onLeave={leaveClub}
                          onClose={() => setShowClubLeavePopup(false)}
                        />
                      )}
                    </div>
                  ) : (
                    <button
                      disabled={!createAndJoinClub}
                      className="px-[30px] py-[18px] text-[11px] font-[sohne-breit] tracking-widest text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
                      onClick={openClubJoinPopup}
                    >
                      Join
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between mb-5">
              <div className="flex items-center gap-6">
                <div className="flex">
                  {club.members.slice(0, 4).map(member => (
                    <div
                      className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
                      key={member.id}
                    >
                      {member.user?.avatar ? (
                        <div className="flex items-center justify-center w-full h-full">
                          <img
                            src={member.user.avatar}
                            className="object-cover w-full h-full rounded-full"
                            alt="Profile Avatar"
                          />
                        </div>
                      ) : (
                        <div>{getInitials(member.user?.name ?? '')}</div>
                      )}
                    </div>
                  ))}

                  {club.members.length > 4 && (
                    <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                      +{club.members.length - 4}
                    </div>
                  )}
                </div>

                <div className="flex text-sm items-center text-[14px]">
                  <div className="text-black mr-2.5">
                    <span className="font-black text-[15px]">{club.members.length} </span>
                    <span>members</span>
                  </div>

                  <div className="h-2.5 border-l border-black border-opacity-50"></div>

                  <div className="text-opacity-70 ml-2.5 underline">
                    {club?.commonMembers} in common
                  </div>
                </div>

                <div className="flex flex-wrap gap-1 text-[#325EAE] capitalize">
                  {club.topics.map((topic, index) => (
                    <div
                      className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE33] font-medium bg-white"
                      key={index}
                    >
                      {topic.name}
                    </div>
                  ))}
                </div>
              </div>

              {isJoined && (
                <div className="text-sm text-black text-opacity-70">
                  Joined on {moment(isJoined?.createdAt).format('LL')}
                </div>
              )}
            </div>

            {isJoined ? (
              <div>
                <Tabs value={activeTab}>
                  <div className="flex items-center justify-between">
                    <TabsHeader
                      placeholder="Empty"
                      className="items-center gap-10 p-0 bg-transparent rounded-none"
                      indicatorProps={{
                        className:
                          'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
                      }}
                    >
                      {tabs.map(
                        ({ key, label }) =>
                          !(
                            key === 'admin' &&
                            !club.members.some(
                              member => member.isAdmin && member.user?.id === profile?.id,
                            )
                          ) && (
                            <Tab
                              placeholder="Empty"
                              key={key}
                              value={key}
                              onClick={() => setActiveTab(key)}
                              className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start ${
                                activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                              }`}
                            >
                              <div className="flex items-center gap-1">
                                <span>{label}</span>
                                {key === 'events' && (
                                  <span className="text-[10px] text-black text-opacity-100 bg-[#E5E5E5] font-medium w-4 h-4 inline-flex justify-center items-center rounded-md">
                                    {club?.events?.length ?? 0}
                                  </span>
                                )}
                              </div>
                            </Tab>
                          ),
                      )}
                    </TabsHeader>

                    {activeTab !== 'updates' && activeTab !== 'admin' && (
                      <button
                        className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg whitespace-nowrap"
                        onClick={() => activeTab === 'events' && setShowSuggestEventPopup(true)}
                      >
                        Suggest {activeTab === 'articles' ? 'article' : 'event'}
                      </button>
                    )}
                  </div>

                  <TabsBody placeholder="Empty">
                    {tabs.map(({ key }) => (
                      <TabPanel key={key} value={key} className="p-0">
                        <TabContent activeTab={key} />
                      </TabPanel>
                    ))}
                  </TabsBody>
                </Tabs>

                {showSuggestEventPopup && (
                  <SuggestEventPopup
                    club={club}
                    onClose={() => setShowSuggestEventPopup(false)}
                    onSuggest={() => {
                      setShowSuggestEventPopup(false);
                      getClubHandler();
                    }}
                  />
                )}

                {showClubMeetingPopup && (
                  <ClubMeetingPopup
                    club={club}
                    onCreate={() => getClubHandler()}
                    onClose={() => setShowClubMeetingPopup(false)}
                  />
                )}
              </div>
            ) : (
              <div>
                <div className="p-[30px] bg-white rounded-xl flex justify-between items-center mb-2.5">
                  <div>
                    <div className="mb-1 text-black text-opacity-70">Admins</div>

                    <div className="flex">
                      {club.members.map(
                        member =>
                          member.isAdmin && (
                            <div
                              className="rounded-full h-[22px] w-[22px] -ml-2 first:ml-0 flex items-center justify-center font-bold bg-blue-400 text-xs"
                              key={member.id}
                            >
                              {member.user?.avatar ? (
                                <div className="flex items-center justify-center w-full h-full">
                                  <img
                                    src={member.avatar}
                                    className="object-cover w-full h-full rounded-full"
                                    alt="Profile Avatar"
                                  />
                                </div>
                              ) : (
                                <div>{getInitials(member.user?.name ?? 'NA')}</div>
                              )}
                            </div>
                          ),
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="mb-1 text-black text-opacity-70">Created by</div>

                    <div className="flex items-center gap-1.5">
                      <div className="rounded-full h-[22px] w-[22px] flex items-center justify-center font-bold bg-blue-400 text-[11px]">
                        {club.user?.avatar ? (
                          <div className="flex items-center justify-center w-full h-full">
                            <img
                              src={club.user?.avatar}
                              className="object-cover w-full h-full rounded-full"
                              alt="Profile Avatar"
                            />
                          </div>
                        ) : (
                          <div>{getInitials(club.user?.name ?? '')}</div>
                        )}
                      </div>

                      <div className="font-bold text-black truncate max-w-[100px]">
                        {club.user?.name ?? 'Deleted User'}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="mb-1 text-black text-opacity-70">Created on</div>

                    <div className="font-bold text-black">
                      {moment(club.createdAt).format('LL')}
                    </div>
                  </div>

                  <div className="h-10 border-l border-[#E5E5E5]"></div>

                  <div>
                    <div className="mb-1 text-black text-opacity-70">Activity</div>

                    <div className="font-bold text-black">12 posts in the last week</div>
                  </div>
                </div>

                <div className="bg-white rounded-xl px-[50px] pt-[40px] pb-[30px] mb-10 relative">
                  <h3 className="text-[22px] text-black font-[sohne-breit] font-bold mb-5">
                    Group description
                  </h3>

                  <p className="text-lg text-black max-w-[65%] relative">
                    {limitedDescription}

                    {club.description.split(' ').length > 50 && (
                      <span className="flex justify-end -mt-8">
                        <button
                          onClick={toggleDescription}
                          className="ml-2 -mr-16 text-[#005AFF] cursor-pointer underline"
                        >
                          {showFullDescription ? 'Hide' : 'See all'}
                        </button>

                        {!showFullDescription && (
                          <span className="absolute inset-x-0 bottom-0 w-full h-20 bg-gradient-to-t from-white to-transparent"></span>
                        )}
                      </span>
                    )}
                  </p>
                </div>

                <div className="py-10 text-center">
                  <h2 className="text-black font-bold font-[sohne-breit] text-[28px] leading-none mb-5">
                    Want to join this club?
                  </h2>

                  <p className="text-lg text-black max-w-[55%] mx-auto mb-5">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the industry&apos;s standard.
                  </p>

                  <button
                    className="px-[30px] py-[18px] text-[11px] font-[sohne-breit] tracking-widest text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity mx-auto"
                    onClick={openClubJoinPopup}
                  >
                    Request to join
                  </button>
                </div>

                {showClubJoinPopup && createAndJoinClub && (
                  <ClubJoinPopup onClose={closePopup} onJoin={joinClub} club={club} />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {isGettingClub && <LoadingSpinner />}
    </>
  );
}

interface DropdownMenuProps {
  onOpen: () => void;
}

const DropdownMenu = ({ onOpen }: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = () => {
    setIsOpen(false);
    onOpen();
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="inline-flex items-center justify-center w-6"
        aria-label="Options"
      >
        <svg
          width="4"
          height="22"
          viewBox="0 0 4 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="2" cy="2" r="2" fill="black" />
          <circle cx="2" cy="12" r="2" fill="black" />
          <circle cx="2" cy="22" r="2" fill="black" />
        </svg>
      </button>

      {isOpen && (
        <div
          className="absolute right-0 w-40 mt-2 origin-top-right bg-white border rounded-md shadow-lg focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <button
              onClick={handleUpdate}
              className="block w-full px-4 py-2 text-sm text-left text-black hover:bg-gray-100"
              role="menuitem"
            >
              Leave Club
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
