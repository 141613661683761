import { SignupSteps } from 'src/components/signup/SignupSteps';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { SignupAbout } from './SignupAbout';
import { SignupProfession } from './SignupProfession';
import { SignupDetails } from './SignupDetails';
import { Survey } from 'src/common/types/signup';
import { SignupCompletedPopup } from './SignupCompletedPopup';

export const SignupForm = () => {
  const { profile, appConfig, accessToken, fetchProfile } = useContext(AuthContext);
  const notify = useNotification();
  const {
    sendRequest: createUser,
    isLoading: isCreatingUser,
    error: errorCreatingUser,
  } = useHttp();

  const {
    sendRequest: updateSurvey,
    isLoading: isUpdatingSurvey,
    error: errorUpdatingSurvey,
  } = useHttp();

  const createNewUser = async () => {
    await createUser(
      {
        url: 'user',
        method: 'POST',
        accessToken,
        payload: {},
      },
      (data: any) => {
        notify('New user created successfully!', 'success');
        fetchProfile();
      },
    );
  };

  useEffect(() => {
    if (accessToken && appConfig && !appConfig.isRegistered) {
      createNewUser();
    }
  }, [accessToken, appConfig]);

  const [currentStep, setCurrentStep] = useState(2);
  const [signupCompleted, setSignupCompleted] = useState(false);
  const [survey, setSurvey] = useState<Survey>({
    about: {
      name: '',
      company: '',
      skills: [],
      level: '',
      countryId: '',
    },
    profession: {
      reasonForMembership: '',
    },
  });

  const updateSurveyHandler = async (survey: Survey) => {
    await updateSurvey(
      {
        url: `user/${profile?.id}`,
        method: 'PATCH',
        accessToken,
        payload: {
          name: survey.about.name,
          countryId: survey.about.countryId,
          company: survey.about.company,
          skillIds: survey.about.skills.map(skill => skill.value),
          survey,
        },
      },
      (data: any) => {
        setSignupCompleted(true);
      },
    );
  };

  const collectSurvey = (survey: Survey) => {
    setSurvey(survey);
  };

  const submitSurvey = (survey: Survey) => {
    collectSurvey(survey);
    updateSurveyHandler(survey);
  };

  const closeSignupCompletedPopup = () => {
    console.log('closeSignupCompletedPopup');
  };

  return (
    <>
      {signupCompleted ? (
        <SignupCompletedPopup onClose={closeSignupCompletedPopup} />
      ) : (
        <>
          {currentStep === 1 && (
            <div className="text-center w-[812px] mx-auto">
              <h1 className="mb-2 text-5xl font-medium font-[sohne-breit]">Welcome to TMRW100!</h1>

              <p className="text-2xl">
                <span className="relative">
                  John Guentara
                  <svg
                    height="9"
                    viewBox="0 0 188 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="absolute left-0 w-full -bottom-2"
                  >
                    <path
                      d="M2.5 7C53.4712 5.18265 161.431 1.64417 185.5 2.02902"
                      stroke="#F7ED00"
                      strokeWidth="4"
                      strokeLinecap="round"
                    />
                  </svg>
                </span>
                <span> thinks you would be a perfect fit for our platform!</span>
              </p>
            </div>
          )}

          {[2, 3].includes(currentStep) && (
            <div className="text-center w-[812px] mx-auto">
              <h1 className="mb-2 text-5xl font-medium font-[sohne-breit]">
                We try to accept everyone that shares our values.
              </h1>
            </div>
          )}

          <SignupSteps currentStep={currentStep} />

          {currentStep === 1 && <SignupDetails setCurrentStep={setCurrentStep} />}

          {currentStep === 2 && (
            <SignupAbout
              setCurrentStep={setCurrentStep}
              initialData={survey}
              collectSurvey={collectSurvey}
            />
          )}

          {currentStep === 3 && (
            <SignupProfession
              setCurrentStep={setCurrentStep}
              initialData={survey}
              collectSurvey={submitSurvey}
            />
          )}

          {(!appConfig || isCreatingUser || isUpdatingSurvey) && <LoadingSpinner />}
        </>
      )}
    </>
  );
};
