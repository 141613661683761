import { ToastContainer } from 'react-toastify';
import Select from 'react-select';
import { RecordingCard } from './RecordingCard';
import { useContext, useEffect, useState } from 'react';
import { Media } from 'src/common/types/media';
import { AuthContext } from 'src/contexts';
import { queryParams } from 'src/common/utils';
import { useHttp } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { Option } from 'src/common/types/event';

const orderByOptions = [
  { label: 'Added Date', value: 'createdAt' },
  { label: 'Name', value: 'name' },
];

const fileFormatOptions = [
  { label: 'All', value: '' },
  { label: 'Mp4', value: 'mp4' },
  { label: 'Mp3', value: 'mp3' },
];

const recordingOfOptions = [
  { label: 'All', value: '' },
  { label: 'Event', value: 'event' },
];

export const Recordings = () => {
  const { accessToken, profile } = useContext(AuthContext);
  const { sendRequest: getMedia, isLoading: isGettingMedia } = useHttp();
  const [media, setMedia] = useState<Media[]>([]);

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [fileFormat, setFileFormat] = useState<Option | null>(fileFormatOptions[0]);
  const [recordingOf, setRecordingOf] = useState<Option | null>(recordingOfOptions[0]);

  useEffect(() => {
    if (accessToken && profile) {
      getMediaHandler();
    }
  }, [accessToken, profile, orderByValue, fileFormat, recordingOf]);

  const getMediaHandler = async () => {
    const params = {
      filter: {
        type: recordingOf?.value,
        format: fileFormat?.value,
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
    };

    await getMedia(
      {
        url: 'media?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setMedia(res);
      },
    );
  };

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-opacity-50 font-[sohne-breit] leading-tight ">
            MEDIA ENGINE
          </span>

          <div className="flex items-center gap-2.5">
            <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Recordings</h1>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Format</span>
            <Select
              value={fileFormat}
              onChange={selectedOptions => {
                setFileFormat(selectedOptions);
              }}
              options={fileFormatOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>

          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Recording of</span>

            <Select
              value={recordingOf}
              onChange={selectedOptions => {
                setRecordingOf(selectedOptions);
              }}
              options={recordingOfOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>
            <Select
              value={orderByValue}
              onChange={selectedOptions => {
                setOrderByValue(selectedOptions);
              }}
              options={orderByOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-3">
        {media.map(media => (
          <RecordingCard media={media} key={media.id} />
        ))}
      </div>

      {!media.length && !isGettingMedia && <div className={`block p-8`}>No records found</div>}

      {isGettingMedia && <LoadingSpinner />}
    </>
  );
};
