import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { useHttp, useNotification } from 'src/common/hooks';
import { Event, EventUser, Option } from 'src/common/types/event';
import { queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { EventCard } from './EventCard';
import Select from 'react-select';
import { Room } from 'src/common/types/room';
import { FeaturedEventCard } from './FeaturedEventCard';
import { Pagination } from '../pagination/Pagination';

const orderByOptions = [
  { label: 'Post Date', value: 'createdAt' },
  { label: 'Start Time', value: 'startTime' },
  { label: 'Title', value: 'title' },
];

export const Events = () => {
  const notify = useNotification();
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const [events, setEvents] = useState<Event[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [roomOptions, setRoomOptions] = useState<Option[]>();
  const [selectedRoom, setSelectedRoom] = useState<Option | null>({
    value: 'all',
    label: 'All',
  });

  const {
    sendRequest: getEvents,
    isLoading: isGettingEvents,
    error: errorGettingEvents,
  } = useHttp();

  const { sendRequest: getRooms, isLoading: isGettingRoom, error: errorGettingRooms } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getEventsHandler();
    }
  }, [accessToken, profile, orderByValue, selectedRoom, currentPage]);

  useEffect(() => {
    if (accessToken) {
      getRoomsHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    if (errorGettingRooms) {
      notify(errorGettingRooms, 'error');
    }

    if (errorGettingEvents) {
      notify(errorGettingEvents, 'error');
    }
  }, [errorGettingEvents, errorGettingRooms]);

  const getEventsHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        roomId: selectedRoom?.value !== 'all' ? selectedRoom?.value : '',
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
      offset,
      limit: perPage,
    };

    const currentTime = new Date();

    await getEvents(
      {
        url: 'events?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedEvents = res.data.reduce((filteredEvents: Event[], event: Event) => {
          const eventStartTime = new Date(event.startTime);

          if (eventStartTime >= currentTime) {
            filteredEvents.push(event);
          }

          return filteredEvents;
        }, []);

        setEvents(transformedEvents);
        setTotalCount(res.total);
      },
    );
  };

  const getRoomsHandler = async () => {
    await getRooms(
      {
        url: 'events/rooms',
        method: 'GET',
      },
      (res: any) => {
        if (res) {
          const options = res.map((room: Room) => ({
            value: room.roid,
            label: room.roomName,
          }));
          const allOption = { value: 'all', label: 'All' };
          setRoomOptions([allOption, ...options]);
        }
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const createAndJoin =
    appConfig?.parentId === null ||
    appConfig?.features['Events'].subFeatures.includes('Create and join events');

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
            COMMUNITY
          </span>
          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Events</h1>
        </div>

        {createAndJoin && (
          <Link
            to="/events/create"
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Create event
          </Link>
        )}
      </div>

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          {/* <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Domain</span>
            <Select
              value={{ label: 'All', value: 'date' }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div> */}

          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Room</span>

            <Select
              value={selectedRoom}
              isDisabled={isGettingRoom}
              options={roomOptions}
              onChange={option => {
                setSelectedRoom(option);
              }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[150px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>
            <Select
              value={orderByValue}
              options={orderByOptions}
              onChange={option => setOrderByValue(option)}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-2.5">
        {events.map((event, index) =>
          index === 3 ? (
            <FeaturedEventCard event={event} key={index} />
          ) : (
            <EventCard event={event} key={event.id} />
          ),
        )}
      </div>

      {events.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!events.length ? <div className={`text-center block p-8`}>No records found</div> : null}

      {isGettingEvents && <LoadingSpinner />}
    </>
  );
};
