import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { formatDate, getInitials } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

export const ActivityTab = () => {
  const { accessToken, profile } = useContext(AuthContext);
  const [subAccounts, setSubAccountsList] = useState<any[]>([]);
  const notify = useNotification();

  const { sendRequest: getSubAccounts, error: errorGettingSubAccounts } = useHttp();

  useEffect(() => {
    if (accessToken && profile && profile?.id) {
      getSubAccountsHandler();
    }
  }, [accessToken, profile]);

  useEffect(() => {
    if (errorGettingSubAccounts) {
      notify(errorGettingSubAccounts, 'error');
    }
  }, [errorGettingSubAccounts]);

  const getSubAccountsHandler = async () => {
    await getSubAccounts(
      {
        url: `user/actions/${profile?.id}`,
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setSubAccountsList(res);
      },
    );
  };

  function getBackgroundColor(type?: string): string {
    switch (type) {
      case 'Access':
        return '#7D8473';
      case 'Members':
        return '#1954C1';
      case 'Clubs':
        return '#119F97';
      case 'Events':
        return '#C1640F';
      case 'Articles':
        return '#9F119A';
      default:
        return '#000000';
    }
  }

  return (
    <>
      <div className="bg-white border border-[#E5E5E5] rounded-[15px] rounded-tl-none">
        <div className="p-7 pl-[50px] flex items-center justify-between border-b border-[#D6D6D6]">
          <h5 className="font-bold text-lg text-black font-[sohne-breit]">Activity Log</h5>
        </div>

        <div className="pl-[50px] pb-[65px]">
          <table className="min-w-full">
            <thead className="text-black text-opacity-50 border-b border-[#E5E5E5]">
              <tr>
                <th className="py-5 text-xs font-medium w-[240px] text-left">
                  <span className="border-b border-black border-opacity-50">User</span>
                </th>

                <th className="px-2 py-5 text-xs font-medium w-[106px] text-left">
                  <span className="border-b border-black border-opacity-50">Type</span>
                </th>

                <th className="px-2 py-5 text-xs font-medium w-[400px] text-left">
                  <span className="border-b border-black border-opacity-50">Subtype</span>
                </th>

                <th className="px-2 py-5 text-xs font-medium w-[400px] text-left">
                  <span className="text-right border-b border-black border-opacity-50">
                    Related entity
                  </span>
                </th>

                <th className="px-2 py-5 text-xs font-medium w-[400px] text-left">
                  <span className="text-right border-b border-black border-opacity-50">Date</span>
                </th>
              </tr>
            </thead>

            <tbody className="leading-tight text-[14px]">
              {subAccounts.map((account, index) => {
                return (
                  <tr className="border-b" key={index}>
                    <td className="py-4 max-w-[200px] relative">
                      <div className="rounded-full h-7 w-7 bg-[#DCE3EB] font-bold text-xs flex items-center justify-center absolute -left-9 inset-y-0 m-auto">
                        {getInitials(account?.user?.name)}
                      </div>

                      <div className="block text-lg font-bold text-black underline truncate">
                        {account?.user?.name}
                      </div>

                      <div className="text-[13px] truncate">{account?.user?.email}</div>
                    </td>

                    <td className="px-2 py-4">
                      <span
                        className={`text-white font-medium px-3 py-2 rounded-xl bg-[${getBackgroundColor(
                          account?.type,
                        )}]`}
                      >
                        {account?.type}
                      </span>
                      {/* <span className="text-white font-medium px-3 py-2 rounded-xl bg-[#119F97]">
                        Clubs
                      </span>
                      <span className="text-white font-medium px-3 py-2 rounded-xl bg-[#1954C1]">
                        Members
                      </span>
                      <span className="text-white font-medium px-3 py-2 rounded-xl bg-[#C1640F]">
                        Events
                      </span>
                      <span className="text-white font-medium px-3 py-2 rounded-xl bg-[#9F119A]">
                        Articles
                      </span> */}
                    </td>

                    <td className="px-2 py-4">
                      <span className="font-medium p-2 rounded-xl text-[#234685] border border-[#325EAE33]">
                        {account?.subType}
                      </span>
                    </td>

                    <td className="px-2 py-4">
                      <span className="underline max-w-[114px] truncate text-black">
                        {account?.entityName}
                      </span>
                    </td>

                    <td className="px-2 py-4 max-w-[114px] truncate">
                      {formatDate(account?.createdAt)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
