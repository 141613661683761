import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Country } from 'src/common/types/country';
import { Option } from 'src/common/types/event';
import { Skill } from 'src/common/types/skill';

export const AboutMeTab = () => {
  const notify = useNotification();

  const { accessToken, profile, fetchProfile } = useContext(AuthContext);

  const [skillsOptions, setSkillsOptions] = useState<Option[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const { sendRequest: submitAboutMeData, isLoading: isUpdatingAbout } = useHttp();
  const { sendRequest: getSkills, isLoading: isGettingSkills } = useHttp();
  const { sendRequest: getCountries, isLoading: isGettingCountries } = useHttp();

  const formik = useFormik({
    initialValues: {
      name: '',
      skills: [] as Option[],
      interests: [] as Option[],
      bio: '',
      countryId: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required()
        .matches(/^[a-zA-Z\s]*$/, 'Name must only contain letters and spaces')
        .max(50, 'Name must not exceed 50 characters'),
      skills: Yup.array().min(1).required(),
      interests: Yup.array().min(1).required(),
      countryId: Yup.string().trim().required(),
      bio: Yup.string().trim().required(),
    }),
    onSubmit: values => {
      submitAboutMeData(
        {
          url: `user/${profile?.id}`,
          method: 'PATCH',
          accessToken,
          payload: {
            ...values,
            skillIds: values.skills.map(skill => skill?.value),
          },
        },
        handleAboutMeResponse,
      );
    },
  });

  const { setValues } = formik;

  useEffect(() => {
    if (!profile) return;

    getSkillsHandler();
    getCountryHandler();

    const { name, skills, country, bio, interests } = profile;

    setValues({
      name: name || '',
      skills: skills
        ? skills.map((skill: Skill) => ({
            value: skill.id,
            label: skill.name,
          }))
        : [],
      interests: interests,
      bio: bio || '',
      countryId: country?.id || '',
    });
  }, [profile]);

  const handleAboutMeResponse = () => {
    fetchProfile();

    notify('Profile updated', 'success');
  };

  const getSkillsHandler = async () => {
    await getSkills(
      {
        url: 'skills',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedSkills = res.map((skill: Skill) => ({
          value: skill.id,
          label: skill.name,
        }));

        setSkillsOptions(transformedSkills);
      },
    );
  };

  const getCountryHandler = async () => {
    await getCountries(
      {
        url: 'countries',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setCountries(res);
      },
    );
  };

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="bg-white border border-[#E5E5E5] rounded-2xl p-16 shadow-lg">
        <h1 className="font-[sohne-breit] text-black text-2xl mb-3.5">About me</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-7">
            <label className="space-y-2">
              <div className="font-bold text-black">Full name</div>

              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="Enter your full name..."
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.name && formik.errors.name
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">My skills</div>

              <Select
                isDisabled={isGettingSkills}
                name="skills"
                value={formik.values.skills}
                onChange={selectedOptions => {
                  const fakeEvent = {
                    target: {
                      name: 'skills',
                      value: selectedOptions,
                    },
                  };
                  formik.handleChange(fakeEvent);
                }}
                options={skillsOptions}
                isMulti
                classNames={{
                  control: () =>
                    `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                      formik.errors.skills && formik.touched.skills
                        ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                        : ''
                    }`,
                  indicatorSeparator: () => '!my-0',
                }}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">My interests</div>

              <Select
                isDisabled={isGettingSkills}
                name="interests"
                value={formik.values.interests}
                onChange={selectedOptions => {
                  const fakeEvent = {
                    target: {
                      name: 'interests',
                      value: selectedOptions,
                    },
                  };
                  formik.handleChange(fakeEvent);
                }}
                options={skillsOptions}
                isMulti
                classNames={{
                  control: () =>
                    `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                      formik.errors.interests && formik.touched.interests
                        ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                        : ''
                    }`,
                  indicatorSeparator: () => '!my-0',
                }}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">Country</div>

              <select
                name="countryId"
                value={formik.values.countryId}
                disabled={isGettingCountries}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.countryId && formik.errors.countryId
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              >
                <option value="" hidden>
                  Select your country...
                </option>

                {countries.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">A few words about me</div>

              <textarea
                name="bio"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bio}
                placeholder="A few words about yourself or a quote that represents you..."
                className={`w-full rounded-md min-h-[120px] p-4 border-gray-300 focus:border-gray-300 ${
                  formik.touched.bio && formik.errors.bio
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              ></textarea>
            </label>

            <button
              disabled={isUpdatingAbout}
              className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
