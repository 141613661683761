import { useState } from 'react';
import { StepStatus } from 'src/common/components/StepStatus';
import ForwardArrowSvg from 'src/common/components/svg/ArrowRight';
import { EditSubAccountData, Feature, Member } from 'src/common/types/sub-account';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { getInitials, queryParams } from 'src/common/utils';
import { EditAdminAccountAccessTab } from './EditAdminAccountAccessTab';
import { EditAdminAccountDetailTab } from './EditAdminAccountDetailTab';

interface EditAdminAccountPopupProps {
  onClose: () => void;
  member: Member;
}

export const EditAdminAccountPopup = ({ onClose, member }: EditAdminAccountPopupProps) => {
  const [features, setFeatures] = useState<Feature[]>([]);
  const { accessToken } = useContext(AuthContext);

  const [currentStep, setCurrentStep] = useState(1);
  const notify = useNotification();

  const [adminAccount, setAdminAccount] = useState<EditSubAccountData>({
    detail: {
      name: member?.name,
    },
    features: member.features.map(feature => feature.subFeature.id),
  });

  const {
    sendRequest: getFeatures,
    isLoading: isGettingFeatures,
    error: errorGettingFeatures,
  } = useHttp();

  const {
    sendRequest: updateAdminAccount,
    isLoading: isUpdatingAdminAccount,
    error: errorUpdatingAdminAccount,
  } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getFeaturesHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    if (errorGettingFeatures) {
      notify(errorGettingFeatures, 'error');
    }

    if (errorUpdatingAdminAccount) {
      notify(errorUpdatingAdminAccount, 'error');
    }
  }, [errorGettingFeatures, errorUpdatingAdminAccount]);

  const getFeaturesHandler = async () => {
    const params = {
      filter: {
        adminOnly: true,
      },
    };

    await getFeatures(
      {
        url: 'features?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setFeatures(res);
      },
    );
  };

  const updateAdminAccountHandler = async (data: EditSubAccountData) => {
    setAdminAccount(data);

    updateAdminAccount(
      {
        url: 'user/update-admin-account/' + member.id,
        method: 'PATCH',
        accessToken,
        payload: {
          ...data.detail,
          features: data.features,
        },
      },
      handleUpdateResponse,
    );
  };

  const stepsAppearance = {
    default: {
      textClasses: 'text-black text-opacity-50',
      backgroundClasses: '',
      borderClasses: 'border border-black border-opacity-50',
    },
    active: {
      textClasses: 'text-black',
      backgroundClasses: 'bg-[#E5E5E5]',
      borderClasses: '',
    },
    completed: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-[#69BB00]',
      borderClasses: '',
    },
  };

  const moveToStep = (step: number) => {
    if (step > currentStep) {
      return;
    }

    setCurrentStep(step);
  };

  const collectData = (data: EditSubAccountData) => {
    setAdminAccount(data);
  };

  const handleUpdateResponse = () => {
    notify(`Account updated successfully!`, 'success');
    onClose();
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50 flex-col py-10">
        <div className="w-[550px]  bg-white rounded-lg">
          <div className="pl-14 py-[30px] border-b border-[#D6D6D6] relative">
            <div className="absolute rounded-full -left-4 w-[60px] h-[60px] overflow-hidden border-[7px] border-white inset-y-0 my-auto flex items-center justify-center font-bold text-lg bg-[#DCE3EB]">
              {member.avatar ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img
                    src={member.avatar}
                    className="object-cover w-full h-full rounded-full"
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div>{getInitials(member.name)}</div>
              )}
            </div>

            <div className="font-bold text-black text-[22px] mb-1 max-w-[320px] font-[sohne-breit] leading-none">
              {member?.name}
            </div>

            <div className="text-black text-opacity-70">{member.email}</div>
          </div>

          <div className="relative flex flex-col overflow-y-scroll shadow-lg p-14 custom-scrollbar-1 max-h-[80vh]">
            <button className="absolute right-3 top-3" onClick={onClose}>
              <svg
                width="43"
                height="42"
                viewBox="0 0 43 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
                  fill="#E5E5E5"
                />
                <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
                <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </button>

            <h2 className="text-[34px] font-bold font-[sohne-breit] tracking-wide text-black leading-8">
              Edit <br /> admin account
            </h2>

            <div className="flex items-center gap-4 my-10 font-[sohne-breit]">
              <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(1)}>
                <StepStatus stepNumber={1} currentStep={currentStep} appearance={stepsAppearance} />

                <span className="font-normal text-gray-500">User details</span>
              </div>

              <ForwardArrowSvg className="w-5 h-5 text-black text-opacity-50" />

              <div className="flex items-center gap-3 cursor-pointer" onClick={() => moveToStep(2)}>
                <StepStatus stepNumber={2} currentStep={currentStep} appearance={stepsAppearance} />

                <span className="font-normal text-gray-500">Define access</span>
              </div>
            </div>

            {currentStep === 1 && (
              <EditAdminAccountDetailTab
                setCurrentStep={setCurrentStep}
                initialData={adminAccount}
                collectData={collectData}
                disabled={isUpdatingAdminAccount || isGettingFeatures}
                onClose={onClose}
              />
            )}
            {currentStep === 2 && (
              <EditAdminAccountAccessTab
                setCurrentStep={setCurrentStep}
                initialData={adminAccount}
                collectData={updateAdminAccountHandler}
                disabled={isUpdatingAdminAccount || isGettingFeatures}
                onClose={onClose}
                features={features}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
