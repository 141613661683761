import { useFormik } from 'formik';
import Select from 'react-select';
import { BlogSettingTabsProps } from 'src/common/types/blog';
import * as Yup from 'yup';

const toneOptions = [
  { value: 'professional', label: 'Professional' },
  { value: 'informative', label: 'Informative' },
  { value: 'friendly', label: 'Friendly' },
  { value: 'optimistic', label: 'Optimistic' },
];

const themeOptions = [
  { value: 'speaker-expertise', label: "Speaker's expertise" },
  { value: 'challenges-and-opportunities', label: 'Challenges and opportunities' },
  { value: 'key-points', label: 'Key points' },
  {
    value: 'TMRWs role in improving the discussed topics',
    label: 'TMRWs role in improving the discussed topics',
  },
];

const readerOptions = [{ value: 'broad-audience', label: 'Broad audience' }];

export const BlogBasicSettingsTab = ({
  initialData,
  collectData,
  setCurrentStep,
}: BlogSettingTabsProps) => {
  const formik = useFormik({
    initialValues: initialData.basic,
    validationSchema: Yup.object({
      tone: Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .required(),
      theme: Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .required(),
      readers: Yup.object()
        .shape({
          value: Yup.string().required(),
          label: Yup.string().required(),
        })
        .required(),
      background_info: Yup.boolean(),
      length: Yup.string().required(),
    }),
    onSubmit: values => {
      const { tone, theme, readers, background_info, length } = values;

      const prompt = `Dear AI Blog Writer, I need your assistance to create an engaging and informative blog post. The topic is "${
        theme?.label
      }", and the target audience is "${readers?.label}". I'm looking for a "${
        tone?.label
      }" tone to make it reader-friendly and engaging. The preferred length is "${length}" with ${
        background_info ? 'background information included' : 'no background information'
      }.`;

      const blog = { ...initialData, basic: { ...values }, prompt };

      collectData(blog);

      setCurrentStep(4);
    },
  });

  const handleLengthChange = (selectedLength: string) => {
    formik.setFieldValue('length', selectedLength);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-y-[30px] mb-10 text-black">
          <label className="space-y-2 border-b border-[#D6D6D6] pb-[30px]">
            <div className="font-bold">Article tone</div>

            <Select
              name="tone"
              value={formik.values.tone}
              onChange={selectedOption => {
                const fakeEvent = {
                  target: {
                    name: 'tone',
                    value: selectedOption,
                  },
                };

                formik.handleChange(fakeEvent);
              }}
              options={toneOptions}
              classNames={{
                control: () =>
                  `h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                    formik.errors.tone && formik.touched.tone
                      ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                      : ''
                  }`,
                indicatorSeparator: () => '!my-0',
              }}
            />
          </label>

          <label className="space-y-2 border-b border-[#D6D6D6] pb-[30px]">
            <div className="font-bold">Focus theme</div>

            <Select
              name="theme"
              value={formik.values.theme}
              options={themeOptions}
              onChange={selectedOption => {
                const fakeEvent = {
                  target: {
                    name: 'theme',
                    value: selectedOption,
                  },
                };
                formik.handleChange(fakeEvent);
              }}
              classNames={{
                control: () =>
                  `h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                    formik.errors.theme && formik.touched.theme
                      ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                      : ''
                  }`,
                indicatorSeparator: () => '!my-0',
              }}
            />
          </label>

          <label className="space-y-2 border-b border-[#D6D6D6] pb-[30px]">
            <div className="font-bold">Targeted readers</div>

            <Select
              name="readers"
              value={formik.values.readers}
              options={readerOptions}
              onChange={selectedOption => {
                const fakeEvent = {
                  target: {
                    name: 'readers',
                    value: selectedOption,
                  },
                };

                formik.handleChange(fakeEvent);
              }}
              classNames={{
                control: () =>
                  `h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                    formik.errors.readers && formik.touched.readers
                      ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                      : ''
                  }`,
                indicatorSeparator: () => '!my-0',
              }}
            />
          </label>

          <label className="space-y-2  border-b border-[#D6D6D6] pb-[30px]">
            <div className="font-bold">Preferred length</div>

            <div className="flex">
              <div
                className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-l-lg ${
                  formik.values.length === 'brief'
                    ? '!text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current !font-bold'
                    : 'text-opacity-50'
                }`}
                onClick={() => handleLengthChange('brief')}
              >
                Summary
              </div>

              <div
                className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] ${
                  formik.values.length === 'medium'
                    ? '!text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current !font-bold'
                    : 'text-opacity-50'
                }`}
                onClick={() => handleLengthChange('medium')}
              >
                Average length
              </div>

              <div
                className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-r-lg ${
                  formik.values.length === 'lengthy'
                    ? '!text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current !font-bold'
                    : 'text-opacity-50'
                }`}
                onClick={() => handleLengthChange('lengthy')}
              >
                Lengthy
              </div>
            </div>
          </label>

          <div className="flex items-center justify-between  border-b border-[#D6D6D6] pb-[30px]">
            <div>
              <div className="font-bold">Include background info about the discussed topics</div>

              <div className="text-sm text-black text-opacity-70">
                A short description about the option and its effects
              </div>
            </div>

            <label className="relative inline-flex items-center cursor-pointer">
              <input
                name="background_info"
                checked={formik.values.background_info}
                type="checkbox"
                onChange={formik.handleChange}
                className="sr-only peer"
              />

              <div className="w-11 h-6 bg-[#F3580033] rounded-full border-0 peer peer-checked:after:translate-x-full peer-checked:after:border-[#F35800] after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300  peer-checked:after:bg-[#F35800] after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#F3580033]"></div>
            </label>
          </div>
        </div>

        <div className="flex gap-6">
          <button
            type="submit"
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Next
          </button>

          <button
            className="text-black underline text-opacity-70"
            onClick={() => setCurrentStep(2)}
          >
            Back to the previous step
          </button>
        </div>
      </form>
    </>
  );
};
