import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHttp, useNotification } from 'src/common/hooks';
import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';

export const CreateCirclePopup = ({ onClose, onCreated }: CreateCirclePopupProps) => {
  const { accessToken, profile } = useContext(AuthContext);
  const notify = useNotification();

  const { sendRequest: createCircle, error: errorCreatingCircle } = useHttp();

  useEffect(() => {
    if (errorCreatingCircle) {
      notify(errorCreatingCircle, 'error');
    }
  }, [errorCreatingCircle]);

  const formik = useFormik({
    initialValues: {
      circleName: '',
    },
    validationSchema: Yup.object({
      circleName: Yup.string().trim().required().max(50),
    }),
    onSubmit: async values => {
      createCircle(
        {
          url: 'circle',
          method: 'POST',
          accessToken,
          payload: { name: values.circleName, userId: profile?.parentId ?? profile?.id },
        },
        handleCreateResponse,
      );
    },
  });

  const handleCreateResponse = () => {
    notify(`The “${formik.values.circleName}” circle was created.`, 'success');
    onCreated();
    onClose();
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50">
      <div className="w-[409px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col">
        <button className="absolute right-3 top-3" onClick={onClose}>
          <svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
              fill="#E5E5E5"
            />
            <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>

        <h2 className="text-[30px] font-bold font-[sohne-breit] tracking-wide leading-[1] mb-10">
          Create a <br /> circle
        </h2>

        <form onSubmit={formik.handleSubmit}>
          <div className="mb-10">
            <label className="space-y-2">
              <div className="font-bold text-black">Circle name</div>

              <input
                type="text"
                name="circleName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.circleName}
                placeholder="A name for the circle (eg: Co-workers)"
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.circleName && formik.errors.circleName
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              />
            </label>
          </div>

          <div className="flex gap-6">
            <button
              type="submit"
              className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              Save
            </button>

            <button onClick={onClose} className="text-black underline text-opacity-70">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

interface CreateCirclePopupProps {
  onClose: () => void;
  onCreated: () => void;
}
