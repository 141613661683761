import { useContext, useEffect, useState } from 'react';
import { useHttp } from 'src/common/hooks';
import { BlogMediaTabsProps, BlogTabData } from 'src/common/types/blog';
import { Media } from 'src/common/types/media';
import { formatDateTime, queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

const getFileTypeStyles = (fileType: string) => {
  switch (fileType) {
    case 'audio/mpeg':
      return {
        text: 'Audio',
        bgColor: '#36448FCC',
      };
    case 'video/mp4':
      return {
        text: 'Video',
        bgColor: '#A63A8E',
      };
    default:
      return {
        text: 'Unknown',
        bgColor: 'gray',
      };
  }
};

export const BlogSelectMediaTab = ({
  collectData,
  onCancel,
  initialData,
  setCurrentStep,
}: BlogMediaTabsProps) => {
  const { accessToken, profile } = useContext(AuthContext);
  const { sendRequest: getMedia, isLoading: isGettingMedia } = useHttp();
  const [media, setMedia] = useState<Media[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [transcript, setTranscript] = useState<string>('');

  useEffect(() => {
    if (accessToken && profile) {
      getMediaHandler();
    }
  }, [accessToken, profile]);

  const getMediaHandler = async () => {
    const params = {
      sort: {
        createdAt: 'DESC',
      },
    };

    await getMedia(
      {
        url: 'media?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setMedia(res);
      },
    );
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const transcript = event.target.value;

    setTranscript(transcript);
  };

  const handleSelectFile = () => {
    if (transcript) {
      const blog = {
        ...initialData,
        transcript: transcript,
      } as BlogTabData;

      collectData(blog);

      setCurrentStep(2);
    }
  };

  return (
    <>
      {media.length > 0 && (
        <div>
          <div className="mb-10 max-h-[372px] overflow-y-scroll custom-scrollbar">
            {media.length > 3 && (
              <input
                type="search"
                placeholder="Start typing to search for a specific file..."
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 mb-5 !border-b-[#005AFF]`}
              />
            )}

            {media
              .filter(media => media.name.toLowerCase().includes(searchValue.toLowerCase()))
              .map((media, index) => (
                <label
                  key={index}
                  className="flex items-center gap-2.5 border-t border-[#E8E8E8] py-[25px] last:border-b relative cursor-pointer"
                >
                  {media.eventId && (
                    <svg
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute right-[10px] top-[10px]"
                    >
                      <path
                        d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V14C24 18.4183 20.4183 22 16 22H8C3.58172 22 0 18.4183 0 14V8Z"
                        fill="#F7ED00"
                      />
                      <g opacity="0.7">
                        <g opacity="0.7">
                          <path
                            d="M13.3877 5.67188V7.33563"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.3877 14.959V16.3505"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M13.3877 12.5976V9.2832"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M17.0266 16.5C18.3931 16.5 19.5 15.498 19.5 14.2609V12.4786C18.5957 12.4786 17.8675 11.8193 17.8675 11.0007C17.8675 10.1821 18.5957 9.52215 19.5 9.52215L19.4992 7.73909C19.4992 6.502 18.3916 5.5 17.0258 5.5H6.97419C5.60842 5.5 4.50078 6.502 4.50078 7.73909L4.5 9.58021C5.40426 9.58021 6.13251 10.1821 6.13251 11.0007C6.13251 11.8193 5.40426 12.4786 4.5 12.4786V14.2609C4.5 15.498 5.60685 16.5 6.9734 16.5H17.0266Z"
                            stroke="black"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                    </svg>
                  )}

                  <input
                    type="radio"
                    name="description"
                    value={media.transcript}
                    onChange={handleRadioChange}
                    className="w-6 h-6 mr-2 bg-[#E5E5E5] rounded-full text-orange-800 !ring-[transparent]"
                  />

                  <div>
                    <div className="flex gap-1.5 items-center">
                      <span className="text-[17px] text-[#000000B2] font-bold leading-none truncate max-w-[12vw]">
                        {media.name}
                      </span>

                      <span
                        className="text-xs leading-none bg-[#36448FCC] rounded-full text-white px-1.5 py-1"
                        style={{
                          background: getFileTypeStyles(media.type).bgColor,
                        }}
                      >
                        {getFileTypeStyles(media.type).text}
                      </span>

                      <span className="text-xs leading-none bg-[#E5E5E5] rounded-full text-black px-1.5 py-1">
                        00:39:12
                      </span>
                    </div>

                    <div className="text-[#000000B2] text-[13px]">
                      {formatDateTime(media.createdAt)}
                    </div>
                  </div>
                </label>
              ))}
          </div>

          <div className="flex gap-6">
            <button
              onClick={handleSelectFile}
              type="submit"
              className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              Next
            </button>

            <button className="text-black underline text-opacity-70" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </div>
      )}

      {!media.length && !isGettingMedia && (
        <div className={`text-center block p-8`}>No records found</div>
      )}
    </>
  );
};
