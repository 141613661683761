import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { CreateBlogProps } from 'src/common/types/blog';
import { AuthContext } from 'src/contexts';

export const BlogGeneratedArticleTab = ({
  setCurrentStep,
  initialData,
  collectData,
  setDisabled,
}: CreateBlogProps) => {
  const { accessToken } = useContext(AuthContext);
  const {
    sendRequest: generateArticle,
    isLoading: generatingArticle,
    error: errorGeneratingArticle,
  } = useHttp();

  const [blogContent, setBlogContent] = useState<{ title: string; body: string } | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const notify = useNotification();

  useEffect(() => {
    if (accessToken) {
      initialData.transcript && handleGenerateArticle();
    }
  }, [accessToken]);

  useEffect(() => {
    if (errorGeneratingArticle) {
      notify(errorGeneratingArticle, 'error');

      setCurrentStep(3);
    }
  }, [errorGeneratingArticle]);

  useEffect(() => {
    if (!blogContent && uploadPercentage < 100) {
      const timer = setTimeout(() => {
        setUploadPercentage(prevPercentage => prevPercentage + 1);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [blogContent, uploadPercentage]);

  const handleGenerateArticle = async () => {
    setDisabled(true);

    await generateArticle(
      {
        url: 'directus/generate-article',
        method: 'POST',
        accessToken,
        payload: { transcript: initialData.transcript, prompt: initialData.prompt },
      },
      (response: any) => {
        const { title, body } = response;

        setBlogContent({
          title: title,
          body: body,
        });

        setDisabled(false);
      },
    );
  };

  const handleTitleChange = (e: { target: { textContent: any } }) => {
    if (e.target.textContent) {
      const updatedBlogContent = {
        title: e.target.textContent,
        body: blogContent?.body || '',
      };

      setBlogContent(updatedBlogContent);
    }
  };

  const handleBodyChange = (e: { target: { textContent: any } }) => {
    if (e.target.textContent) {
      const updatedBlogContent = {
        title: blogContent?.title || '',
        body: e.target.textContent,
      };

      setBlogContent(updatedBlogContent);
    }
  };

  const handleChangeTab = () => {
    const blog = { ...initialData, generate: blogContent } as any;
    collectData(blog);

    setCurrentStep(5);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="mb-10">
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 4
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[80%]">
          {blogContent ? 'Article generated' : 'Generating article...'}
        </h2>

        {generatingArticle && (
          <>
            <div className="h-[8px] rounded-full bg-[#E5E5E5] overflow-hidden mt-4">
              <div
                className="h-full rounded-full bg-[#65912c] transition-all duration-300"
                style={{ width: `${uploadPercentage}%` }}
              ></div>
            </div>

            <div className="mt-2 text-sm text-black text-opacity-70">
              {!blogContent ? 'Please wait, generating...' : 'Completed'}
            </div>
          </>
        )}
      </div>

      {generatingArticle && (
        <div className="border border-[#1954C1] rounded-lg bg-[#1954C10D] px-[30px] py-5 mb-10">
          We&apos;re generating the article. You will see the result below, once is ready.
        </div>
      )}

      {blogContent && !generatingArticle && (
        <div className="border border-[#1954C1] rounded-lg bg-[#1954C10D] px-[30px] py-5 mb-10">
          The article was generated. You can review it and make changes below.
        </div>
      )}

      {blogContent && (
        <>
          <div className="flex-1 flex gap-y-[30px] flex-col max-h-[45vh] overflow-y-scroll custom-scrollbar bg-[#F1F2F4] rounded-lg p-5">
            <div className="flex items-start gap-x-8">
              <span>
                <svg
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="1.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="1.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="1.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
                </svg>
              </span>

              <h5
                className="-mt-2 text-[25px] font-bold font-[sohne-breit] leading-tight"
                contentEditable={true}
                onBlur={handleTitleChange}
                dangerouslySetInnerHTML={{ __html: blogContent.title }}
                style={{ outline: 'none', border: 'none', padding: '0' }}
              ></h5>
            </div>

            <div className="flex items-start gap-x-8">
              <span>
                <svg
                  width="9"
                  height="17"
                  viewBox="0 0 9 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="1.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="1.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="1.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="8.5" r="1.5" fill="black" fillOpacity="0.7" />
                  <circle cx="7.5" cy="15.5" r="1.5" fill="black" fillOpacity="0.7" />
                </svg>
              </span>

              <p
                className="-mt-1.5"
                contentEditable={true}
                onBlur={handleBodyChange}
                dangerouslySetInnerHTML={{ __html: blogContent.body }}
                style={{ outline: 'none', border: 'none', padding: '0' }}
              ></p>
            </div>
          </div>

          <div className="flex gap-6 mt-10">
            <button
              onClick={handleChangeTab}
              disabled={!blogContent}
              type="submit"
              className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              Next
            </button>

            <button
              className="text-black underline text-opacity-70"
              onClick={() => setCurrentStep(3)}
            >
              Back to the previous step
            </button>
          </div>
        </>
      )}
    </div>
  );
};
