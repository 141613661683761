import { useContext, useEffect, useState } from 'react';
import { ApproveMemberPopup } from './ApproveMemberPopup';
import { RejectMemberPopup } from './RejectMemberPopup';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { User } from 'src/common/types/user';
import { Pagination } from 'src/components/pagination/Pagination';
import { queryParams } from 'src/common/utils';
import { MembersFilter } from 'src/common/types/filters';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface MembersRequestProps {
  setTotalCount: (totalCount: number) => void;
  getApprovedMembers: () => void;
  filters: MembersFilter;
  totalCount: number;
  searchValue: string;
}

export const MembersRequest = ({
  getApprovedMembers,
  totalCount,
  setTotalCount,
  searchValue,
  filters,
}: MembersRequestProps) => {
  const { accessToken } = useContext(AuthContext);
  const notify = useNotification();
  const [members, setMembers] = useState<User[]>([]);
  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState<User>();
  const [currentPage, setCurrentPage] = useState(1);
  const [startingIndex, setStartingIndex] = useState(0);
  const perPage = 10;

  const { sendRequest: pendingApprovals } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getPendingApprovals();
    }
  }, [accessToken, filters]);

  useEffect(() => {
    getPendingApprovals();
  }, [searchValue, currentPage]);

  const getPendingApprovals = async () => {
    if (searchValue !== '') {
      setCurrentPage(1);
    }

    const offset = (currentPage - 1) * perPage;
    const params = {
      filter: {
        isApproved: null,
        referralId: filters.parent?.value,
      },
      search: searchValue,
      offset,
      limit: perPage,
    };

    await pendingApprovals(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMembers(data.data);
        setTotalCount(data.total);

        setStartingIndex((currentPage - 1) * perPage);
      },
    );
  };

  const { sendRequest: updateApprove, error: errorUpdatingApprove } = useHttp();

  useEffect(() => {
    if (errorUpdatingApprove) {
      notify(errorUpdatingApprove, 'error');
    }
  }, [errorUpdatingApprove]);

  const updateApproveHandler = async (isApprove: boolean) => {
    if (selectedMember) {
      await updateApprove(
        {
          url: `user/approve/${selectedMember.id}`,
          method: 'PATCH',
          accessToken,
          payload: { isApprove },
        },
        (data: any) => {
          if (isApprove) {
            closeApprovePopup();
            notify('Member approved successfully', 'success');
            getPendingApprovals();
          } else {
            getPendingApprovals();
            closeRejectPopup();
            notify('Membership rejected successfully', 'success');
          }

          getApprovedMembers();
        },
      );
    }
  };

  const approveMember = (member: User) => {
    setSelectedMember(member);
    setShowApprovePopup(true);
  };

  const rejectMember = (member: User) => {
    setSelectedMember(member);
    setShowRejectPopup(true);
  };

  const closeApprovePopup = () => {
    setSelectedMember(undefined);
    setShowApprovePopup(false);
  };

  const closeRejectPopup = () => {
    setSelectedMember(undefined);
    setShowRejectPopup(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const formatDate = (createdAt: string) => {
    const createdAtDate = new Date(createdAt);

    return createdAtDate.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  return (
    <>
      {showApprovePopup && (
        <ApproveMemberPopup
          onClose={closeApprovePopup}
          member={selectedMember}
          updateApproveHandler={updateApproveHandler}
        />
      )}

      {showRejectPopup && (
        <RejectMemberPopup
          onClose={closeRejectPopup}
          member={selectedMember}
          updateApproveHandler={updateApproveHandler}
        />
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full mb-12 text-left text-black">
          <thead className="text-opacity-70">
            <tr>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Id</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Name &amp; Email</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">
                  Company &amp; Position
                </span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Invited by</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Invited on</span>
              </th>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">
                  Applied for membership on
                </span>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody className="leading-tight text-[14px]">
            {members.map((member, index) => {
              return (
                <tr className="border-b" key={member.id}>
                  <td className="px-6 py-4">{startingIndex + index + 1}</td>
                  <td className="px-6 py-4">
                    <Link
                      to={`/admin/members/${member.id}`}
                      className="text-lg font-bold underline"
                    >
                      {member.name ? member.name : '-'}
                    </Link>
                    <br />
                    <span className="text-[13px]">{member.email}</span>
                  </td>
                  <td className="px-6 py-4  max-w-[114px] truncate">
                    <span className="text-lg font-bold underline">
                      {member.company && member.company.name}
                    </span>
                    <br />
                    <span className="text-[13px]">Software Developer</span>
                  </td>
                  <td className="px-6 py-4 max-w-[114px] truncate">
                    <span>{member.parent?.name ? member.parent.name : '-'}</span>
                    <br />
                    <span className="font-[sohne-breit] text-white text-[11px] py-0.5 px-1.5 rounded-sm bg-[#CD9400]">
                      Gold
                    </span>
                  </td>
                  <td className="px-6 py-4">{formatDate(member?.invitation?.createdAt)}</td>
                  <td className="px-6 py-4">{formatDate(member.createdAt)}</td>
                  <td className="px-6 py-4">
                    <div className="flex gap-x-2">
                      <button
                        className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#5AA0001A] text-[#213A00]"
                        onClick={() => approveMember(member)}
                      >
                        Accept
                      </button>
                      <button
                        className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#C51C001A] text-[#600E00]"
                        onClick={() => rejectMember(member)}
                      >
                        Reject
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          onPageChange={(page: number) => handlePageChange(page)}
        />

        {!members.length ? <div className={`text-center block p-8`}>No records found</div> : null}
      </div>
    </>
  );
};
