import { User } from 'src/common/types/user';
import { AddMemberToCirclePopup } from '../popups/AddMemberToCirclePopup';
import { useState } from 'react';

interface MemberSuggestionCardProps {
  member: User;
}

export const MemberSuggestionCard = ({ member }: MemberSuggestionCardProps) => {
  const [showAddToCirclePopup, setShowAddToCirclePopup] = useState(false);

  return (
    <div className="flex justify-between w-full bg-white rounded-2xl">
      {showAddToCirclePopup && (
        <AddMemberToCirclePopup onClose={() => setShowAddToCirclePopup(false)} member={member} />
      )}

      <div className="py-[30px] pl-[13px] flex gap-x-2.5 items-center">
        <img src="assets/profile.png" alt="Profile" className="w-8 h-8" />

        <div>
          <div className="flex items-center leading-none">
            <div className="font-bold text-black text-[15px] mr-2.5 max-w-[150px] truncate">
              {member.name}
            </div>

            <span className="text-[12px] text-[#CD9400]">Gold</span>
          </div>

          <div className="text-[13px] text-black max-w-[170px]">{member.company?.role}</div>
        </div>
      </div>

      <div className="flex">
        <div className="px-[12px] border-l border-[#E5E5E5] flex items-center py-[25px] bg-[#005AFF05] rounded-r-3xl">
          <button onClick={() => setShowAddToCirclePopup(true)}>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="10.375" cy="10" r="8.75" stroke="#005AFF" strokeWidth="1.5" />
              <circle cx="10.3751" cy="10.0294" r="4.27941" stroke="#005AFF" strokeWidth="1.5" />
              <rect x="12.125" y="3.5" width="9" height="13" rx="3" fill="#FAFCFF" />
              <path d="M16.625 7.5V12.5" stroke="#005AFF" strokeWidth="1.5" strokeLinecap="round" />
              <path
                d="M14.125 10H19.125"
                stroke="#005AFF"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
