import { useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { Pagination } from 'src/components/pagination/Pagination';
import { formatDate, queryParams } from 'src/common/utils';
import { Invite } from 'src/common/types/invite';

interface PendingInvitesProps {
  setTotalCount: (totalCount: number) => void;
  totalCount: number;
  searchValue: string;
}

export const PendingInvites = ({ totalCount, setTotalCount, searchValue }: PendingInvitesProps) => {
  const [pendingInvites, setPendingInvites] = useState<Invite[]>([]);
  const { accessToken, profile } = useContext(AuthContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [startingIndex, setStartingIndex] = useState(0);
  const notify = useNotification();
  const perPage = 10; // Number of members per page

  const { sendRequest: getPendingInvites } = useHttp();
  const {
    sendRequest: resendInvite,
    isLoading: isSendingInvite,
    error: errorSendingInvite,
  } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getPendingInvitesHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    getPendingInvitesHandler();
  }, [searchValue, currentPage]);

  useEffect(() => {
    if (errorSendingInvite) {
      notify(errorSendingInvite, 'error');
    }
  }, [errorSendingInvite]);

  const getPendingInvitesHandler = async () => {
    if (searchValue !== '') {
      setCurrentPage(1);
    }

    const offset = (currentPage - 1) * perPage;
    const params = {
      pending: true,
      search: searchValue,
      offset,
      limit: perPage,
    };

    getPendingInvites(
      {
        url: 'invites?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setPendingInvites(data.data);
        setTotalCount(data.total);

        setStartingIndex((currentPage - 1) * perPage);
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const resendInviteHandler = async (email: string) => {
    if (!email) return;

    resendInvite(
      {
        url: 'invites/resend',
        method: 'POST',
        accessToken,
        payload: {
          userId: profile?.id,
          email,
        },
      },
      handleInviteResponse,
    );
  };

  const handleInviteResponse = () => {
    notify('Invitation sent successfully!', 'success');
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full text-left text-black">
          <thead className="text-opacity-70">
            <tr>
              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Id</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Name &amp; Email</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Invited by</span>
              </th>

              <th className="px-6 py-3 text-xs font-medium">
                <span className="border-b border-black border-opacity-70">Invite on</span>
              </th>

              <th></th>
            </tr>
          </thead>

          <tbody className="leading-tight text-[14px]">
            {pendingInvites.map((invite, index) => {
              return (
                <tr className="border-b" key={invite.id}>
                  <td className="px-6 py-4">{startingIndex + index + 1}</td>

                  <td className="px-6 py-4">
                    <span>{invite.email}</span>
                  </td>

                  <td className="px-6 py-4 max-w-[114px] truncate">
                    {invite.user?.name ?? 'Jane Smith'}
                  </td>

                  <td className="px-6 py-4">{formatDate(invite?.createdAt)}</td>

                  <td className="px-6 py-4 space-x-2">
                    <button
                      className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#F0F4F2] text-black"
                      onClick={() => resendInviteHandler(invite.email)}
                      disabled={isSendingInvite}
                    >
                      Resend invitation
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={perPage}
          onPageChange={(page: number) => handlePageChange(page)}
        />
      </div>
    </>
  );
};
