import { useState } from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';

import { BlogBasicSettingsTab } from './BlogBasicSettingsTab';
import { BlogsAdvancedSettingTab } from './BlogsAdvancedSettingTab';
import { BlogTabData, CreateBlogProps } from 'src/common/types/blog';
import { BlogsCustomSettingTab } from './BlogsCustomSettingTab';

export const BlogSettingsTab = ({ setCurrentStep, initialData, collectData }: CreateBlogProps) => {
  const [activeTab, setActiveTab] = useState('basic');

  const tabs = [
    {
      key: 'basic',
      label: 'Basic',
    },
    {
      key: 'predefined',
      label: 'Predefined',
    },
    {
      key: 'custom',
      label: 'Custom',
    },
  ];

  const collect = (prompt: string) => {
    const blog = { ...initialData, prompt } as BlogTabData;

    collectData(blog);
    setCurrentStep(4);
  };

  return (
    <>
      <div className="mb-[30px]">
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 3
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[78%]">
          Generation settings
        </h2>
      </div>

      <div className="flex-1 flex gap-y-[30px] flex-col relative">
        <Tabs value={activeTab}>
          <TabsHeader
            placeholder="Empty"
            className="items-center justify-between p-0 mb-[30px] bg-transparent rounded-none border-b border-[#E8E8E8]"
            indicatorProps={{
              className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
            }}
          >
            <div className="flex justify-between gap-10">
              {tabs.map(({ key, label }) => (
                <Tab
                  placeholder="Empty"
                  key={key}
                  value={key}
                  onClick={() => setActiveTab(key)}
                  className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black z-0 ${
                    activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                  }`}
                >
                  {label}
                </Tab>
              ))}
            </div>
          </TabsHeader>

          <TabsBody placeholder="Empty">
            {tabs.map(({ key }) => (
              <TabPanel key={key} value={key} className="p-0">
                {key === 'basic' && (
                  <BlogBasicSettingsTab
                    collectData={collectData}
                    setCurrentStep={setCurrentStep}
                    initialData={initialData}
                  />
                )}

                {key === 'predefined' && (
                  <BlogsAdvancedSettingTab
                    collectData={collectData}
                    setCurrentStep={setCurrentStep}
                    initialData={initialData}
                  />
                )}

                {key === 'custom' && (
                  <BlogsCustomSettingTab
                    collectData={collectData}
                    setCurrentStep={setCurrentStep}
                    initialData={initialData}
                  />
                )}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </>
  );
};
