import { useFormik } from 'formik';
import { BlogSettingTabsProps } from 'src/common/types/blog';
import * as Yup from 'yup';

export const BlogsAdvancedSettingTab = ({
  collectData,
  setCurrentStep,
  initialData,
}: BlogSettingTabsProps) => {
  const prompts = [
    'Write a compelling article with key insights and takeaways',
    'Summary of the main points with focus on actionable tips and advice',
    'Provides readers with a deep understanding of the topic, breaking down complex concepts into digestible information',
    'Generate a concise and engaging read, for a broad audience',
  ];

  const validationSchema = Yup.object({
    prompt: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      prompt: initialData.prompt,
    },
    validationSchema,
    onSubmit: values => {
      const blog = { ...initialData, prompt: values.prompt };

      collectData(blog);

      setCurrentStep(4);
    },
  });

  return (
    <>
      <form className="text-black" onSubmit={formik.handleSubmit}>
        <h3 className="mb-5 text-lg font-bold max-w-[65%]">
          Choose a predefined prompt from our list of suggested prompts
        </h3>

        <div className="mb-10">
          {prompts.map((prompt, index) => (
            <div
              key={index}
              className="flex items-center gap-2.5 text-black font-[courier] text-[14px] border-t border-[#E8E8E8] last:border-b-0 py-[10px]"
            >
              <input
                type="radio"
                name="prompt"
                value={prompt}
                onChange={formik.handleChange}
                className="w-6 h-6 mr-2 bg-[#E5E5E5] rounded-full text-orange-800 !ring-[transparent]"
              />

              <span>{prompt}</span>
            </div>
          ))}
        </div>

        <div className="flex gap-6 mt-4">
          <button
            type="submit"
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Next
          </button>

          <button
            className="text-black underline text-opacity-70"
            onClick={() => setCurrentStep(2)}
          >
            Back to the previous step
          </button>
        </div>
      </form>
    </>
  );
};
