import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { Circle, CircleUser } from 'src/common/types/circle';
import { Summary } from './Summary';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { Connections } from './Connections';
import { queryParams } from 'src/common/utils';
import { Event, EventUser } from 'src/common/types/event';
import { Notifications } from './Notifications';
import { ConnectionSuggestions } from './ConnectionSuggestions';
import { Blog } from 'src/common/types/blog';

export const Dashboard = () => {
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const [circles, setCircles] = useState<Circle[]>([]);
  const [connectionsCount, setConnectionsCount] = useState<number>();
  const [clubsCount, setClubsCount] = useState<number>();
  const [eventsCount, setEventsCount] = useState<number>();
  const [postsCount, setPostsCount] = useState<number>();
  const notify = useNotification();

  const { sendRequest: getItems, isLoading: isGettingItems, error: errorGettingItems } = useHttp();

  const useDashboard =
    appConfig?.parentId === null || appConfig?.features['Dashboard & Activity feed'];

  useEffect(() => {
    if (accessToken && profile && useDashboard) {
      getCirclesHandler();
      getClubsHandler();
      getEventsHandler();
      getBlogsHandler();
    }

    if (errorGettingItems) {
      notify(errorGettingItems, 'error');
    }
  }, [accessToken, profile]);

  const getCirclesHandler = async () => {
    await getItems(
      {
        url: 'circle',
        method: 'GET',
        accessToken,
      },
      (circles: any) => {
        setCircles(circles);

        const uniqueUserIds = new Set();

        circles
          .filter((circle: Circle) => circle.owner.id === profile?.id)
          .forEach((circle: Circle) => {
            circle.users.forEach((circleUser: CircleUser) => {
              uniqueUserIds.add(circleUser.user.id);
            });
          });

        setConnectionsCount(uniqueUserIds.size);
      },
    );
  };

  const getClubsHandler = async () => {
    const params = {
      filter: {
        currentUserOnly: true,
      },
    };

    await getItems(
      {
        url: 'clubs?' + queryParams(params),
        method: 'GET',
        accessToken,
      },

      (clubs: any) => {
        setClubsCount(clubs.total);
      },
    );
  };

  const getEventsHandler = async () => {
    const currentTime = new Date();
    let eventsCount = 0;

    await getItems(
      {
        url: 'events',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        eventsCount = res.data.reduce((count: number, event: Event) => {
          const eventStartTime = new Date(event.startTime);
          const isHost = event.hosts.some((host: EventUser) => host.user.id === profile?.id);

          if (eventStartTime >= currentTime && (event.user.id === profile?.id || isHost)) {
            return count + 1;
          }

          return count;
        }, 0);

        setEventsCount(eventsCount);
      },
    );
  };

  const getBlogsHandler = async () => {
    await getItems(
      {
        url: 'directus/articles',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const filteredBlogs = res.data.filter((blog: Blog) => {
          return blog.author.id === profile?.directusUserId;
        });

        setPostsCount(filteredBlogs.length);
      },
    );
  };

  return (
    <>
      <div className="flex items-end justify-between mb-14">
        <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Dashboard</h1>

        <div className="text-sm flex items-center gap-1.5">
          <svg
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.5"
              d="M9.49333 1.59642H10.3333C11.8067 1.59642 13 2.68072 13 4.01943V10.077C13 11.4157 11.8067 12.5 10.3333 12.5H3.66667C2.19333 12.5 1 11.4157 1 10.077V4.01943C1 2.68072 2.19333 1.59642 3.66667 1.59642H4.62M2.8 4.50404H11.42M7.08 0.5C6.52667 0.5 6.08 0.905856 6.08 1.40863C6.08 1.91141 6.52667 2.31726 7.08 2.31726C7.63333 2.31726 8.08 1.91141 8.08 1.40863C8.08 0.905856 7.63333 0.5 7.08 0.5ZM4.74667 7.04215C4.74667 7.21176 4.6 7.34503 4.41333 7.34503C4.22667 7.34503 4.08 7.21176 4.08 7.04215C4.08 6.87254 4.22667 6.73927 4.41333 6.73927C4.6 6.73927 4.74667 6.87254 4.74667 7.04215ZM7.41333 7.04215C7.41333 7.21176 7.26667 7.34503 7.08 7.34503C6.89333 7.34503 6.74667 7.21176 6.74667 7.04215C6.74667 6.87254 6.89333 6.73927 7.08 6.73927C7.26667 6.73927 7.41333 6.87254 7.41333 7.04215ZM10.08 7.04215C10.08 7.21176 9.93333 7.34503 9.74667 7.34503C9.56 7.34503 9.41333 7.21176 9.41333 7.04215C9.41333 6.87254 9.56 6.73927 9.74667 6.73927C9.93333 6.73927 10.08 6.87254 10.08 7.04215ZM4.74667 9.46517C4.74667 9.63478 4.6 9.76805 4.41333 9.76805C4.22667 9.76805 4.08 9.63478 4.08 9.46517C4.08 9.29556 4.22667 9.16229 4.41333 9.16229C4.6 9.16229 4.74667 9.29556 4.74667 9.46517ZM7.41333 9.46517C7.41333 9.63478 7.26667 9.76805 7.08 9.76805C6.89333 9.76805 6.74667 9.63478 6.74667 9.46517C6.74667 9.29556 6.89333 9.16229 7.08 9.16229C7.26667 9.16229 7.41333 9.29556 7.41333 9.46517ZM10.08 9.46517C10.08 9.63478 9.93333 9.76805 9.74667 9.76805C9.56 9.76805 9.41333 9.63478 9.41333 9.46517C9.41333 9.29556 9.56 9.16229 9.74667 9.16229C9.93333 9.16229 10.08 9.29556 10.08 9.46517Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="text-black textOpacity-70">Data from the last</span>

          <Select
            value={{ label: '30 days', value: 'days' }}
            classNames={{
              indicatorsContainer: () => '!p-0 !text-black',
              valueContainer: () => '!p-0',
              singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
              control: () =>
                '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
              indicatorSeparator: () => '!w-0',
              menu: () => '!min-w-[110px] !m-0',
              menuList: () => '!p-0',
            }}
          />
        </div>
      </div>

      {useDashboard && (
        <div className="flex justify-between">
          <div className="w-[68%] flex flex-col gap-10">
            <Summary
              connectionsCount={connectionsCount}
              eventsCount={eventsCount}
              clubsCount={clubsCount}
              postsCount={postsCount}
            />

            <Connections circles={circles} />
          </div>

          <div className="w-[30%] pt-5 flex flex-col gap-[30px]">
            {/* <Notifications /> */}

            <ConnectionSuggestions />
          </div>
        </div>
      )}

      {isGettingItems && <LoadingSpinner />}
    </>
  );
};
