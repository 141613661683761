import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';

export function HandleSubAccount() {
  const { loginWithRedirect, accessToken, logout } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const notify = useNotification();
  const subAccountId = searchParams.get('id');
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState<string>();
  const { sendRequest: decryptEmail, error: errorDecryptingEmail } = useHttp();
  const { sendRequest: checkInvite, error: errorCheckInvite } = useHttp();

  useEffect(() => {
    if (errorCheckInvite) {
      notify(errorCheckInvite, 'error');
    }

    if (errorDecryptingEmail) {
      notify(errorDecryptingEmail, 'error');
    }
  }, [errorCheckInvite, errorDecryptingEmail]);

  const handleCheckSubAccount = (email: string) => {
    checkInvite(
      {
        url: `user/check-sub-account/${email}`,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        if (data) setIsChecked(true);
      },
    );
  };

  useEffect(() => {
    if (!subAccountId) return;

    decryptEmail(
      {
        url: `invites/decrypt/${subAccountId}`,
        method: 'GET',
        accessToken,
      },
      email => {
        if (email) {
          setEmail(email as string);
          handleCheckSubAccount(email as string);
        }
      },
    );
  }, [subAccountId]);

  useEffect(() => {
    if (isChecked && email) {
      logout();
      loginWithRedirect({
        appState: { returnTo: '/complete-sub-account-signup' },
        screen_hint: 'signup',
        login_hint: email,
      });
    }
  }, [isChecked]);

  return <LoadingSpinner />;
}
