import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { Option } from 'src/common/types/event';
import { Country } from 'src/common/types/country';
import { ToastContainer } from 'react-toastify';

const companySectorOptions = [
  { value: 'it', label: 'Information Technology' },
  { value: 'health', label: 'Healthcare' },
];

export const AboutCompanyTab = () => {
  const { accessToken, profile, fetchProfile } = useContext(AuthContext);
  const notify = useNotification();

  const [countries, setCountries] = useState<Country[]>([]);

  const { sendRequest: updateCompany, isLoading: isUpdatingCompany } = useHttp();
  const { sendRequest: createCompany, isLoading: isCreatingCompany } = useHttp();
  const { sendRequest: getCountries, isLoading: isGettingCountries } = useHttp();

  const formik = useFormik({
    initialValues: {
      name: '',
      sector: [] as Option[],
      website: '',
      role: '',
      countryId: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().trim().required().max(50),
      sector: Yup.array().min(1).required(),
      website: Yup.string().trim().required().url(),
      role: Yup.string().trim().required().max(50),
      countryId: Yup.string().trim().required(),
    }),
    onSubmit: values => {
      if (profile?.company) {
        updateCompany(
          {
            url: `company/${profile?.company?.id}`,
            method: 'PATCH',
            accessToken,
            payload: values,
          },
          handleAboutMeResponse,
        );
      } else {
        createCompany(
          {
            url: 'company',
            method: 'POST',
            accessToken,
            payload: {
              ...values,
              userId: profile?.id,
            },
          },
          handleAboutMeResponse,
        );
      }
    },
  });

  const handleAboutMeResponse = () => {
    fetchProfile();

    notify('Company updated', 'success');
  };

  const { setValues } = formik;

  useEffect(() => {
    if (!profile) return;

    getCountryHandler();

    const { company } = profile;

    setValues({
      name: company?.name || '',
      sector: company?.sector || [],
      website: company?.website || '',
      role: company?.role || '',
      countryId: company?.country?.id || '',
    });
  }, [profile]);

  const getCountryHandler = async () => {
    await getCountries(
      {
        url: 'countries',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setCountries(res);
      },
    );
  };
  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="bg-white border border-[#E5E5E5] rounded-2xl p-16 shadow-lg">
        <h1 className="font-[sohne-breit] text-black text-2xl mb-3.5">About me</h1>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-7">
            <label className="space-y-2">
              <div className="font-bold text-black">Company name</div>

              <input
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                placeholder="Enter your company name..."
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.name && formik.errors.name
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">Company Sector</div>
              <Select
                name="sector"
                value={formik.values.sector}
                onChange={selectedOptions => {
                  // Formik's handleChange expects an event object with a 'target' property,
                  // so we need to manually create a fake event object for Formik.
                  const fakeEvent = {
                    target: {
                      name: 'sector',
                      value: selectedOptions,
                    },
                  };
                  formik.handleChange(fakeEvent);
                }}
                options={companySectorOptions}
                isMulti
                classNames={{
                  control: () =>
                    `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                      formik.errors.sector && formik.touched.sector
                        ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                        : ''
                    }`,
                  indicatorSeparator: () => '!my-0',
                }}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">Company website</div>

              <input
                type="url"
                name="website"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website}
                pattern="https?://.*"
                title="Please enter a valid URL starting with http:// or https://"
                placeholder="Enter your company website..."
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.website && formik.errors.website
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">My role in the company</div>

              <input
                type="text"
                name="role"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                placeholder="Enter your role..."
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.role && formik.errors.role
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              />
            </label>

            <label className="space-y-2">
              <div className="font-bold text-black">Company Country</div>

              <select
                name="countryId"
                value={formik.values.countryId}
                disabled={isGettingCountries}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300 ${
                  formik.touched.countryId && formik.errors.countryId
                    ? '!border-b-[#F72300] bg-[#FFF2F2]'
                    : '!border-b-[#005AFF]'
                }`}
              >
                <option value="" hidden>
                  Select your country...
                </option>

                {countries.map(country => (
                  <option value={country.id} key={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </label>

            <button
              disabled={isUpdatingCompany || isCreatingCompany}
              className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
