import { EventCard } from '../events/EventCard';
import { ClubEvent } from 'src/common/types/clubs';

interface ClubCardProps {
  clubEvents: ClubEvent[];
}

export default function ClubEventTab({ clubEvents }: ClubCardProps) {
  return (
    <div className="pt-[40px]">
      <div className="flex flex-wrap gap-2.5">
        {clubEvents.map((clubEvent, index) => (
          <EventCard event={clubEvent.event} key={index} />
        ))}
      </div>
    </div>
  );
}
