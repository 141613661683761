import { useContext, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { BlogCard } from './BlogCard';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { Blog } from 'src/common/types/blog';
import Select from 'react-select';
import { Link } from 'react-router-dom';

export default function Blogs() {
  const { accessToken, appConfig } = useContext(AuthContext);
  const { sendRequest: getBlogs, isLoading: isGettingBlogs, error: errorGettingBlogs } = useHttp();
  const [blogs, setBlogs] = useState<Blog[]>([]);
  const notify = useNotification();

  useEffect(() => {
    if (accessToken) {
      getBlogsHandler();
    }

    if (errorGettingBlogs) {
      notify(errorGettingBlogs, 'error');
    }
  }, [accessToken]);

  const getBlogsHandler = async () => {
    await getBlogs(
      {
        url: 'directus/articles',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setBlogs(res.data);
      },
    );
  };

  const createAndEdit =
    appConfig?.parentId === null ||
    appConfig?.features['Articles'].subFeatures.includes('Create and edit articles');

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
            Library
          </span>

          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Blog articles</h1>
        </div>

        {createAndEdit && (
          <Link
            to={'/blogs/create'}
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Create
          </Link>
        )}
      </div>

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Status</span>

            <Select
              value={{ label: 'All', value: 'date' }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>

          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Source</span>

            <Select
              value={{ label: 'All', value: 'date' }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>

            <Select
              value={{ label: 'Added Date', value: 'date' }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-2.5 flex-wrap">
        {!blogs.length && <div className={`text-center block p-8`}>No records found</div>}

        {blogs.map(blog => (
          <BlogCard blog={blog} key={blog.id} />
        ))}

        {isGettingBlogs && <LoadingSpinner />}
      </div>
    </>
  );
}
