import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { User } from 'src/common/types/user';
import { useHttp, useNotification } from 'src/common/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { MemberClubsTab } from 'src/components/members/MemberClubsTab';
import { EventsTab } from 'src/components/members/MemberEventsTab';
import { ActivityTab } from 'src/components/members/MemberActivityTab';
import { BlogsTab } from 'src/components/members/MemberBlogsTab';
import { AdminGeneralTab } from './AdminMemberGeneralTab';
import { ApproveMemberPopup } from './ApproveMemberPopup';
import { RejectMemberPopup } from './RejectMemberPopup';
import { ToastContainer } from 'react-toastify';
import { DeleteUserPopup } from 'src/components/popups/DeleteUserPopup';

export const AdminMemberDetail = () => {
  const { id } = useParams();
  const [member, setMember] = useState<User>();

  const [eventsCount, setEventsCount] = useState<number>(0);
  const [clubsCount, setClubsCount] = useState<number>(0);
  const [activityCount, setActivityCount] = useState<number>(0);
  const [blogCount, setBlogCount] = useState<number>(0);

  const [activeTab, setActiveTab] = useState('general');
  const { accessToken, logout } = useContext(AuthContext);

  const [showApprovePopup, setShowApprovePopup] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);

  const notify = useNotification();
  const navigate = useNavigate();

  const { sendRequest: getMember, isLoading: isGettingMember } = useHttp();
  const { sendRequest: updateApprove, error: errorUpdatingApprove } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getMemberHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    if (errorUpdatingApprove) {
      notify(errorUpdatingApprove, 'error');
    }
  }, [errorUpdatingApprove]);

  const getMemberHandler = async () => {
    await getMember(
      {
        url: 'user/' + id,
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMember(data);
      },
    );
  };

  const updateApproveHandler = async (isApprove: boolean) => {
    if (member) {
      await updateApprove(
        {
          url: `user/approve/${member.id}`,
          method: 'PATCH',
          accessToken,
          payload: { isApprove },
        },
        (data: any) => {
          if (isApprove) {
            getMemberHandler();

            closeApprovePopup();
            notify('Member approved successfully', 'success');
          } else {
            closeRejectPopup();
            notify('Membership rejected successfully, Redirecting...', 'success');

            setTimeout(() => {
              navigate('admin/members');
            }, 3000);
          }
        },
      );
    }
  };

  const closeApprovePopup = () => {
    setShowApprovePopup(false);
  };

  const closeRejectPopup = () => {
    setShowRejectPopup(false);
  };

  const tabs = [
    {
      key: 'general',
      label: 'General',
    },
    {
      key: 'clubs',
      label: 'Clubs',
    },
    {
      key: 'events',
      label: 'Events',
    },
    {
      key: 'activity',
      label: 'Activity Feed',
    },
    {
      key: 'blog',
      label: 'Blog',
    },
  ];

  const TabContent = ({ activeTab, member }: { activeTab: string; member: User }) => {
    switch (activeTab) {
      case 'general':
        return <AdminGeneralTab member={member} />;
      case 'clubs':
        return <MemberClubsTab member={member} setCount={setClubsCount} />;
      case 'events':
        return <EventsTab member={member} setCount={setEventsCount} />;
      case 'activity':
        return <ActivityTab member={member} setCount={setActivityCount} />;
      case 'blog':
        return <BlogsTab member={member} setCount={setBlogCount} />;
      default:
        return null;
    }
  };

  return (
    <>
      <ToastContainer autoClose={2000} />

      {member && (
        <div className="max-w-[1300px] w-4/5 mx-auto relative">
          {showApprovePopup && (
            <ApproveMemberPopup
              onClose={closeApprovePopup}
              member={member}
              updateApproveHandler={updateApproveHandler}
            />
          )}

          {showRejectPopup && (
            <RejectMemberPopup
              onClose={closeRejectPopup}
              member={member}
              updateApproveHandler={updateApproveHandler}
            />
          )}

          <div className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight">
            MEMBERS
          </div>

          <div className="flex items-start justify-between mt-3 mb-10">
            <div className="flex flex-col gap-3">
              <div>
                <div className="flex items-start gap-4">
                  <h1 className="text-4xl font-semibold leading-none font-[sohne-breit] tracking-wide relative">
                    <button
                      className="h-[30px] w-[30px] bg-[#E5E5E5] rounded-lg flex items-center justify-center absolute top-1 -left-[45px] my-auto"
                      onClick={() => navigate(-1)}
                    >
                      <svg
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.7"
                          d="M14 5.99992H2M2 5.99992L6.64516 10.6666M2 5.99992L6.64516 1.33325"
                          stroke="black"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>

                    <span> {member.name ?? 'Michelle Young'}</span>
                  </h1>

                  <span className="font-[sohne-breit] text-white text-[11px] py-0.5 px-1.5 rounded-sm bg-[#CD9400] relative top-3">
                    Gold
                  </span>
                </div>

                <div className="font-extralight text-lg mt-1.5 text-black text-opacity-70">
                  {member?.company?.role}
                </div>
              </div>

              <div className="flex gap-1">
                {member?.skills?.map((skill, index) => (
                  <div
                    className="border py-1.5 px-2.5 text-xs rounded-lg leading-none text-[#234685] border-[#325EAE33] border-opacity-20 bg-white"
                    key={index}
                  >
                    {skill.name}
                  </div>
                ))}
              </div>
            </div>

            {!member.isApproved && (
              <div className="flex items-center gap-2.5">
                <button
                  className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#5AA0001A] text-[#213A00]"
                  onClick={() => setShowApprovePopup(true)}
                >
                  Accept
                </button>

                <button
                  className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#C51C001A] text-[#600E00]"
                  onClick={() => setShowRejectPopup(true)}
                >
                  Reject
                </button>
              </div>
            )}

            {member.isApproved && (
              <div className="flex items-center gap-2.5">
                <button
                  className="px-2.5 py-2 text-[13px] font-semibold rounded-md bg-[#C51C001A] text-[#600E00]"
                  onClick={() => setShowDeleteAccountPopup(true)}
                >
                  Delete account
                </button>

                {showDeleteAccountPopup && (
                  <DeleteUserPopup
                    userId={member?.id}
                    onClose={() => setShowDeleteAccountPopup(false)}
                    onDelete={() => navigate(-1)}
                  />
                )}
              </div>
            )}
          </div>

          <Tabs value={activeTab}>
            <TabsHeader
              placeholder="Empty"
              className="items-center gap-10 p-0 bg-transparent rounded-none"
              indicatorProps={{
                className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
              }}
            >
              {tabs.map(({ key, label }) => (
                <Tab
                  placeholder="Empty"
                  key={key}
                  value={key}
                  disabled={key !== 'general' && !member.isApproved}
                  onClick={() => setActiveTab(key)}
                  className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black ${
                    activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                  }`}
                >
                  <div className="flex items-center gap-1">
                    <span>{label}</span>

                    {member.isApproved && key !== 'general' && (
                      <span className="text-[10px] text-black text-opacity-100 bg-[#E5E5E5] font-medium w-4 h-4 inline-flex justify-center items-center rounded-md">
                        {key === 'activity' && activityCount}
                        {key === 'events' && eventsCount}
                        {key === 'clubs' && clubsCount}
                        {key === 'blog' && blogCount}
                      </span>
                    )}
                  </div>
                </Tab>
              ))}
            </TabsHeader>

            <TabsBody placeholder="Empty">
              {tabs.map(({ key }) => (
                <TabPanel key={key} value={key} className="p-0">
                  <TabContent activeTab={key} member={member} />
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs>
        </div>
      )}

      {isGettingMember && <LoadingSpinner />}
    </>
  );
};
