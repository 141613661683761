import { User } from 'src/common/types/user';
import { formatDate } from 'src/common/utils';

interface GeneralTabProps {
  member: User;
}
export const AdminGeneralTab = ({ member }: GeneralTabProps) => {
  return (
    <>
      <div className="bg-white border border-[#E5E5E5] rounded-md text-black shadow flex justify-between">
        <div className="max-w-[600px] w-[50%] p-16">
          <div className="border-b border-[#E5E5E5] pb-10">
            <h2 className="font-[sohne-breit] text-2xl mb-3.5 font-bold">General details</h2>
            <p className="leading-snug text-opacity-70">{member.bio}</p>
          </div>
          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Email</div>
            <div className="text-lg font-bold">{member.email}</div>
          </div>

          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Location</div>
            <div className="text-lg font-bold">{member?.country?.name}</div>
          </div>

          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Company</div>
            <div className="text-lg font-bold">{member.company?.name}</div>
          </div>
        </div>

        <div className="bg-[#E5E5E533]  border-l border-[#E8E8E8] max-w-[400px] w-[40%] flex flex-col pt-[65px]">
          <div className="w-[60%] mx-auto">
            <h2 className="font-[sohne-breit] text-2xl font-bold pb-5 border-b border-[#E5E5E5]">
              Invitation details
            </h2>

            <div className="py-6 border-b border-[#E5E5E5] leading-none">
              <div>Invited by</div>
              <div className="text-lg font-bold">{member.parent?.name ?? 'Michael Johnson'}</div>
            </div>

            <div className="py-6 border-b border-[#E5E5E5] leading-none">
              <div>Invitation sent on</div>
              <div className="text-lg font-bold">
                {member.invitation ? formatDate(member.invitation?.createdAt) : 'N/A'}
              </div>
            </div>

            <div className="py-6 border-b border-[#E5E5E5] leading-none">
              <div>Invitation accepted on</div>

              <div className="text-lg font-bold">{formatDate(member.createdAt)}</div>
            </div>

            <div className="py-6 border-b border-[#E5E5E5] leading-none">
              <div>Membership accepted on</div>

              <div className="text-lg font-bold">
                {member.approvedAt ? formatDate(member.approvedAt) : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
