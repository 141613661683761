import { useContext, useEffect, useState } from 'react';
import Select from 'react-select';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Option } from 'src/common/types/event';
import { useHttp } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { Invite } from 'src/common/types/invite';
import { MembersFilter } from 'src/common/types/filters';

interface MemberFilterProps {
  show: boolean;
  collectData: (filters: MembersFilter) => void;
  onClose: () => void;
}

const topicsOptions = [
  { value: 'health', label: 'Health' },
  { value: 'global-warming', label: 'Global warming' },
];

const levels = [
  { id: 'bronze', label: 'Bronze' },
  { id: 'silver', label: 'Silver' },
  { id: 'gold', label: 'Gold' },
  { id: 'platinum', label: 'Platinum' },
  { id: 'diamond', label: 'Diamond' },
];

export const MemberFilter = ({ show, onClose, collectData }: MemberFilterProps) => {
  const { accessToken } = useContext(AuthContext);
  const [invitee, setInvitee] = useState<Option[]>([]);
  const [activeLevel, setActiveLevel] = useState('gold');
  const [range, setRange] = useState<number[]>([0, 20]);
  const { sendRequest: getInvitee } = useHttp();
  const [isFiltersActive, setIsFiltersActive] = useState(false);

  const [filters, setFilters] = useState<MembersFilter>({
    parent: null,
    createdAt: null,
  });

  useEffect(() => {
    if (accessToken) {
      getInviteeHandler();
    }
  }, [accessToken]);

  useEffect(() => {
    const isFilterActive = !!filters.parent || !!filters.createdAt;
    setIsFiltersActive(isFilterActive);
  }, [filters]);

  const getInviteeHandler = async () => {
    getInvitee(
      {
        url: 'invites',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const uniqueInviteeValues = new Set();
        const invitee = res?.data
          .map((invite: Invite) => {
            const option = {
              label: invite.user.name,
              value: invite.user.id,
            };

            if (!uniqueInviteeValues.has(option.value)) {
              uniqueInviteeValues.add(option.value);
              return option;
            }
            return null;
          })
          .filter((option: null) => option !== null);

        setInvitee(invitee);
      },
    );
  };

  const handleLevelClick = (levelId: string) => {
    setActiveLevel(levelId);
  };

  const handleRangeChange = (newRange: number | number[]) => {
    if (typeof newRange === 'object') setRange(newRange);
  };

  const handleDateChange = (date: Date) => {
    setFilters({
      ...filters,
      createdAt: date,
    });
  };

  const handleClearFilters = () => {
    setFilters({
      parent: null,
      createdAt: null,
    });
  };

  return (
    <div
      className={`fixed inset-y-0 bg-[#F5F5F5] border min-w-[300px] max-w-[300px] z-10 pt-[54px] pb-[30px] px-[34px] transition-all duration-300 flex flex-col justify-between overflow-y-scroll custom-scrollbar ${
        show ? 'right-0' : '-right-full'
      }`}
    >
      <button className="absolute right-2.5 top-2.5" onClick={onClose}>
        <svg
          width="43"
          height="42"
          viewBox="0 0 43 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
            fill="#E5E5E5"
          />
          <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </button>

      <div>
        <h3 className="text-[26px] font-bold text-black font-[sohne-breit] leading-none mb-[30px]">
          Filter <br /> results
        </h3>

        <div
          className={`bg-[#FFEADE] border border-[#EDC7B2] p-3 rounded-[8px] leading-tight ${
            isFiltersActive ? 'block' : 'hidden'
          }`}
        >
          <h6>Results are filtered.</h6>

          <button className="font-extrabold text-[#983600] underline" onClick={handleClearFilters}>
            Clear filters
          </button>
        </div>

        <div className="flex flex-col gap-5">
          <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Domains</div>

            <Select
              options={topicsOptions}
              placeholder="Choose domain(s)"
              isMulti
              classNames={{
                control: () =>
                  `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none !rounded-[8px]`,
                indicatorSeparator: () => '!my-0 !bg-[#E5E5E5]',
              }}
            />
          </label>

          <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Position</div>

            <Select
              options={topicsOptions}
              placeholder="Choose position(s)"
              isMulti
              classNames={{
                control: () =>
                  `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none !rounded-[8px]`,
                indicatorSeparator: () => '!my-0 !bg-[#E5E5E5]',
              }}
            />
          </label>

          <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Level</div>

            <div className="flex gap-[5px] flex-wrap">
              {levels.map(level => (
                <button
                  key={level.id}
                  className={`${
                    activeLevel === level.id
                      ? 'bg-[#F3580008] text-[#983600] border border-[#F35800]'
                      : 'bg-[#E5E5E566] text-[#00000080] border border-[#E5E5E5]'
                  } text-sm leading-[0.9] rounded-[8px] px-[14px] py-[15px] font-bold`}
                  onClick={() => handleLevelClick(level.id)}
                >
                  {level.label}
                </button>
              ))}
            </div>
          </label>

          <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Invitee</div>

            <Select
              value={filters.parent}
              options={invitee}
              onChange={selectedOptions => {
                setFilters({
                  ...filters,
                  parent: selectedOptions,
                });
              }}
              placeholder="Search for an invitee"
              classNames={{
                control: () =>
                  `!min-h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none !rounded-[8px]`,
                indicatorSeparator: () => '!my-0 !bg-[#E5E5E5]',
              }}
            />
          </label>

          {/* <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Number of invited members</div>

            <div className="flex items-center justify-center">
              <Slider
                range
                min={0}
                max={100}
                step={1}
                styles={{
                  track: {
                    background: '#F35800',
                    height: '7px',
                  },
                  rail: {
                    background: '#ECEEF2',
                    height: '7px',
                  },
                }}
                defaultValue={range}
                onChange={handleRangeChange}
                allowCross={false}
                dots={false}
              />
            </div>

            <div className="flex justify-between mt-4">
              <span className="text-sm">{range[0]}</span>
              <span className="text-sm">{range[1]}</span>
            </div>
          </label> */}

          <label className="space-y-[14px] pt-[20px]">
            <div className="text-lg">Registration date</div>

            <DatePicker
              selected={filters.createdAt}
              onChange={handleDateChange}
              dateFormat="yyyy/MM/dd"
              placeholderText="Choose a date"
              className={`w-full rounded-md !min-h-[60px] border-gray-300 focus:border-gray-300 !border-b-[#005AFF]`}
            />
          </label>
        </div>
      </div>

      <button
        onClick={() => collectData(filters)}
        className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity mt-4 font-[sohne-breit]"
        type="submit"
      >
        See results
      </button>
    </div>
  );
};
