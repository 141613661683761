import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { useState } from 'react';
import { CreateBlogProps } from 'src/common/types/blog';
import { BlogUploadMediaTab } from './BlogUploadMediaTab';
import { BlogSelectMediaTab } from './BlogSelectMediaTab';

export const BlogMediaTab = ({
  setCurrentStep,
  initialData,
  collectData,
  onCancel,
  disabled,
  setDisabled,
}: CreateBlogProps) => {
  const [activeTab, setActiveTab] = useState('upload-media');

  const tabs = [
    {
      key: 'upload-media',
      label: 'Upload media file',
    },
    {
      key: 'select-media',
      label: 'Choose library file',
    },
  ];

  return (
    <div>
      <div>
        <h6 className="text-xs text-black text-opacity-60 font-[sohne-breit] leading-none mb-1.5 uppercase tracking-wider">
          Step 1
        </h6>

        <h2 className="text-black text-[34px] font-[sohne-breit] font-bold leading-none max-w-[70%] mb-8">
          {disabled
            ? 'Uploading file and generating transcript...'
            : 'Upload media file or transcript'}
        </h2>

        <Tabs value={activeTab}>
          <TabsHeader
            placeholder="Empty"
            className={`items-center justify-between p-0 mb-[30px] bg-transparent rounded-none border-b border-[#E8E8E8] ${
              disabled ? 'hidden' : ''
            }`}
            indicatorProps={{
              className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
            }}
          >
            <div className="flex justify-between gap-10">
              {tabs.map(({ key, label }) => (
                <Tab
                  placeholder="Empty"
                  key={key}
                  disabled={disabled}
                  value={key}
                  onClick={() => setActiveTab(key)}
                  className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black z-0 ${
                    activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                  }`}
                >
                  {label}
                </Tab>
              ))}
            </div>
          </TabsHeader>

          <TabsBody placeholder="Empty">
            {tabs.map(({ key }) => (
              <TabPanel key={key} value={key} className="p-0">
                {key === 'upload-media' && (
                  <BlogUploadMediaTab
                    setCurrentStep={setCurrentStep}
                    initialData={initialData}
                    setDisabled={setDisabled}
                    collectData={collectData}
                    onCancel={onCancel}
                  />
                )}

                {key === 'select-media' && (
                  <BlogSelectMediaTab
                    setCurrentStep={setCurrentStep}
                    initialData={initialData}
                    setDisabled={setDisabled}
                    collectData={collectData}
                    onCancel={onCancel}
                  />
                )}
              </TabPanel>
            ))}
          </TabsBody>
        </Tabs>
      </div>
    </div>
  );
};
