import moment from 'moment-timezone';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHttp, useNotification } from 'src/common/hooks';
import { CreateEventProps } from 'src/common/types/event';
import { Room } from 'src/common/types/room';
import { AuthContext } from 'src/contexts';

export const EventRoomTab = ({ initialData }: CreateEventProps) => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
  const { accessToken, profile } = useContext(AuthContext);
  const notify = useNotification();
  const navigate = useNavigate();

  const { sendRequest: getRooms, isLoading: isGettingRoom, error: errorGettingRooms } = useHttp();

  const {
    sendRequest: createEvent,
    isLoading: creatingEvent,
    error: errorCreatingEvent,
  } = useHttp();

  useEffect(() => {
    getRoomsHandler();

    if (errorCreatingEvent) {
      notify(errorCreatingEvent, 'error');
    }

    if (errorGettingRooms) {
      notify(errorGettingRooms, 'error');
    }
  }, [errorCreatingEvent, errorGettingRooms]);

  const getRoomsHandler = async () => {
    await getRooms(
      {
        url: 'events/rooms',
        method: 'GET',
      },
      (res: any) => {
        res = res.filter((room: Room) => room.maxSeats >= initialData.meeting.hosts.length);

        setRooms(res);
      },
    );
  };

  const createEventHandler = async () => {
    if (!selectedRoom) return;

    if (selectedRoom.maxSeats < initialData.meeting.hosts.length + 1) {
      notify('The selected room does not have enough seats for all hosts.', 'success');
    }

    const userTimezone = moment.tz.guess();

    const userDate = moment.tz(initialData.meeting.date, userTimezone);

    const userStartTime = userDate
      .clone()
      .set('hour', parseInt(initialData.meeting.startTime.split(':')[0]))
      .set('minute', parseInt(initialData.meeting.startTime.split(':')[1]));
    const userEndTime = userDate
      .clone()
      .set('hour', parseInt(initialData.meeting.endTime.split(':')[0]))
      .set('minute', parseInt(initialData.meeting.endTime.split(':')[1]));

    const gmtStartTime = userStartTime.clone().utc();
    const gmtEndTime = userEndTime.clone().utc();

    const gmtStartTimeISO8601 = gmtStartTime.toISOString();
    const gmtEndTimeISO8601 = gmtEndTime.toISOString();

    const payload = {
      userId: profile?.id,
      ...initialData.info,
      topics: initialData.info.topics.map(topic => topic.value),
      ...initialData.meeting,
      hosts: initialData.meeting.hosts.map(host => host.value),
      room: selectedRoom,
      startTime: gmtStartTimeISO8601,
      endTime: gmtEndTimeISO8601,
    };

    createEvent(
      {
        url: 'events',
        method: 'POST',
        accessToken,
        payload,
      },
      handleCreateResponse,
    );
  };

  const handleCreateResponse = () => {
    notify('Your event was saved and published.', 'success');

    setTimeout(() => {
      navigate('/events');
    }, 1000);
  };

  const renderRooms = () => {
    return rooms.map((room, index) => (
      <div
        key={index}
        className={`p-2.5 pb-5 max-w-[190px] shadow-lg border border-[#E5E5E5] rounded-lg ${
          selectedRoom === room ? 'bg-[#F3580005] border-[#F35800]' : ''
        }`}
      >
        <div className="relative">
          <img src="/assets/placeholder-1.jpeg" alt="Placeholder" className="mb-5 rounded-lg" />

          <div
            className={`rounded-full text-white h-[34px] w-[34px] bg-[#F35800] flex items-center justify-center absolute -bottom-4 left-2 transition-opacity ${
              selectedRoom === room ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <svg
              width="10"
              height="8"
              viewBox="0 0 10 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 3.65789L4.08333 6.5L9 1.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>

        <div className="pl-1.5">
          <h3 className="mb-1 text-lg font-extrabold leading-tight text-black">{room.roomName}</h3>

          <p className="mb-4 text-xs text-black text-opacity-70">Fits {room.maxSeats} people</p>

          <button
            className={`text-[10px] border border-black py-[13px] px-[17px] rounded-lg font-[sohne-breit] font-medium leading-none ${
              selectedRoom === room ? 'text-[#F35800] !border-[#F35800]' : ''
            }`}
            onClick={() => setSelectedRoom(prevRoom => (prevRoom === room ? null : room))}
          >
            {selectedRoom === room ? 'Room is selected' : 'Select this room'}
          </button>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className="max-w-[700px] w-full mx-auto mt-7">
        <div className="bg-white border border-[#E5E5E5] rounded-2xl p-14 text-black shadow">
          <h1 className="font-[sohne-breit] text-2xl mb-3.5">Event Room</h1>

          <p className="mb-10 leading-snug text-black text-opacity-70">
            The power of reality and how people come together, collaborate, and communicate. It
            represents the third evolution of the internet.
          </p>

          {rooms.length > 0 && (
            <div className="flex flex-wrap gap-1.5 items-center transition-all">
              {renderRooms()}
            </div>
          )}

          {isGettingRoom && !rooms.length && (
            <div role="status" className="mx-auto w-fit">
              <svg
                aria-hidden="true"
                className="inline w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>

              <span className="sr-only">Loading...</span>
            </div>
          )}

          {!rooms.length && !isGettingRoom && (
            <div className={`text-center block p-8`}>No records found</div>
          )}

          <button
            className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit] w-full mt-10"
            onClick={createEventHandler}
            disabled={creatingEvent}
          >
            Save Event & Publish
          </button>
        </div>
      </div>
    </>
  );
};
