import { Outlet } from 'react-router-dom';
import { SignupLayout } from '../layouts/SignupLayout';

export function SignupPage() {
  return (
    <>
      <div className="hidden md:block h-full">
        <SignupLayout>
          <Outlet />
        </SignupLayout>
      </div>
      <div className="md:hidden h-full px-4 flex flex-col items-center justify-center">
        <p className="font-linear-sans mt-5 text-[22px] text-black font-semibold">
          Sorry, for a better experience, we kindly ask you to use a desktop or laptop device.
        </p>
      </div>
    </>
  );
}
