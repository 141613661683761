import Select from 'react-select';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { Event } from 'src/common/types/event';
import { formatDateTime } from 'src/common/utils';
import { BlogMediaTabsProps, BlogTabData } from 'src/common/types/blog';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const BlogUploadMediaTab = ({
  collectData,
  onCancel,
  setDisabled,
  initialData,
  setCurrentStep,
}: BlogMediaTabsProps) => {
  const { accessToken, profile } = useContext(AuthContext);
  const [selectedFile, setSelectedFile] = useState<File | null>(initialData.media.file);

  const [percentCompleted, setPercentCompleted] = useState(0);
  const [events, setEvents] = useState<Event[]>([]);
  const notify = useNotification();

  const {
    sendRequest: createMedia,
    isLoading: creatingMedia,
    error: errorCreatingMedia,
  } = useHttp();

  const { sendRequest: getEvents, isLoading: isGettingEvents } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getEventsHandler();
    }

    if (errorCreatingMedia) {
      notify(errorCreatingMedia, 'error');
    }
  }, [accessToken, errorCreatingMedia]);

  const formik = useFormik({
    initialValues: initialData.media,
    validationSchema: Yup.object({
      recordingOf: Yup.string().required(),
      event: Yup.mixed().when(['recordingOf'], {
        is: (recordingOf: string) => recordingOf === 'event',
        then: Yup.object().required(),
        otherwise: Yup.mixed(),
      }),
      description: Yup.string().when('recordingOf', {
        is: 'none',
        then: Yup.string().required(),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: async values => {
      if (!selectedFile) {
        return;
      }

      const { event, description, recordingOf } = values;

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('userId', profile?.parentId ?? profile?.id);

      if (recordingOf === 'event' && event) {
        formData.append('eventId', event?.value);
      }

      if (recordingOf === 'none' && description) {
        formData.append('description', description);
      }

      setDisabled(true);

      await createMedia(
        {
          url: 'media',
          method: 'POST',
          accessToken,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setPercentCompleted(percentCompleted);
          },
          payload: formData,
        },
        (transcript: unknown) => {
          setDisabled(false);

          const blog = {
            ...initialData,
            media: {
              file: selectedFile,
              recordingOf,
              event,
              description,
            },
            transcript: transcript,
          } as BlogTabData;

          collectData(blog);
          setCurrentStep(2);
        },
      );
    },
  });

  const getEventsHandler = async () => {
    const currentTime = new Date();

    await getEvents(
      {
        url: 'events',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedEvents = res.data.reduce((filteredEvents: Event[], event: Event) => {
          const eventStartTime = new Date(event.startTime);

          if (eventStartTime >= currentTime && event.visibility === 'public') {
            filteredEvents.push(event);
          }

          return filteredEvents;
        }, []);

        setEvents(transformedEvents);
      },
    );
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (file.type === 'video/mp4' || file.type === 'audio/mpeg') {
        setSelectedFile(file);
      } else {
        event.target.value = '';
      }
    }
  };

  const handleMediaTypeChange = (recordingOf: string) => {
    formik.setFieldValue('recordingOf', recordingOf);
  };

  const formatBytes = (sizeInBytes: number) => {
    const units = ['B', 'KB', 'MB', 'GB', 'TB'];
    let size = sizeInBytes;
    let unitIndex = 0;

    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
  };

  const eventsOptions = events?.map(event => ({
    value: event.id,
    label: event.title,
  }));

  const event = events?.find(event => event.id === formik.values?.event?.value);

  return (
    <>
      {!creatingMedia ? (
        <form className="grid gap-y-[30px]" onSubmit={formik.handleSubmit}>
          <div className="bg-[#E5E5E533] border border-[#00000033] border-dotted p-5 rounded-lg">
            <h5 className="font-bold text-black mb-1.5">Upload media file</h5>

            <h6 className="text-black text-opacity-70 text-sm leading-none mb-1.5">
              mp4 and mp3 are accepted
            </h6>

            <label className="flex items-center text-sm font-bold cursor-pointer">
              <input
                type="file"
                className="hidden sr-only"
                onChange={handleFileChange}
                accept=".mp3,.mp4"
              />

              <span
                className={`text-black text-opacity-50 mr-1.5 max-w-[200px] truncate block ${
                  selectedFile
                    ? 'bg-[#F358000D] !text-[#F35800] p-[5px] border border-[#F358004D] rounded-lg'
                    : ''
                }`}
              >
                {selectedFile ? selectedFile.name : 'No file selected'}
              </span>

              <span className={`underline ${selectedFile ? 'text-[#000000B2]' : 'text-[#005AFF]'}`}>
                {selectedFile ? 'Browse for another file' : 'Browse for a media file'}
              </span>
            </label>
          </div>

          <label className="space-y-2">
            <div className="font-bold">This is a recording of an</div>

            <div className="flex">
              <div
                className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-l-lg ${
                  formik.values.recordingOf === 'event'
                    ? '!text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current !font-bold'
                    : 'text-opacity-50'
                }`}
                onClick={() => handleMediaTypeChange('event')}
              >
                Event
              </div>

              <div
                className={`py-[20px] text-center cursor-pointer leading-none text-black bg-[#E5E5E566] w-full border border-[#E5E5E5] rounded-r-lg ${
                  formik.values.recordingOf === 'none'
                    ? '!text-[#983600] border-[#F35800] bg-[#F3580008] shadow-current !font-bold'
                    : 'text-opacity-50'
                }`}
                onClick={() => handleMediaTypeChange('none')}
              >
                None
              </div>
            </div>
          </label>

          {formik.values.recordingOf === 'event' ? (
            <label className="space-y-2">
              <div className="font-bold leading-none">Event</div>

              <Select
                name="event"
                value={formik.values.event}
                options={eventsOptions}
                isDisabled={isGettingEvents}
                onChange={selectedOption => {
                  const fakeEvent = {
                    target: {
                      name: 'event',
                      value: selectedOption,
                    },
                  };

                  formik.handleChange(fakeEvent);
                }}
                classNames={{
                  control: () =>
                    `h-[60px] !border-gray-300 focus:border-gray-300 !border-b-[#005AFF] !shadow-none ${
                      formik.errors.event && formik.touched.event
                        ? '!border-b-[#F72300] !bg-[#FFF2F2]'
                        : ''
                    }`,
                  indicatorSeparator: () => '!my-0',
                }}
              />
            </label>
          ) : (
            <div>
              <label className="space-y-2">
                <div className="font-bold leading-none">Description</div>

                <textarea
                  name="description"
                  placeholder="Enter a short description for the club"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`w-full rounded-md min-h-[120px] border-gray-300 focus:border-gray-300 ${
                    formik.touched.description && formik.errors.description
                      ? '!border-b-[#F72300] bg-[#FFF2F2]'
                      : '!border-b-[#005AFF]'
                  }`}
                ></textarea>
              </label>
            </div>
          )}

          <div className="flex gap-6">
            <button
              type="submit"
              className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            >
              Next
            </button>

            <button className="text-black underline text-opacity-70" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      ) : (
        <div>
          <div className="text-[50px] font-[300] mb-2.5">{percentCompleted}%</div>

          <div className="flex items-center justify-between text-black mb-2.5">
            <span>{selectedFile?.name}</span>

            <span className="bg-[#E5E5E5] p-[5px] rounded-full text-[13px] leading-none">
              {formatBytes(selectedFile?.size || 0)}
            </span>
          </div>

          <div className="h-[8px] rounded-full bg-[#E5E5E5] overflow-hidden">
            <div
              className="h-full rounded-full bg-[#65912c] transition-all duration-300"
              style={{ width: `${percentCompleted}%` }}
            ></div>
          </div>

          <div className="text-sm text-black text-opacity-70 mt-2 mb-[30px]">
            {percentCompleted < 100 ? 'Uploading...' : 'Processing...'}
          </div>

          {event && (
            <div className="rounded-xl bg-[#E5E5E566] p-[15px] relative mb-5">
              <svg
                width="24"
                height="22"
                viewBox="0 0 24 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute right-[10px] top-[10px]"
              >
                <path
                  d="M0 8C0 3.58172 3.58172 0 8 0H16C20.4183 0 24 3.58172 24 8V14C24 18.4183 20.4183 22 16 22H8C3.58172 22 0 18.4183 0 14V8Z"
                  fill="#F7ED00"
                />
                <g opacity="0.7">
                  <g opacity="0.7">
                    <path
                      d="M13.3877 5.67188V7.33563"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3877 14.959V16.3505"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.3877 12.5976V9.2832"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.0266 16.5C18.3931 16.5 19.5 15.498 19.5 14.2609V12.4786C18.5957 12.4786 17.8675 11.8193 17.8675 11.0007C17.8675 10.1821 18.5957 9.52215 19.5 9.52215L19.4992 7.73909C19.4992 6.502 18.3916 5.5 17.0258 5.5H6.97419C5.60842 5.5 4.50078 6.502 4.50078 7.73909L4.5 9.58021C5.40426 9.58021 6.13251 10.1821 6.13251 11.0007C6.13251 11.8193 5.40426 12.4786 4.5 12.4786V14.2609C4.5 15.498 5.60685 16.5 6.9734 16.5H17.0266Z"
                      stroke="black"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </g>
              </svg>

              <div className="text-xs text-[#000000B2] leading-none font-medium">
                {formatDateTime(event.createdAt)}
              </div>

              <div className="text-black font-medium text-[15px]">{event.title}</div>

              <div className="flex">
                {event.hosts.slice(0, 4).map(host => (
                  <div className="rounded-full h-[18px] w-[18px] -ml-2.5 first:ml-0" key={host.id}>
                    <img
                      className="w-full h-full rounded-full"
                      src="/assets/profile.png"
                      alt="Profile"
                    />
                  </div>
                ))}

                {event.hosts.length > 4 && (
                  <div className="rounded-full h-[18px] min-w-[18px] -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                    +{event.hosts.length - 4}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
