import { ReactNode } from 'react';

export const SignupLayout = ({ children }: SignupLayoutProps) => {
  return (
    <div className="bg-[#0B1444] min-h-screen pb-24">
      <nav className="flex items-center justify-between py-3 mx-auto px-9">
        <img src="/assets/logo.svg" alt="Tmrw100" />

        <div className="text-white text-opacity-50 text-[15px] flex gap-6">
          <a href="#" className="underline">
            About Us
          </a>

          <a href="#" className="underline">
            Terms & Condition
          </a>
        </div>
      </nav>

      <main className="mt-6 text-white">{children}</main>
    </div>
  );
};

interface SignupLayoutProps {
  children: ReactNode;
}