import { Club } from 'src/common/types/clubs';

export default function ClubUpdateTab({ club }: ClubUpdateTabProps) {
  return (
    <>
      <div className="pt-[40px]">
        {club.meetings &&
          club.meetings.length > 0 &&
          club.meetings?.some(meeting => new Date(meeting.event.endTime) > new Date()) && (
            <div className="flex justify-between items-center bg-[#1954C10D] border border-[#1954C1] py-[18px] pr-2.5 pl-[30px] rounded-lg mb-10">
              <h5 className="text-[17px] font-black text-black">Club meeting in progress</h5>

              <a
                href={
                  club.meetings.find(meeting => new Date(meeting.event.endTime) > new Date())?.event
                    .meetingLink
                }
                target="_blank"
                rel="noopener noreferrer"
                className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg"
              >
                Join Meeting
              </a>
            </div>
          )}

        {/* <h3 className="text-[22px] font-bold font-[sohne-breit] text-black mb-5 leading-none">
          Latest Articles
        </h3>

        <div className="flex gap-2.5 flex-wrap mb-10">
          <div className="py-[50px] pl-[40px] pr-[60px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px]">
            <div className="mb-2.5 text-sm leading-none tracking-wider">August 14, 2023</div>

            <h3 className="underline font-bold leading-tight mb-[15px] text-[22px]">
              How to invest your first 100k
            </h3>

            <div className="flex mb-5">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>

            <button className="text-sm font-bold underline">Read article</button>
          </div>

          <div className="py-[50px] pl-[40px] pr-[60px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px]">
            <div className="mb-2.5 text-sm leading-none tracking-wider">August 14, 2023</div>

            <h3 className="underline font-bold leading-tight mb-[15px] text-[22px]">
              How to invest your first 100k
            </h3>

            <div className="flex mb-5">
              <div className="rounded-full h-7 w-7">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>

              <div className="rounded-full h-7 w-7 -ml-2.5">
                <img
                  className="w-full h-full rounded-full"
                  src="/assets/profile.png"
                  alt="Profile"
                />
              </div>
            </div>

            <button className="text-sm font-bold underline">Read article</button>
          </div>

          <div className="py-[50px] px-[40px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px] relative">
            <h3 className="font-bold leading-tight mb-[7px] text-[22px]">Suggest an article</h3>

            <p className="mb-2.5">You can suggest an article by clicking the button below</p>

            <div className="relative w-full">
              <button className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg whitespace-nowrap">
                Suggest article
              </button>

              <svg
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -right-[40px] -top-6"
              >
                <path
                  d="M31.6225 2.11972C50.0737 3.93266 41.1436 30.5561 18.3083 45.5494"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M21.7177 34L12.7651 46.3087L27.4656 51.4656"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>

        <h3 className="text-[22px] font-bold font-[sohne-breit] text-black mb-5 leading-none">
          Next Events
        </h3>

        <div className="flex flex-wrap gap-2.5">
          <div className="p-2.5 pb-5 bg-white rounded-2xl border border-[#E5E5E5] shadow-lg min-w-[480px]">
            <div className="relative w-full rounded-lg overflow-hidden flex items-end p-[22px] min-h-[120px] mb-5 min-w-[470px]">
              <img
                src="/assets/bitcoin.jpeg"
                alt=""
                className="absolute inset-0 object-cover w-full h-full"
              />

              <div className="text-white z-10 relative flex items-center gap-3.5">
                <div className="flex">
                  <div className="rounded-full h-7 w-7">
                    <img
                      className="w-full h-full rounded-full"
                      src="/assets/profile.png"
                      alt="Profile"
                    />
                  </div>

                  <div className="rounded-full h-7 w-7 -ml-2.5">
                    <img
                      className="w-full h-full rounded-full"
                      src="/assets/profile.png"
                      alt="Profile"
                    />
                  </div>

                  <div className="rounded-full h-7 w-7 -ml-2.5">
                    <img
                      className="w-full h-full rounded-full"
                      src="/assets/profile.png"
                      alt="Profile"
                    />
                  </div>
                </div>

                <p className="text-sm">
                  <span className="underline">Alex Jones,</span>
                  <span className="underline"> Natasha</span>
                  <span> and </span>
                  <span className="underline"> Michaelson</span>
                  <span className="underline"> Johnny Mitchell</span>
                </p>
              </div>
            </div>

            <div className="px-[20px]">
              <div className="flex items-center gap-5 mb-2.5 font-bold text-black">
                <div className="p-2.5 text-xs rounded-lg leading-none bg-[#0B14441A] w-fit">
                  Augest 14, 2021, 10:00 AM
                </div>

                <div className="flex items-center gap-1.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                    />
                  </svg>

                  <span className="text-sm">Paris Salon</span>
                </div>
              </div>

              <h3 className="text-[22px] font-bold leading-tight border-b border-black border-opacity-50 w-fit mb-5 text-black">
                How to invest your first 100k
              </h3>

              <div className="flex gap-[17px]">
                <button className="px-[13px] py-[10px] text-[10px] font-[sohne-breit] tracking-widest text-white bg-[#2F3248] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity">
                  Register
                </button>

                <button className="text-sm font-bold text-black underline">
                  Add to my calender
                </button>
              </div>
            </div>
          </div>

          <div className="py-[50px] px-[40px] bg-white shadow-lg border border-[#E5E5E5] rounded-lg text-black w-fit max-w-[240px] relative">
            <h3 className="font-bold leading-tight mb-[7px] text-[22px]">Suggest an article</h3>

            <p className="mb-2.5">You can suggest an event by clicking the button below</p>

            <div className="relative w-full">
              <button className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg whitespace-nowrap">
                Suggest event
              </button>

              <svg
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="absolute -right-[40px] -top-6"
              >
                <path
                  d="M31.6225 2.11972C50.0737 3.93266 41.1436 30.5561 18.3083 45.5494"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
                <path
                  d="M21.7177 34L12.7651 46.3087L27.4656 51.4656"
                  stroke="#D6D6D6"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

interface ClubUpdateTabProps {
  club: Club;
}
