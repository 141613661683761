import { CreateClubProps } from 'src/common/types/clubs';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { useContext, useEffect, useState } from 'react';
import { queryParams } from 'src/common/utils';
import { User } from 'src/common/types/user';
import { useNavigate } from 'react-router-dom';

export default function ClubMembersTab({ initialData }: CreateClubProps) {
  const [inputValue, setInputValue] = useState('');
  const [members, setMembers] = useState<User[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<User[]>([]);
  const [disabled, setDisabled] = useState(false);

  const notify = useNotification();
  const navigate = useNavigate();

  const { accessToken, profile } = useContext(AuthContext);
  const { sendRequest: getMembers } = useHttp();

  const { sendRequest: createClub, error: errorCreatingClub } = useHttp();

  useEffect(() => {
    if (profile) {
      getMembersHandler();
    }

    if (errorCreatingClub) {
      setDisabled(false);
      notify(errorCreatingClub, 'error');
    }
  }, [errorCreatingClub, accessToken, profile]);

  const getMembersHandler = async () => {
    const params = {
      filter: {
        isApproved: true,
        skipCurrentUser: true,
      },
    };

    await getMembers(
      {
        url: 'user?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (data: any) => {
        setMembers(data.data);
      },
    );
  };

  const handleCreateClub = () => {
    const event = {
      userId: profile?.id,
      ...initialData.info,
      topics: initialData.info.topics.map(topic => topic.value),
      members: selectedMembers.map(member => member.id),
    };

    setDisabled(true);

    createClub(
      {
        url: 'clubs',
        method: 'POST',
        accessToken,
        payload: event,
      },
      handleCreateResponse,
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
  };

  const handleResultClick = (member: User) => {
    setSelectedMembers([...selectedMembers, member]);
    setInputValue('');
  };

  const filteredMembers = members.filter(
    member =>
      member.name?.toLowerCase().includes(inputValue.toLowerCase()) &&
      !selectedMembers.find(selectedMember => selectedMember.id === member.id),
  );

  const handleRemoveMember = (memberId: string) => {
    const updatedSelectedMembers = selectedMembers.filter(member => member.id !== memberId);
    setSelectedMembers(updatedSelectedMembers);
  };

  const handleCreateResponse = () => {
    notify(`Your club “${initialData.info.title}” was created`, 'success');

    setTimeout(() => {
      navigate('/clubs');
    }, 3000);
  };

  const getMemberTypeColor = (role: string) => {
    switch (role) {
      case 'Gold':
        return '#CD9400';
      case 'Silver':
        return '#76835D';
      case 'Bronze':
        return '#C97C00';
      default:
        return '#CD9400';
    }
  };

  return (
    <>
      <div className="max-w-[570px] w-full mx-auto mt-7">
        <div className="bg-white border border-[#E5E5E5] rounded-2xl p-14 text-black shadow">
          <h1 className="font-[sohne-breit] text-2xl mb-3.5">Invite members</h1>
          <p className="text-black text-opacity-70 leading-snug mb-[40px]">
            The Internet of Life® is inspired by the power of reality and how people come together,
            collaborate, and communicate. It represents the third evolution of the internet.
          </p>

          <div className="flex flex-col gap-7">
            <div className="space-y-2">
              <div>
                <div className="font-bold">Invite members</div>
                <span className="text-sm text-[#000000B2]">
                  A maximum of 10 invitations can be send
                </span>
              </div>

              {selectedMembers.length > 0 && (
                <div className="pt-[25px] pb-[30px]">
                  {selectedMembers.map((member, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center py-[17px] border-t border-[#E5E5E5] last:border-b"
                    >
                      <div className="flex items-center gap-3">
                        <div className="rounded-full h-9 w-9">
                          <img
                            className="w-full h-full rounded-full"
                            src="/assets/profile.png"
                            alt="Profile"
                          />
                        </div>

                        <div className="flex flex-col">
                          <div>
                            <span className="mr-1 font-bold">{member?.name ?? 'John Deo'}</span>
                            <span
                              className="text-[10px] text-white px-[4px] py-[3px] leading-none rounded-sm"
                              style={{
                                backgroundColor: getMemberTypeColor(member?.type ?? 'Gold'),
                              }}
                            >
                              {member?.type ?? 'Gold'}
                            </span>
                          </div>

                          <span className="text-sm text-[#000000B2]">{member?.role?.name}</span>
                        </div>
                      </div>

                      <button
                        className="p-[11px] rounded-md bg-[#E5E5E5] h-fit"
                        onClick={() => handleRemoveMember(member.id)}
                      >
                        <svg
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L7 7"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M7 1L1 7"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="relative">
                <input
                  type="search"
                  name="members"
                  value={inputValue}
                  onChange={handleInputChange}
                  disabled={selectedMembers.length >= 10}
                  placeholder="Start typing to search for members..."
                  className="w-full rounded-md h-[60px] border-gray-300 focus:border-gray-300"
                  autoComplete="off"
                />

                <ul
                  className={`absolute left-0 z-10 mt-2 w-full bg-white border border-gray-300 rounded shadow ${
                    !inputValue ? 'hidden' : ''
                  }`}
                >
                  {filteredMembers.length === 0 ? (
                    <li className="px-4 py-2 text-center text-gray-500">No members found.</li>
                  ) : (
                    filteredMembers.map(member => (
                      <li
                        key={member.id}
                        onClick={() => handleResultClick(member)}
                        className="px-4 py-2 border-t cursor-pointer hover:bg-gray-100 first:border-t-0"
                      >
                        {member?.name}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            </div>

            <button
              className="px-5 py-5 text-sm text-center font-medium text-white bg-[#005AFF] rounded-xl focus:outline-none hover:bg-opacity-90 transition-opacity font-[sohne-breit]"
              onClick={handleCreateClub}
              disabled={disabled}
            >
              Invite members
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
