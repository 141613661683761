import ForwardArrowSvg from 'src/common/components/svg/ArrowRight';
import { StepStatus } from '../../common/components/StepStatus';

export const SignupSteps = ({ currentStep }: SignupStepsProps) => {
  const stepsAppearance = {
    default: {
      textClasses: 'text-white text-opacity-50',
      backgroundClasses: '',
      borderClasses: 'border border-white border-opacity-50',
    },
    active: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-white bg-opacity-50',
      borderClasses: '',
    },
    completed: {
      textClasses: 'text-white',
      backgroundClasses: 'bg-[#69BB00]',
      borderClasses: '',
    },
  };

  return (
    <div className="flex items-center gap-4 mx-auto my-10 w-fit font-[sohne]">
      <div className="flex items-center gap-3">
        <StepStatus stepNumber={1} currentStep={currentStep} appearance={stepsAppearance} />

        <span className="font-normal text-gray-500">Account details</span>
      </div>

      <ForwardArrowSvg className="w-5 h-5 text-white text-opacity-50" />

      <div className="flex items-center gap-3">
        <StepStatus stepNumber={2} currentStep={currentStep} appearance={stepsAppearance} />

        <span className="font-normal text-gray-500">About you</span>
      </div>

      <ForwardArrowSvg className="w-5 h-5 text-white text-opacity-50" />

      <div className="flex items-center gap-3">
        <StepStatus stepNumber={3} currentStep={currentStep} appearance={stepsAppearance} />

        <span className="font-normal text-gray-500">Professional background</span>
      </div>
    </div>
  );
};

interface SignupStepsProps {
  currentStep: number;
}
