import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { CircleMemberCard } from '../circles/CircleMemberCard';
import { CircleOwnerCard } from '../circles/CircleOwnerCard';
import { Circle } from 'src/common/types/circle';
import { useContext, useState } from 'react';
import { AuthContext } from 'src/contexts';

interface ConnectionProps {
  circles: Circle[];
}

const tabs = [
  {
    key: 'added-by-me',
    label: 'Added by me',
  },
  {
    key: 'added-me',
    label: 'Added me',
  },
];

export const Connections = ({ circles }: ConnectionProps) => {
  const [activeTab, setActiveTab] = useState('added-by-me');
  const { profile } = useContext(AuthContext);

  const getUsersAddedByMe = () => {
    const uniqueUsers = new Map();

    circles
      .filter(circle => circle.owner.id === profile?.id)
      .forEach(circle => {
        circle.users.forEach(circleUser => {
          uniqueUsers.set(circleUser.user.id, circleUser.user);
        });
      });

    return Array.from(uniqueUsers.values()).map(user => (
      <CircleMemberCard key={user.id} member={user} />
    ));
  };

  const getUsersAddedMe = () => {
    const uniqueUsers = new Map();

    circles
      .filter(
        circle =>
          circle.owner.id !== profile?.id && circle.users.some(user => user.userId === profile?.id),
      )
      .forEach(circle => {
        uniqueUsers.set(circle.owner.id, circle.owner);
      });

    return Array.from(uniqueUsers.values()).map(user => (
      <CircleOwnerCard owner={user} key={user.id} />
    ));
  };

  return (
    <div>
      <h4 className="text-[22px] font-bold mb-[25px]">New connections</h4>

      <Tabs value={activeTab} className="!overflow-visible">
        <TabsHeader
          placeholder="Empty"
          className="items-center justify-between p-0 bg-transparent rounded-none"
          indicatorProps={{
            className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
          }}
        >
          <div className="flex justify-between gap-10">
            {tabs.map(({ key, label }) => (
              <Tab
                placeholder="Empty"
                key={key}
                value={key}
                onClick={() => setActiveTab(key)}
                className={`h-auto w-auto px-0 pb-4 text-left text-inherit text-lg place-items-start text-black z-0 ${
                  activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                }`}
              >
                {label}
              </Tab>
            ))}
          </div>
        </TabsHeader>

        <TabsBody placeholder="Empty" className="!overflow-visible">
          {tabs.map(({ key }) => (
            <TabPanel key={key} value={key} className="p-0">
              <div>
                {key === 'added-by-me' && <div>{getUsersAddedByMe()}</div>}

                {key === 'added-me' && <div>{getUsersAddedMe()}</div>}
              </div>
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  );
};
