import { Link } from 'react-router-dom';

interface SummaryProps {
  connectionsCount: number | undefined;
  eventsCount: number | undefined;
  clubsCount: number | undefined;
  postsCount: number | undefined;
}

export const Summary = ({
  connectionsCount = 0,
  eventsCount = 0,
  clubsCount = 0,
  postsCount = 0,
}: SummaryProps) => {
  return (
    <div className="flex flex-wrap gap-2.5">
      <div className="bg-white rounded-lg min-h-[193px] min-w-[205px] relative pt-12 pl-10">
        <div className="bg-[#F4F4F4] rounded-lg flex items-center justify-center h-[34px] w-[34px] absolute top-2.5 right-2.5">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.7">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.46569 9.03418C7.8258 9.03418 9.843 9.39181 9.843 10.8204C9.843 12.249 7.83924 12.6169 5.46569 12.6169C3.10494 12.6169 1.08838 12.2625 1.08838 10.8332C1.08838 9.40397 3.0915 9.03418 5.46569 9.03418Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.4657 6.99458C3.91618 6.99458 2.65967 5.73871 2.65967 4.18918C2.65967 2.63966 3.91618 1.38379 5.4657 1.38379C7.01459 1.38379 8.2711 2.63966 8.2711 4.18918C8.27685 5.73295 7.0293 6.98882 5.48553 6.99458H5.4657Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9.87476 6.26671C10.899 6.12276 11.6879 5.24371 11.6898 4.17977C11.6898 3.13119 10.9253 2.2611 9.92274 2.09668"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.2263 8.72949C12.2186 8.87728 12.9115 9.22531 12.9115 9.94186C12.9115 10.4351 12.5852 10.755 12.058 10.9553"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>

        <div>
          <h3 className="text-[27px] font-bold text-black font-[sohne-breit]">
            {connectionsCount}
          </h3>

          <h6 className="text-[#000000B2]">connections</h6>

          <Link to={'/circles'} className="text-sm font-bold text-black underline">
            See my circles
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-lg min-h-[193px] min-w-[205px] relative pt-12 pl-10">
        <div className="bg-[#F4F4F4] rounded-lg flex items-center justify-center h-[34px] w-[34px] absolute top-2.5 right-2.5">
          <svg
            width="13"
            height="10"
            viewBox="0 0 13 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.7">
              <path
                d="M7.60957 0.640625V2.00188"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.60957 8.24023V9.37873"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.60957 6.30654V3.59473"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.5213 9.5C11.6145 9.5 12.5 8.68019 12.5 7.66802V6.20973C11.7766 6.20973 11.194 5.67034 11.194 5.00058C11.194 4.33082 11.7766 3.79085 12.5 3.79085L12.4994 2.33198C12.4994 1.31981 11.6133 0.5 10.5207 0.5H2.47935C1.38673 0.5 0.500626 1.31981 0.500626 2.33198L0.5 3.83835C1.2234 3.83835 1.80601 4.33082 1.80601 5.00058C1.80601 5.67034 1.2234 6.20973 0.5 6.20973V7.66802C0.5 8.68019 1.38548 9.5 2.47872 9.5H10.5213Z"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>

        <div>
          <h3 className="text-[27px] font-bold text-black font-[sohne-breit]">{eventsCount}</h3>

          <h6 className="text-[#000000B2]">events</h6>

          <Link to={'/events'} className="text-sm font-bold text-black underline">
            See my events
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-lg min-h-[193px] min-w-[205px] relative pt-12 pl-10">
        <div className="bg-[#F4F4F4] rounded-lg flex items-center justify-center h-[34px] w-[34px] absolute top-2.5 right-2.5">
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.7"
              d="M11.4083 2.79811C11.8003 2.26891 12.1896 2.05798 12.5679 2.169C13.1793 2.35403 13.4534 3.3273 13.4836 3.43832C13.4945 3.47903 13.5 3.51974 13.5 3.56414V6.29893C13.5 7.30181 12.8941 8.11965 12.1512 8.11965H2.24905C1.28406 8.11965 0.5 7.06127 0.5 5.75863V3.39761C0.5 3.34211 0.510966 3.28289 0.527415 3.23109C0.565795 3.12747 0.91396 2.22451 1.53079 2.13939C1.91459 2.08758 2.28743 2.36143 2.63286 2.96094M3.78701 8.11225V9.5M10.2294 8.11225V9.5M2.69317 4.94079H11.3288V1.93586C11.3288 1.14391 10.8518 0.5 10.2651 0.5H3.63075C3.11535 0.5 2.69317 1.0662 2.69317 1.76563V4.94079Z"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div>
          <h3 className="text-[27px] font-bold text-black font-[sohne-breit]">{clubsCount}</h3>

          <h6 className="text-[#000000B2]">clubs</h6>

          <Link to={'/clubs'} className="text-sm font-bold text-black underline">
            See my clubs
          </Link>
        </div>
      </div>

      <div className="bg-white rounded-lg min-h-[193px] min-w-[205px] relative pt-12 pl-10">
        <div className="bg-[#F4F4F4] rounded-lg flex items-center justify-center h-[34px] w-[34px] absolute top-2.5 right-2.5">
          <svg
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.7">
              <path
                d="M8.7522 4.86654L3.9501 4.86654"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.05 7.13314L3.95014 7.13314"
                stroke="black"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect x="1" y="0.5" width="13" height="11" rx="3.5" stroke="black" />
            </g>
          </svg>
        </div>

        <div>
          <h3 className="text-[27px] font-bold text-black font-[sohne-breit]">{postsCount}</h3>

          <h6 className="text-[#000000B2]">posts</h6>

          <Link to={'/blogs'} className="text-sm font-bold text-black underline">
            See my posts
          </Link>
        </div>
      </div>
    </div>
  );
};
