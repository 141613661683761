import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHttp, useNotification } from 'src/common/hooks';
import { Event } from 'src/common/types/event';
import { User } from 'src/common/types/user';
import { formatDateTime, getInitials, handleAddToCalendar, queryParams } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { Pagination } from '../pagination/Pagination';

interface EventsTabProps {
  member: User;
  setCount: (count: number) => void;
}

export const EventsTab = ({ member, setCount }: EventsTabProps) => {
  const notify = useNotification();
  const { accessToken, profile } = useContext(AuthContext);
  const [events, setEvents] = useState<Event[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 12;

  const {
    sendRequest: getEvents,
    isLoading: isGettingEvents,
    error: errorGettingEvents,
  } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getEventsHandler();
    }
  }, [accessToken, profile, currentPage]);

  useEffect(() => {
    if (errorGettingEvents) {
      notify(errorGettingEvents, 'error');
    }
  }, [errorGettingEvents]);

  const getEventsHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        userId: member.parentId ?? member.id,
      },
      offset,
      limit: perPage,
    };

    await getEvents(
      {
        url: 'events?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setEvents(res.data);
        setCount(res.total);
        setTotalCount(res.total);
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      {events.map(event => (
        <div
          className="p-2.5 bg-white rounded-2xl border border-[#E5E5E5] mb-2.5 shadow-md flex"
          key={event.id}
        >
          <div className="py-[30px] pl-[45px] pr-[55px] max-w-[70%] w-full">
            <div className="flex items-center gap-5 mb-2 font-bold text-black">
              <div className="p-2.5 text-xs rounded-lg leading-none bg-[#0B1444] bg-opacity-10 w-fit">
                {formatDateTime(event.startTime)}
              </div>

              <div className="flex items-center gap-1.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                  />
                </svg>

                <span className="text-sm">{event.room?.roomName}</span>
              </div>
            </div>

            <div className="flex items-start justify-between">
              <div className="max-w-[60%] text-black">
                <h3 className="text-[22px] font-bold text-opacity-50 leading-tight border-b border-black border-opacity-50 w-fit mb-3.5">
                  {event.title}
                </h3>

                <p className="mb-3.5">
                  {event.description.length > 100
                    ? event.description.slice(0, 100) + '...'
                    : event.description}
                </p>

                <button
                  className="text-sm font-bold text-black underline"
                  onClick={() => handleAddToCalendar(event)}
                >
                  Add to my calender
                </button>
              </div>

              <Link
                to={`/events/detail/${event.id}`}
                className="px-[13px] py-[10px] text-[10px] font-[sohne-breit] tracking-widest text-white bg-[#2F3248] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
              >
                Register
              </Link>
            </div>
          </div>

          <div className="relative min-h-full max-w-[30%] w-full rounded-lg overflow-hidden flex items-center px-[30px]">
            <img src="/assets/bitcoin.jpeg" alt="" className="absolute inset-0 w-full h-full" />

            <div className="relative z-10 -mb-6 text-white">
              <h6 className="text-[13px] mb-1.5 font-[sohne-breit]">Hosts</h6>

              <p className="mb-3 text-sm">
                {event.hosts.slice(0, 4).map((host, index) => (
                  <span key={index}>
                    {index > 0 && index === 3 ? ' and ' : index > 0 ? ', ' : ''}
                    <span className="underline">{host?.user?.name ?? 'John Doe'}</span>
                  </span>
                ))}
                <span>{event.hosts.length > 4 && ' and more'}</span>
              </p>

              <div className="flex">
                {event.hosts.slice(0, 4).map(host => (
                  <div
                    className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
                    key={host.id}
                  >
                    {host.user.avatar ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          src={host.user.avatar}
                          className="object-cover w-full h-full rounded-full"
                          alt="Profile Avatar"
                        />
                      </div>
                    ) : (
                      <div>{getInitials(host.user.name)}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}

      {events.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!events.length && isGettingEvents && (
        <div className={`text-center block p-8`}>No records found</div>
      )}
    </>
  );
};
