import { useContext, useEffect, useState } from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import { SubAccounts } from './SubAccounts';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from 'src/contexts';
import { useNavigate } from 'react-router-dom';
import { ActivityTab } from './ActivityTab';

export const Preferences = () => {
  const [activeTab, setActiveTab] = useState('sub-accounts');
  const { appConfig } = useContext(AuthContext);
  const navigate = useNavigate();
  const tabs = [
    {
      key: 'sub-accounts',
      label: 'Sub Accounts',
    },
    {
      key: 'activity-log',
      label: 'Activity Log',
    },
    // {
    //   key: 'notifications',
    //   label: 'Notifications',
    // },
  ];

  useEffect(() => {
    if (appConfig?.parentId !== null) {
      navigate('/dashboard');
    }
  }, []);

  const navigateToActivityLog = () => {
    setActiveTab('activity-log');
  };

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="mb-[30px]">
        <span className="text-xs text-black text-opacity-50 font-[sohne-breit] leading-tight ">
          Dashboard
        </span>

        <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Preferences</h1>
      </div>

      <Tabs value={activeTab} key={activeTab}>
        <TabsHeader
          placeholder="Empty"
          className="items-center justify-between p-0 bg-transparent rounded-none"
          indicatorProps={{
            className: 'bg-transparent border-b-2 border-[#F35800] shadow-none rounded-none',
          }}
        >
          <div className="flex justify-between gap-10">
            {tabs.map(({ key, label }) => (
              <Tab
                placeholder="Empty"
                key={key}
                value={key}
                onClick={() => setActiveTab(key)}
                className={`h-auto w-auto px-0 py-4 text-left text-inherit text-lg place-items-start text-black z-0 ${
                  activeTab === key ? 'font-extrabold' : 'text-opacity-80 font-medium'
                }`}
              >
                {label}
              </Tab>
            ))}
          </div>
        </TabsHeader>

        <TabsBody placeholder="Empty">
          {tabs.map(({ key }) => (
            <TabPanel key={key} value={key} className="p-0">
              {key === 'sub-accounts' && <SubAccounts onNavigate={navigateToActivityLog} />}
              {key === 'activity-log' && <ActivityTab />}
              {key === 'notifications' && <div></div>}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </>
  );
};
