import { useState } from 'react';
import { Circle } from 'src/common/types/circle';
import { DeleteCirclePopup } from '../popups/DeleleCirclePopup';
import { Link } from 'react-router-dom';
import { CircleMemberCard } from './CircleMemberCard';

interface CircleCardProps {
  circle: Circle;
  getCircles: () => void;
}

export const CircleCard = ({ circle, getCircles }: CircleCardProps) => {
  const [showDeleteCirclePopup, setShowDeleteCirclePopup] = useState(false);

  return (
    <div className="mb-[50px]" key={circle.id}>
      {showDeleteCirclePopup && (
        <DeleteCirclePopup
          onClose={() => setShowDeleteCirclePopup(false)}
          circle={circle}
          onDeleted={getCircles}
        />
      )}

      <div className="flex gap-8  mb-[32px] items-end leading-none">
        <h3 className="text-[25px] font-[sohne-breit] text-black leading-none max-w-[65%] truncate">
          {circle.name}
        </h3>

        <button
          className="text-[#C51C00] text-sm underline font-bold leading-none"
          onClick={() => setShowDeleteCirclePopup(true)}
        >
          Delete Circle
        </button>
      </div>

      {circle.users.length ? (
        <div>
          {circle.users.map(circleUser => (
            <CircleMemberCard
              circle={{ id: circle.id, name: circle.name }}
              member={circleUser.user}
              key={circleUser.userId}
              getCircles={getCircles}
            />
          ))}
        </div>
      ) : (
        <div className="bg-white relative flex items-center flex-col p-[50px] rounded-xl">
          <h2 className="text-[24px] font-bold text-black leading-none">
            There are no members in this circle
          </h2>

          <p className="mb-4 text-sm leading-none text-black text-opacity-70">
            You can add your first one, by clicking the button below
          </p>

          <div className="relative">
            <Link
              to="/members"
              className="py-[14px] px-[20px] bg-[#005AFF] font-[sohne-breit] text-[10px] text-white font-bold tracking-widest rounded-lg whitespace-nowrap block"
            >
              Add members in this circle
            </Link>

            <svg
              width="86"
              height="56"
              viewBox="0 0 86 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute -top-5 -right-24"
            >
              <path
                d="M71.1577 2.09131C98.7841 16.7486 53.5376 53.3629 12.4996 42.5013"
                stroke="#D6D6D6"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <path
                d="M20.377 35.9997L6.00098 40.998L15.1296 53.6221"
                stroke="#D6D6D6"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};
