import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Event } from 'src/common/types/event';
import { formatDateTime, getInitials, handleAddToCalendar } from 'src/common/utils';
import { AuthContext } from 'src/contexts';

interface EventCardProps {
  event: Event;
}

export const EventCard = ({ event }: EventCardProps) => {
  const { profile } = useContext(AuthContext);

  const isRegistered = event.hosts.find(
    eventUser => eventUser.user?.id === (profile?.parentId ?? profile?.id),
  );

  return (
    <div className="min-h-[541px] p-[10px] bg-white max-w-[245px] min-w-[245px] rounded-xl shadow-md border border-[#E5E5E5]">
      <div
        className="rounded-lg px-3.5 pt-[34px] bg-cover"
        style={{
          backgroundImage:
            'linear-gradient(to top, rgba(255, 255, 255), rgba(255, 255, 255, 100%), rgb(251 ,251 ,251), rgb(251 ,251 ,251), rgb(251 ,251 ,251, 0%)), url("/assets/bitcoin-placeholder.jpg")',
        }}
      >
        <div className="flex mb-2.5">
          {event.hosts.slice(0, 4).map(host => (
            <div
              className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
              key={host.id}
            >
              {host.user?.avatar ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img
                    src={host.user?.avatar}
                    className="object-cover w-full h-full rounded-full"
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div>{getInitials(host.user?.name)}</div>
              )}
            </div>
          ))}

          {event.hosts.length > 4 && (
            <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
              +{event.hosts.length - 4}
            </div>
          )}
        </div>

        <div className="text-black text-sm mb-[22px] min-h-[60px]">
          {event.hosts.slice(0, 4).map((host, index) => (
            <span key={index}>
              {index > 0 ? ', ' : ''}
              <span className="underline">
                {host?.user?.name.length > 14 ? (
                  <>{host?.user?.name.substring(0, 14)}...</>
                ) : (
                  host?.user?.name
                )}
              </span>
            </span>
          ))}
          <span>{event.hosts.length > 4 && ' and more'}</span>
        </div>

        <div className="p-2.5 text-[13px] rounded-lg leading-none bg-[#0B1444] bg-opacity-10 w-fit text-black font-medium mb-2.5">
          {formatDateTime(event.startTime)}
        </div>

        <Link
          to={`/events/detail/${event.id}`}
          className="text-[22px] font-bold leading-tight underline w-fit"
        >
          {event.title}
        </Link>

        <div className="flex items-center gap-1.5 mb-6 mt-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
            />
          </svg>

          <span className="text-sm text-black">{event.room?.roomName}</span>
        </div>

        <p className="mb-5">
          {event.description.length > 70 ? (
            <>{event.description.substring(0, 70)}...</>
          ) : (
            event.description
          )}
        </p>

        <div className="flex flex-wrap gap-1 text-[#325EAE] mb-5">
          {event.topics.map((topic, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE] font-medium capitalize"
              key={index}
            >
              {topic.name}
            </div>
          ))}
        </div>

        <Link
          to={`/events/detail/${event.id}`}
          className={`px-[14px] py-[10px] text-[10px] font-[sohne-breit] tracking-widest rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity mb-1.5 block w-fit ${
            !isRegistered ? 'text-white bg-[#2F3248]' : 'border border-black font-bold'
          }`}
        >
          {isRegistered ? 'Registered' : 'Register'}
        </Link>

        <button
          className="text-sm font-bold text-black underline"
          onClick={() => handleAddToCalendar(event)}
        >
          Add to my calender
        </button>
      </div>
    </div>
  );
};
