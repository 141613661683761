import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { useHttp, useNotification } from 'src/common/hooks';
import { Event } from 'src/common/types/event';
import { formatDateTime, getInitials } from 'src/common/utils';
import { AuthContext } from 'src/contexts';
import { EventMemberCard } from './EventMemberCard';
import { DateArray, EventAttributes, createEvent } from 'ics';
import moment from 'moment';

export const EventDetail = () => {
  const { id } = useParams();
  const { accessToken, profile, appConfig } = useContext(AuthContext);
  const [event, setEvent] = useState<Event>();
  const notify = useNotification();
  const { sendRequest: getEvent, isLoading: isGettingEvent, error: errorGettingEvent } = useHttp();

  const navigate = useNavigate();
  const isRegistered = event?.hosts.find(
    eventUser => eventUser.user?.id === (profile?.parentId ?? profile?.id),
  );

  useEffect(() => {
    if (id && accessToken) {
      getEventHandler();
    }
  }, [id]);

  const {
    sendRequest: attachUser,
    isLoading: isAttachingUser,
    error: errorAttachingUser,
  } = useHttp();

  const {
    sendRequest: detachUser,
    isLoading: isDetachingUser,
    error: errorDetachingUser,
  } = useHttp();

  useEffect(() => {
    if (errorAttachingUser) {
      notify(errorAttachingUser, 'error');
    }

    if (errorDetachingUser) {
      notify(errorDetachingUser, 'error');
    }
  }, [errorAttachingUser, errorGettingEvent, errorDetachingUser]);

  const handleAttachUser = () => {
    if (!event) return;

    if (isRegistered) {
      detachUser(
        {
          url: 'events/detach-user',
          method: 'POST',
          payload: { eventId: event.id },
          accessToken,
        },
        handleResponse,
      );
    } else {
      attachUser(
        {
          url: 'events/attach-user',
          method: 'POST',
          payload: { eventId: event.id },
          accessToken,
        },
        handleResponse,
      );
    }
  };

  const handleResponse = () => {
    const successMessage = isRegistered
      ? 'Successfully left the event.'
      : 'Successfully registered for the event.';

    notify(successMessage, 'success');
    getEventHandler();
  };

  const getEventHandler = async () => {
    getEvent(
      {
        url: `events/${id}`,
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        if (res.endTime && new Date(res.endTime) > new Date()) {
          setEvent(res);
        } else {
          navigate('/events');
        }
      },
    );
  };

  const handleAddToCalendar = () => {
    if (!event) return;

    const startTimeArray = formatDateTimeArray(event.startTime);
    const endTimeArray = formatDateTimeArray(event.endTime);

    const attendees = event.hosts
      .filter(host => host.user.id !== event.user.id)
      .map(host => ({
        name: host.user.name,
        email: host.user.email,
      }));

    const calendarEvent: EventAttributes = {
      start: startTimeArray,
      end: endTimeArray,
      title: event.title,
      description: event.description,
      location: event.room?.roomName || '',
      url: event.meetingLink,
      status: 'CONFIRMED',
      categories: event.topics.map(topic => topic.name),
      organizer: { name: event?.user?.name, email: event?.user?.email },
      attendees,
    };

    createEvent(calendarEvent, (error, value) => {
      if (error) {
        console.error(error);
      } else {
        downloadIcsFile(value, `${event.title.replace(/\s+/g, '_')}.ics`);
      }
    });
  };

  const formatDateTimeArray = (dateTimeString: string): DateArray => {
    const momentObject = moment(dateTimeString);

    return [
      momentObject.year(),
      momentObject.month() + 1, // Months are zero-based, so add 1
      momentObject.date(),
      momentObject.hours(),
      momentObject.minutes(),
    ];
  };

  const downloadIcsFile = (icsData: string, fileName: string) => {
    const blob = new Blob([icsData], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  };

  const createAndJoin =
    appConfig?.parentId === null ||
    appConfig?.features['Events'].subFeatures.includes('Create and join events');

  return (
    <>
      <ToastContainer autoClose={8000} />

      {event && (
        <div className="max-w-[88.6%] mx-auto">
          <div
            className="rounded-lg bg-start px-[63px] py-[22px] bg-cover min-h-[440px] flex flex-col justify-end relative"
            style={{
              backgroundImage:
                'linear-gradient(to top, rgba(255, 255, 255, 90%), rgb(251 251 251 / 60%)), url("/assets/bitcoin-placeholder.jpg")',
            }}
          >
            <button
              className="h-[30px] w-[30px] bg-white rounded-lg flex items-center justify-center absolute top-0 bottom-0 left-[18px] my-auto"
              onClick={() => navigate(-1)}
            >
              <svg
                width="15"
                height="12"
                viewBox="0 0 15 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.7"
                  d="M14 6.00016H2M2 6.00016L6.64516 10.6668M2 6.00016L6.64516 1.3335"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </button>

            <div className="flex justify-between items-center border-b border-[#BBBBBB] pb-[30px]">
              <div className="max-w-[55%]">
                <div className="text-black text-opacity-50 font-[sohne-breit] mb-[15px] text-sm tracking-widest">
                  Community / Events
                </div>

                <div className="p-2.5 text-[13px] rounded-lg leading-none bg-black bg-opacity-70 w-fit text-white font-medium mb-2.5">
                  {formatDateTime(event.startTime)}
                </div>

                <h1 className="text-[36px] font-bold leading-none w-fit mb-5 text-black font-[sohne-breit] max-w-full">
                  {event.title}
                </h1>

                <div className="flex gap-1.5 mb-5">
                  {event.topics.map((topic, index) => (
                    <div
                      className="bg-white rounded-full text-xs py-[6px] px-[10px] text-[#325EAE] leading-none w-fit border border-[#325EAE33] capitalize"
                      key={index}
                    >
                      {topic.name}
                    </div>
                  ))}
                </div>

                <div className="flex items-center gap-1.5 text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 text-opacity-50"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                    />
                  </svg>

                  <span className="text-[19px] font-bold leading-none">{event.room?.roomName}</span>
                </div>
              </div>

              <div className="flex items-center justify-between">
                <button
                  className="mr-5 text-sm font-bold text-black underline whitespace-nowrap"
                  onClick={handleAddToCalendar}
                >
                  Add to my calender
                </button>

                {createAndJoin && (
                  <button
                    className="px-[30px] py-[18px] text-[11px] font-[sohne-breit] tracking-widest text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
                    disabled={isAttachingUser || isDetachingUser ? true : false}
                    onClick={handleAttachUser}
                  >
                    {isRegistered ? 'Leave' : 'Register'}
                  </button>
                )}
              </div>
            </div>

            <div className="flex gap-3.5 items-center mt-[20px]">
              <div className="flex">
                {event.hosts.slice(0, 4).map(member => (
                  <div
                    className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
                    key={member.id}
                  >
                    {member.user?.avatar ? (
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          src={member.user?.avatar}
                          className="object-cover w-full h-full rounded-full"
                          alt="Profile Avatar"
                        />
                      </div>
                    ) : (
                      <div>{getInitials(member.user?.name)}</div>
                    )}
                  </div>
                ))}

                {event.hosts.length > 4 && (
                  <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                    +{event.hosts.length - 4}
                  </div>
                )}
              </div>

              <div className="text-sm text-black">
                {event.hosts.map((host, index) => (
                  <span key={index}>
                    {index > 0 && index === event.hosts.length - 1
                      ? ' and '
                      : index > 0
                      ? ', '
                      : ''}
                    <span className="underline">{host?.user?.name ?? 'John Doe'}</span>
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="pt-[40px] text-black">
            <h3 className="text-[23px] font-[sohne-breit] text-center tracking-wider font-medium mb-[20px]">
              About the event
            </h3>

            <p className="leading-[23px] text-center max-w-[60%] mx-auto">{event.description}</p>
          </div>

          <div className="pt-[40px] text-black">
            <h3 className="text-[23px] font-[sohne-breit] text-center tracking-wider font-medium mb-[20px]">
              The hosts
            </h3>

            <div className="max-w-[88%] mx-auto flex gap-2.5 flex-wrap w-fit">
              {event.hosts
                .filter(host => host.isHost)
                .map(host => (
                  <EventMemberCard user={host.user} key={host.id} />
                ))}
            </div>
          </div>
        </div>
      )}

      {isGettingEvent && <LoadingSpinner />}
    </>
  );
};
