import { Link } from 'react-router-dom';
import { Profile } from 'src/common/types';
import { Club } from 'src/common/types/clubs';
import { getInitials } from 'src/common/utils';

interface ClubCardProps {
  club: Club;
  profile: Profile;
}

export const ClubCard = ({ club, profile }: ClubCardProps) => {
  return (
    <div
      className="min-w-[244px] max-w-[245px] border border-[#E5E5E5] rounded-lg bg-white shadow px-[29px] pt-[59px] pb-[30px] text-black bg-cover bg-center min-h-[466px] flex flex-col justify-between"
      style={{
        backgroundImage:
          'linear-gradient(to top, rgba(255, 255, 255), rgba(255, 255, 255, 100%), rgb(251 251 251 / 70%)), url("/assets/bitcoin-placeholder.jpg")',
      }}
    >
      <div>
        <Link to={`/clubs/${club.id}`}>
          <h3 className="font-bold text-[22px] underline mb-5 leading-7">{club.title}</h3>
        </Link>

        <p className="mb-2.5 leading-5">
          {club.description.length > 90 ? club.description.slice(0, 90) + '...' : club.description}
        </p>

        <div className="flex flex-wrap gap-1 text-[#325EAE] mb-[30px]">
          {club.topics.map((topic, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE] font-medium capitalize"
              key={index}
            >
              {topic.name}
            </div>
          ))}
        </div>
      </div>

      <div>
        <div className="flex mb-3.5">
          {club.members.slice(0, 4).map(member => (
            <div
              className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 bg-blue-400 font-bold text-xs flex items-center justify-center"
              key={member.id}
            >
              {member.user?.avatar ? (
                <div className="flex items-center justify-center w-full h-full">
                  <img
                    src={member.user?.avatar}
                    className="object-cover w-full h-full rounded-full"
                    alt="Profile Avatar"
                  />
                </div>
              ) : (
                <div>{getInitials(member.user?.name ?? '')}</div>
              )}
            </div>
          ))}

          {club.members.length > 4 && (
            <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
              +{club.members.length - 4}
            </div>
          )}
        </div>

        <div>
          <span className="text-xl font-black leading-none">{club.members.length} </span>
          <span className="text-sm leading-none">members</span>
        </div>

        <div className="underline text-black !text-opacity-50 text-sm tracking-wider mb-[30px]">
          {club?.commonMembers} in common
        </div>

        {club.members.some(member => member.user?.id === (profile?.parentId ?? profile?.id)) ? (
          <Link
            to={`/clubs/${club.id}`}
            className="py-[14px] px-[34px] leading-none border border-black font-[sohne-breit] text-black rounded-lg text-[10px] font-bold tracking-wider block w-fit"
          >
            Joined
          </Link>
        ) : (
          <Link
            to={`/clubs/${club.id}`}
            className="py-[14px] px-[34px] leading-none bg-[#2F3248] font-[sohne-breit] text-white rounded-lg text-[10px] tracking-wider block w-fit"
          >
            Join club
          </Link>
        )}
      </div>
    </div>
  );
};
