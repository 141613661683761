import { ReactNode, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthContext } from 'src/contexts';

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const { logout, profile, appConfig } = useContext(AuthContext);
  const logoutHandler = () => {
    logout();
  };
  return (
    <>
      <div className="flex h-screen">
        <div className="flex flex-col justify-between min-w-[220px] h-full bg-[#0B1444] pt-[50px] overflow-y-scroll custom-scrollbar">
          <div className="flex flex-col gap-10">
            <div className="flex items-center justify-between ml-8 mr-5">
              <svg
                width="53"
                height="43"
                viewBox="0 0 53 43"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.5022 2.0794V19.3454M26.5022 23.734V41M50.656 19.9445L43.8165 6.29484C42.4156 3.64575 39.6855 2 36.704 2H16.2574C13.2615 2 10.5314 3.65297 9.1161 6.33815L2.35563 19.9228C1.88146 20.8179 1.88146 21.8862 2.35563 22.7812C2.82979 23.6835 3.72065 25.5025 4.74802 27.603C6.21362 30.5986 7.86603 33.9839 9.13766 36.3948C10.5386 39.0511 13.2687 40.7041 16.2645 40.7041H36.704C39.6927 40.7041 42.4156 39.0583 43.8381 36.3803L50.6489 22.7668C51.1158 21.8717 51.1158 20.8107 50.656 19.9445Z"
                  stroke="white"
                  strokeWidth="3"
                  strokeMiterlimit="10"
                />
              </svg>

              <div className="bg-[#454B6D] rounded-[15px] h-[36px] w-[36px] flex items-center justify-center">
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.44586 14.5319C5.68989 15.0978 6.29327 15.5 7.00027 15.5C7.70728 15.5 8.30898 15.0993 8.55301 14.5319M4.81574 11.8998H3.00561C2.51755 11.8998 2.07129 11.6918 1.78381 11.3278C1.49967 10.9653 1.42445 10.5142 1.57822 10.089L2.65795 8.04423C2.87858 7.44012 2.98889 6.81155 2.98889 6.17532V5.47639C2.98889 3.28326 4.60347 1.5 7.00027 1.5C9.39707 1.5 11.0117 3.28326 11.0117 5.47639V6.17532C11.0117 6.81155 11.122 7.43859 11.3409 8.0427L12.4206 10.0875C12.5761 10.5126 12.5009 10.9638 12.2151 11.3278C11.9293 11.6918 11.4847 11.8998 10.9949 11.8998H4.81574Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
            </div>

            <div className="px-5">
              {/* <button className="flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] text-white pr-4">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.7"
                    d="M8.15722 19.7714V16.7047C8.1572 15.9246 8.79312 15.2908 9.58101 15.2856H12.4671C13.2587 15.2856 13.9005 15.9209 13.9005 16.7047V16.7047V19.7809C13.9003 20.4432 14.4343 20.9845 15.103 21H17.0271C18.9451 21 20.5 19.4607 20.5 17.5618V17.5618V8.83784C20.4898 8.09083 20.1355 7.38935 19.538 6.93303L12.9577 1.6853C11.8049 0.771566 10.1662 0.771566 9.01342 1.6853L2.46203 6.94256C1.86226 7.39702 1.50739 8.09967 1.5 8.84736V17.5618C1.5 19.4607 3.05488 21 4.97291 21H6.89696C7.58235 21 8.13797 20.4499 8.13797 19.7714V19.7714"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="text-[15px]">Dashboard</span>
              </button> */}

              {(appConfig?.isSuperAdmin || appConfig?.features['Members']) && (
                <NavLink
                  to="/admin/members"
                  className={({
                    isActive,
                  }) => `flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] pr-4 
                  ${isActive ? 'bg-[#F7ED00] text-black !font-bold' : 'text-white'}`}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.60201 13.1785C11.291 13.1785 14.444 13.7375 14.444 15.9705C14.444 18.2035 11.312 18.7785 7.60201 18.7785C3.91201 18.7785 0.76001 18.2245 0.76001 15.9905C0.76001 13.7565 3.89101 13.1785 7.60201 13.1785Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.60206 9.99144C5.18006 9.99144 3.21606 8.02844 3.21606 5.60644C3.21606 3.18444 5.18006 1.22144 7.60206 1.22144C10.0231 1.22144 11.9871 3.18444 11.9871 5.60644C11.9961 8.01944 10.0461 9.98244 7.63306 9.99144H7.60206Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.4937 8.85321C16.0947 8.62821 17.3277 7.25421 17.3307 5.59121C17.3307 3.95221 16.1357 2.59221 14.5687 2.33521"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.606 12.7039C18.157 12.9349 19.24 13.4789 19.24 14.5989C19.24 15.3699 18.73 15.8699 17.906 16.1829"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>

                  <span className="text-[15px]">Members</span>
                </NavLink>
              )}

              {/* <button className="flex gap-2.5 items-center w-full font-medium rounded-lg py-3.5 pl-[30px] text-white pr-4">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.7">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.60207 13.1786C11.2911 13.1786 14.4441 13.7376 14.4441 15.9706C14.4441 18.2036 11.3121 18.7786 7.60207 18.7786C3.91207 18.7786 0.760071 18.2246 0.760071 15.9906C0.760071 13.7566 3.89107 13.1786 7.60207 13.1786Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.60206 9.99156C5.18006 9.99156 3.21606 8.02856 3.21606 5.60656C3.21606 3.18456 5.18006 1.22156 7.60206 1.22156C10.0231 1.22156 11.9871 3.18456 11.9871 5.60656C11.9961 8.01956 10.0461 9.98256 7.63306 9.99156H7.60206Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.4937 8.85333C16.0947 8.62833 17.3277 7.25433 17.3307 5.59133C17.3307 3.95233 16.1357 2.59233 14.5687 2.33533"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.606 12.704C18.157 12.935 19.24 13.479 19.24 14.599C19.24 15.37 18.73 15.87 17.906 16.183"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>

                <span className="text-[15px]">Admins</span>
              </button> */}
            </div>
          </div>

          <div className="flex flex-col gap-10">
            <div className="pl-12 ">
              <div className="flex items-center gap-3">
                <h3 className="text-lg leading-tight text-white max-w-[150px] truncate">
                  {profile?.name ?? 'Michael Johnson'}
                </h3>
              </div>

              <p className="text-xs text-white text-opacity-50">{profile?.role?.name}</p>
            </div>

            <div>
              {appConfig?.isSuperAdmin && (
                <Link
                  to="/admin/preferences"
                  className="flex items-center justify-between w-full px-10 py-4 text-white bg-black border-t border-white bg-opacity-20 border-opacity-20"
                >
                  <span>Preferences</span>

                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g opacity="0.7">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.7526 6.45689L17.1577 5.44546C16.6545 4.58964 15.5391 4.2944 14.6644 4.78546V4.78546C14.2481 5.02577 13.7513 5.09395 13.2835 4.97496C12.8158 4.85597 12.4156 4.55959 12.1712 4.15117C12.0139 3.89157 11.9294 3.59589 11.9262 3.29403V3.29403C11.9404 2.81007 11.754 2.34108 11.4096 1.99392C11.0652 1.64675 10.592 1.45097 10.0978 1.45117H8.89928C8.41516 1.45117 7.95099 1.64018 7.60948 1.97639C7.26798 2.31259 7.07729 2.76827 7.07962 3.2426V3.2426C7.06527 4.22192 6.25085 5.00842 5.25121 5.00831C4.94312 5.00518 4.64134 4.92239 4.37638 4.76832V4.76832C3.50169 4.27725 2.38637 4.57249 1.8831 5.42832L1.24447 6.45689C0.741798 7.31164 1.03903 8.40371 1.90934 8.89974V8.89974C2.47506 9.21975 2.82355 9.81115 2.82355 10.4512C2.82355 11.0912 2.47506 11.6826 1.90934 12.0026V12.0026C1.04013 12.4953 0.742579 13.5847 1.24447 14.4369V14.4369L1.8481 15.4569C2.08391 15.8738 2.47955 16.1814 2.94748 16.3117C3.41541 16.442 3.91705 16.3842 4.34138 16.1512V16.1512C4.75853 15.9127 5.25563 15.8473 5.72219 15.9697C6.18874 16.092 6.58609 16.3919 6.82592 16.8026C6.98317 17.0622 7.06767 17.3579 7.07087 17.6597V17.6597C7.07087 18.6491 7.88948 19.4512 8.89928 19.4512H10.0978C11.1042 19.4512 11.9214 18.6543 11.9262 17.6683V17.6683C11.9239 17.1925 12.1158 16.7355 12.4592 16.3991C12.8026 16.0626 13.269 15.8746 13.7546 15.8769C14.062 15.8849 14.3625 15.9674 14.6295 16.1169V16.1169C15.5019 16.6094 16.6165 16.3182 17.1227 15.4655V15.4655L17.7526 14.4369C17.9964 14.0269 18.0633 13.5385 17.9386 13.08C17.8138 12.6215 17.5076 12.2307 17.0877 11.994V11.994C16.6679 11.7574 16.3617 11.3665 16.2369 10.908C16.1121 10.4495 16.1791 9.96119 16.4229 9.55117C16.5814 9.27996 16.8109 9.05509 17.0877 8.89974V8.89974C17.9528 8.40398 18.2494 7.31829 17.7526 6.46546V6.46546V6.45689Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <ellipse
                        cx="9.50293"
                        cy="10.451"
                        rx="2.51953"
                        ry="2.46857"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </Link>
              )}

              <Link
                to="/members"
                className="flex items-center justify-between w-full px-10 py-4 text-white bg-black border-t border-white bg-opacity-20 border-opacity-20"
              >
                <span>Open platform</span>

                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.7">
                    <path
                      d="M12.1123 9.45163H5.8877"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.60156 6.95172L12.1121 9.45151L9.60156 11.9517"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <rect x="0.5" y="0.951721" width="17" height="17" rx="6.5" stroke="white" />
                  </g>
                </svg>
              </Link>

              <button
                className="flex items-center justify-between w-full px-10 py-4 text-white bg-black border-t border-white bg-opacity-20 border-opacity-20"
                onClick={logoutHandler}
              >
                <span>Logout</span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div className="h-screen w-full overflow-y-scroll custom-scrollbar py-[40px]">
          <div className="max-w-[1600px] w-[85%] mx-auto">{children}</div>
        </div>
      </div>
    </>
  );
};

interface AdminLayoutProps {
  children: ReactNode;
}
