import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts';
import { useHttp, useNotification } from 'src/common/hooks';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

export const SignupFormSuperAdmin = () => {
  const navigate = useNavigate();
  const { profile, appConfig, accessToken, fetchProfile } = useContext(AuthContext);
  const notify = useNotification();
  const { sendRequest: createUser } = useHttp();

  const createNewUser = async () => {
    await createUser(
      {
        url: 'user/create-super-admin',
        method: 'POST',
        accessToken,
        payload: {},
      },
      (data: any) => {
        notify('Admin created!', 'success');
        fetchProfile();
        navigate('/admin/members');
      },
    );
  };

  useEffect(() => {
    if (accessToken && appConfig && !appConfig.isRegistered) {
      createNewUser();
    }
  }, [accessToken, appConfig]);

  return (
    <>
      <div>in progress</div>
      <LoadingSpinner />
    </>
  );
};
