export const SignupCompletedPopup = ({ onClose }: SignupCompletedPopupProps) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50">
      <div className="w-[480px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col">
        {/* <button className="absolute right-3 top-3" onClick={onClose}>
          <svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
              fill="#E5E5E5"
            />
            <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button> */}
        <h2 className="text-2xl text-black text-center font-bold font-[sohne-breit] tracking-wide mb-4">
          Thank you for <br />
          applying!
        </h2>
        <p className="leading-tight text-md text-center text-black text-opacity-70">
          We will analyse your membership <br />
          application and <strong>get back to you in 48h. </strong>
        </p>
      </div>
    </div>
  );
};

interface SignupCompletedPopupProps {
  onClose: () => void;
}
