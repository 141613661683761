import { Link } from 'react-router-dom';
import { ClubCard } from './ClubCard';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { useHttp, useNotification } from 'src/common/hooks';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { Club } from 'src/common/types/clubs';

import Select from 'react-select';
import { Option } from 'src/common/types/event';
import { queryParams } from 'src/common/utils';
import { Pagination } from '../pagination/Pagination';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Joined', value: 'joined' },
  { label: 'Not Joined', value: 'not_joined' },
];

const orderByOptions = [
  { label: 'Post Date', value: 'createdAt' },
  { label: 'Name', value: 'name' },
];

export default function Clubs() {
  const notify = useNotification();
  const { accessToken, profile, appConfig } = useContext(AuthContext);

  const [clubs, setClubs] = useState<Club[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [filterValue, setFilterValue] = useState<Option | null>({
    value: 'all',
    label: 'All',
  });

  const { sendRequest: getClubs, isLoading: isGettingClubs, error: errorGettingClubs } = useHttp();

  useEffect(() => {
    getClubsHandler();
  }, [filterValue, orderByValue, currentPage]);

  useEffect(() => {
    if (errorGettingClubs) {
      notify(errorGettingClubs, 'error');
    }
  }, [errorGettingClubs]);

  const getClubsHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        type: filterValue?.value,
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
      offset,
      limit: perPage,
    };

    await getClubs(
      {
        url: 'clubs?' + queryParams(params),
        method: 'GET',
        accessToken,
      },

      (res: any) => {
        setClubs(res.data);
        setTotalCount(res.total);
      },
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const createAndJoinClub =
    appConfig?.parentId === null ||
    appConfig?.features['Clubs'].subFeatures.includes('Create and join clubs');

  return (
    <>
      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-opacity-50 font-[sohne-breit] leading-tight ">
            COMMUNITY
          </span>
          <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">Clubs</h1>
        </div>

        {createAndJoinClub && (
          <Link
            to="/clubs/create"
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Create a club
          </Link>
        )}
      </div>

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          {/* <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Domain</span>
            <Select
              value={{ label: 'All', value: 'date' }}
              isSearchable={false}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div> */}

          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Type</span>

            <Select
              value={filterValue}
              isSearchable={false}
              onChange={selectedOptions => {
                setFilterValue(selectedOptions);
              }}
              options={filterOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[100px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>

            <Select
              value={orderByValue}
              options={orderByOptions}
              onChange={selectedOptions => {
                setOrderByValue(selectedOptions);
              }}
              isSearchable={false}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex gap-2.5 flex-wrap">
        {clubs.map(club => (
          <ClubCard club={club} profile={profile} key={club.id} />
        ))}
      </div>

      {clubs.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {!clubs.length && !isGettingClubs && (
        <div className={`text-center block p-8`}>No records found</div>
      )}

      {isGettingClubs && <LoadingSpinner />}
    </>
  );
}
