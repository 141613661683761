import { useHttp, useNotification } from 'src/common/hooks';
import { ClubCreatedActivity } from './ClubCreatedActivity';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from 'src/contexts';
import { Activity } from 'src/common/types/activity';
import { EventCreatedActivity } from './EventCreatedActivity';

import Select from 'react-select';
import { Option } from 'src/common/types/event';
import { queryParams } from 'src/common/utils';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { Pagination } from '../pagination/Pagination';

const filterOptions = [
  { label: 'All', value: 'all' },
  { label: 'Only from my circles', value: 'only_from_my_circles' },
];

const orderByOptions = [{ label: 'Post Date', value: 'createdAt' }];

export default function ActivityFeed() {
  const notify = useNotification();
  const { accessToken, profile } = useContext(AuthContext);
  const [activities, setActivities] = useState<Activity[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 12;

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [filterValue, setFilterValue] = useState<Option | null>(filterOptions[0]);

  const {
    sendRequest: getActivities,
    isLoading: isGettingActivities,
    error: errorGettingActivities,
  } = useHttp();

  useEffect(() => {
    getActivitiesHandler();
  }, [filterValue, orderByValue, currentPage]);

  useEffect(() => {
    if (errorGettingActivities) {
      notify(errorGettingActivities, 'error');
    }
  }, [errorGettingActivities]);

  const getActivitiesHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      filter: {
        type: filterValue?.value,
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
      offset,
      limit: perPage,
    };

    await getActivities(
      {
        url: 'activities?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setActivities(res.data);
        setTotalCount(res.total);
      },
    );
  };

  const ActivityContent = ({ activity }: { activity: Activity }) => {
    switch (activity.action) {
      case 'club_created':
        return <ClubCreatedActivity activity={activity} />;
      case 'event_created':
        return <EventCreatedActivity activity={activity} />;
      default:
        return null;
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="max-w-[588px] mx-auto">
        <h1 className="text-4xl font-bold leading-none font-[sohne-breit] mb-8">Activity Feed</h1>

        <div className="flex items-center justify-between mb-12">
          <div className="flex items-center gap-4">
            <div className="text-sm flex items-center gap-1.5">
              <span className="text-black text-opacity-70">Type</span>

              <Select
                value={filterValue}
                isSearchable={false}
                onChange={selectedOptions => {
                  setFilterValue(selectedOptions);
                }}
                options={filterOptions}
                classNames={{
                  indicatorsContainer: () => '!p-0 !text-black',
                  valueContainer: () => '!p-0',
                  singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                  control: () =>
                    '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[180px]',
                  indicatorSeparator: () => '!w-0',
                  menu: () => '!min-w-[110px] !m-0',
                  menuList: () => '!p-0',
                }}
              />
            </div>

            {/* <div className="text-sm flex items-center gap-1.5">
              <span className="text-black text-opacity-70">Social Network</span>

              <Select
                value={{ label: 'All', value: 'date' }}
                isSearchable={false}
                classNames={{
                  indicatorsContainer: () => '!p-0 !text-black',
                  valueContainer: () => '!p-0',
                  singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                  control: () =>
                    '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                  indicatorSeparator: () => '!w-0',
                  menu: () => '!min-w-[110px] !m-0',
                  menuList: () => '!p-0',
                }}
              />
            </div>

            <div className="text-sm flex items-center gap-1.5">
              <span className="text-black text-opacity-70">Members</span>
              <Select
                value={{ label: 'All', value: 'date' }}
                isSearchable={false}
                classNames={{
                  indicatorsContainer: () => '!p-0 !text-black',
                  valueContainer: () => '!p-0',
                  singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                  control: () =>
                    '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                  indicatorSeparator: () => '!w-0',
                  menu: () => '!min-w-[110px] !m-0',
                  menuList: () => '!p-0',
                }}
              />
            </div> */}
          </div>

          <div className="text-sm">
            <div className="text-sm flex items-center gap-1.5">
              <span className="text-black text-opacity-70">Sort by</span>

              <Select
                value={orderByValue}
                isSearchable={false}
                onChange={selectedOptions => {
                  setOrderByValue(selectedOptions);
                }}
                options={orderByOptions}
                classNames={{
                  indicatorsContainer: () => '!p-0 !text-black',
                  valueContainer: () => '!p-0',
                  singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                  control: () =>
                    '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                  indicatorSeparator: () => '!w-0',
                  menu: () => '!min-w-[110px] !m-0',
                  menuList: () => '!p-0',
                }}
              />
            </div>
          </div>
        </div>

        {activities.map((activity, index) => (
          <ActivityContent key={index} activity={activity} />
        ))}

        {activities.length > 0 && (
          <div className="mt-5 overflow-hidden bg-white w-fit">
            <Pagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={perPage}
              onPageChange={(page: number) => handlePageChange(page)}
            />
          </div>
        )}

        {!activities.length && !isGettingActivities && (
          <div className={`text-center block p-8`}>No records found</div>
        )}

        {isGettingActivities && <LoadingSpinner />}
      </div>
    </>
  );
}
