import { useContext, useEffect, useState } from 'react';
import { formatDate, getInitials, queryParams } from 'src/common/utils';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { Member } from 'src/common/types/sub-account';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { CreateAdminAccountPopup } from './CreateAdminAccountPopup';
import { Pagination } from 'src/components/pagination/Pagination';
import { EditAdminAccountPopup } from './EditAdminAccountPopup';
import { DeleteUserPopup } from 'src/components/popups/DeleteUserPopup';

export const Admins = () => {
  const { accessToken, profile } = useContext(AuthContext);

  const [showCreateAdminPopup, setShowCreateAdminPopup] = useState(false);
  const [showEditSubAccountPopup, setShowEditAccountPopup] = useState(false);
  const [showDeleteAccountPopup, setShowDeleteAccountPopup] = useState(false);
  const [selectedMember, setSelectedMember] = useState<Member>();
  const [admins, setAdmins] = useState<Member[]>([]);
  const notify = useNotification();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 10;

  const {
    sendRequest: getSubAccounts,
    isLoading: isGettingSubAccounts,
    error: errorGettingSubAccounts,
  } = useHttp();

  useEffect(() => {
    if (accessToken && profile && profile?.id) {
      getAdminHandler();
    }
  }, [accessToken, profile, currentPage]);

  useEffect(() => {
    if (errorGettingSubAccounts) {
      notify(errorGettingSubAccounts, 'error');
    }
  }, [errorGettingSubAccounts]);

  const getAdminHandler = async () => {
    const offset = (currentPage - 1) * perPage;

    const params = {
      sort: {
        createdAt: 'DESC',
      },
      offset,
      limit: perPage,
    };

    await getSubAccounts(
      {
        url: `user/admins?` + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        setAdmins(res.data);
        setTotalCount(res.total);
      },
    );
  };

  const updateAccount = (member: Member) => {
    setSelectedMember(member);
    setShowEditAccountPopup(true);
  };

  const deleteAccount = (member: Member) => {
    setSelectedMember(member);
    setShowDeleteAccountPopup(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="bg-white border border-[#E5E5E5] rounded-[15px] rounded-tl-none">
        <div className="p-5 pl-[50px] flex items-center justify-between border-b border-[#D6D6D6]">
          <h5 className="font-bold text-lg text-black font-[sohne-breit]">Admins</h5>

          <button
            onClick={() => setShowCreateAdminPopup(true)}
            className="px-5 py-[15px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          >
            Add admin
          </button>
        </div>

        {showCreateAdminPopup && (
          <CreateAdminAccountPopup
            onClose={() => {
              setShowCreateAdminPopup(false);
              getAdminHandler();
            }}
          />
        )}

        {showEditSubAccountPopup && selectedMember && (
          <EditAdminAccountPopup
            member={selectedMember}
            onClose={() => {
              setSelectedMember(undefined);
              setShowEditAccountPopup(false);
              getAdminHandler();
            }}
          />
        )}

        {showDeleteAccountPopup && selectedMember && (
          <DeleteUserPopup
            userId={selectedMember.id}
            onClose={() => setShowDeleteAccountPopup(false)}
            onDelete={() => {
              setShowDeleteAccountPopup(false);
              getAdminHandler();
            }}
          />
        )}

        {!isGettingSubAccounts && !admins.length && profile && (
          <div className="text-center text-black py-[50px]">
            <h4 className="mb-3 text-2xl font-bold">Create admin</h4>

            <p className="text-black text-opacity-70 max-w-[580px] mx-auto mb-5">
              Save time by delegating specific actions of your account to employees or friends. You
              can fine-tune their access rights, see every action they take and revoke their access
              at any time.
            </p>

            <button
              onClick={() => setShowCreateAdminPopup(true)}
              className="px-5 py-[13px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity border border-[#005AFF]"
            >
              Add admin
            </button>
          </div>
        )}

        {admins.length > 0 && (
          <div className="pl-[50px] pb-[65px]">
            <table className="min-w-full">
              <thead className="text-black text-opacity-50 border-b border-[#E5E5E5]">
                <tr>
                  <th className="py-5 text-xs font-medium text-left">
                    <span className="border-b border-black border-opacity-50">
                      Name &amp; Email
                    </span>
                  </th>

                  <th className="px-6 py-5 text-xs font-medium text-left w-[100px]">
                    <span className="text-black text-opacity-100 border-b border-black">
                      Added on
                    </span>
                  </th>

                  <th className="w-fit"></th>
                </tr>
              </thead>

              <tbody className="leading-tight text-[14px]">
                {admins.map((member, index) => {
                  return (
                    <tr className="border-b" key={index}>
                      <td className="relative py-4">
                        <div className="rounded-full h-7 w-7 bg-[#DCE3EB] font-bold text-xs flex items-center justify-center absolute -left-9 inset-y-0 m-auto">
                          {member.avatar ? (
                            <div className="flex items-center justify-center w-full h-full">
                              <img
                                src={member.avatar}
                                className="object-cover w-full h-full rounded-full"
                                alt="Profile Avatar"
                              />
                            </div>
                          ) : (
                            <div>{getInitials(member.name)}</div>
                          )}
                        </div>

                        <div className="block text-lg font-bold text-black underline truncate">
                          {member?.name}
                        </div>

                        <div className="text-[13px] truncate">{member?.email}</div>
                      </td>

                      <td className="px-6 py-4 truncate w-fit">{formatDate(member?.createdAt)}</td>

                      <td className="z-10 flex items-center gap-5 px-2 py-4 w-fit">
                        <DropdownMenu
                          member={member}
                          updateAccount={updateAccount}
                          deleteAccount={deleteAccount}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {admins.length > 0 && (
        <div className="mt-5 overflow-hidden bg-white w-fit">
          <Pagination
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={perPage}
            onPageChange={(page: number) => handlePageChange(page)}
          />
        </div>
      )}

      {isGettingSubAccounts && <LoadingSpinner />}
    </>
  );
};

interface DropdownMenuProps {
  member: Member;
  updateAccount: (member: Member) => void;
  deleteAccount: (member: Member) => void;
}

const DropdownMenu = ({ member, updateAccount, deleteAccount }: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdate = () => {
    updateAccount(member);
    setIsOpen(false);
  };

  const handleDelete = () => {
    deleteAccount(member);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        type="button"
        className="z-0 inline-flex items-center justify-center w-6"
        aria-label="Options"
      >
        <svg
          width="3"
          height="19"
          viewBox="0 0 3 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="black" />
          <circle cx="1.5" cy="1.5" r="1.5" fill="black" fillOpacity="0.2" />
          <circle cx="1.5" cy="9.5" r="1.5" fill="black" />
          <circle cx="1.5" cy="9.5" r="1.5" fill="black" fillOpacity="0.2" />
          <circle cx="1.5" cy="17.5" r="1.5" fill="black" />
          <circle cx="1.5" cy="17.5" r="1.5" fill="black" fillOpacity="0.2" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-10 w-40 mt-2 origin-top-right bg-white border rounded-md shadow-lg focus:outline-none">
          <div className="py-1">
            <button
              onClick={handleUpdate}
              className="block w-full px-4 py-2 text-sm text-left text-black hover:bg-gray-100"
              role="menuitem"
            >
              Edit
            </button>

            <button
              onClick={handleDelete}
              className="block w-full px-4 py-2 text-sm text-left text-red-600 hover:bg-gray-100"
              role="menuitem"
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
