import { User } from 'src/common/types/user';
import { formatDate } from 'src/common/utils';

interface GeneralTabProps {
  member: User;
}
export const GeneralTab = ({ member }: GeneralTabProps) => {
  return (
    <>
      <div className="bg-white border border-[#E5E5E5] rounded-md p-16 text-black shadow">
        <div className="max-w-[600px] w-8/10">
          <div className="border-b border-[#E5E5E5] pb-10">
            <h1 className="font-[sohne-breit] text-2xl mb-3.5">About</h1>
            <p className="leading-snug text-opacity-70">{member?.bio}</p>
          </div>
          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Email</div>
            <div className="text-lg font-bold">{member.email}</div>
          </div>

          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Location</div>
            <div className="text-lg font-bold">{member?.country?.name}</div>
          </div>

          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Invited by</div>
            <div className="text-lg font-bold">{member.parent?.name ?? 'Michael Johnson'}</div>
          </div>

          <div className="py-7 border-b border-[#E5E5E5]">
            <div>Member since</div>
            <div className="text-lg font-bold">{formatDate(member.createdAt)}</div>
          </div>
        </div>
      </div>
    </>
  );
};
