import { useContext, useEffect, useState } from 'react';
import { CreateCirclePopup } from '../popups/CreateCirclePopup';
import { ToastContainer } from 'react-toastify';
import { useHttp, useNotification } from 'src/common/hooks';
import { AuthContext } from 'src/contexts';
import { queryParams } from 'src/common/utils';
import { LoadingSpinner } from 'src/common/components/loading-spinner/LoadingSpinner';
import { Circle, CircleUser } from 'src/common/types/circle';
import { CircleCard } from './CircleCard';

import Select from 'react-select';
import { Option } from 'src/common/types/event';
import { Skill } from 'src/common/types/skill';

const orderByOptions = [
  { label: 'Create Date', value: 'createdAt' },
  { label: 'Name', value: 'name' },
];

export default function Circles() {
  const [showCreateCirclePopup, setShowCreateCirclePopup] = useState(false);
  const notify = useNotification();
  const { accessToken, profile } = useContext(AuthContext);
  const [circles, setCircles] = useState<Circle[]>([]);
  const [skillsOptions, setSkillsOptions] = useState<Option[]>([]);
  const [connectionsCount, setConnectionsCount] = useState<number>();

  const {
    sendRequest: getCircles,
    isLoading: isGettingCircles,
    error: errorGettingCircles,
  } = useHttp();

  const [orderByValue, setOrderByValue] = useState<Option | null>(orderByOptions[0]);
  const [orderBySkillValue, setOrderBySkillValue] = useState<Option | null>({
    value: 'all',
    label: 'All',
  });

  const { sendRequest: getSkills, isLoading: isGettingSkills } = useHttp();

  useEffect(() => {
    if (accessToken) {
      getCirclesHandler();
      getSkillsHandler();
    }
  }, [accessToken, profile, orderBySkillValue, orderByValue]);

  useEffect(() => {
    if (errorGettingCircles) {
      notify(errorGettingCircles, 'error');
    }
  }, [errorGettingCircles]);

  const getCirclesHandler = async () => {
    const params = {
      filter: {
        userId: profile?.id,
        skillId: orderBySkillValue?.value !== 'all' ? orderBySkillValue?.value : '',
      },
      sort: {
        [orderByValue?.value || 'createdAt']: 'DESC',
      },
    };

    await getCircles(
      {
        url: 'circle?' + queryParams(params),
        method: 'GET',
        accessToken,
      },
      (circles: any) => {
        setCircles(circles);

        const uniqueUserIds = new Set();

        circles
          .filter((circle: Circle) => circle.owner.id === profile?.id)
          .forEach((circle: Circle) => {
            circle.users.forEach((circleUser: CircleUser) => {
              uniqueUserIds.add(circleUser.user.id);
            });
          });

        setConnectionsCount(uniqueUserIds.size);
      },
    );
  };

  const getSkillsHandler = async () => {
    await getSkills(
      {
        url: 'skills',
        method: 'GET',
        accessToken,
      },
      (res: any) => {
        const transformedSkills = res.map((skill: Skill) => ({
          value: skill.id,
          label: skill.name,
        }));

        const allOption = { value: 'all', label: 'All' };
        setSkillsOptions([allOption, ...transformedSkills]);
      },
    );
  };

  return (
    <>
      <ToastContainer autoClose={8000} />

      <div className="flex items-end justify-between mb-6">
        <div>
          <span className="text-xs text-opacity-50 font-[sohne-breit] leading-tight ">
            COMMUNITY
          </span>

          <div className="flex items-center gap-2.5">
            <h1 className="text-4xl font-bold leading-none font-[sohne-breit]">My Circles</h1>

            <div className="text-xs py-[8px] px-[8px] leading-none bg-[#E5E5E5] rounded-lg w-fit font-bold">
              {circles?.length} circles
            </div>

            <div className="text-xs py-[8px] px-[8px] leading-none bg-[#E5E5E5] rounded-lg w-fit font-bold">
              {connectionsCount} connections
            </div>
          </div>
        </div>

        <button
          className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#005AFF] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
          onClick={() => setShowCreateCirclePopup(true)}
        >
          Create circle
        </button>
      </div>

      {showCreateCirclePopup && (
        <CreateCirclePopup
          onClose={() => setShowCreateCirclePopup(false)}
          onCreated={() => getCirclesHandler()}
        />
      )}

      <div className="flex items-center justify-between mb-11">
        <div className="flex items-center gap-4">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Skills</span>
            <Select
              value={orderBySkillValue}
              options={skillsOptions}
              isSearchable={false}
              onChange={selectedOptions => {
                setOrderBySkillValue(selectedOptions);
              }}
              isDisabled={isGettingSkills}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>

          {/* <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Position</span>
            <span className="bg-white px-2.5 py-[4px] text-xs border border-[#E5E5E5] rounded-xl">
              CEO & Management
            </span>
            <svg
              width="10"
              height="7"
              viewBox="0 0 10 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L5 5.5L9 1.5"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Status</span>
            <Select
              value={{ label: 'All', value: 'date' }}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div> */}
        </div>

        <div className="text-sm">
          <div className="text-sm flex items-center gap-1.5">
            <span className="text-black text-opacity-70">Order by</span>

            <Select
              value={orderByValue}
              onChange={selectedOptions => {
                setOrderByValue(selectedOptions);
              }}
              options={orderByOptions}
              classNames={{
                indicatorsContainer: () => '!p-0 !text-black',
                valueContainer: () => '!p-0',
                singleValue: () => '!text-[#005AFF] !border-b !border-[#005AFF] !font-semibold',
                control: () =>
                  '!bg-transparent !border-none !border-transparent !shadow-none !max-w-[120px]',
                indicatorSeparator: () => '!w-0',
                menu: () => '!min-w-[110px] !m-0',
                menuList: () => '!p-0',
              }}
            />
          </div>
        </div>
      </div>

      {!circles.length && !isGettingCircles && (
        <div className={`text-center block p-8`}>No records found</div>
      )}

      {circles.map(circle => (
        <CircleCard circle={circle} key={circle.id} getCircles={() => getCirclesHandler()} />
      ))}

      {isGettingCircles && <LoadingSpinner />}
    </>
  );
}
