import { DeleteCoWorkerPopup } from '../popups/DeleteCoWorkerPopup';
import { useState } from 'react';
import { MemberMeetingPopup } from '../popups/MemberMeetingPopup';
import { getInitials } from 'src/common/utils';
import { User } from 'src/common/types/user';

interface CircleMemberCardProps {
  member: User;
  circle?: Circle;
  getCircles?: () => void;
}

interface Circle {
  id: string;
  name: string;
}

export const CircleMemberCard = ({ member, circle, getCircles }: CircleMemberCardProps) => {
  const [showDeleteCoWorkerPopup, setShowDeleteCoWorkerPopup] = useState(false);
  const [showMeetingPopup, setShowMeetingPopup] = useState(false);

  return (
    <div className="relative flex justify-between mb-3 bg-white rounded-3xl" key={member.id}>
      <div className="absolute rounded-full -left-5 w-[60px] h-[60px] overflow-hidden border-[5px] border-white my-auto inset-y-0 flex items-center justify-center bg-blue-200 font-bold text-lg">
        {member.avatar ? (
          <div className="flex items-center justify-center w-full h-full">
            <img
              src={member.avatar}
              className="object-cover w-full h-full rounded-full"
              alt="Profile Avatar"
            />
          </div>
        ) : (
          <div>{getInitials(member.name)}</div>
        )}
      </div>

      <div className="py-[25px] pl-[50px]">
        <div className="leading-none">
          <span className="font-bold text-black text-[19px] mr-2.5">
            {member.name ?? 'John Doe'}
          </span>
          <span className="text-[12px] text-[#CD9400]">Gold Level Member</span>
        </div>

        <div className="text-base text-black">{member.company?.role}</div>
      </div>

      <div className="flex">
        <div className="flex flex-wrap gap-1 text-[#325EAE] items-center pr-[20px] py-[25px]">
          {member.skills.map((tag, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE33] font-medium bg-white"
              key={index}
            >
              {tag.name}
            </div>
          ))}
        </div>

        {circle && (
          <div className="px-[12px] border-l border-[#E5E5E5] flex items-center py-[25px]">
            <button onClick={() => setShowDeleteCoWorkerPopup(true)}>
              <svg
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="22" height="22" rx="4" fill="#C51C00" fillOpacity="0.05" />
                <path d="M8.5 9L13.5 14" stroke="black" strokeLinecap="round" />
                <path d="M13.5 9L8.5 14" stroke="black" strokeLinecap="round" />
              </svg>
            </button>
          </div>
        )}

        {showDeleteCoWorkerPopup && circle && getCircles && (
          <DeleteCoWorkerPopup
            circle={circle}
            member={member}
            onClose={() => setShowDeleteCoWorkerPopup(false)}
            onDeleted={getCircles}
          />
        )}

        {showMeetingPopup && (
          <MemberMeetingPopup member={member} onClose={() => setShowMeetingPopup(false)} />
        )}

        <div className="px-[12px] border-l border-[#E5E5E5] flex items-center py-[25px] bg-[#005AFF05]">
          <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.8">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.6571 14.1559C12.2122 16.6011 8.59182 17.1294 5.62913 15.7592C5.19177 15.5831 4.83319 15.4408 4.4923 15.4408C3.54279 15.4464 2.36093 16.3671 1.74669 15.7536C1.13244 15.1393 2.05381 13.9565 2.05381 13.0012C2.05381 12.6603 1.91713 12.3081 1.74106 11.8699C0.370265 8.9077 0.899287 5.28615 3.34421 2.84177C6.46528 -0.280454 11.536 -0.280454 14.6571 2.84096C17.7838 5.96801 17.7782 11.0345 14.6571 14.1559Z"
                stroke="#00369A"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.1513 8.83066H12.1585"
                stroke="#00369A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.94434 8.83008H8.95154"
                stroke="#00369A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.73722 8.83066H5.74442"
                stroke="#00369A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </div>

        <div className="px-[12px] border-l border-[#E5E5E5] flex items-center py-[25px] bg-[#005AFF05] rounded-r-3xl">
          <button onClick={() => setShowMeetingPopup(true)}>
            <svg
              width="18"
              height="13"
              viewBox="0 0 18 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M9.8999 1.35742H2.6999C1.70579 1.35742 0.899902 2.12493 0.899902 3.07171V9.92885C0.899902 10.8756 1.70579 11.6431 2.6999 11.6431H9.8999C10.894 11.6431 11.6999 10.8756 11.6999 9.92885V3.07171C11.6999 2.12493 10.894 1.35742 9.8999 1.35742Z"
                  fill="white"
                  stroke="#00369A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.3102 9.8601L11.7002 8.21438V4.78581L14.3102 3.1401C15.4982 2.38581 17.1002 3.19153 17.1002 4.56295V8.43724C17.1002 9.80867 15.4982 10.6315 14.3102 9.8601Z"
                  fill="white"
                  stroke="#00369A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
