import {useContext} from "react";
import {AuthContext} from "../../contexts";

export const Locked = () => {
  const { appConfig } = useContext(AuthContext);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-50">
      <div className="w-[480px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col">
        {
          appConfig && (appConfig.isApproved === null) ?
            (
              <>
                <h2 className="text-2xl text-black text-center font-bold font-[sohne-breit] tracking-wide mb-4">
                  Thank you for <br />
                  applying! Your account is Locked
                </h2>
                <p className="leading-tight text-md text-center text-black text-opacity-70">
                  We are analysing your membership <br />
                  application and <strong>get back to you in 48h. </strong>
                </p>
              </>
            ):
              (
                <>
                  <h2 className="text-2xl text-black text-center font-bold font-[sohne-breit] tracking-wide mb-4">
                    We are very sorry <br />
                    Your account is Rejected
                  </h2>
                  <p className="leading-tight text-md text-center text-black text-opacity-70">
                    Please contact support: support@tmrw100.org for further information
                  </p>
                </>
              )
        }

      </div>
    </div>
  );
};

interface SignupCompletedPopupProps {
  onClose: () => void;
}
