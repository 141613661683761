import moment from 'moment';
import { Link } from 'react-router-dom';
import { Activity } from 'src/common/types/activity';
import { Event } from 'src/common/types/event';
import { formatDateTime, getInitials, handleAddToCalendar } from 'src/common/utils';

export const EventCreatedActivity = ({
  activity,
  showUserInfo = true,
}: EventCreatedActivityProps) => {
  const event = activity.data as unknown as Event;

  return (
    <div className="rounded-2xl bg-white border border-[#E5E5E5] shadow-md mb-[10px]">
      <div className="bg-[#F8F9FB80] p-[10px] border-b border-[#E5E5E5] flex items-center rounded-t-2xl">
        <svg
          width="34"
          height="24"
          viewBox="0 0 34 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mr-[7px]"
        >
          <path
            d="M0 8C0 3.58172 3.58172 0 8 0H26C30.4183 0 34 3.58172 34 8V16C34 20.4183 30.4183 24 26 24H8C3.58172 24 0 20.4183 0 16V8Z"
            fill="#F7ED00"
          />
          <path
            opacity="0.7"
            d="M22.7647 9.3088C23.2773 8.66201 23.7864 8.40419 24.2811 8.53989C25.0806 8.76604 25.4391 9.95559 25.4785 10.0913C25.4928 10.141 25.5 10.1908 25.5 10.2451V13.5876C25.5 14.8133 24.7077 15.8129 23.7362 15.8129H10.7872C9.52531 15.8129 8.5 14.5193 8.5 12.9272V10.0415C8.5 9.97368 8.51434 9.90132 8.53585 9.83799C8.58604 9.71135 9.04133 8.60773 9.84795 8.5037C10.3499 8.44038 10.8374 8.77508 11.2891 9.50781M12.7984 15.8039V17.5M21.2231 15.8039V17.5M11.368 11.9276H22.6607V8.25493C22.6607 7.28701 22.0369 6.5 21.2697 6.5H12.5941C11.9201 6.5 11.368 7.19202 11.368 8.04688V11.9276Z"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <span className="text-[14px] text-black text-opacity-70 mr-5 leading-none">
          New event created
        </span>

        <span className="text-[13px] text-black leading-none">
          {formatDateTime(event?.createdAt ?? '')}
        </span>
      </div>

      {showUserInfo && (
        <div className="relative border-b border-[#E5E5E5]">
          <div className="absolute rounded-full -left-5 w-[60px] h-[60px] overflow-hidden border-[5px] border-white my-auto inset-y-0 flex items-center justify-center bg-blue-400 font-bold text-lg">
            {activity?.user?.avatar ? (
              <div className="flex items-center justify-center w-full h-full">
                <img
                  src={activity?.user?.avatar}
                  className="object-cover w-full h-full rounded-full"
                  alt="Profile Avatar"
                />
              </div>
            ) : (
              <div>{getInitials(activity?.user?.name)}</div>
            )}
          </div>

          <div className="py-[27px] pl-[50px]">
            <div className="flex items-center mb-1 leading-none">
              <span className="font-bold text-black text-[20px] mr-2.5">
                {activity.user?.name ?? 'Deleted User'}
              </span>

              <span className="text-[10px] bg-[#CD9400] p-[5px] rounded-md text-white font-[sohne-breit]">
                Gold
              </span>
            </div>

            <div className="text-base text-black">{activity.user?.company?.role}</div>
          </div>
        </div>
      )}

      <div className="px-[50px] py-[30px] text-[17px] text-black">
        <div className="flex items-center gap-4 mb-2.5">
          <div className="px-2.5 py-1.5 text-[13px] rounded-lg leading-none bg-[#0B1444] bg-opacity-10 w-fit text-black font-medium">
            {formatDateTime(event.startTime)}
          </div>

          <div className="flex items-center gap-1.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
              />
            </svg>

            <span className="text-sm text-black">{event.room?.roomName}</span>
          </div>
        </div>

        <h3 className="text-[20px] mb-[10px] font-bold leading-none">{event?.title}</h3>

        <p className="mb-[15px]">{event?.description}</p>

        <div className="flex flex-wrap gap-1 text-[#325EAE] items-center mb-[20px]">
          {event.topics.map((topic, index) => (
            <div
              className="border py-1.5 px-2.5 text-xs rounded-[10px] leading-none border-[#325EAE33] font-medium bg-white capitalize"
              key={index}
            >
              {topic.name}
            </div>
          ))}
        </div>

        {event.hosts.length > 0 && (
          <div className="flex items-center gap-2.5">
            <div className="flex">
              {event.hosts.slice(0, 4).map(host => (
                <div
                  className="rounded-full h-7 w-7 -ml-2.5 first:ml-0 flex items-center justify-center font-bold text-xs bg-blue-400"
                  key={host.id}
                >
                  {host?.user?.avatar ? (
                    <div className="flex items-center justify-center w-full h-full">
                      <img
                        src={host?.user?.avatar}
                        className="object-cover w-full h-full rounded-full"
                        alt="Profile Avatar"
                      />
                    </div>
                  ) : (
                    <div>{getInitials(host?.user?.name)}</div>
                  )}
                </div>
              ))}

              {event.hosts.length > 4 && (
                <div className="rounded-full h-7 min-w-7 -ml-2.5 bg-[#636989E5] text-white text-[12px] flex items-center justify-center p-1.5 font-bold">
                  +{event.hosts.length - 4}
                </div>
              )}
            </div>

            <div className="text-black mr-2.5 text-sm">
              <span className="font-black text-[15px]">{event.hosts.length} </span>
              <span>members</span>
            </div>
          </div>
        )}
      </div>

      <div className="bg-[#F8F9FB80] border-t border-[#E5E5E5] flex justify-between">
        <div className="py-[20px] pl-[30px] flex gap-8">
          <div className="flex items-center gap-[8px]">
            <button>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.5536 11.2158C9.54794 11.2158 12.1072 11.6934 12.1072 13.6012C12.1072 15.509 9.56498 16.0002 6.5536 16.0002C3.55846 16.0002 1 15.5269 1 13.6183C1 11.7096 3.54141 11.2158 6.5536 11.2158Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.55276 8.49274C4.58684 8.49274 2.99268 6.81563 2.99268 4.74637C2.99268 2.67711 4.58684 1 6.55276 1C8.51787 1 10.112 2.67711 10.112 4.74637C10.1193 6.80794 8.53654 8.48505 6.57792 8.49274H6.55276Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.1482 7.51989C13.4477 7.32766 14.4485 6.15377 14.451 4.73297C14.451 3.33267 13.481 2.17074 12.2091 1.95117"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.8618 10.8105C15.1208 11.0079 15.9998 11.4727 15.9998 12.4296C15.9998 13.0883 15.5859 13.5155 14.917 13.7829"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

            <span className="text-black font-bold leading-none text-[15px]">
              {event.hosts.length}
            </span>
          </div>
        </div>

        <div className="flex">
          {moment(event.endTime).isAfter(moment()) && (
            <button
              className="border-l border-[#E5E5E5] px-[25px] flex items-center justify-center"
              onClick={() => handleAddToCalendar(event)}
            >
              <svg
                width="16"
                height="18"
                viewBox="0 0 16 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.1167 2.47866H12.1667C14.0083 2.47866 15.5 3.9207 15.5 5.70109V13.7572C15.5 15.5376 14.0083 16.9796 12.1667 16.9796H3.83333C1.99167 16.9796 0.5 15.5376 0.5 13.7572V5.70109C0.5 3.9207 1.99167 2.47866 3.83333 2.47866H5.025M2.75 6.34558H13.525M8.1 1.02051C7.40833 1.02051 6.85 1.56027 6.85 2.22892C6.85 2.89757 7.40833 3.43733 8.1 3.43733C8.79167 3.43733 9.35 2.89757 9.35 2.22892C9.35 1.56027 8.79167 1.02051 8.1 1.02051ZM5.18333 9.72108C5.18333 9.94665 5 10.1239 4.76667 10.1239C4.53333 10.1239 4.35 9.94665 4.35 9.72108C4.35 9.49551 4.53333 9.31827 4.76667 9.31827C5 9.31827 5.18333 9.49551 5.18333 9.72108ZM8.51667 9.72108C8.51667 9.94665 8.33333 10.1239 8.1 10.1239C7.86667 10.1239 7.68333 9.94665 7.68333 9.72108C7.68333 9.49551 7.86667 9.31827 8.1 9.31827C8.33333 9.31827 8.51667 9.49551 8.51667 9.72108ZM11.85 9.72108C11.85 9.94665 11.6667 10.1239 11.4333 10.1239C11.2 10.1239 11.0167 9.94665 11.0167 9.72108C11.0167 9.49551 11.2 9.31827 11.4333 9.31827C11.6667 9.31827 11.85 9.49551 11.85 9.72108ZM5.18333 12.9435C5.18333 13.1691 5 13.3463 4.76667 13.3463C4.53333 13.3463 4.35 13.1691 4.35 12.9435C4.35 12.7179 4.53333 12.5407 4.76667 12.5407C5 12.5407 5.18333 12.7179 5.18333 12.9435ZM8.51667 12.9435C8.51667 13.1691 8.33333 13.3463 8.1 13.3463C7.86667 13.3463 7.68333 13.1691 7.68333 12.9435C7.68333 12.7179 7.86667 12.5407 8.1 12.5407C8.33333 12.5407 8.51667 12.7179 8.51667 12.9435ZM11.85 12.9435C11.85 13.1691 11.6667 13.3463 11.4333 13.3463C11.2 13.3463 11.0167 13.1691 11.0167 12.9435C11.0167 12.7179 11.2 12.5407 11.4333 12.5407C11.6667 12.5407 11.85 12.7179 11.85 12.9435Z"
                  stroke="black"
                  strokeOpacity="0.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          )}

          <Link
            to={`/events/detail/${event.id}`}
            className="border-l border-[#E5E5E5] px-[25px] flex items-center justify-center"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.23804 1L11.238 6L6.23804 11"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
              <path
                d="M11.2382 6H0.761963"
                stroke="black"
                strokeOpacity="0.5"
                strokeLinecap="round"
              />
            </svg>
          </Link>
        </div>
      </div>
    </div>
  );
};

interface EventCreatedActivityProps {
  activity: Activity;
  showUserInfo?: boolean;
}
