import { User } from 'src/common/types/user';

export const RejectMemberPopup = ({
  onClose,
  member,
  updateApproveHandler,
}: RejectMemberPopupProps) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#0B1444E5] bg-opacity-90 z-[999]">
      <div className="w-[600px] relative bg-white rounded-lg p-14 shadow-lg flex flex-col">
        <button className="absolute right-3 top-3" onClick={onClose}>
          <svg
            width="43"
            height="42"
            viewBox="0 0 43 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 15C0.5 6.71573 7.21573 0 15.5 0H27.5C35.7843 0 42.5 6.71573 42.5 15V27C42.5 35.2843 35.7843 42 27.5 42H15.5C7.21573 42 0.5 35.2843 0.5 27V15Z"
              fill="#E5E5E5"
            />
            <path d="M17.5 17L25.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M25.5 17L17.5 25" stroke="black" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </button>
        <h2 className="text-3xl text-black text-center font-bold font-[sohne-breit] tracking-wide mb-4">
          Are you sure you want to
          <br />
          <span className="relative">
            <span className="absolute top-1 inset-0 bg-[#F7ED00] rounded-full transform scale-y-50 rotate-[-1deg]"></span>
            <span className="relative z-10">decline the membership</span>
          </span>
          <br />
          <span className="relative">
            <span className="absolute top-1 inset-0 bg-[#F7ED00] rounded-full transform scale-y-50 rotate-[1deg]"></span>
            <span className="relative z-10">for {member?.name ?? 'Michael Johnson'}?</span>
          </span>
        </h2>
        <p className="leading-tight text-center text-black text-md text-opacity-70">
          You wont be able to undo this action.
        </p>

        <div className="flex justify-center gap-6 mt-10">
          <button
            className="px-8 py-[21px] text-xs text-center font-medium font-[sohne-breit] tracking-wider text-white bg-[#C51C00] rounded-lg focus:outline-none hover:bg-opacity-90 transition-opacity"
            onClick={() => updateApproveHandler(false)}
          >
            Yes, decline membership
          </button>
          <button onClick={onClose} className="text-black underline text-opacity-70">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

interface RejectMemberPopupProps {
  onClose: () => void;
  member: User | undefined;
  updateApproveHandler: (isApprove: boolean) => void;
}
